export default {
  namespaced: true,
  reseller: {
    title: "reseller | resellers",
    update_password_periodically: "Actualizar Contraseña Periódicamente",
    name: "nombre",
    lastname: "apellido",
    address: "dirección",
    country: "país",
    business: "empresa",
    time_zone: "zona horaria",
    password: "contraseña",
    email: "Correo",
    number_of_cameras: "número de camaras",
    last_login: "último inicio de sesión",
    max_amount_cameras: "número maximo de cámaras (licencias)",
    cm_number_of_cameras: "cámeras registradas en camera manager",
    invoice_period: "periodo de facturación",
    quality: "calidad de video",
  },
};
