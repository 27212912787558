import axios from '@axios'

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    getItems(state) {
      return state.items
    },
    getSelectedItems(state) {
      return state.items.map(permission => ({
        value: permission.id,
        label: permission.description,
      }))
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value
    },
  },
  actions: {
    async getAll({ commit }, params) {
      const { data } = await axios.get('v1/permission', { params })

      if (!data) {
        return []
      }

      commit('setData', data.data)
      return data
    },
    async add(_, params) {
      const { data } = await axios.post('v1/permission', params)

      return data
    },
    async edit(_, { id, params }) {
      const { data } = await axios.put(`v1/permission/${id}`, params)

      return data
    },
    async delete(_, id) {
      const { data } = await axios.delete(`v1/permission/${id}`)

      return data
    },
    clear({ commit }) {
      commit('setData', [])
    }
  },
}
