export default {
  namespaced: true,
  vxg_master_account: {
    title: "conta mestre",
    name: "Nome",
    description: "Descrição",
    api_server: "Servidor api",
    api_key: "Chave api",
    number_cameras: "Número de câmeras"
  },
};
