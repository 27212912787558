export default {
  namespaced: true,
  chat: {
    error: "Você não tem um usuário de bate-papo",
    new_message: "Novo mensagem de {user}",
    group_name: "Nome do grupo",
    users: "Usuários",
    chats: "Chats",
    contacts: "Contatos",
    chats_group: "Grupo de Chats",
    start_conversation: "Iniciar conversa",
    send: "Enviar"
  }
}
