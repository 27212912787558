export default {
  namespaced: true,
  swift_collector: {
    title: "Swift Gateways",
    id_external: "id external",
    first_contact_time: "first contact time",
    last_contact_time: "last contact time",
    guid: "guid",
    manufacturer: "manufacturer",
    model: "model",
    hardware_revision: "hardware revision",
    software_revision: "software revision",
    serial_number: "serial number",
    name: "name",
    description: "description",
    latitude: "latitude",
    longitude: "longitude",
    elevation: "elevation",
    connection_type: "connection type",
    connection_pref: "connection pref",
    network: "network",
    signal_strength: "signal strength",
    is_new: "is new",
    ethernet_mac_address: "ethernet mac address",
    wifi_mac_address: "wifi mac address",
    ip_address: "ip address",
    wifi: "wifi",
    ble: "ble",
    cell: "cell",
    gps: "gps",
    radio_gen2: "radio gen2",
    ble_gen3: "ble gen3",
    zigbee: "zegbee",
    zwave: "zwave",
    radio: "radio",
    id_swift_account: "id swift account",
    id_unit: "id unit",
  },
};
