export default {
  namespaced: true,
  log_microservice: {
    title: "Log microservice",
    client_host: "client host",
    provider: "provider",
    level: "level",
    message: "message id",
    log_message: "log message",
    description: "description",
    path: "path",
    resource: "resource",
    difference: "difference",
    tenant_id: "tenant",
    user_id: "user",
    action: "action",
    start_date: "start date",
    end_date: "end date",
    The_start_date_value_is_required_for_filter_by_date: "The start date value is required for filter by date",
    The_end_date_value_is_required_to_filter_by_date: "The end date value is required to filter by date",
  },
};
