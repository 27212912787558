export default {
  namespaced: true,
  bridge: {
    bridge_cameras:"Cámaras de Bridge",
    title: "bridge | bridges",
    name: "nombre",
    number_of_cameras: "numero de cámaras",
    video_status: "status video",
    ip: "IP",
    accounts: "Cuentas",
  },
};
