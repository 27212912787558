import axios from "@axios";
import store from "@/store";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    getItems(state) {
      return state.items;
    },
    getSelectedItems(state) {
      return state.items.map((preset) => ({
        value: preset.id,
        label: preset.name,
      }));
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value;
    },
  },
  actions: {
    async getAll({ commit }, params) {
      const response = await axios.get("v1/preset", {
        params,
        responseType: params?.action == "export" ? "blob" : "json",
      });

      if (!response.data) {
        return [];
      }
      if (params?.action == "export") {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        const { t } = useI18nUtils();
        const notification = {
          id: response.headers["content-disposition"].split("filename=")[1],
          progress: 100,
          completed: true,
          view: "Presets",
          url: url,
          download: response.headers["content-disposition"].split("filename=")[1],
          title: "Reporte de Presets",
          body: "Descarga completa",
          error: false,
          timestamp:
            new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(),
        };
        store.dispatch("grid/updateDownloadNotification", notification);
      } else {
        commit("setData", response.data.data);
        return response.data;
      }
    },
    async add(_, params) {
      const { data } = await axios.post("v1/preset", params);

      return data;
    },
    async edit(_, { id, params }) {
      const { data } = await axios.put(`v1/preset/${id}`, params);

      return data;
    },
    async delete(_, id) {
      const { data } = await axios.delete(`v1/preset/${id}`);

      return data;
    },
    clear({ commit }) {
      commit("setData", []);
    },
  },
};
