export default {
  namespaced: true,
  camera_proxy: {
    title: "camera proxy | cameras proxy",
    id: "camera proxy id",
    rtsp_available: "Activar transmisión RTSP",
    last_connection: "última conexión",
    assigned: "asignado"
  },
};
