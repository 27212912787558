export default {
  namespaced: true,
  alarm: {
    alarm_id: "Alarm ID",
    description: "Descripción",
    title: "Alarma | Alarmas",
    folioCAD: "Folio CAD",
    statusCAD: "Status CAD",
    reaction_patrol: "Reaction patrol",
    alarm_type: "Tipo de alarma",
    status: "Estatus",
    attending: "En Atención",
    attended: "Atendidas",
    waiting: "En Espera",
    canceled: "Canceladas",
    all: "Todas",
    start_date_filter: "Fecha Inicio",
    end_date_filter: "Fecha Fin",
    date_filter: "Filtro de Fecha",
    new_alarms: "Nuevas alarmas",
    alarms: "Alarmas",
    subsystem_action_modal: {
      send_alarm: "¿Está seguro de enviar la alarma?",
      notify_c5_cdmx: "Se enviará la notificación al CAD de C5 de la CDMX, ¿Estás seguro?",
      notify_c5_jal: "Se enviará la notificación al CAD de C5 de Jalisco, ¿Estás seguro?",
      notify_c5_edomexe: "Se enviará la notificación al CAD de C5 del Estado de México, ¿Estás seguro?",
      create_vitual_alarm: "Se creará una alarma virtual, ¿Estás seguro?",
      comments: "Comentarios",
      level_alarm: "Nivel de Alarma",
    },
  },
};
