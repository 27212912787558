export default [
  {
    path: "/inicio",
    name: "home",
    meta: {
      resource: "Home",
      action: "read",
    },
    component: () => import("@/views/pages/index.vue"),
  },
  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/pages/login.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/auth",
    name: "auth-login-query",
    component: () => import("@/views/pages/loginToken.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/error/Error404.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      action: "read",
    },
  },
  {
    path: "/center",
    name: "center",
    meta: {
      resource: "Center",
      action: "read",
    },
    component: () => import("@/views/center/Index.vue"),
  },
  {
    path: "/sector",
    name: "sector",
    meta: {
      resource: "Sector",
      action: "read",
    },
    component: () => import("@/views/sector/Index.vue"),
  },
  {
    path: "/camera_manager/master_account",
    name: "master_account",
    meta: {
      resource: "CameraManager",
      action: "read_masters_accounts",
    },
    component: () => import("@/views/camera_manager/master_account/Index.vue"),
  },
  {
    path: "/camera_manager/reseller/:id_master_account?",
    name: "reseller",
    meta: {
      resource: "CameraManager",
      action: "read_resellers",
    },
    component: () => import("@/views/camera_manager/reseller/Index.vue"),
  },
  {
    path: "/camera_manager/camera/:id_reseller?",
    name: "camera",
    meta: {
      resource: "CameraManager",
      action: "read",
    },
    component: () => import("@/views/camera_manager/camera/Index.vue"),
  },
  {
    path: "/bridge/camera/:id_bridge?",
    name: "bridge_camera",
    meta: {
      resource: "Bridge",
      action: "read",
    },
    component: () => import("@/views/bridge/camera/Index.vue"),
  },
  {
    path: "/bridge/bridge/:id_account?",
    name: "bridge",
    meta: {
      resource: "Bridge",
      action: "read_accounts",
    },
    component: () => import("@/views/bridge/bridge/Index.vue"),
  },
  {
    path: "/bridge/account/:id_master_account?",
    name: "account",
    meta: {
      resource: "Bridge",
      action: "read_bridge",
    },
    component: () => import("@/views/bridge/account/Index.vue"),
  },
  {
    path: "/bridge/master_account",
    name: "bridge_master_account",
    meta: {
      resource: "Bridge",
      action: "read_masters_accounts",
    },
    component: () => import("@/views/bridge/master_account/Index.vue"),
  },
  {
    path: "/tenant/:id_master_tenant?",
    name: "tenant",
    meta: {
      resource: "Tenant",
      action: "read",
    },
    component: () => import("@/views/tenant/Index.vue"),
  },
  {
    path: "/incident/:id_tenant?",
    name: "incident",
    meta: {
      resource: "Incident",
      action: "read",
    },
    component: () => import("@/views/tenant/incident/Index.vue"),
  },
  {
    path: "/chart/incident",
    name: "incident_chart",
    meta: {
      resource: "Incident",
      action: "read_chart",
    },
    component: () => import("@/views/tenant/incident/Charts.vue"),
  },
  {
    path: "/master_tenant",
    name: "master_tenant",
    meta: {
      resource: "MasterTenant",
      action: "read",
    },
    component: () => import("@/views/master_tenant/Index.vue"),
  },
  {
    path: "/bbva/tag",
    name: "bbva_request_tag",
    meta: {
      resource: "Video",
      action: "manage_request_record_video",
    },
    component: () => import("@/views/recording_request/tag/Index.vue"),
  },
  {
    path: "/user",
    name: "user",
    meta: {
      resource: "User",
      action: "read",
    },
    component: () => import("@/views/user/Index.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    meta: {
      resource: "Profile",
      action: "read",
    },
    component: () => import("@/views/profile/Index.vue"),
  },
  /*{
    path: "/router",
    name: "router",
    meta: {
      resource: "Router",
      action: "read",
    },
    component: () => import("@/views/router/Index.vue"),
  },*/
  {
    path: "/permission",
    name: "permission",
    meta: {
      resource: "Permission",
      action: "read",
    },
    component: () => import("@/views/permission/Index.vue"),
  },
  {
    path: "/logs",
    name: "logs",
    meta: {
      resource: "Log",
      action: "read",
    },
    component: () => import("@/views/logs/Index.vue"),
  },
  {
    path: "/action-plan",
    name: "action-plan",
    meta: {
      resource: "DecisionTree",
      action: "read",
    },
    component: () => import("@/views/action_plan/Index.vue"),
  },
  {
    path: "/action-plan/config/:id_action_plan?",
    name: "actionPlanConfig",
    meta: {
      resource: "Command",
      action: "read",
    },
    component: () => import("@/views/action_plan/Config.vue"),
  },
  {
    path: "/unit",
    name: "unit",
    meta: {
      resource: "Unit",
      action: "read",
    },
    component: () => import("@/views/unit/Index.vue"),
  },
  {
    path: "/traffic",
    name: "traffic",
    meta: {
      resource: "Log",
      action: "read",
    },
    component: () => import("@/views/traffic/Index.vue"),
  },
  {
    path: "/omnicloud_camera",
    name: "omnicloud_camera",
    meta: {
      resource: "CameraOmnicloud",
      action: "read",
    },
    component: () => import("@/views/vxg_camera/camera/Index.vue"),
  },
  {
    path: "/presets",
    name: "presets",
    meta: {
      resource: "CameraBridge",
      action: "read_presets",
    },
    component: () => import("@/views/preset/Index.vue"),
  },
  {
    path: "/buttons",
    name: "buttons",
    meta: {
      resource: "Button",
      action: "read",
    },
    component: () => import("@/views/buttons/Index.vue"),
  },
  {
    path: "/button_type",
    name: "button_type",
    meta: {
      resource: "Button",
      action: "read",
    },
    component: () => import("@/views/button_type/Index.vue"),
  },
  {
    path: "/status_cameras",
    name: "status_cameras",
    meta: {
      resource: "CameraManager",
      action: "read_status",
    },
    component: () => import("@/views/status_cameras/Index.vue"),
  },
  {
    path: "/seproban",
    name: "seproban",
    meta: {
      resource: "SeprobanRequest",
      action: "read",
    },
    component: () => import("@/views/seproban/Index.vue"),
  },
  {
    path: "/tokens_omnitag",
    name: "tokens_omnitag",
    meta: {
      resource: "TokenOmnitag",
      action: "read",
    },
    component: () => import("@/views/tokens_omnitag/Index.vue"),
  },
  {
    path: "/alarm/:filter?",
    name: "alarm",
    meta: {
      resource: "Alarm",
      action: "read_history",
    },
    component: () => import("@/views/alarm/Index.vue"),
  },
  {
    path: "/group",
    name: "group",
    meta: {
      resource: "Group",
      action: "read",
    },
    component: () => import("@/views/group/Index.vue"),
  },
  {
    path: "/user_profile/",
    name: "user_profile",
    meta: {
      resource: "User",
      action: "read_profile",
    },
    component: () => import("@/views/user_profile/AccountSetting.vue"),
  },
  {
    path: "/plate_list",
    name: "plate_list",
    meta: {
      resource: "PlateList",
      action: "read",
    },
    component: () => import("@/views/plate_list/Index.vue"),
  },
  {
    path: "/plate",
    name: "plate",
    meta: {
      resource: "Plate",
      action: "read",
    },
    component: () => import("@/views/plate/Index.vue"),
  },
  {
    path: "/lpr_matches",
    name: "lpr_matches",
    meta: {
      resource: "Lpr",
      action: "read_match",
    },
    component: () => import("@/views/lpr_matches/Index.vue"),
  },
  {
    path: "/luxriot_master_accounts",
    name: "luxriot_master_accounts",
    meta: {
      resource: "Lpr",
      action: "read_match",
    },
    component: () => import("@/views/luxriot/luxriot_master_accounts/Index.vue"),
  },
  {
    path: "/luxriot_cameras",
    name: "luxriot_cameras",
    meta: {
      resource: "Lpr",
      action: "read_match",
    },
    component: () => import("@/views/luxriot/luxriot_cameras/Index.vue"),
  },
  {
    path: "/facial_recognition/:person_id?",
    name: "facial_recognition",
    meta: {
      resource: "FaceRecognition",
      action: "read_match",
    },
    component: () => import("@/views/facial_recognition/Index.vue"),
  },
  {
    path: "/incident_list",
    name: "incident_list",
    meta: {
      resource: "Incident",
      action: "read",
    },
    component: () => import("@/views/incident_list/Index.vue"),
  },
  {
    path: "/swift_account",
    name: "swift_account",
    meta: {
      resource: "Swift",
      action: "read_account",
    },
    component: () => import("@/views/swift_account/Index.vue"),
  },
  {
    path: "/swift_collector",
    name: "swift_collector",
    meta: {
      resource: "Swift",
      action: "read_collector",
    },
    component: () => import("@/views/swift_collector/Index.vue"),
  },
  {
    path: "/swift_sensor",
    name: "swift_sensor",
    meta: {
      resource: "Swift",
      action: "read_sensor",
    },
    component: () => import("@/views/swift_sensor/Index.vue"),
  },
  // Layout builder
  {
    path: "/layouts",
    name: "layouts",
    meta: {
      resource: "Layouts",
      action: "read",
    },
    component: () => import("@/views/layout_builder/Index.vue"),
  },
  {
    path: "/layouts/config-layout/:id_layout?",
    name: "configLayout",
    meta: {
      resource: "Layouts",
      action: "read",
    },
    component: () => import("@/views/layout_builder/layoutConfig.vue"),
  },
  {
    path: "/alarm-views",
    name: "alarm_views",
    meta: {
      resource: "Layouts",
      action: "read",
    },
    component: () => import("@/views/alarm_views/Index.vue"),
  },
  {
    path: "/alarm-views/config-view/:id_view?",
    name: "configAlarmView",
    meta: {
      resource: "Layouts",
      action: "read",
    },
    component: () => import("@/views/alarm_views/Config.vue"),
  },
  {
    path: "/video-watermarks",
    name: "video_watermarks",
    meta: {
      resource: "Layouts",
      action: "read",
    },
    component: () => import("@/views/video_watermarks/Index.vue"),
  },
  {
    path: "/video-watermarks/config/:id_video_watermark?",
    name: "configVideoWatermark",
    meta: {
      resource: "Layouts",
      action: "read",
    },
    component: () => import("@/views/video_watermarks/Config.vue"),
  },
  {
    path: "/device-tags",
    name: "device_tags",
    meta: {
      resource: "Tenant",
      action: "read",
    },
    component: () => import("@/views/device_tags/Index.vue"),
  },
  {
    path: "/token",
    name: "token",
    meta: {
      resource: "Token",
      action: "read",
    },
    component: () => import("@/views/token/Index.vue"),
  },
  {
    path: "/recording_request",
    name: "recording_request",
    meta: {
      resource: "Video",
      action: "request_record_video",
    },
    component: () => import("@/views/recording_request/recording/Index.vue"),
  },
  {
    path: "/show_recording/:id_recording",
    name: "show_recording",
    meta: {
      resource: "Video",
      action: "request_record_video",
    },
    component: () => import("@/views/recording_request/show_recording/Index.vue"),
  },
  {
    path: "/alarm_comment",
    name: "alarm_comment",
    meta: {
      resource: "Recording",
      action: "read",
    },
    component: () => import("@/views/alarm_comment/Index.vue"),
  },
  {
    path: "/user_cameras",
    name: "user_cameras",
    meta: {
      resource: "User",
      action: "update_cameras",
    },
    component: () => import("@/views/user_cameras/Index.vue"),
  },
  {
    path: "/group_cameras",
    name: "group_cameras",
    meta: {
      resource: "Group",
      action: "update_cameras",
    },
    component: () => import("@/views/group_cameras/Index.vue"),
  },
  {
    path: "/plane/:id",
    name: "plane",
    meta: {
      resource: "FaceRecognition",
      action: "read_match",
    },
    component: () => import("@/views/plane/Index.vue"),
  },
  {
    path: "/users_app",
    name: "users_app",
    meta: {
      resource: "UsersApp",
      action: "read_users_app",
    },
    component: () => import("@/views/users_app/Index.vue"),
  },
  {
    path: "/reset_password/:token/:id",
    name: "reset_password",
    meta: {
      layout: "full",
      resource: "Auth",
      action: "read",
    },
    component: () => import("@/views/users_app/ResetPassword.vue"),
  },
  {
    path: "/person_list",
    name: "person_list",
    meta: {
      resource: "PersonList",
      action: "read",
    },
    component: () => import("@/views/person_list/Index.vue"),
  },
  {
    path: "/access_devices",
    name: "access_devices",
    meta: {
      resource: "AccessDevices",
      action: "read",
    },
    component: () => import("@/views/access_devices/Index.vue"),
  },
  {
    path: "/safr_events",
    name: "safr_events",
    meta: {
      resource: "PersonList",
      action: "read",
    },
    component: () => import("@/views/safr/safr_events/Index.vue"),
  },
  {
    path: "/safr_groups",
    name: "safr_groups",
    meta: {
      resource: "PersonList",
      action: "read",
    },
    component: () => import("@/views/safr/safr_groups/Index.vue"),
  },
  {
    path: "/safr_master_accounts",
    name: "safr_master_accounts",
    meta: {
      resource: "PersonList",
      action: "read",
    },
    component: () => import("@/views/safr/safr_master_accounts/Index.vue"),
  },
  {
    path: "/safr_cameras",
    name: "safr_cameras",
    meta: {
      resource: "PersonList",
      action: "read",
    },
    component: () => import("@/views/safr/safr_cameras/Index.vue"),
  },
  {
    path: "/safr_people",
    name: "safr_people",
    meta: {
      resource: "PersonList",
      action: "read",
    },
    component: () => import("@/views/safr/safr_people/Index.vue"),
  },
  {
    path: "/safr_access_clearances",
    name: "safr_access_clearances",
    meta: {
      resource: "PersonList",
      action: "read",
    },
    component: () => import("@/views/safr/safr_access_clearances/Index.vue"),
  },
  {
    path: "/workflow",
    name: "workflow",
    meta: {
      resource: "Workflow",
      action: "read",
    },
    component: () => import("@/views/workflow/Index.vue"),
  },
  {
    path: "/schedule_task",
    name: "schedule_task",
    meta: {
      resource: "ScheduleTask",
      action: "read",
    },
    component: () => import("@/views/schedule_task/Index.vue"),
  },
  {
    path: "/user_shared_tenant",
    name: "user_shared_tenant",
    meta: {
      resource: "User",
      action: "update_cameras",
    },
    component: () => import("@/views/user_shared_tenant/Index.vue"),
  },
  {
    path: "/group_shared_tenant/:id_group/:group",
    name: "group_shared_tenant",
    meta: {
      resource: "Group",
      action: "update_shared_tenant",
    },
    component: () => import("@/views/group_shared_tenant/Index.vue"),
  },
  {
    path: "/iotcomms_master_account",
    name: "iotcomms_master_account",
    meta: {
      resource: "IotcommsMasterAccount",
      action: "read",
    },
    component: () => import("@/views/iotcomms/master_account/Index.vue"),
  },
  {
    path: "/type_device_interphone",
    name: "type_device_interphone",
    meta: {
      resource: "TypeDeviceInterphone",
      action: "read",
    },
    component: () => import("@/views/iotcomms/type_device_interphone/Index.vue"),
  },
  {
    path: "/device_interphone",
    name: "device_interphone",
    meta: {
      resource: "DeviceInterphone",
      action: "read",
    },
    component: () => import("@/views/iotcomms/device_interphone/Index.vue"),
  },
  {
    path: "/sip_call",
    name: "sip_call",
    meta: {
      resource: "SipCall",
      action: "read",
    },
    component: () => import("@/views/sip_call/Index.vue"),
  },
  {
    path: "/type_alarm",
    name: "type_alarm",
    meta: {
      resource: "DeviceInterphone",
      action: "read",
    },
    component: () => import("@/views/alarm/type_alarm/Index.vue"),
  },
  {
    path: "/alarm_level",
    name: "alarm_level",
    meta: {
      resource: "AlarmLevel",
      action: "read",
    },
    component: () => import("@/views/alarm/alarm_level/Index.vue"),
  },
  {
    path: "/command",
    name: "command",
    meta: {
      resource: "Command",
      action: "read",
    },
    component: () => import("@/views/command/Index.vue"),
  },
  {
    path: "/command/custom/:id_command?",
    name: "customCommand",
    meta: {
      resource: "Command",
      action: "read",
    },
    component: () => import("@/views/command/Config.vue"),
  },
  {
    path: "/command/logs",
    name: "commandLogs",
    meta: {
      resource: "Command",
      action: "read",
    },
    component: () => import("@/views/command/logs/Index.vue"),
  },
  {
    path: "/action-plan/logs",
    name: "actionPlanLogs",
    meta: {
      resource: "ActionPlan",
      action: "read",
    },
    component: () => import("@/views/action_plan/logs/Index.vue"),
  },
  {
    path: "/region",
    name: "region",
    meta: {
      resource: "Region",
      action: "read",
    },
    component: () => import("@/views/region/Index.vue"),
  },
  {
    path: "/log_incident",
    name: "log_incident",
    meta: {
      resource: "LogIncident",
      action: "read",
    },
    component: () => import("@/views/tenant/log_incident/Index.vue"),
  },
  {
    path: "/external_token/:id_user",
    name: "external_token",
    meta: {
      resource: "ExternalToken",
      action: "read",
    },
    component: () => import("@/views/user/external_token/Index.vue"),
  },
  {
    path: "/external_user/:id_user",
    name: "external_user",
    meta: {
      resource: "ExternalUser",
      action: "read",
    },
    component: () => import("@/views/user/external_user/Index.vue"),
  },
  {
    path: "/server",
    name: "server",
    meta: {
      resource: "Intelexvision",
      action: "read",
    },
    component: () => import("@/views/intelexvision/server/Index.vue"),
  },
  {
    path: "/unit-groups",
    name: "unit_groups",
    meta: {
      resource: "UnitGroups",
      action: "read",
    },
    component: () => import("@/views/unit_groups/Index.vue"),
  },
  {
    path: "/unit-groups/config/:id_units_group?",
    name: "unit_groups_config",
    meta: {
      resource: "UnitGroups",
      action: "read",
    },
    component: () => import("@/views/unit_groups/Config.vue"),
  },
  {
    path: "/intelexvision_alarm_type",
    name: "intelexvision_alarm_type",
    meta: {
      resource: "Intelexvision",
      action: "read",
    },
    component: () => import("@/views/intelexvision/alarm_type/Index.vue"),
  },
  {
    path: "/analytic_record",
    name: "analytic_record",
    meta: {
      resource: "Intelexvision",
      action: "read",
    },
    component: () => import("@/views/intelexvision/analytic_record/Index.vue"),
  },
  {
    path: "/omnicloud_master_account",
    name: "omnicloud_master_account",
    meta: {
      resource: "OmnicloudMasterAccount",
      action: "read",
    },
    component: () => import("@/views/vxg_camera/master_account/Index.vue"),
  },
  {
    path: "/countries",
    name: "countries",
    meta: {
      resource: "Countries",
      action: "read",
    },
    component: () => import("@/views/countries/Index.vue"),
  },
  {
    path: "/luna_master_account",
    name: "luna_master_account",
    meta: {
      resource: "LunaMasterAccount",
      action: "read",
    },
    component: () => import("@/views/luna/master_account/Index.vue"),
  },
  {
    path: "/luna_list",
    name: "luna_list",
    meta: {
      resource: "LunaList",
      action: "read",
    },
    component: () => import("@/views/luna/list/Index.vue"),
  },
  {
    path: "/luna_face",
    name: "luna_face",
    meta: {
      resource: "LunaFace",
      action: "read",
    },
    component: () => import("@/views/luna/face/Index.vue"),
  },
  {
    path: "/alarm_purge",
    name: "alarm_purge",
    meta: {
      resource: "PurgeAlarm",
      action: "read",
    },
    component: () => import("@/views/alarm/purge_alarm/Index.vue"),
  },
  {
    path: "/video/:alarm/:token",
    name: "video",
    component: () => import("@/views/pages/Video.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/stream",
    name: "stream",
    component: () => import("@/views/pages/Stream.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/schedule_alarm",
    name: "schedule_alarm",
    meta: {
      resource: "Schedule_Alarm",
      action: "read",
    },
    component: () => import("@/views/schedule_alarm/Index.vue"),
  },
  {
    path: "/dahua_dvr",
    name: "dahua_dvr",
    meta: {
      resource: "DahuaDvr",
      action: "read",
    },
    component: () => import("@/views/dahua/dvr/Index.vue"),
  },
  {
    path: "/dahua_camera",
    name: "dahua_camera",
    meta: {
      resource: "DahuaCamera",
      action: "read",
    },
    component: () => import("@/views/dahua/camera/Index.vue"),
  },
  {
    path: "/density_master_account",
    name: "density_master_account",
    meta: {
      resource: "DensityMasterAccount",
      action: "read",
    },
    component: () => import("@/views/density_master_account/Index.vue"),
  },
  {
    path: "/density_devices",
    name: "density_device",
    meta: {
      resource: "DensityDevice",
      action: "read",
    },
    component: () => import("@/views/density_device/Index.vue"),
  },
  {
    path: "/detection_event",
    name: "detection_event",
    meta: {
      resource: "DetectionEvent",
      action: "read",
    },
    component: () => import("@/views/detection_event/Index.vue"),
  },
  {
    path: "/omniview_reseller",
    name: "omniview_reseller",
    meta: {
      resource: "OmniviewReseller",
      action: "read",
    },
    component: () => import("@/views/omniview_reseller/Index.vue"),
  },
  {
    path: "/omniview_reseller_assignment",
    name: "omnia_reseller_assignment",
    meta: {
      resource: "OmniviewReseller",
      action: "read",
    },
    component: () => import("@/views/omniview_reseller/camera_assignment/Index.vue"),
  },
  {
    path: "/municipality",
    name: "municipality",
    meta: {
      resource: "Municipality",
      action: "read",
    },
    component: () => import("@/views/municipality/Index.vue"),
  },
  {
    path: "/recording_request_stream",
    name: "recording_request_stream",
    component: () => import("@/views/pages/RecordingRequestStream.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/luna_facestream",
    name: "luna_facestream",
    meta: {
      resource: "LunaFacestream",
      action: "read",
    },
    component: () => import("@/views/luna/facestream/Index.vue"),
  },
  {
    path: "/generic_devices",
    name: "generic_devices",
    meta: {
      resource: "GenericDevice",
      action: "read",
    },
    component: () => import("@/views/generic_devices/devices/Index.vue"),
  },
  {
    path: "/microservice_logs",
    name: "microservice_logs",
    meta: {
      resource: "MicroserviceLogs",
      action: "read",
    },
    component: () => import("@/views/microservice_logs/Index.vue"),
  },
  {
    path: "/face_detection",
    name: "face_detection",
    meta: {
      resource: "LunaList",
      action: "read",
    },
    component: () => import("@/views/luna/face_detection/Index.vue"),
  },

];
