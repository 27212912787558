export default {
    namespaced: true,
    detection_event: {
      title: "evento de detecção|eventos de detecção",
      event_type: "tipo de evento",
      event_created_at: "Evento criado em",
      provider: "fornecedor",
      data: "facto",
      metadata:"metadados",
      media: "imagem",
    },
  };
  