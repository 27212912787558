import { SLOT_TYPES } from "@/config/layoutConfig";

export default {
  namespaced: false,
  base: {
    id: "ID",
    iot: "IOT",
    downloads: "descargas",
    download: "descargar",
    remove_all: "eliminar todo",
    Tag: "Tag",
    BBVA: "BBVA",
    "Camera Manager": "Camera Manager",
    Menu: "Menú",
    "Master Accounts": "Cuentas Maestras",
    Accounts: "Cuentas",
    Bridges: "Bridges",
    Resellers: "Resellers",
    Tenants: "Tenants",
    "All Tenants": "Todos los Tenants",
    "Master Tenants": "Master Tenants",
    Cameras: "Cámaras",
    "EE VMS": "EE VMS",
    Incidents: "Incidentes",
    "Charts Incidents": "Gráficas Incidentes",
    Users: "Usuarios",
    "All Users": "Todos los Usuarios",
    Profiles: "Perfiles",
    Permissions: "Permisos",
    Units: "Units",
    "All Units": "Todas las Instalaciones",
    Routers: "Routers",
    VaaS: "VaaS",
    VOIP: "VoIP",
    omnicloud: "Omnicloud",
    analytics: "Analiticos",
    Description: "Descripción",
    active: "activo",
    no_record_found: "ningún registro encontrado",
    filter: "filtro",
    filters: "filtros",
    downloads: "descargas",
    frame: "marco",
    name: "nombre",
    home: "inicio",
    no: "no",
    yes: "sí",
    email: "correo",
    video: "video",
    table: {
      show: "mostrar",
      of: "de",
      entries: "registros",
      search: "Buscar",
      showing: "mostrando del {from} al {to} de un total de {of} registros",
      total_assigned: "camaras asignadas de este sector {total_assigned}",
    },
    date: {
      created_at: "fecha de creación",
      updated_at: "fecha de actualización",
      expired_at: "fecha de expiración",
    },
    shared_tenants: {
      title: "tenant compartido | tenants compartidos",
    },
    types_alarms: {
      title: "tipo de alarma | tipos de alarmas",
      description: "descripción",
      acronym: "acronimo",
      alarms_count: "número of alarmas",
    },
    permissions: {
      title: "permiso | permisos",
      count: "número de permisos",
      add_permission: "agregar permiso",
      description: "descripción",
      shared_tenant_only: "solamente tenant compartido",
      resourse: "recurso",
      active: "activo",
      action: "acción",
      edit_permission: "editar permiso",
    },
    centers: {
      title: "centro | centros",
      add_center: "agregar centro",
      _name: "nombre",
      description: "descripción",
      active: "activo",
      edit_center: "editar centro",
      sectors: "sectores",
    },
    sectors: {
      title: "sector | sectores",
      add_sector: "agregar sector",
      center: "centro",
      town_hall: "alcaldia",
      supplier: "proveedor",
      edit_sector: "editar sector",
    },
    account: {
      title: "cuenta | cuentas",
      _name: "nombre",
      bridge_number: "Número de Bridges",
      online_cameras: "Cámaras Online",
      number_of_cameras: "Número de Cámaras",
      number_of_users: "Número de Usuarios",
      active: "activo",
    },
    master_account: {
      title: "cuenta maestra | cuentas maestras",
      user: "usuario",
      password: "Contraseña",
      client_id: "Id cliente",
      customer_key: "llave cliente",
      reseller_id: "id reseller",
      reseller_number: "Numero de reseller",
      api_key: "Llave de API",
      account_number: "Número de Cuenta",
    },
    bridge: {
      title: "bridge | bridges",
      name: "nombre",
      number_of_cameras: "número de cámaras",
      video_status: "video estatus",
      ip: "IP",
    },
    incident: {
      title: "incidente | incidentes",
      add_incident: "Agregar Incidente",
      name: "nombre",
      alarm_type: "tipo de alarma",
      colour: "color",
      alarm: "alarma",
      priority: "prioridad",
      resolution: "resolución",
      frames_for_second: "frames por sergundo",
      live_video_time: "tiempo de video en vivo",
      edit_incident: "Editar Incidente",
    },

    buttons: {
      title: "botón | botones",
      all: "todos los botones",
      button_type: "tipo de botón | tipos de botón",
      description: "descripción",
      divice_id: "identificación del dispositivo",
      divice_type_id: "identificación del tipo de dispositivo",
    },
    status_cameras: {
      name: "status de cámaras",
      _name: "nombre",
      camera_id: "id de camara",
      online: "online",
      recording_on_cloud: "grabando en la nube",
      recording_on_sd: "grabando en sd",
      audio_enabled: "Audio habilitado",
      password_known: "contraseña conocida",
      password_status: "estado de la contraseña",
      firmware_status: "estado del firmware",
      connection_type: "tipo de conexión",
      last_connection_result: "resultado de la última conexión",
      update_the_status_of_the_cameras_with_the_highest_request_for_live_video:
        "actualizar estado de las cámaras con mayor peticion de video en vivo",
      update_the_status_of_the_cameras_with_the_highest_recorded_video_request:
        "actualizar estado de las cámaras con mayor peticion de video grabado",
      update_status_of_cameras_that_are_currently_offline:
        "actualizar estado de las cámaras que actualmente estan en offline",
      update_status_of_the_cameras_that_are_currently_online:
        "actualizar estado de las cámaras que actualmente estan en online",
      update_all_cameras_from_camera_manage:
        "actualizar todas las cámaras desde camara manager",
    },
    seproban: {
      title: "SEPROBAN",
      id_alarm: "id alarm",
      path: "path",
      order: "orden de envío",
      is_send: "enviado",
      sent_at: "enviado a las",
    },
    cameras: {
      label: "cámara | cámaras",
    },
    token_omnitag: {
      title: "token omnitag | tokens omnitag",
      token_number: "Número de tokens",
      qr: "QR",
      token: "token",
    },
    lpr: {
      title: "LPR",
    },
    swift: {
      title: "SWIFT",
    },
    plate_list: {
      _name: "lista de placas",
      list: "lista",
      name: "nombre",
      list_type: "tipo de lista",
      colour: "color",
      description: "Descripción",
      renew_items_expiration: "renew items expiration",
    },
    group: {
      title: "grupo | grupos",
      name: "nombre",
      user: "usuario",
      alarm: "alarma",
      tenants: "tenants",
      shared_tenants: "tenants compartidos",
      permissions: "permisos",
      type_alarms: "tipos de alarmas",
      add_group: "agregar grupo",
      edit_group: "editar grupo",
      check_alarms: "marco alarmas video"
    },
    user_profile: {
      general: "general",
      change_password: "cambiar contraseña",
      username: "usuario",
      email: "correo",
      name: "nombre",
      first_name: "apellido paterno",
      second_name: "apellido materno",
      plate_number: "numero de placa",
      password: "contraseña",
      new_password: "nueva contraseña",
      retype_password: "Confirma nueva contraseña",
      retype_password_holder: "escribe la nueva contraseña de nuevo",
      save_changes: "guardar cambios",
      reset: "limpiar",
      upload: "subir",
      choose_holder: "Escoge un archivo o arrastralo",
      allowed: "Permitidos JPG, GIF or PNG. Tamaño máximo 800kB",
      selected: "Archivo seleccionado:",
      _name: "lista de placas",
      notifications: {
        title: "Notifications",
        alarm_notifications: "Mostrar notificaciones de alarmas",
        video_alarm_notify: "Marco de notificación de alarmas en video",
        show_frame_color: "Mostrar marco de color",
      },
    },
    plate: {
      _name: "placas",
      list: "lista",
      active_since: "activo desde",
      activated_until: "activo hasta",
      comments: "comentarios",
      description: "descripción",
      number_of_detections: "número de detecciones",
      last_detection: "última deteccion",
    },
    lpr_matches: {
      _name: "coincidencias LPR",
      date_of_capture: "fecha de captura",
      plate: "placa",
      plate_detected: "placa detectada",
      original_plate: "placa original",
      picture_plate: "imagen de la placa",
      status: "estatus",
      cameras: "camaras",
      country: "país",
      direction: "dirección",
      speed: "velocidad",
      zone_id: "zona id",
      original_city: "ciudad original",
      confidential: "confidencial",
      ocr_image_id: "id de imagen ocr",
      signposted: "señalizado",
      digit_height: "altura de digitos",
      multiplate_rate: "multiplate rate",
      processing_time_ocr: "tiempo de procesamiento ocr",
      kamikaze_alarm: "alarma kamikaze",
      time_in_zone_alarm: "tiempo en alarma de zona",
      alarm_blacklist: "lista negra de alarmas",
      alarm_speed: "velocidad de alarmas",
      prowling_alarm: "prowling alarm",
      vehicle_maker: "marca del vehículo",
      vehicle_model: "modelo del vehículo",
      vehicle_color: "color del vehículo",
      cross_time: "cross time",
      has_exit: "has exit",
      signal_id: "id de señal",
      category: "categoria",
      vehicle_class: "clase del vehiculo",
      region: "region",
      alarm: "alarma",
      edit: "editar coincidencias LPR",
      reliability: "fiabilidad",
      list: "lista",
      isAlarm: "es una alarma",
    },
    facial_recognition: {
      facial_recognition: "Reconocimiento Facial",
      title: "Coincidencias Reconocimiento Facial",
      people_list: "lista de personas",
      facility: "instalacion",
      image: "imagen",
      person_id: "id de la persona",
      name: "nombre",
      age: "edad",
      gender: "genero",
      wears_glasses: "usa gafas",
      capture_blur: "borrocidad de captura",
      capture_darkness: "oscuridad de captura",
      catch_light: "luz de captura",
      capture_saturation: "saturacion de captura",
      captured_emotion: "emocion de captura",
      captured_ethnicity: "etnia capturada",
      catch_percentage: "porcentaje de captura",
      match_percentage: "porcentaje de coincidencia",
    },
    incident_list: {
      title: "Lista de Incidentes",
      name: "nombre",
      users: "usuarios",
      incident_count: "incidentes asociados",
      tenant: "tenant",
      edit_incidents: "Editar Incidentes",
    },
    swift_collector: {
      _name: "Swift Gateways",
      id_external: "id externa",
      first_contact_time: "primera hora de contacto",
      last_contact_time: "última hora de contacto",
      guid: "guía",
      manufacturer: "fabricante",
      model: "modelo",
      hardware_revision: "revisión de hardware",
      software_revision: "revisión de software",
      serial_number: "número de serie",
      name: "nombre",
      description: "descripción",
      latitude: "latitud",
      longitude: "longitud",
      elevation: "elevación",
      connection_type: "tipo de conección",
      connection_pref: "preferencia de conección",
      network: "red",
      signal_strength: "intensidad de señal",
      is_new: "es nuevo",
      ethernet_mac_address: "dirección mac ethernet",
      wifi_mac_address: "dirección mac wifi",
      ip_address: "dirección ip",
      wifi: "wifi",
      ble: "ble",
      cell: "cell",
      gps: "gps",
      radio_gen2: "radio gen2",
      ble_gen3: "ble gen3",
      zigbee: "zegbee",
      zwave: "zwave",
      radio: "radio",
      id_swift_account: "id swift account",
      id_unit: "id unit",
    },
    command: {
      title: "comando | comandos",
      name: "nombre",
      description: "descripción",
    },
    import_excel: {
      _name: "exportar",
    },
    alarm_comment: {
      title: "comentario alarma",
      add_comment: "agregar comentario",
      edit_comment: "editar comentario",
      comment: "comentario",
      alarm_type: "tipo de alarma",
      tenant: "tenant",
      attending: "atendiendo",
      attended: "atendida",
      canceled: "cancelada",
      status: "estatus",
    },
    user_cameras: {
      camera_proxy_user_is_assigned: "asignada",
      unit: "instalación",
      camera_type: "tipo de dispositivo",
      cameraID: "cameraID",
      name: "nombre",
      id_user: "ID user",
      id_account: "ID cuenta",
      assign_all: "asignar todas las camaras",
      remove_all: "quitar todas las camaras",
      assign_all_center: "asignar camaras del centro",
      remove_all_center: "quitar camaras del centro",
    },
    group_cameras: {
      camera_proxy_group_is_assigned: "asignada",
      unit: "instalación",
      camera_type: "tipo de dispositivo",
      cameraID: "cameraID",
      name: "nombre",
      id_user: "ID user",
      id_account: "ID cuenta",
      assign_all: "asignar todas las camaras",
      remove_all: "quitar todas las camaras",
      assign_all_center: "asignar camaras del centro",
      remove_all_center: "quitar camaras del centro",
    },
    users_app: {
      title: "usuarios app",
      name: "nombre",
      first_name: "apellido paterno",
      second_name: "apellido materno",
      email: "correo",
      phone_number: "número teléfonico",
      photo: "Foto",
      height: "estatura",
      complexion: "complexion",
      lips: "labios",
      nose: "nariz",
      eye_size: "tamaño de ojos",
      skin: "tez",
      face_type: "tipo de cara",
      hair_type: "tipo de cabello",
      age: "edad",
      particular_signs: "señas particulares",
      edit: "editar users app",
      add: "agregar users app",
      reset_password: "reestablecer contraseña",
      cancel: "cancelar",
      next: "siguiente",
      new_password: "nueva contraseña",
      write_the_password_again: "vuelva a escribir la contraseña",
    },
    layout: {
      title: "layout",
    },
    alarm_view: {
      title: "vista de alarmas",
    },
    video_watermarks: {
      title: "Marca de agua de videos",
    },
    device_tag: {
      title: "etiqueta de dispositivo",
    },
    chart_incident: {
      title: "gráfica de incidentes",
    },
    automation: {
      title: "automatización",
    },
    alarm_workflow: {
      title: "flujo de trabajo de alarmas",
    },
    workflow: {
      add_workflow: "agregar flujo de trabajo",
      edit_workflow: "editar flujo de trabajo",
      workflow: "flujo de trabajo",
      alarm_status: "estatus de alarma",
      frequency: "habilitar frecuencia de la alarma",
      params: "parametros",
      data: "data",
      view: "vista",
      days: "days",
      start_time: "hora inicio",
      end_time: "hora final",
    },
    schedule_task: {
      title: "tarea programada",
      frequency: "frecuencia",
      add_user_views: "Vistas/Tabs",
    },
    plane: {
      title: "planos",
    },
    shared_tenant: {
      name: "nombre",
      permissions: "permisos",
      type_alarms: "tipos de alarma permitidos",
    },
    iotcomms_master_account: {
      title: "cuenta maestra iotcomms | cuentas maestras iotcomms",
      email: "correo",
      password: "contraseña",
      token: "token",
      domain: "dominio",
      number_devices: "número de dispotivos",
    },
    cronEditor: {
      title: "Horario personalizado",
      options: {
        op1: "Cada hora(s)",
        op2: "Diariamente",
        op3: "Semanalmente",
        op4: "Mensualmente",
      },
      every: "Cada",
      hours: "Hora(s)",
      minutes: "Minuto(s)",
      days: "Día(s)",
      day: "Día",
      months: "Mes(es)",
      on_minute: "En el minuto",
      at: "A las",
      on_the: "En el día",
      day_of_every_month: "de cada mes",
      weekdays: {
        sun: "Domingo",
        mon: "Lunes",
        tue: "Martes",
        wed: "Miércoles",
        thu: "Jueves",
        fri: "Viernes",
        sat: "Sábado",
      },
      table: {
        desc: "Descripción",
      },
      btn_add: "Agregar",
      btn_update: "Actualizar",
      errors: {
        err1: "Ya existe un expresión igual",
        err2: "Por favor verifica los campos",
      },
    },
    scheduleOptions: {
      every_minute: {
        name: "Cada minuto",
        desc: "",
      },
      every_two_minutes: {
        name: "Cada dos minutos",
        desc: "",
      },
      every_three_minutes: {
        name: "Cada tres minutos",
        desc: "",
      },
      every_four_minutes: {
        name: "Cada cuatro minutos",
        desc: "",
      },
      every_five_minutes: {
        name: "Cada cinco minutos",
        desc: "",
      },
      every_ten_minutes: {
        name: "Cada diez minutos",
        desc: "",
      },
      every_fifteen_minutes: {
        name: "Cada quince minutos",
        desc: "",
      },
      every_thirty_minutes: {
        name: "Cada treinta minutos",
        desc: "",
      },
      hourly: {
        name: "Cada hora",
        desc: "",
      },
      hourly_at: {
        name: "Cada hora desde",
        desc: "",
      },
      every_two_hours: {
        name: "Cada dos horas",
        desc: "",
      },
      every_three_hours: {
        name: "Cada tres horas",
        desc: "",
      },
      every_four_hours: {
        name: "Cada cuatro horas",
        desc: "",
      },
      every_six_hours: {
        name: "Cada seis horas",
        desc: "",
      },
      daily_at: {
        name: "Diario a",
        desc: "",
      },
      weekly_on: {
        name: "Cada semana a",
        desc: "",
      },
      monthly_on: {
        name: "Cada mes a",
        desc: "",
      },
      last_day_of_month: {
        name: "El ultimó día de cada mes a",
        desc: "",
      },
      custom: {
        name: "Personalizado",
        desc: "",
      },
    },
    type_device_interphone: {
      title: "tipo de dispositivo interfones| tipos de dispositivos interfones",
      type: "tipo",
    },
    device_interphone: {
      title: "dispositivo interfone | dispositivos interfones",
      ha1: "ha1",
      alarm: "alarma",
      device_id: "número",
      password: "contraseña",
      ivr: "IVR",
    },
    slot_types: {
      [SLOT_TYPES.CAM_LIVE]: "Cámara en Vivo",
      [SLOT_TYPES.CAM_PA]: "Pre-Alarma",
      [SLOT_TYPES.CAM_RECORDED]: "Grabación",
      [SLOT_TYPES.ACTION_PLAN]: "Plan de acción",
      [SLOT_TYPES.INFO]: "Información de alarma",
      [SLOT_TYPES.MAP]: "Mapa de alarma",
      [SLOT_TYPES.PLANE]: "Planos de la instalación",
      [SLOT_TYPES.TAG]: "Tag",
      [SLOT_TYPES.UNITS_MAP]: "Mapa de instalaciones",
      [SLOT_TYPES.TIMELINE]: "Timeline",
      [SLOT_TYPES.GPS]: "Geolocalización",
      [SLOT_TYPES.DETECTION_EVENT]: "Eventos de detección",
      [SLOT_TYPES.INTERPHONE]: "Interfono",
      [SLOT_TYPES.FRAME]: "Widget de sitio web",
      [SLOT_TYPES.CHARTS_SDK]: "Widget de gráfico",
      [SLOT_TYPES.CHARTS_SDK_DATA]: "Datos de gráfico",
      [SLOT_TYPES.FR_TRACKING_MAP]: "Widget de mapa de reconocimiento facial",
      [SLOT_TYPES.LPR_TRACKING_MAP]: "Widget de mapa de reconocimiento de matrículas",
      [SLOT_TYPES.AR_TABLE_DATA]: "Tabla de Registro de analíticos",
      [SLOT_TYPES.WEATHER]: "Widget del clima",
      [SLOT_TYPES.FACE_DETECTION]: "Widget de detección de rostros",
      [SLOT_TYPES.WAZE]: "Widget de Waze",
      [SLOT_TYPES.DETECTION_EVENT]: "Widget de detección de eventos",
    },
    live: "En vivo",
    pre_alarm: "Pre-Alarma",
    boolean: {
      y: "Sí",
      n: "No",
    },
    profile: 'Perfil',
    logout: 'Salir',
    search: 'Buscar',
    close: 'Cerrar',
    no_results_found: 'No se han encontrado resultados',
    light_mode: "Modo claro",
    dark_mode: "Modo oscuro",
  },
};
