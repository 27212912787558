export default {
  namespaced: true,
  router: {
    title: "router | routers",
    status: "status",
    description: "Descrição",
    latitude: "latitud",
    longitude: "longuitud",
    velocity: "velocidad",
    direction: "direção",
    CAD_folio: "folio CAD",
    address: "endereço",
    active: "activo",
    notify_cad_c5_cdmx: "notifica CAD C5 CDMX",
    notify_cad_c5_jalisco: "notifica CAD C5 JALISCO",
    notify_cad_c5_edomex: "notifica CAD C5 EDOMEX",
    altitude: "altitud",
    imei: "IMEI",
    totem_key: "totem key",
    totem_id: "id totem",
    ip_vpn: "IP VPN",
    last_log: "último registro",
    has_router: "Tem roteador",
  }
}
