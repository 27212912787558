import axios from '@axios'

export default {
  namespaced: true,
  state: {
    items: [],
    item: []
  },
  getters: {
    getItem(state) {
      return state.item.map(model => ({
        value: model.id,
        label: model.name,
      }))
    },
    getSelectedItems(state) {
      return state.items.map(brand => ({
        value: brand.id,
        label: brand.name,
      }))
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value
    },
    setDataModel(state, value) {
      state.item = value
    },
  },
  actions: {
    async getAll({commit}, params) {
      const {data} = await axios.get('v1/vehicle_brand', {params})

      if (!data) {
        return []
      }

      commit('setData', data.data)
      return data
    },

    async get({commit}, id) {
      const {data} = await axios.get(`v1/vehicle_brand/${id}`)

      if (!data) {
        return []
      }

      commit('setDataModel', data.data.vehicle_models)
      return data
    },
    clear({commit}) {
      commit('setDataModel', [])

    }


  },
}
