import store from '..'
import defaultSideBarsItems from './sidebarsDefaultConfig';

import layout from './layout';
import tabs from './tabs';
import widgets from './widgets';
import sidebars from './sidebars';
import websockets from './websockets';
import downloads from './downloads';
import views from './views';
import units from './units';
import { debounce, reject } from 'lodash';

export default {
  namespaced: true,

  // State =========================================================
  state: {
    
    treeConfig: JSON.parse(localStorage.getItem('treeConfig')) || [],
    visited: JSON.parse(localStorage.getItem('visited')) || [],

    // Layout config
    ...layout.state,

    // Tabs
    ...tabs.state,

    // Sidebar / Bottom bar
    ...sidebars.state,

    // Widgets
    ...widgets.state,

    //downloads notifications
    ...downloads.state,

    // Views
    ...views.state,

    // Units
    ...units.state,

    alarms: []
  },
  getters: {
    
    userLayouts() {
      return store.state.layouts.userLayouts.map((l) => ({
        id: l.id,
        name: l.name,
        grid: l.data,
      }));
    },

    // Layout config
    ...layout.getters,

    // Tabs
    ...tabs.getters,

    ...sidebars.getters,

    // downloads notifications
    ...downloads.getters,

    // Widgets
    ...widgets.getters,

    // Views
    ...views.getters,

    // Units
    ...units.getters,

    getAlarms(state) {
      return state.alarms
    },

    // Subsystem tree config (local storage)
    getAllTrees: state => {
      return state.treeConfig;
    },
    getTreeById: state => id => {
      return state.treeConfig.find(tree => tree.tenantId === id);
    },

    // Visted items
    getVisited: state => {
      return state.visited;
    },
    getVisitedById: state => id => {
      return state.visited.find(item => item.id === id);
    },
  },

  // Mutations =========================================================
  mutations: {
    // Layout config
    ...layout.mutations,

    // Tabs
    ...tabs.mutations,

    // Sidebar / Bottom bar
    ...sidebars.mutations,

    // Widgets
    ...widgets.mutations,

    // Views
    ...views.mutations,

    // Units
    ...units.mutations,

    addNotificationAlarm(state, value) {
      state.alarms = state.alarms.filter(alarm => alarm.id != value.id)
      state.alarms.push(value)
    },
    removeNotificationAlarm(state, value) {
      state.alarms = state.alarms.filter(alarm => alarm.alarmId != value.alarmId)
    },
    removeAllNotificationAlarm(state) {
      state.alarms = []
    },

    // Subsystem tree config (local storage)
    setTreeConfig(state, config) {
      state.treeConfig = config;
    },
    addTree(state, tree) {
      state.treeConfig.push(tree);
    },
    updateTree(state, payload) {
      const config = state.treeConfig.find(item => item.tenantId == payload.tenantId);
      if (payload.entity === 'tenant') {
        config.open = payload.open;
      } else {
        const unit = config.children.find(item => item.unitId == payload.unitId);
        if (unit) {
          unit.open = payload.open;
        } else {
          config.children.push(payload);
        }
      }
    },
    deleteTree(state, tenantId) {
      const index = state.treeConfig.findIndex(item => item.tenantId == tenantId);
      if (index >= 0) {
        state.treeConfig.splice(index, 1);
      }
    },

    // Visted items
    addVisited(state, item) {
      state.visited.push(item);
    },
    removeVisited(state, item) {
      state.visited = state.visited.filter(itm => itm.id !== item.id && itm.type === item.type);
    },
    setVisited(state, visited) {
      state.visited = visited;
    },
    clearVisited(state) {
      state.visited = [];
    },
  },

  // Actions =========================================================
  actions: {
    // General
    initializeStore({ commit }, data) {

      if (data) {
        store.dispatch('grid/setTabs', data.tabs);
        store.dispatch('grid/setActiveTab', data.activeTab);
        store.commit('grid/updateSidebarWidth', data.sideBarWidth);

        if (!data.sideBar?.length && !data.bottomBar?.length) {
          commit('setSideBarItems', defaultSideBarsItems.sideBarItems);
          commit('setBottomBarItems', defaultSideBarsItems.bottomBarItems);
        } else {
          const defaultWidgets = defaultSideBarsItems.sideBarItems.concat(defaultSideBarsItems.bottomBarItems);
          let sideBar = data && data.sideBar ? data.sideBar : defaultSideBarsItems.sideBarItems;
          let bottomBar = data && data.bottomBar ? data.bottomBar : defaultSideBarsItems.bottomBarItems;
          const storedWidgets = sideBar.concat(bottomBar);
          const missingWidgets = [];
          const difference = defaultWidgets.filter(x => !storedWidgets.find(y => x.id === y.id));

          difference.forEach(widget => {
            if (widget.defaultPosition === 'sidebar') {
              sideBar.push(widget);
            } else {
              bottomBar.push(widget);
            }
          });

          commit('setSideBarItems', sideBar);
          commit('setBottomBarItems', bottomBar);
        }
      }
    },

    // Layout config
    ...layout.actions,

    // Tabs
    ...tabs.actions,

    // Sidebars
    ...sidebars.actions,

    // Widgets
    ...widgets.actions,

    // Websockets
    ...websockets.actions,

    // downloads notifications
    ...downloads.actions,

    // Views
    ...views.actions,

    // Units
    ...units.actions,

    // User
    updateUserConfig: debounce(function ({ commit, state }) {
      store.dispatch('user/updateUserConfig', {
        grid: {
          activeTab: state.activeTab,
          tabs: state.tabs,
          sideBar: state.sideBarItems,
          sideBarWidth: state.sidebarWidth,
          bottomBar: state.bottomBarItems,
        }
      })
    }, 1000),

    addNotificationAlarm({ commit, state }, params) {
      commit('addNotificationAlarm', params);
    },
    removeNotificationAlarm({ commit, state }, params) {
      commit('removeNotificationAlarm', params);
    },
    removeAllNotificationAlarm({ commit, state }) {
      commit('removeAllNotificationAlarm');
    },

    // Subsystem tree config (local storage)
    loadTreeConfig({ commit }) {
      const config = localStorage.getItem('treeConfig');
      if (config) {
        commit('setTreeConfig', JSON.parse(config));
      }
    },
    saveTreeConfig({ state }) {
      localStorage.setItem('treeConfig', JSON.stringify(state.treeConfig));
    },
    addTree({ commit, dispatch }, tree) {
      commit('addTree', tree);
      dispatch('saveTreeConfig');
    },
    updateTree({ commit, dispatch }, payload) {
      commit('updateTree', payload);
      dispatch('saveTreeConfig');
    },
    deleteTree({ commit, dispatch }, index) {
      commit('deleteTree', index);
      dispatch('saveTreeConfig');
    },

    // Visited items
    loadVisited({ commit }) {
      const visited = localStorage.getItem('visited');
      if (visited) {
        commit('setVisited', JSON.parse(visited));
      }
    },
    addVisited({ commit, dispatch, state }, item) {
      if (!state.visited.find(v => v.id === item.id)) {
        commit('addVisited', item);
        dispatch('saveVisited');
      }
    },
    removeVisited({ commit, dispatch }, item) {
      commit('removeVisited', item);
      dispatch('saveVisited');
    },
    clearVisited({ commit }) {
      commit('clearVisited');
      localStorage.removeItem('visited');
    },
    saveVisited({ state }) {
      localStorage.setItem('visited', JSON.stringify(state.visited));
    },

    clear({ commit }) {
      commit('setTenants', [])
      commit('setUnits', [])
      commit('setUnitsLoadStatus', false)
      commit('removeAllDownloadNotification', false)
      commit('removeAllNotificationAlarm')
    },
  },
}
