export default {
  namespaced: true,
  actions: {
    name: "ação | ações",
    accept: "aceitar",
    add: "adicionar",
    edit: "editar",
    delete: "eliminar",
    cancel: "cancelar",
    filter: "filtro | filtros",
    update: "atualizar",
    export: "exportar",
    close: "fechar",
    save: "salve",
    save_changes: "salvar alterações",
    send: "mandar",
    download: "baixar",
    reset: "reiniciar",
    drag_drop_1: "Arraste e solte para fazer upload de conteúdo!",
    drag_drop_2: "...ou clique para selecionar um arquivo do seu computador",
    load: "upload",
    refresh: "atualizar",
    next: "próximo",
    previous: "anterior",
    finish: "terminar",
    delete_all: "excluir tudo",
    select_deselect_all: "selecionar/desselecionar tudo",
  },
};
