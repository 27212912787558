export default {
    namespaced: true,
    safr: {
        title: 'SAFR',
        addGroup: 'Agregar grupo',
        editGroup: 'Editar grupo',
        name: 'Nombre',
        enabled: 'Activo',
        groups: 'Grupos',
        people: 'Personas',
        addPeople: 'Agregar persona',
        editPeople: 'Editar persona',
        age: 'Edad',
        photo: 'Imagen',
        gender: 'Genéro',
        options: 'Opciones',
        genderFemale: 'Femenino',
        genderMale: 'Masculino',
        events: 'Eventos',
        event_id: 'Evento',
        source_id: 'Fuente',
        type: 'Tipo',
        date: 'Fecha',
        person: 'Persona',
        similarity: 'Similaridad',
        matches: 'Coincidencias',
        person_match: 'Match con',
        from: 'Desde',
        to: 'Hasta',
        placeholderPerson: 'Selecionar persona',
        placeholderDate1: 'Seleccionar desde',
        placeholderDate2: 'Seleccionar hasta',
        resetFilters: 'Resetear filtros',
        password: 'Contraseña',
        user: 'Usuario',
        tenant: 'Tenants',
        links: 'Enpoints',
        addMasterAccount: 'Agregar cuenta maestra',
        editMasterAccount: 'Editar cuenta maestra',
        placeholderMasterAccount: 'Seleccionar tenant',
        placeholderDescription: 'Ingresar descripción',
        placeholderId: 'Ingresar id',
        placeholderEndpoint: 'Ingresar enpoint',
        placeholderName: 'Ingresa nombre',
        placeholderAge: 'Ingresar edad',
        placeholderGender: 'Ingresa género',
        placeholderGroups: 'Ingresar grupos',
        placeholderImage: 'Ingresar imagen',
        placeholderType: 'Ingresar tipo',
        type: 'Tipo',
        delete: 'Eliminar',
        master_account: 'Cuenta maestra',
        addCamera: 'Agregar cámara',
        editCamera: 'Editar cámara',
        sector: 'Sector',
        center: 'Centro',
        units: 'Instalaciones',
        placeholderSector: 'Ingresar sector',
        placeholderMasterAccount: 'Ingresa cuenta maestra',
        placeholderDirectory: 'Ingresar directorio',
        placeholderTenants: 'Ingresar tenants',
        placeholderCenter: 'Ingresa centro',
        placeholderUnit: 'Ingresar instalación',
        update_from_api: "Actualizar camaras desde API",
        directory: 'Directorio',
        safr_name: 'Cámara safr',
        update_people_from_api: "Actualizar personas desde API",
        token: 'Token',
        accessClearance: 'Autorización de accesso',
        placeholderAccessClearance: 'Ingresar autorización de acceso',
        update_access_clearance_from_api: 'Actualizar autorizaciones de acceso desde API',
        permanent: 'Permanente',
        addAccessClearance: 'Agregar autorización de acceso',
        level: 'Nivel',
        placeholderLevel: 'Ingresar nivel'
    }
}
