export default {
  namespaced: true,
  master_tenants: {
    title: "master tenant | master tenants",
    _name: "Nome",
    number_of_tenants: "Número de Tenants",
    active: "de ativos",
    description: "Descrição",
  },
};
