export default {
  namespaced: true,
  dahua_camera: {
    title: "Câmera Dahua | Câmeras Dahua",
    dahua: 'Dahua',
    name: "nome",
    channel: "canal",
    type: "tipo",
    ip: "IP",
    class: "classe",
    encryption: "criptografia",
    poe: "tem POE?",
    poe_port: "POE porta",
    http_port: "http porta",
    https_port: "https porta",
    port: "porta",
    port_type: "tipo de porta",
    rtsp_port: "RTSP porta",
    serial_number: "número de série",
    user: "usuário",
    dvr_validation: "você precisa selecionar um DVR"
  },
};

