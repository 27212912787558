export default {
    namespaced: true,
    users: {
        title: "usuario | usuarios",
        email: "correo",
        username : "usuario",
        name: "nombre",
        first_name: "primer apellido",
        second_name: "segundo apellido",
        password: "contraseña",
        phone_number: "número teléfonico",
        active: "activo",
        vpn_access: "acceso por vpn",
        last_login: "último inicio de sesión",
        alarms_history: "historial de alarmas",
        allowed_units_live_video_flows: "flujos de video permitidos",
        alarm_history_per_hours: "horas permitidas de historial alarmas",
        alarm_history_per_days: "días permitidos de historial alarmas",
        alarm_history_by_records: "registros permitidos de historial alarmas",
        plate_number: "no. de placa o no. de empleado",
        shared_tenants: "tenants compartidos",
        video_wall: "video wall",
        info: "info",
        saving_info: "guardando la información",
        enabled_chat: "chat habilitado",
        id_country: "id del país"
    },
};
