export default {
  namespaced: true,
  generic_devices: {
    title: 'Dispositivos genéricos',
    status: 'Estado',
    name: 'Nome',
    provider: 'Fornecedor',
    type: 'Tipo',
    idCameraProxy: 'ID Cameras Proxy',
    data: 'Configuração',
    units: 'Instalações',
    add_device: 'Adicionar novo dispositivo',
    edit_device: 'Editar dispositivo',
    device_config: 'Configuração do dispositivo',
    reseller: 'Reseller',
    camera_type: 'Tipo de câmera',
    onvif: 'Onvif',
    form: {
      name: {
        label: 'Nome',
        placeholder: 'Nome',
      },
      provider: {
        label: 'Fornecedor',
        placeholder: 'Fornecedor'
      },
      type: {
        label: 'Tipo',
        placeholder: 'Tipo'
      },
      custom_fields: {
        url: {
          label: 'Url',
          placeholder: 'Url',
        },
        protocol: {
          label: 'Protocolo',
          placeholder: 'Protocolo',
        },
        port: {
          label: 'Porta',
          placeholder: 'Porta',
        },
        user: {
          label: 'Usuário',
          placeholder: 'Usuário',
        },
        password: {
          label: 'Senha',
          placeholder: 'Senha',
        },
        onvif_host: {
          label: 'Ruta Onvif (IP/Hostname)',
          placeholder: 'Ruta Onvif',
        },
        onvif_port: {
          label: 'Porto Onvif',
          placeholder: 'Porto Onvif',
        },
        enabled_onvif_events: {
          label: 'Habilitar eventos Onvif',
          placeholder: 'Habilitar eventos Onvif',
        },
      }
    },
    types: {
      camera: 'Câmara',
      generic_device: 'Dispositivo genérico',
    },

  }
}
