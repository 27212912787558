export default {
  namespaced: true,
  intelexvision: {
    title: "intelexvision",
    host: "host",
    description: "description",
    tenant: "tenant",
    servers: "servers",
 }
}
