export default {
  namespaced: true,
  cm_master_account: {
    title: "master account | master accounts",
    name: "name",
    topic: "message broker topic",
    user: "user",
    password: "password",
    client_id: "client id",
    customer_key: "Client Key",
    reseller_id: "reseller id",
    reseller_number: "reseller number",
    api_key: "API key",
    account_number: "account number"
  },
};
