export default {
  namespaced: true,
  dahua_camera: {
    title: "Cámara Dahua | Cámaras Dahua",
    dahua: 'Dahua',
    name: "nombre",
    channel: "canal",
    type: "tipo",
    ip: "IP",
    class: "clase",
    encryption: "encriptación",
    poe: "¿Tiene POE?",
    poe_port: "puerto POE",
    http_port: "puerto http",
    https_port: "puerto https",
    port: "puerto",
    port_type: "tipo de puerto",
    rtsp_port: "puerto RTSP",
    serial_number: "número de serie",
    user: "usuario",
    dvr_validation: "tienes que seleccionar un DVR"
  },
};

