export default {
  namespaced: true,
  swift_account: {
    title: "swift account",
    id_external: "id external",
    name: "name",
    is_enabled: "is enabled",
    is_monitored: "is monitored",
    retention: "retention",
    brand_id: "brand id",
    language: "language",
    creation_time: "creation time",
    id_parent: "id parent",
  },
}
