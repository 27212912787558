export default {
  namespaced: true,
  commands: {
    title: "Comando personalizado",
    title_main: "Comando personalizado",
    action_add: 'Crear comando personalizado',
    action_update: 'Actualizar comando personalizado',
    base_commands: 'Comandos base',
    custom_commands: 'Comandos personalizados',
    fields: {
      name: {
        label: 'Nombre del comando',
        placeholder: 'Nombre del comando'
      },
      description: {
        label: 'Descripción del comando',
        placeholder: 'Descripción del comando'
      },
      alarm_compatible: {
        label: "¿Es compatible con alarmas?"
      },
      requirements: {
        label: 'Requerimientos (opcional)'
      }
    },
    messages: {
      success_created: 'Comando personalizado creada correctamente',
      success_updated: 'Comando personalizado actualizado correctamente',
      layout_empty: 'No se puede guardar un comando vacío',
      connections_empty: 'No están permitido nodos sin conexiones',
      connections_qty_exceeded: 'No están permitido nodos con múltiples conexiones',
      incomplete_node_config: 'Configuración incompleta en los siguientes nodos',
    },
    btns: {
      delete_image: 'Eliminar imagen',
      create: 'Guardar',
      update: 'Actualizar',
      custom: 'Personalizado',
      base: 'Estándar',
    },
    requirements: {
      cols: {
        col1: 'Tipo',
        col2: 'Selec. Múltiple'
      }
    },
    list: {
      enable_unit_cameras_do: {
        name: "Activar la salida digital de las cámaras",
        desc: "Activa la salida digital de las cámaras de la instalación",
      },
      enable_speaker: {
        name: "Activar DO1 del router",
        desc: "Activa el salida digital del router"
      },
      show_user_view: {
        name: "Mostrar vista/tab",
        desc: "Muestra una vista/tab en SBO"
      },
      delay: {
        name: "Retraso",
        desc: "",
        hint: "Cantidad de tiempo en segundos:"
      },
      active_digital_output_camera: {
        name: "Activar salida digital de las cámaras (old)",
        desc: "Activa salida digital de las cámaras",
        hint: "",
      },
      activate_digital_output_camera: {
        name: "Activar salida digital de las cámaras",
        desc: "Activa salida digital de las cámaras",
        hint: "",
      },
      deactivate_digital_output_camera: {
        name: "Desactivar salida digital de las cámaras",
        desc: "Desactiva salida digital de las cámaras",
        hint: "",
      },
      deactivate_sound_router: {
        name: "Desactivar DO1 del router",
        desc: "Desactivar la salida digital del router",
        hint: "",
      },
      interphone_call: {
        name: "Llamada de interfon",
        desc: "Hace una llamda de interfon"
      },
      active_digital_output_rtu_router: {
        name: "Activar salida digital RTU router",
        desc: "Activa una salida digital especifica del router",
        hint: "",
      },
      density_shoot: {
        name: "Efectuar disparo real de neblina",
        desc: "Efectua un disparo real de neblina desde el dispositvo especificado",
      },
      density_test_shoot: {
        name: "Efectuar disparo de prueba de neblina",
        desc: "Efectua un disparo de prueba de neblina desde el dispositvo especificado",
      },
      notification_command: {
        name: "Envia una notificación",
        desc: "Envia una notificación desde diferentes maneras como Mensaje de Texto, Webhook, Correo Electronico, Whatsapp...",
      },
      show_action_plan: {
        name: "Mostrar un plan de acción",
        desc: "Muestra el plan de acción específicado",
      },
      send_c5_edomex: {
        name: "Envía alarma a C5 EDOMEX",
        desc: "Envía información de una alarma a C5 EDOMEX",
      },
      camera_round: {
        name: "Ronda de cámaras",
        desc: "Comando para iniciar una ronda de cámaras",
      },
      assignment_cameras: {
        name: "Asignación de camaras",
        desc: "Asignación de camaras",
      },
    },
    form: {
      units_select: {
        label: 'Selecciona las instalaciones para este nodo:'
      },
      cameras_select: {
        label: 'Selecciona las cámaras para este nodo:'
      },
      user_views_select: {
        label: 'Selecciona las vistas/tabs para este nodo:'
      },
      users_select: {
        label: 'Selecciona los usuarios para este nodo:'
      },
      interphone_from: {
        label: 'Seleccione el teléfono de origen:'
      },
      interphone_to: {
        label: 'Seleccione el teléfono de destino:'
      },
      density_device: {
        label: 'Seleccione el dispositivo Density:'
      },
      type_notificaction: {
        label: 'Tipo de notificación:'
      },
      type_verb_webhook: {
        label: 'Verbo RESTful:'
      },
      url1: {
        label: 'URL alarma:'
      },
      url2: {
        label: 'URL de comentarios para alarma:'
      },
      action_plan: {
        label: 'Seleccione un plan de acción:'
      },
      layouts: {
        label: 'Seleccione un diseño:'
      },
      location: {
        label: 'Ingrese la ubicación:',
        fields: {
          lat: 'Latitud',
          lng: 'Longitud',
          from_alarm: 'Tomar valor de la alarma'
        }
      },
      radius: {
        label: 'Ingrese el radio:'
      },
      delay: {
        label: 'Ingrese el retraso',
      },
      loop: {
        label: 'Bucle:'
      },
      message_template: {
        label: 'Plantilla del mensaje',
        characters_remaining: 'Caracteres restantes',
        available_placeholders: 'Placeholders disponibles',
        help: {
          header: 'Escribe el texto que se enviará por WhatsApp. Puedes utilizar lo siguientes placeholders que posteriormente serán reemplazados con la información de la alarma:',
          footer: {
            title: 'Ejemplo:',
            body: 'Comando ejecutado desde el instalación @facility_name, id de alarma #@id_alarm del tipo @alarm_type'
          }
        }
      },
      groups_select: {
        label: 'Selecciona los grupos para este nodo:'
      },
      tenants_select: {
        label: 'Selecciona los tenants para este nodo:'
      },
      centers_select: {
        label: 'Selecciona los centros para este nodo:'
      },
      sectors_select: {
        label: 'Selecciona los sectores para este nodo:'
      },
      filter_field: {
        label: 'Agregar filtro para este nodo:'
      },
      email: {
        label: 'Introduce los correos electrónicos',
      },
      phone: {
        label: 'Introduce los teléfonos',
      },
      receiver: {
        label: 'Introduce el receptor',
      },
      authorization: {
        label: 'Introduce tu clave de autorización',
      },
      url1: {
        label: 'Introduce la URL de la alarma',
      },
      url2: {
        label: 'Introduce la URL de retroalimentaciones de la alarma',
      },
    },
    data_types: {
      users: 'Usuarios',
      user_views: 'Vistas de usuario',
      units: 'Instalaciones',
      cameras: 'Cámaras',
      phone: 'Teléfonos',
      email: 'E-mails',
      log_id: 'Registros',
      do: 'Salida digital',
      interphone_from: 'Llamada interfon (de)',
      interphone_to: 'Llamada interfon (a)',
      message_template: 'Mensaje',
      type: 'Tipo de notificación',
      receiver: 'Receptor',
      verb: 'Verbo RESTful',
      authorization: 'Authorización',
      url1: 'URL de alarma',
      url2: 'URL de comentarios para alarma',
      webhook: 'URL Webhook',
      action_plan: 'Plan de acción',
      delay: 'Retraso',
      layout: 'Layouts',
      location: 'Ubicación',
      radius: 'Radio',
      loop: 'Bucle',
      density_device: 'Dispositivo Density',
      groups: 'Grupos',
      tenants: 'Tenants',
      centers: 'Centros',
      sectors: 'Sectores',
    },
    flowchart: {
      show_hide_node_config: 'Mostrar/ocultar configuración del comando',
    },
    gridWidget: {
      title: 'Comandos',
      table: {
        no_data: "No hay información disponible",
        rows: {
          1: "Nombre",
          2: "Descripción",
        }
      }
    },
    modal: {
      title: 'Ejecución de comando',
      body: 'Se ejecutará el siguiente comando',
      btns: {
        continue: 'Continuar',
        cancel: 'Cancelar'
      },
      messages: {
        success: 'Estado: Comando puesto en ejecución correctamente',
        error: 'Estado: Ocurrió un error'
      }
    },
    config_modal: {
      title: 'Configuración del comando',
      messages: {
        invalid_form: 'Por favor, corrige los errores en el formulario',
      }
    },
    logs: {
      title: 'Log de comandos',
      filters: {
        commands: 'Comandos'
      },
      table: {
        id_operation: 'ID de operación',
        user: 'Usuario',
        description: 'Descripción',
        command: 'Comando',
        command_source: 'Comando relacionado',
        level: 'Nivel',
        data: 'Info.',
        date: 'Fecha'
      }
    },
    units: {
      seconds: 'segundo(s)',
      meters: 'metro(s)',
    }
  }
}
