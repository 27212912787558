import axios from '@axios'

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    getItems(state) {
      return state.items
    },
    getSelectedItems(state) {
        return state.items.map(camera_type => ({
            value: camera_type.id,
            label: camera_type.type,
        }))
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value
    },
  },
  actions: {
    async getAll({ commit }, params) {
      const { data } = await axios.get('v1/camera_type', { params })

      if (!data.success) {
        return []
      }

      commit('setData', data.data)
      return data
    },
    clear({ commit }) {
      commit('setData', [])
    }
  },
}
