export default {
  namespaced: true,
  swift_sensor: {
    title: "Sensores Swift",
    id_external: "id externa",
    first_contact_time: "primera hora de contacto",
    last_contact_time: "última hora de contacto",
    guid: "guía",
    manufacturer: "fabricante",
    model: "modelo",
    hardware_revision: "revisión de hardware",
    software_revision: "revisión de software",
    serial_number: "número de serie",
    name: "nombre",
    description: "descripción",
    latitude: "latitud",
    longitude: "longitud",
    elevation: "elevación",
    connection_type: "tipo de conección",
    connection_pref: "preferencia de conección",
    network: "red",
    signal_strength: "intensidad de señal",
    is_new: "es nuevo",
    ethernet_mac_address: "dirección mac ethernet",
    wifi_mac_address: "dirección mac wifi",
    ip_address: "dirección ip",
    wifi: "wifi",
    ble: "ble",
    cell: "cell",
    gps: "gps",
    radio_gen2: "radio gen2",
    ble_gen3: "ble gen3",
    zigbee: "zegbee",
    zwave: "zwave",
    radio: "radio",
    id_swift_account: "id swift account",
    id_unit: "id unit",
    id_hardware: "id hardware",
    batery_level: "nivel de bateria",
    wireless_type: "tipo inalámbrico",
    sample_rate: "frecuencia de muestreo",
    is_event_based: "está basado en eventos",
    id_sensor: "id sensor",
    id_threshold: "id límite",
    id_unit_measure: "id unidad de medida",
    id_type_sensor: "id sensor de tipo",
    offset: "offset",
    id_swift_gateway: "id swift gateway",
    sync_threshold: "sincronizar umbrales desde la API",
  },
};
