import axios from "@axios";
import Vue from "vue";
import CallContent from "@/components/toastification/CallContent.vue";
import store from "..";
import LayoutApi from "@/libs/LayoutApi";
import { SLOT_TYPES } from "@/config/layoutConfig";

export default {
  namespaced: true,
  state: {
    items: [],
    presavedCaller: "",
    isRegistered: false,
    presavedAlarm: {},
  },
  getters: {
    getItems(state) {
      return state.items;
    },
    getSelectedItems(state) {
      return state.items.map((call) => ({
        value: call.id,
        label: call.sessionId,
      }));
    },
    getPersevedCaller(state) {
      return state.presavedCaller;
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value;
    },
    setPresavedCaller(state, value) {
      state.presavedCaller = value;
    },
    setPresavedAlarm(state, value) {
      console.log("Set Presaved Alarm");
      console.log({ value });
      state.presavedAlarm = value;
      // const { data } = await axios.get(`v1/sip/getAlarmJson?alarm_id=${value.id}`)
      // console.log({data})
      // state.presavedAlarm = {...value, alarmJson : data}
    },
    setIsRegistered(state, value) {
      state.isRegistered = value;
    },
    setAlarmJson(state, value) {
      //add unit name for diferent places and data alarm when calling button is presed
      value.unit_name = value.router ? value.router.unit.description : value.unit.description 
      state.presavedAlarm = { ...state.presavedAlarm, alarmJson: value };
    },
  },
  actions: {
    async getAll({ commit }, params) {
      const { data } = await axios.get("/v1/sip/waiting", { params });

      if (!data) {
        return [];
      }

      commit("setData", data.data);
      return data;
    },
    async takeCall({ commit, dispatch }, params) {
      console.log("storeTakeCall");
      console.log({ params });
      const { presavedCaller, alarm } = params;
      commit("setPresavedCaller", presavedCaller);
      if (alarm) commit("setPresavedAlarm", alarm);
      dispatch("setAlarmJson", alarm);
      const { data } = await axios.post(`v1/sip/takeCall`, params);
      return data;
    },
    async setAlarmJson({ commit }, params) {
      console.log("setAlarmJson");
      console.log({ params });
      const { data } = await axios.get(
        `v1/services/alarms/alarm-by-id?alarm_id=${params.id}`
      );
      console.log({ data });
      commit("setAlarmJson", data);
      return data;
    },
    resetPresavedCaller({ commit }) {
      console.log("resetPresavedCaller");
      const presavedCaller = "";
      commit("setPresavedCaller", presavedCaller);
      return presavedCaller;
    },
    async setPresavedCall({ commit, dispatch }, params) {
      console.log("preCall");
      console.log({ params });
      const { alarm_id, remotePartyNumber, callingPartyNumber } = params;
      const { data } = await axios.post(`v1/sip/createPresavedSipCall`, params);
      return data;
    },
    async getStatus({ commit }, params) {
      const { device_id } = params;
      const { data } = await axios.get(`v1/sip/getStatus?device_id=${device_id}`);
      return data;
    },
    async getDevice({ commit }, params) {
      console.log("GET DEVICE");
      const { device_id } = params;
      const { data } = await axios.get(
        `v1/device_interphone/getDeviceByDeviceId/${device_id}`
      );
      return data;
    },
    async getRecording({ commit }, params) {
      console.log("GET RECORDING");
      const { sessionId } = params;
      const { data } = await axios.get(`v1/sip/getRecordingFile?sessionId=${sessionId}`, {
        responseType: "blob",
      });
      return data;
    },
    setPresavedAlarm({ commit, params }) {
      const { alarm } = params;
      console.log({ alarm });

      commit("setPresavedAlarm", alarm);
      return alarm;
    },
    resetPresavedAlarm({ commit }) {
      console.log("resetPresavedAlarm");
      const presavedAlarm = {};
      commit("setPresavedAlarm", presavedAlarm);
      return presavedAlarm;
    },
    changePresavedAlarmStatus({ state, commit }, params) {
      console.log("changePresavedAlarmStatus");
      console.log({ params });
      const presavedAlarm = { ...state.presavedAlarm };
      presavedAlarm.alarmJson.alarm_status = params;
      commit("setPresavedAlarm", presavedAlarm);
      return presavedAlarm;
    },
    setIsRegistered({ commit }) {
      commit("setIsRegistered", true);
    },
    unsetIsRegistered({ commit }) {
      commit("setIsRegistered", false);
    },
    showMessage({ state }, payload) {
      Vue.$toast({
        component: CallContent,
        position: "top-right",
        timeout: 20000,
        props: {
          title: payload.title,
          variant: payload.variant,
          icon: "PhoneCallIcon",
          text: payload.msg,
          data: payload.data,
        },
      });
    },
    subscribeToCalls({ state, commit, dispatch, getters }, params) {
      console.log("Entro a SubscribeToCallsDispatch" +  params.user_id);
      //Websocket Omnia api
      window.Echo.connector.socket.on("subscription_error", (channel, data) => {
        console.log("SIP::call-events", channel, data);
      });

      if (!window.Echo.connector.socket.connected) window.Echo.connect();
      try {
        if (!window.Echo.connector.channels["private-call-events-"+params.user_id]) {
          console.log("ENTRO A SUBSCRIBIR CALL EVENTS");
          window.Echo.private("call-events-"+params.user_id)
            .listen(".created", (data) => {
              console.log(data);
            })
            .listen(".updated", (data) => {
              console.log("sip call updated");
              console.log(data);

              let items = [...state.items];

              if (data.model.state != "waiting") {
                //Si el estatus no cambia a en espera, quita las llamdas
                if (items) {
                  const indexToRemove = items.findIndex(
                    (call) => call.id === data.model.id
                  );
                  items.splice(indexToRemove, 1);

                  commit("setData", items);

                  const layoutApi = new LayoutApi(store);
                  const tab_data = layoutApi.getCurrentTabData();
                  //Search for active infoslots to update the state of the call
                  //TODO: Check if we should add another state to get the recordings
                  tab_data?.slots?.forEach((tab) => {
                    if (tab.type == "info") {
                      if (tab.data.id == data.model.id_alarm) {
                        let new_data = { ...tab.data };
                        new_data.sip_call.forEach((sip_call) => {
                          if (sip_call.id == data.model.id) {
                            sip_call.state = data.model.state; 
                            sip_call.remotePartyNumber = data.model.remotePartyNumber
                            if(data.model.recording_url) sip_call.recording_url = data.model.recording_url
                          }
                        });
                        console.log({new_data})
                        layoutApi.updateSlotData(3, SLOT_TYPES.INFO, { new_data });
                      }
                    }
                  });
                }
              } else {
                if (!items) items = [];
                const index = items.findIndex((call) => call.id === data.model.id);
                if (index == -1) {
                  //Si no existe
                  items.push(data.model);
                  commit("setData", items);
                  // dispatch("showMessage", {
                  //   title: null,
                  //   msg: null,
                  //   variant: "success",
                  //   data: { ...data.model },
                  // });
                }
              }
            });
        }
      } catch (error) {
        console.log("ERROR AL SUSCRIBIR CALL EVENTS" + error);
      }
    },
    unSubscribeToCalls({ state, commit, dispatch, getters }, params) {
      window.Echo.leave('call-events-'+params.user_id);
    },
  },
};
