export default {
  namespaced: true,
  alarm: {
    alarm_id: "Alarm ID",
    description: "Description",
    title: "Alarm | Alarms",
    folioCAD: "Folio CAD",
    statusCAD: "Status CAD",
    reaction_patrol: "Reaction patrol",
    alarm_type: "Alarm type",
    status: "Status",
    attending: "Attending",
    attended: "Attended",
    waiting: "Waiting",
    canceled: "Canceled",
    all: "All",
    start_date_filter: "Start Datetime",
    end_date_filter: "End Datetime",
    date_filter: "Date Filter",
    new_alarms: "New alarms",
    alarms: "Alarms",
    subsystem_action_modal: {
      send_alarm: "Are you sure you want to send the alarm?",
      notify_c5_cdmx: "Notification will be sent to the C5 CAD of CDMX, are you sure?",
      notify_c5_jal: "Notification will be sent to the C5 CAD of Jalisco, are you sure?",
      notify_c5_edomexe: "Notification will be sent to the C5 CAD of the State of Mexico, are you sure?",
      create_vitual_alarm: "A virtual alarm will be created, are you sure?",
      comments: "Comments",
      level_alarm: "Alarm Level",
    },
  },
};
