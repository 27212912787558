import { SOURCES } from "@/config/layoutConfig";
import { nextTick } from '@vue/composition-api'

export default class {

  constructor(store) {
    this.logPrefix = '[LayoutApi]'
    this.store = store;
  }

  /**
   * Add a new tab
   *
   * @param {String} name
   * @param {Number|String} layout
   * @param {Object} tabData
   * @returns Promise
   */
  addNewTab(name = '', layout = null, tabData = null) {
    return this.store.dispatch('grid/addNewTab', { tabName: name, layout, viewData: tabData });
  }

  /**
   * Remove/Close a tab by id
   *
   * @param {String} tabId
   */
  removeTab(tabId) {
    if (!tabId) {
      console.error(this.logPrefix, 'removeTab missing required param [tabId]');
    }
    this.store.dispatch('grid/removeTab', tabId);
  }

  /**
   * Returns the id of the current active tab
   *
   * @returns String
   */
  getCurrentTabId() {
    return this.store.getters['grid/activeTabId'];
  }

  /**
   * Return the data of the current active tab
   *
   * @returns Object
   */
  getCurrentTabData() {
    return this.store.getters['grid/currentTab'];
  }

  checkIfTabExists(id) {
    return this.store.getters['grid/tabs'].hasOwnProperty(id);
  }

  /**
   * Set the active tab by id
   *
   * @param {String} tabId
   */
  setActiveTab(tabId = null) {
    this.store.dispatch('grid/setActiveTab', tabId);
  }

  /**
   * Create an object with the necessary slot structure
   *
   * @param {String} name
   * @param {String} position
   * @param {Object|Array} data
   * @param {String} type
   * @returns Object
   */
  buildSlot(name = null, position = null, data = null, type = null) {

    if ([name, position, type].includes(null)) {
      console.error(this.logPrefix, 'buildSlot: missing required params');
      return false;
    }

    return {
      name,
      pos: position,
      data,
      type
    }
  }

  /**
   * Set data for the slot in the current active tab in the given position
   *
   * Note: For the slotType value check the file: src/config/layoutConfig.js
   *
   * @param {Sting} slotName
   * @param {Number} slotPosition
   * @param {Object|Array} slotData
   * @param {String} slotType
   */
  setSlot(slotName = '', slotPosition = 1, slotData = null, slotType = null) {

    const slot = this.buildSlot(slotName, slotPosition, slotData, slotType);

    if (!slot) return;


    nextTick(() => this.store.commit("grid/unsetLayoutSlot", slot.pos));
    nextTick(() => this.store.dispatch('grid/addItemToLayout', slot));
  }

  /**
   * Empty slot in the given position
   *
   * @param {String|Number} slotPosition
   */
  unsetSlot(slotPosition) {
    this.store.commit("grid/unsetLayoutSlot", slotPosition);
  }

  /**
   * Search and retrieve the given slot by position or name
   *
   * @param {String|Number} slotPosition
   * @returns {Object|null}
   */
  getSlotData(slotPosOrName) {

    const tab = this.getCurrentTabData();
    let data = null;

    if (tab) {
      tab.slots.forEach(slot => {
        if (slot.pos === slotPosOrName || slot.name === slotPosOrName) data = slot;
      });
    }

    return data;
  }

  /**
   * Update the data for slot in the current active tab in the given position and type
   *
   * Note: For the slotType value check the file: src/config/layoutConfig.js
   *
   * @param {Number} slotPosition
   * @param {String} slotType
   * @param {Object|Array} slotData
   */
  updateSlotData(slotPosition, slotType, slotData) {
    const tab = this.getCurrentTabData();

    if (tab) {
      tab.slots.forEach(slot => {
        if (slot.pos === slotPosition && slot.type === slotType) {
          this.store.dispatch("grid/updateSlotData", {
            pos: slotPosition,
            data: slotData,
          });
        }
      });
    }
  }

  setSlotData(type, name, data, source = null) {
    this.store.dispatch("grid/setSlotData", {
      type,
      name,
      data: {
        ...JSON.parse(JSON.stringify(data)),
        in_app_source: source,
      },
      source: source ? source : SOURCES.ALARM
    });
  }

  getLayoutDataById(layoutId) {
    if (this.store.getters["layouts/getUserLayouts"]) {
      const layout = this.store.getters["layouts/getUserLayouts"].find(l => l.id === layoutId);

      if (layout) return layout;

      return null;
    }

    return null;
  }

  resetSlotSelection() {
    this.store.dispatch("grid/resetSlotSelection");
  }

}
