export default {
  namespaced: true,
  tenants: {
    title: "tenant | tenants",
    number_of_routers: "Número de Routers",
    _name: "Nome",
    main_image: "imagem principal",
    logo_image: "imagem do logotipo",
    image_mobile_app: "Imagem do aplicativo móvel",
    all: "todos os tenants",
  },
};
