export default {
  namespaced: true,
  notifications: {
    alarm: {
      new_alarm_title: "New alarm registered",
      new_alarm_subtitle: "A new alarm has been registered.",
      updated_alarm_title: "Alarm update",
      updated_alarm_subtitle: "The alarm was successfully updated.",
      type: "Type",
      unit: "Facility",
      show: "Show alarm",
      updated_to_attending_success: "Alarm status changed to attending correctly",
      updated_to_attended_success: "Alarm status changed to attended correctly",
      updated_to_canceled_success: "Alarm status changed to canceled correctly",
      updated_error: "An error occurred while updating the alarm",
    },
    sip: {
      new_call_title: "New Call Registered",
      new_call_subtitle: "A new call has been registered",
      facilitie: "Facilitie",
      calling_number: "Calling Number",
      answer_call: "Answer Call",
    },
    read_allnotifications: "Read all notifications",
  },
};
