import axios from "@axios";

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    getSelectedItems(state) {
      return state.items.map((model) => ({
        value: model.id,
        label: model.name,
      }));
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value;
    },
  },
  actions: {
    async getAll({ commit }, params) {
      const { data } = await axios.get("v1/vehicle_model", { params });

      if (!data) {
        return [];
      }

      commit("setData", data.data);
      return data;
    },

    clear({ commit }) {
      commit("setDataModel", []);
    },
  },
};
