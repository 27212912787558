export default {
  namespaced: true,
  plate: {
    title: "plates | plate",
    list: "list",
    active_since: "active since",
    activated_until: "activated until",
    comments: "comments",
    description: "description",
    number_of_detections: "number of detections",
    last_detection: "last detection",
    brand: "brand",
    color: "color",
    model: "model",
    type: "type",
    vehicle: "vehicle",
    vehicle_data: "vehicle data",
  },
};
