import axios from "@axios";

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    getItems(state) {
      return state.items;
    },
    getSelectedItems(state) {
      return state.items.map((schedule_alarm) => ({
        value: schedule_alarm.id,
        label: schedule_alarm.name,
      }));
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value;
    },
  },
  actions: {
    async getAll({ commit }, params) {
      const { data } = await axios.get("v1/schedule_alarm", { params });

      if (!data) {
        return [];
      }

      commit("setData", data.data);
      return data;
    },
    async add(_, params) {
      const { data } = await axios.post("v1/schedule_alarm", params);

      return data;
    },
    async edit(_, { id, params }) {
      const { data } = await axios.put(`v1/schedule_alarm/${id}`, params);

      return data;
    },
    async delete(_, id) {
      const { data } = await axios.delete(`v1/schedule_alarm/${id}`);

      return data;
    },
    clear({ commit }) {
      commit("setData", []);
    },
  },
};
