export default {
  namespaced: true,
  breadcrumbs: {
    vass:{
      camera_manager: {
        master_account: 'VaaS > Camera Manager > Master Account',
        reseller: 'VaaS > Camera Manager > Reseller',
        cameras: 'VaaS > Camera Manager > Cameras',
        status_cameras: 'VaaS > Camera Manager > Status Cameras',
      },
      ee_vms: {
        master_accounts: 'VaaS > EE VMS > Master Accounts',
        accounts: 'VaaS > EE VMS > Accounts',
        bridges: 'VaaS > EE VMS > Bridges',
        cameras: 'VaaS > EE VMS > Cameras',
        presets: 'VaaS > EE VMS > Presets',
      },
      luxriot: {
        master_accounts: 'VaaS > Luxriot > Master Accounts',
        cameras: 'VaaS > Luxriot > Cameras',
      },
      omnicloud: {
        master_accounts: 'VaaS > OmniCloud > Master Accounts',
        cameras: 'VaaS > OmniCloud > Cameras',
        omnia_reseller: 'VaaS > OmniCloud > Omnia Reseller',
      },
      dahua: {
        dahua_dvr: 'VaaS > Dahua > Dahua DVR',
        dahua_cameras: 'VaaS > Dahua > Dahua Cameras',
      },
      generic_devices: 'VaaS > Generic Devices',
      recording_requests: {
        tag: 'VaaS > Recording Requests > Tag',
        recording_requests: 'VaaS > Recording Requests > Recording Requests',
      },
      analytics: {
        facial_recognition: {
          sarf: {
            events: 'VaaS > Analytics > Facial Recognition > SAFR > Events',
            groups: 'VaaS > Analytics > Facial Recognition > SAFR > Groups',
            people: 'VaaS > Analytics > Facial Recognition > SAFR > People',
          },
          luna: {
            master_accounts: 'VaaS > Analytics > Facial Recognition > Luna > Master Accounts',
            list: 'VaaS > Analytics > Facial Recognition > Luna > List',
            face: 'VaaS > Analytics > Facial Recognition > Luna > Face',
            facial_recognition_matches: 'VaaS > Analytics > Facial Recognition > Luna > Facial Recognition Matches',
            facestream: 'VaaS > Analytics > Facial Recognition > Luna > FaceStream',
          }
        },
        lpr: {
          plate_list: 'VaaS > Analytics > LPR > Plate List',
          plate: 'VaaS > Analytics > LPR > Plate',
          lpr_matches: 'VaaS > Analytics > LPR > LPR Matches',
        },
        detections_events: 'VaaS > Analytics > Detections Events',
      }

    },
    tenants: {
      all_tenants: 'Tenants > All Tenants',
      master_tenants: 'Tenants > Master Tenants',
      incident_list: 'Tenants > Incident List',
      incident_chart: 'Tenants > Incident Chart',
      logs_incidents: 'Tenants > Logs Incidents',
      layouts: 'Tenants > Layouts',
      device_tag: 'Tenants > Device Tag',
      token: 'Tenants > Token',
      token_omnitag: 'Tenants > Token Omnitag',
    },
    users: {
      all_users: 'Users > All Users',
      profile: 'Users > Profile',
      permissions: 'Users > Permissions',
      groups: 'Users > Groups',
    },
    alarms: {
      all: 'Alarms > All',
      seproban: 'Alarms > Seproban',
      alarms_levels: 'Alarms > Alarms Levels',
      alarm_comment: 'Alarms > Alarm Comment',
      alarm_views: 'Alarms > Alarm Views',
    },
    facilities: {
      all_facilities: 'Facilities > All Facilities',
      centers: 'Facilities > Centers',
      sectors: 'Facilities > Sectors',
      municipalities: 'Facilities > Municipalities',
    },
    logs: {
      system_logs: 'Logs > System Logs',
      traffic: 'Logs > Traffic',
      command_logs: 'Logs > Command Logs',
      action_plan_logs: 'Logs > Action Plan Logs',
    },
    automation: {
      custom_command: 'Automation > Custom Command',
      workflow: 'Automation > Workflow',
      action_plan: 'Automation > Action Plan',
    },
    voip: {
      iotcomms_master_accounts: 'VoIP > IoTComms Master Accounts',
      types_devices_interphones: 'VoIP > Types Devices Interphones',
      devices_interphones: 'VoIP > Devices Interphones',
      calls: 'VoIP > Calls',
    },
  },
}
