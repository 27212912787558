export default {
  namespaced: true,
  access_devices: {
    title: "access devices",
    name: "name",
    serial_number: "serial number",
    mac_address: "mac address",
    model: "model",
    synchronize:"synchronize",
    iot_id: "IOT ID"
  },
};
