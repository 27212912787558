export default {
  namespaced: true,
  intelexvision_alarm_type: {
    title: "Intelexvision tipo de alarma|Intelexvision tipos de alarmas",
    alarm_type: "Tipo de alarma",
    name: "Nombre",
    description: "Descripcion",
    rule: "Generar alarma sin regla",
    alarm_type_intelexvision: "tipo de alarma en intelexvision",
    priority: "prioridad",
  },
};
