export default {
  namespaced: true,
  tenants: {
    title: "tenant | tenants",
    number_of_routers: "Number of routers",
    _name: "name",
    main_image: "Main Image",
    logo_image: "Logo Image",
    image_mobile_app: "Image Mobile App",
    all: "all tenants",
    shared: "shared tenants"
  },
};
