export default {
  namespaced: true,
  generic_devices: {
    title: 'Dispositivos genéricos',
    status: 'Estado',
    name: 'Nombre',
    provider: 'Proveedor',
    type: 'Tipo',
    idCameraProxy: 'ID Cameras Proxy',
    data: 'Configuración',
    units: 'Instalaciones',
    add_device: 'Añadir nuevo dispositivo',
    edit_device: 'Editar dispositivo',
    device_config: 'Configuración del dispositivo',
    reseller: 'Reseller',
    camera_type: 'Tipo de cámara',
    onvif: 'Onvif',
    form: {
      name: {
        label: 'Nombre',
        placeholder: 'Nombre',
      },
      provider: {
        label: 'Proveedor',
        placeholder: 'Proveedor'
      },
      type: {
        label: 'Tipo',
        placeholder: 'Tipo'
      },
      custom_fields: {
        url: {
          label: 'Url',
          placeholder: 'Url',
        },
        protocol: {
          label: 'Protocolo',
          placeholder: 'Protocolo',
        },
        port: {
          label: 'Puerto',
          placeholder: 'Puerto',
        },
        user: {
          label: 'Usuario',
          placeholder: 'Usuario',
        },
        password: {
          label: 'Contraseña',
          placeholder: 'Contraseña',
        },
        onvif_host: {
          label: 'Ruta Onvif (IP/Hostname)',
          placeholder: 'Ruta Onvif',
        },
        onvif_port: {
          label: 'Puerto Onvif',
          placeholder: 'Puerto Onvif',
        },
        enabled_onvif_events: {
          label: 'Habilitar eventos Onvif',
          placeholder: 'Habilitar eventos Onvif',
        },
      }
    },
    types: {
      camera: 'Cámara',
      generic_device: 'Dispositivo genérico',
    },
  }
}
