export default {
    namespaced: true,
    users: {
        title: "user | users",
        email: "email",
        username : "username",
        name: "name",
        first_name: "first name",
        second_name: "second name",
        password: "password",
        phone_number: "phone number",
        active: "active",
        vpn_access: "vpn access",
        last_login: "last login",
        alarms_history: "alarms history",
        allowed_units_live_video_flows: "allowed units flows of stream",
        alarm_history_per_hours: "allowed hours for alarms history",
        alarm_history_per_days: "allowed days for alarms history",
        alarm_history_by_records: "allowed records for alarms history",
        plate_number: "plate number or employed number",
        shared_tenants: "shared tenants",
        video_wall: "video wall",
        info: "info",
        saving_info: "saving user info",
        enabled_chat:"enabled chat",
        id_country:"id country"
    },
};
