import axios from '@axios'

const state = {
    tenants: [],
    tenantsFilter: [],
    units: [],
    unitsFilter: [],
    unitsVersion: 1,
    loadingUnits: false,
};

const getters = {
    getTenants(state) {
        let tenants = state.tenantsFilter.sort(( a, b ) => {
            if (a.name < b.name){
              return -1;
            }
            if (a.name > b.name){
              return 1;
            }
            return 0;
          });
        return tenants
    },
    getUnits: state => filters => {
        let units = state.unitsFilter.filter((unit) => {
            if (filters.idUnit) {
                return unit.id_tenant === filters.idTenant && unit.id === filters.idUnit;
            }
            return unit.id_tenant === filters.idTenant;
        });

        if (filters.filter && filters.filter.length > 0) {
            units = units.filter((unit) => filters.filter.includes(unit.id));
        }

        return units.slice(
            (filters.currentPage - 1) * filters.perPage,
            filters.currentPage * filters.perPage
        );
    },
    getTotalUnits: state => filters => {
        let units = state.unitsFilter.filter((unit) => {
            if (filters.idUnit) {
                return unit.id_tenant === filters.idTenant && unit.id === filters.idUnit;
            }
            return unit.id_tenant === filters.idTenant;
        });

        if (filters.filter && filters.filter.length > 0) {
            units = units.filter((unit) => filters.filter.includes(unit.id));
        }

        return units.length
    },
};

const mutations = {
    // General
    setTenants(state, value) {
        state.tenants = value;
        state.tenantsFilter = value;
    },

    setTenantsFilter(state, value) {
        state.tenantsFilter = value;
    },

    setUnits(state, value) {
        state.units = value;
        state.unitsFilter = value;
    },

    resetUnits(state) {
        state.units = [];
    },

    setUnitsFilter(state, value) {
        state.unitsFilter = value;
    },

    setUnitsLoadStatus(state, value) {
        state.loadingUnits = value;
    },

    updateUnitsVersion(state) {
        state.unitsVersion += 1;
    },
};

const actions = {
    getUnitsByTenant({ commit, dispatch }, params) {
        return new Promise((resolve, reject) => {

            axios
                .get('/v1/services/tenants/tenants-by-user', { params })
                .then(async ({ data }) => {

                    commit('setTenants', data.data);
                    commit('setUnitsLoadStatus', true);

                    let subPromises = [];

                    let promises = data.data.map(tenant => {
                        return new Promise(resolve => {
                            axios.get(`${tenant.units}&max=500`).then(({ data }) => {

                                if (data.meta.last_page > 1) {

                                    let baseUrl = data.links.first;
                                    baseUrl = baseUrl.substr(0, baseUrl.length - 2);

                                    for (let page = 2; page <= data.meta.last_page; page++) {
                                        subPromises.push(new Promise(resolve => {
                                            axios.get(`${baseUrl}=${page}&max=500`).then(({ data }) => resolve(data.data))
                                        }))
                                    }

                                }

                                resolve(data.data)
                            })
                        })
                    });

                    let units = [];

                    await Promise
                        .all(promises)
                        .then(resp => units = units.concat(...resp))

                    await Promise
                        .all(subPromises)
                        .then(resp => units = units.concat(...resp))

                    let tenants = []

                    data.data.forEach(tenant => {
                        tenants.push({
                            ...tenant,
                            units_count: units.filter((unit) => {
                                return unit.id_tenant === tenant.id;
                            }).length
                        })
                    })

                    commit('setTenants', tenants);

                    commit('setUnits', units);
                    commit('setUnitsLoadStatus', false);

                    resolve();
                }).catch((error) => {
                    reject(error);
                });

        });
    },

    resetUnits({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            commit('resetUnits');
            dispatch("getUnitsByTenant").then(() => {
                dispatch('updateUnitsVersion');
            })
            resolve();
        });
    },

    filterUnits({ commit, state }, { search }) {
        let units = state.units.filter((unit) => {
            return (
              unit.description.toLowerCase().includes(search) ||
              unit.address_1.toLowerCase().includes(search) ||
              unit.address_2.toLowerCase().includes(search) ||
              unit.suburb.toLowerCase().includes(search) ||
              unit.municipality?.name.toLowerCase().includes(search) ||
              unit.postal_code.toLowerCase().includes(search) ||
              unit.region?.name.toLowerCase().includes(search) ||
              unit.country?.name.toLowerCase().includes(search)
            );
          });

        commit("setUnitsFilter", units);

        let tenants = units.map((unit) => unit.id_tenant);

        let tenantsFilter = state.tenants.filter((tenant) => {
            return tenants.includes(tenant.id);
          })
        
        tenantsFilter.forEach(tenant => {
            tenant.units_count = units.filter((unit) => {
                                        return unit.id_tenant === tenant.id;
                                    }).length
        })

        commit("setTenantsFilter", tenantsFilter);
    },

    unfilterUnits({ state }) {
        state.unitsFilter = state.units;
        state.tenantsFilter = state.tenants;

        state.tenantsFilter.forEach(tenant => {
            tenant.units_count = state.unitsFilter.filter((unit) => {
                                        return unit.id_tenant === tenant.id;
                                    }).length
        })
    },

    updateUnitsVersion({ commit }) {
        commit('updateUnitsVersion');
    },
};

export default {
    state,
    getters,
    mutations,
    actions
};
