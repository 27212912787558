export default {namespaced: true,
  municipality: {
    title: "municipalidade | municipalidades",
    name: "nome",
    acronym: "acrônimo",
    id_region: "id da região",
    updated_at: "atualizado em",
    created_at: "criado em",
    id: "id"
  },
};
