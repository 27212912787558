export default {
  namespaced: true,
  state: {
    roundCommandStatus: false,
    tabId: null,
    paused: false,
    ignoredSlot: 0,
  },

  getters: {
    isRoundCommandRunning(state) {
      return state.roundCommandStatus;
    },
    getTabId(state) {
      return state.tabId;
    },
    isPaused(state) {
      return state.paused;
    },
    getIgnoredSlot(state) {
      return state.ignoredSlot;
    },
  },

  mutations: {
    setRoundCommandStatus(state, value) {
      state.roundCommandStatus = value;
    },
    setTabId(state, value) {
      state.tabId = value;
    },
    setPaused(state, value) {
      state.paused = value;
    },
    setIgnoredSlot(state, value) {
      state.ignoredSlot = value;
    },
  },

  actions: {
    setRoundCommandStatus({ commit }, payload) {
      commit('setRoundCommandStatus', payload);
    },
    setTabId({ commit }, payload) {
      commit('setTabId', payload);
    },
    setPaused({ commit }, payload) {
      commit('setPaused', payload);
    },
    setIgnoredSlot({ commit }, payload) {
      commit('setIgnoredSlot', payload);
    },
    reset({ commit }) {
      commit('setRoundCommandStatus', false);
      commit('setTabId', null);
      commit('setPaused', false);
      commit('setIgnoredSlot', 0);
    }
  }

}
