export default {
  namespaced: true,
  camera: {
    title: "cámara | cámaras",
    name: "nombre",
    mac_address: "mac address",
    type: "Tipo",
    address: "dirección",
    review_facial_recognition_events: "Revisar eventos de detección de personas en área",
    previous_time_of_prealarma: "tiempo previo de prealarma",
    later_time_of_prealarma: "tiempo posterior de prealarma",
    online: "en linea",
    user: "usuario",
    password: "contraseña",
    people_detection_start: "inicio de detección de detección de personas en área",
    end_of_person_detection: "fin de detección de detección de personas en área",
    camera_id: "id cámara",
    serial_number: "numero de serie",
    people_detection: "Detección de Personas",
    update_cameras: "actualizar cámaras",
    esn: "ESN",
    resellers_validation: "necesitas seleccionar al menos un reseller",
    enable_smart_detection: "Detección Inteligente Habilitada",
    enable_person_smart_detection: "Detección Inteligente de Personas Habilitada",
    camera_manager: "Gestor de Cámara",
    status_cameras: "Estado de la Cámara",
  },
};
