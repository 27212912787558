export default {namespaced: true,
  municipality: {
    title: "municipio | municipios",
    name: "nombre",
    acronym: "acromino",
    id_region: "id de la region",
    updated_at: "actualizado en",
    created_at: "creado en",
    id: "id"
  },
};
