export default {
  namespaced: true,
  auth_two_factor: {
    title: "autenticação em duas etapas",
    description:
      "Para habilitar a autenticação de dois fatores, selecione qualquer um dos métodos disponíveis. Posteriormente, ser-lhe-á enviado um código através do método selecionado, que deverá introduzir na caixa de texto correspondente. Quando esta etapa estiver concluída, clique em salvar e a autenticação de dois fatores será ativada.",
    verify: "verificar",
    send: "enviar",
    saved_successfully: "Salvo com sucesso",
    an_error_occurred: "um erro ocorreu",
    sending_mail: "Enviando email",
    email_sent: "email enviado",
    sending_sms: "enviando sms",
    sms_sent: "sms enviado",
    checking_email: "verificando email",
    email_verification_successful: "verificação de email bem-sucedida",
    checking_sms:"verificando sms",
    sms_verification_successful:"verificação sms bem-sucedida",
    change_authentication_method:"alterar método de autenticação"
  },
};
