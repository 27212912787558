export default {
  namespaced: true,
  intelexvision_alarm_type: {
    title: "Intelexvision alarm type|Intelexvision alarms types",
    alarm_type: "Alarm type",
    name: "Name",
    description: "Description",
    rule: "Generate alarm without rule",
    alarm_type_intelexvision: "alarm type intelexvision",
    priority: "prority",
  },
};
