export default {
  namespaced: true,
  alarm_comment: {
    title: "alarm comment",
    comment: "comment",
    alarm_type: "alarm type",
    tenant: "tenant",
    attending: "attending",
    attended: "attended",
    canceled: "canceled",
    status: "status",
  },
};
