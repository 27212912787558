export default {
  namespaced: true,
  notifications: {
    alarm: {
      new_alarm_title: "Nueva alarma registrada",
      new_alarm_subtitle: "Una nueva alarma ha sido registrada.",
      updated_alarm_title: "Actualización de alarma",
      updated_alarm_subtitle: "La alarma fue actualizada correctamente.",
      type: "Tipo",
      unit: "Instalación",
      show: "Mostrar alarma",
      updated_to_attending_success: "Alarma puesta en atención correctamente",
      updated_to_attended_success: "Alarma marcada como antendida correctamente",
      updated_to_canceled_success: "Alarma marcada como cancelada correctamente",
      updated_error: "Ocurrió un error al actualizar la alarma",
    },
    sip: {
      new_call_title: "Nueva Llamada Registrada",
      new_call_subtitle: "Una nueva llamada ha sido registrada",
      facilitie: "Instalación",
      calling_number: "Extension que llama",
      answer_call: "Answer Call",
    },
    read_allnotifications: "Leer todas las notificaciones",
  },
};
