import axios from "@axios";
import store from "@/store";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    getItems(state) {
      return state.items;
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value;
    },
  },
  actions: {
    async saveconfig(_, params) {
      const { data } = await axios.post("v1/authTwoFactor/saveconfig", params);
      if (!data) {
        return [];
      }
      return data;
    },
    async verifyEmail() {
      const { data } = await axios.post("v1/authTwoFactor/verifyEmail");
      if (!data) {
        return [];
      }
      return data;
    },
    async verifySms() {
      const { data } = await axios.post("v1/authTwoFactor/verifySms");
      if (!data) {
        return [];
      }
      return data;
    },
    async getUserAuthTwoFactor({ commit }) {
      const { data } = await axios.get("v1/authTwoFactor/getUser");
      commit("setData", data.data);
    },

    async sendVerify(_, params) {
      const { data } = await axios.post("v1/authTwoFactor/sendverify", params);
      return data;
    },
    async sendVerifySms(_, params) {
      const { data } = await axios.post("v1/authTwoFactor/sendverifysms", params);
      return data;
    },
  },
};
