export default {
  namespaced: true,
  camera: {
    title: "camera | cameras",
    mac_address: "mac address",
    name: "name",
    type: "type",
    address: "address",
    review_facial_recognition_events: "review events of people in area",
    previous_time_of_prealarma: "previous time of prealarma",
    later_time_of_prealarma: "later time of prealarma",
    online: "online",
    user: "user",
    password: "password",
    people_detection_start: "start detection of people in area",
    end_of_person_detection: "end of detection of people in area",
    camera_id: "camera id",
    serial_number: "serial number",
    people_detection: "people detection",
    update_cameras: "update cameras",
    esn: "ESN",
    resellers_validation: "you need to select a resellager:",
    enable_smart_detection: "Enable Smart Detection",
    enable_person_smart_detection: "Enabled Person Smart Detection",
    camera_manager: "Camera Manager",
    status_cameras: "Status Camera",

  },
};
