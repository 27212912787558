export default {
  namespaced: true,
  actions: {
    name: "acción | acciones",
    accept: "aceptar",
    add: "agregar",
    edit: "editar",
    delete: "eliminar",
    cancel: "cancelar",
    filter: "filtro | filtros",
    update: "actualizar",
    export: "exportar",
    close: "cerrar",
    save: "guardar",
    save_changes: "guardar cambios",
    send: "enviar",
    download: "descargar",
    reset: "resetear",
    drag_drop_1: "¡Arrastra y suelta para subir contenido!",
    drag_drop_2: "...o haga clic para seleccionar un archivo de su computadora",
    load: "cargar",
    refresh: "refrescar",
    next: "siguiente",
    previous: "anterior",
    finish: "terminar",
    delete_all: "eliminar todos",
    select_deselect_all: "seleccionar/deseleccionar todo",
  },
};
