import axios from '@axios'
import store from "@/store"

export default {
  namespaced: true,
  state: {
    items: [],
    alarm_type_items: [],
  },
  getters: {
    getItems(state) {
      return state.items
    },
    getSelectedItems(state) {
      return state.items.map(alarm => ({
        value: alarm.id,
        label: alarm.alarm_type,
      }))
    },
    getAlarmTypes(state) {
      return state.alarm_type_items.map(alarm_type => ({
        value: alarm_type.type,
        label: alarm_type.description,
      }))
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value
    },
    setAlarmTypesData(state, value) {
      state.alarm_type_items = value
    }

  },
  actions: {
    async getAll({ commit }, params) {
      const response = await axios.get('v1/alarm',
        {
          params,
          responseType: params?.action == 'export' ? 'blob' : 'json'
        })

      if (!response.data) {
        return []
      }

      if (params?.action == 'export') {
        const url = window.URL.createObjectURL(new Blob([response.data],
          { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',
          response.headers["content-disposition"].split("filename=")[1]);
        document.body.appendChild(link);
        link.click();
        link.remove();

        const notification = {
          id: response.headers["content-disposition"].split("filename=")[1],
          progress: 100,
          completed: true,
          view: 'alarm',
          url: url,
          download: response.headers["content-disposition"].split("filename=")[1],
          title: 'Alarms report',
          body: 'Downloaded successfully',
          error: false,
          timestamp: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
        }
        store.dispatch('grid/updateDownloadNotification', notification)
      } else {
        commit('setData', response.data.data)
        return response.data
      }
    },
    async getAlarmTypes({ commit }) {
      const response = await axios.get('v1/type_alarm');

      if (!response.data) {
        return []
      }

      commit('setAlarmTypesData', response.data.data)

      return response.data
    },
    clear({ commit }) {
      commit('setData', [])
    }
  },
}
