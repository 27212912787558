export default {
  namespaced: true,
  omniview_reseller: {
    title: "Omnia reseller",
    device_assignment: "Asignacion de dispositivos",
    actions: "actions",
    name: "nombre",
    description: "descripcion",
    time_zone: "zona horaria",
    number_of_devices: "número de dispositivos",
    add_omnia_reseller: "agregar omnia reseller",
    assign: "asignar",
    type: "tipo",
    camera_id: "id camara",
    center:"centro",
    sector:"sector",
    facilities:"Intalaciones",
    email: "email",
    password: "password",
    assign_cameras:"asignar cámaras",
    id_camera_proxy:"id camara proxy"
  },
};
