export default {
    namespaced: true,
    safr: {
        title: 'SAFR',
        addGroup: 'Add group',
        editGroup: 'Edit group',
        name: 'Name',
        enabled: 'Enabled',
        groups: 'Grupos',
        people: 'People',
        addPeople: 'Add people',
        editPeople: 'Edit people',
        age: 'Age',
        photo: 'Image',
        gender: 'Gender',
        options: 'Options',
        genderFemale: 'Female',
        genderMale: 'Male',
        events: 'Events',
        event_id: 'Event',
        source_id: 'Source',
        type: 'Type',
        date: 'Date',
        person: 'Person',
        similarity: 'Similarity',
        matches: 'Matches',
        person_match: 'Match with',
        from: 'From',
        to: 'To',
        placeholderPerson: 'Select people',
        placeholderDate1: 'Select from',
        placeholderDate2: 'Select to',
        resetFilters: 'Reset filters',
        password: 'Password',
        user: 'User',
        tenant: 'Tenants',
        links: 'Enpoints',
        addMasterAccount: 'Add master account',
        editMasterAccount: 'Edit master account',
        placeholderMasterAccount: 'Select tenant',
        placeholderDescription: 'Enter description',
        placeholderId: 'Enter id',
        placeholderEndpoint: 'Enter enpoint',
        placeholderName: 'Enter name',
        placeholderAge: 'Enter age',
        placeholderGender: 'Enter gender',
        placeholderGroups: 'Enter groups',
        placeholderImage: 'Enter image',
        placeholderType: 'Enter type',
        type: 'Type',
        delete: 'Delete',
        master_account: 'Master account',
        addCamera: 'Add camera',
        editCamera: 'Edit camera',
        sector: 'Sector',
        center: 'Center',
        units: 'Units',
        placeholderSector: 'Enter sector',
        placeholderMasterAccount: 'Enter master account',
        placeholderCenter: 'Enter center',
        placeholderDirectory: 'Enter directory',
        placeholderTenants: 'Enter tenants',
        placeholderUnit: 'Enter unit',
        update_from_api: "Update cameras from API",
        directory: 'Directory',
        safr_name: 'Safr camera',
        update_people_from_api: "Update people from API",
        token: 'Token',
        accessClearance: 'Access clearance',
        placeholderAccessClearance: 'Enter access clearance',
        update_access_clearance_from_api: 'Update access clearance from API',
        permanent: 'Permanent',
        addAccessClearance: 'Add access clearance',
        level: 'Level',
        placeholderLevel: 'Enter level'
    }
}
