import axios from '@axios'
import store from '@/store'

export default {
  namespaced: true,
  state: {
    items: [],
    itemsChart: [],
  },
  getters: {
    getItems(state) {
      return state.items
    },
    getTotal(state) {
      return state.itemsChart.length
    },
    getChartItems(state) {
      let itemsC = [];

      state.itemsChart.forEach(log => {
        let exist = itemsC.findIndex(value => value.name === log.incident)
        if(exist === -1) {
          itemsC.push({
            name: log.incident,
            value: 1,
          })
        } else {
          itemsC[exist].value = itemsC[exist].value + 1;
        }
      })

      return itemsC;
    },
    getChartItemsColors(state) {
      let itemsC = [];

      state.itemsChart.forEach(log => {
        let exist = itemsC.findIndex(value => value.name === log.incident)
        if(exist === -1) {
          itemsC.push(log.color)
        }
      })

      return itemsC;
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value
    },
    setDataChart(state, value) {
      state.itemsChart = value
    },
  },
  actions: {
    async getAll({ commit }, params) {
      const response = await axios.get("v1/log_incident", {
        params,
        responseType: params?.action == "export" ? "blob" : "json",
      });

      if (!response.data) {
        return [];
      }

      if (params?.action == "export") {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
        link.remove();

        const notification = {
          id: response.headers["content-disposition"].split("filename=")[1],
          progress: 100,
          completed: true,
          view: "log-incident",
          url: url,
          download: response.headers["content-disposition"].split("filename=")[1],
          title: "Reporte de registro de incidentes",
          body: "Descarga completa",
          error: false,
          timestamp:
            new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(),
        };
        store.dispatch("grid/updateDownloadNotification", notification);
      } else {
        commit("setData", response.data.data);
        return response.data;
      }
    },
    async getChart({ commit }, params) {
      const { data } = await axios.get('v1/chart/log_incident', { params })

      if (!data.success) {
        return []
      }

      commit('setDataChart', data.data)
      return data
    },
    clear({ commit }) {
      commit('setData', [])
      commit('setDataChart', [])
    }
  },
}
