export default {
  namespaced: true,
  bridge: {
    bridge_cameras:"Bridge cameras",
    title: "bridge | bridges",
    name: "name",
    number_of_cameras: "number of cameras",
    video_status: "video status",
    ip: "IP",
    accounts: "Accounts",

  },
};
