import axios from "@axios";
import store from "@/store";

export default {
  state: {
    userViews: [],
    allViews: [],
  },
  getters: {
    getUserViews(state) {
      return state.userViews.map((userView) => ({
        id: userView.id,
        name: userView.name,
        data: userView.data,
        layout_id: userView.data.layout,
        updated_at: userView.updated_at,
      }));
    },
    getUserViewsAll(state) {
      return state.allViews.map((userView) => ({
        id: userView.id,
        name: userView.name,
        data: userView.data,
        id_user: userView.id_user,
        layout_id: userView.data.layout,
        updated_at: userView.updated_at,
      }));
    },
  },
  mutations: {
    setUserViewsAll(state, value) {
      state.allViews = value;
    },
    setUserViews(state, value) {
      state.userViews = value;
    },
    updateView(state, value) {
      let view = state.userViews.find((view) => view.data.id === value.tabId);
      view.data = Object.assign(view.data, { ...value.data });
      view.name = view.data.name;
      view.updated_at = new Date().getTime();
    },
    updateViewVideoWall(state, value) {
      let view = state.allViews.find((view) => view.data.id === value.tabId);
      view.data = Object.assign(view.data, { ...value.data });
      view.name = view.data.name;
      view.updated_at = new Date().getTime();
    },
    removeView(state, value) {
      state.userViews = state.userViews.filter((view) => view.id !== value);
    },
  },
  actions: {
    async getUserViews({ commit }) {
      const { data } = await axios.get("v1/user-views");
      commit("setUserViews", data.data);

      if (!data.success) {
        return [];
      }

      return data;
    },
    createOrUpdateView({ commit, dispatch, getters }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const view = getters["getUserViews"].find(
            (view) => view.data.id === payload.tabId
          );

          if (view) {
            commit("updateView", payload);
            const { data } = await axios.put(`v1/user-views/${view.id}`, payload);
          } else {
            const { data } = await axios.post("v1/user-views", payload);
            dispatch("getUserViews");
          }

          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
    createOrUpdateViewvideoWall({ commit, dispatch, getters }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch("getUserViewsAll");
          console.log(getters["getUserViewsAll"]);
          let views = getters["getUserViewsAll"];
          let listViews = [];
          views.forEach((element) => {
            listViews.push({
              id: element.id,
              tabId: element.data.id,
            });
          });
          console.log("nuevo array", listViews);
          console.log("vista", payload);

          const exists = listViews.some((obj) => obj.tabId === payload.tabId);
          let updateViewVideoWall = true;
          if (exists == false) {
            updateViewVideoWall = false;
          }
          if (updateViewVideoWall) {
            axios.put(`v1/users/videowallusers/updateview/${payload.id}`, payload);
          } else {
            const { data } = axios.post(
              `v1/users/videowallusers/view/${payload.id}`,
              payload
            );
          }
          dispatch("getUserViewsAll");
          store.dispatch("video_wall/uservideowall");
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
    async removeView({ commit }, payload) {
      return axios
        .delete(`v1/user-views/${payload.id}`)
        .then(() => {
          commit("removeView", payload.id);
        })
        .catch((error) => {
          console.log("Remove View: ", error.response);
        });
    },
    async removeViewVideoWall({ commit }, payload) {
      return axios
        .delete(`v1/users/videowallusers/deleteviewvideowall/${payload}`)
        .then(() => {
        })
        .catch((error) => {
          console.log("Remove View: ", error.response);
        });
    },
    async getUserViewsAll({ commit }) {
      const { data } = await axios.get("v1/users/videowallusers/viewall");
      commit("setUserViewsAll", data.data);

      if (!data.success) {
        return [];
      }
      console.log(data);
      return data;
    },
  },
};
