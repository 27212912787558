export default {
  namespaced: true,
  seproban: {
    title: "seproban",
    id_alarm: "id alarm",
    path: "path",
    order: "orden de envío",
    is_send: "enviado",
    sent_at: "enviado a las"
 }
}
