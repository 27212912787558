export default {
  namespaced: true,
  seproban: {
    title: "seproban",
    id_alarm: "id alarm",
    path: "path",
    order: "send order",
    is_send: "is sent",
    sent_at: "sent at"
 }
}
