import axios from "@axios";

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    getItems(state) {
      return state.items;
    },
    getSelectedItems(state) {
      return state.items
        .filter(logs => logs.lang === "en")
        .map(logs => ({
          value: logs.id,
          label: logs.message,
        }));
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value;
    },
  },
  actions: {
    async getAll({ commit }, params) {
      const { data } = await axios.get(`${process.env.VUE_APP_LOGS_MS_URL}/v1/log_messages`);

      if (!data) {
        return [];
      }

      commit("setData", data.data);
      return data;
    },
    clear({ commit }) {
      commit("setData", []);
    },
  },
};
