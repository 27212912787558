// ====== Slot Types =====
export const SLOT_TYPES = {
  CAM_LIVE: 'cam',
  CAM_PA: 'cam_pa', // PA = Pre-Alarm
  CAM_RECORDED: 'cam_recorded',
  ACTION_PLAN: 'action_plan',
  INFO: 'info',
  INTERPHONE:'info-interphone',
  MAP: 'map',
  PLANE: 'plane',
  TAG: 'tag',
  UNITS_MAP: 'units_map',
  TIMELINE: 'timeline',
  GPS: 'gps',
  FRAME: 'frame',
  CHARTS_SDK: 'charts_sdk',
  CHARTS_SDK_DATA: 'charts_sdk_data',
  FR_TRACKING_MAP: 'fr_tracking_map',
  LPR_TRACKING_MAP: 'lpr_tracking_map',
  AR_TABLE_DATA: 'ar_table_data',
  WEATHER: 'weather',
  FACE_DETECTION: 'face_detection',
  WAZE: 'waze',
  CHAT: 'chat',
  LPR_DETECTION: 'lpr_detection',
  DETECTION_EVENT: 'detection_event',
};

// ====== Sources =====
export const SOURCES = {
  ALARM: 'alarm',
  ALARM_VIEW: 'alarm_view',
  CAMERA: 'camera',
  COMMAND: 'command',
  SUBSYSTEM_TREE: 'subsystem_tree',
  UNIT: 'unit',
  ACTUATOR: 'actuator',
  GENERIC_DEVICE: 'generic_devices',
  SUBSYSTEM_TREE_DO: 'digital_output',
  SUBSYSTEM_TREE_VA: 'virtual_alarm',
  SUBSYSTEM_TREE_SENSOR: 'subsystem_tree_sensor',
  SUBSYSTEM_TREE_DND: 'subsystem_tree_dnd',
  MAIN_SEARCH: 'main_search'
};


