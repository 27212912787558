export default {
  namespaced: true,
  alarm_views: {
    title: 'Vistas de alarma',
    action_add: 'Crear vista',
    action_update: 'Actualizar vista',
    subtitle_1: 'Configuración General',
    subtitle_2: 'Configuración Del Layout',
    is_default: 'por defecto',
    fields: {
      name: {
        label: 'Nombre de la vista',
        placeholder: 'Nombre de la vista',
      },
      tenant: {
        label: 'Tenant',
        placeholder: 'Tenant',
      },
      users: {
        label: 'Usuarios',
        placeholder: 'Usuarios',
      },
      alarm_level: {
        label: 'Nivel de alarma',
        placeholder: 'Nivel de alarma',
      },
      alarm_type: {
        label: 'Tipo de alarma',
        placeholder: 'Tipo de alarma',
      },
      alarm_status: {
        label: 'Estado de alarma',
        placeholder: 'Estado de alarma',
      },
      default_view: {
        label: 'Vista por default',
      },
      periodicity: {
        label: 'Periodicidad',
        always: 'Siempre',
        days: {
          sun: 'D',
          mon: 'L',
          tue: 'M',
          wed: 'M',
          thu: 'J',
          fri: 'V',
          sat: 'S',
        },
        from: 'de',
        to: 'a',
        messages: {
          invalid_schedule: 'Lista de horarios vacía o existen horarios inválidos'
        },
        btns: {
          delete: 'Eliminar',
          add: 'Agregar horario'
        }
      },
    },
    strings: {
      tags: 'Etiquetas'
    },
    messages: {
      layout_cannot_be_empty: 'El layout no puede estar completamente vacío',
      success_created: 'Vista creada correctamente',
      success_updated: 'Vista actualizada correctamente',
    },
    btns: {
      create: 'Crear',
      update: 'Actualizar'
    }
  }
}
