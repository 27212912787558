export default {
  namespaced: true,
  unit_groups: {
    title: "Grupos de instalação",
    title_main: "Configurar grupo de instalações",
    action_add: 'Criar grupo de instalações',
    action_update: 'Atualizar grupo de instalação',
    parent_name: 'Grupos',
    fields: {
      name: {
        label: 'Nome para o grupo de instalação',
        placeholder: 'Nome para o grupo de instalações'
      },
      group_name: {
        label: 'Nome do grupo',
        placeholder: 'Nome do grupo'
      },
    },
    messages: {
      success_created: 'Grupo criado com sucesso',
      success_updated: 'Grupo atualizado com sucesso',
      success_deleted: 'Grupo excluído com sucesso',
      delete_prompt: 'Tem certeza que deseja excluir este grupo?',
    },
    btns: {
      add: 'criar grupo'
    }
  }
}
