
const state = {
  fullScreenEnabled: false,
  showRemoveBtn: false,
  alarmsTS: 0,
  floatVideos: []
};

const mutations = {
  setAlarmsTS(state, value) {
    state.alarmsTS = value;
  },
  setFullScreen(state, value) {
    state.fullScreenEnabled = value;
  },
  setShowRemoveBtn(state, value) {
    state.showRemoveBtn = value
  },
  addFloatVideos(state, value) {
    state.floatVideos.push(value)
  },
  removeFloatVideos(state, value) {
    state.floatVideos = state.floatVideos.filter(video => {
      return video.id != value.id || video.type != value.type
    })
  },
};

const actions = {
  updateAlarms({ commit }) {
    commit('setAlarmsTS', new Date().getTime());
  },
  setFullScreen({ commit }, data) {
    commit('setFullScreen', data);
  },
  addFloatVideos({ commit }, data) {
    commit('addFloatVideos', data);
  },
  removeFloatVideos({ commit }, data) {
    commit('removeFloatVideos', data);
  },
};

const getters = {
  getFloatVideos(state) {
    return state.floatVideos;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
