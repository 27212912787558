export default {
  namespaced: true,
  sip: {
    dropdown: {
      calls_title: "Llamadas",
      calls_bandage: "Nuevas Llamadas",
      connected: "Conectado",
      answer_call: "Contestar",
      transfering_call: "Transfiriendo Llamada...",
      on_call_with: "En Llamada Con",
      calling_to: "Llamando a ",
      hang_up: "Colgar",
      incoming_call: "Llamada Entrante",
      alarm_from: "Alarma Desde",
      show_alarm: "Mostrar",
      hold: "Poner en espera",
      unhold: "Reanudar",
      holded: "En Espera",
      connect: "Conectar",
      disconnect: "Desconectar"
    },
    view:{
      title: "Llamadas",
      recording: "Grabación",
      calling_party_number: "Llamada desde",
      remote_party_number: "Hacia",
      state: "Estado",
      account: "Cuenta"
    }
  }
}
