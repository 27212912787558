import axios from '@axios'
import store from "@/store"

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    getItems(state) {
      return state.items
    },
    getSelectedItems(state) {
      return state.items.map(sip_call => ({
        value: sip_call.id,
        label: sip_call.sessionId,
      }))
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value
    },
  },
  actions: {
    async getAll({ commit }, params) {
      const response = await axios.get('v1/sip_call',
                                        {
                                          params,
                                          responseType: params?.action == 'export' ? 'blob': 'json'
                                        })

      if (!response.data) {
        return []
      }

      if (params?.action == 'export') {
        const url = window.URL.createObjectURL(new Blob([response.data],
          { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',
            response.headers["content-disposition"].split("filename=")[1]);
        document.body.appendChild(link);
        link.click();
        link.remove();

        const notification = {
          id: response.headers["content-disposition"].split("filename=")[1],
          progress: 100,
          completed: true,
          view: 'sip-call',
          url: url,
          download: response.headers["content-disposition"].split("filename=")[1],
          title: 'Reporte de llamadas',
          body: 'Descarga completa',
          error: false,
          timestamp: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
        }
        store.dispatch('grid/updateDownloadNotification', notification)
      } else {
        commit('setData', response.data.data)
        return response.data
      }
    },
    async add(_, params) {
      const { data } = await axios.post('v1/sip_call', params)

      return data
    },
    async edit(_, { id, params }) {
      const { data } = await axios.put(`v1/sip_call/${id}`, params)

      return data
    },
    async delete(_, id) {
      const { data } = await axios.delete(`v1/sip_call/${id}`)

      return data
    },
    clear({ commit }) {
      commit('setData', [])
    }
  },
}

