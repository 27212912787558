import axios from "@axios";
import store from "@/store";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    getItems(state) {
      return state.items;
    },
    getSelectedItems(state) {
      return state.items.map((logs) => ({
        data: logs.data,
        old_data: logs.old_data,
      }));
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value;
    },
  },
  actions: {

    async getAll({ commit }, params) {
      const { data } = await axios.get(`${process.env.VUE_APP_LOGS_MS_URL}/v1/logs`, {params});

      if (!data) {
        return [];
      }

      commit("setData", data.data);
      return data;
    },
    async add(_, params) {
      const { data } = await axios.post("v1/logs", params);

      return data;
    },
    async edit(_, { id, params }) {
      const { data } = await axios.put(`v1/logs/${id}`, params);

      return data;
    },
    async delete(_, id) {
      const { data } = await axios.delete(`v1/logs/${id}`);

      return data;
    },
    clear({ commit }) {
      commit("setData", []);
    },
  },
};
