export default {
  namespaced: true,
  person_list: {
    title: "lista de pessoas",
    list: "lista",
    luna_id: "luna id",
    people: "pessoas",
    review_list: "revisar lista",
    expected_similarity: "semelhança esperada",
    alarm_level: "nivel de alarme",
    add_person_list: "adicionar lista de pessoas",
    edit_person_list: "editar lista de pessoas",
  },
};
