export default {
  namespaced: true,
  buttons: {
    title: "buttons",
    button_type: "button type",
    description: " description",
    divice_id: "divice id",
    device_type_id: "device type id",
    button_report:"button report",
    all: "all buttons",
    preview: "Preview",
  },
};
