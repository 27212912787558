export default {
  namespaced: true,
  country: {
    title: "country | countries",
    code: "code",
    name: "name",
    telephone_code: "telephone code",
    region_count: "region count",
    created: "created",
    updated: "updated",
  },
};
