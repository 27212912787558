<template>
  <div class="toastification">
    <div class="d-flex align-items-start">
      <b-avatar
        size="1.8rem"
        class="mr-75 flex-shrink-0"
        :style="{ backgroundColor: data.alarm_level.color }"
      >
        <span v-if="data.alarm_acronym === 'SD'">
          <feather-icon icon="UserIcon" size="15" />
        </span>
        <span v-else-if="data.alarm_acronym === 'FR'">
          <feather-icon icon="EyeIcon" size="15" />
        </span>
        <span v-else-if="data.alarm_acronym === 'LPR'">
          <feather-icon icon="TruckIcon" size="15" />
        </span>
        <span v-else>
          <feather-icon :icon="icon" size="15" />
        </span>
      </b-avatar>
      <div class="d-flex flex-grow-1">
        <div>
          <h5
            class="mb-0 font-weight-bolder toastification-title"
            :class="`text-${variant}`"
            v-text="title ? title : resolveWindowTitle()"
          />
          <small
            class="d-inline-block text-body"
            v-text="text ? text : resolveWindowSubTitle()"
          />
        </div>
        <span
          class="cursor-pointer toastification-close-icon ml-auto"
          @click="$emit('close-toast')"
        >
          <feather-icon v-if="!hideClose" icon="XIcon" class="text-body" />
        </span>
      </div>
    </div>
    <div style="margin: 1rem 0 0 2.5rem">
      <table class="table table-sm table-borderless">
        <tr>
          <th>{{ trans.alarm.type }}</th>
          <td>
            <span class="mr-50">
              {{ data.alarm_type }}
            </span>
          </td>
        </tr>
        <tr>
          <th>{{ trans.alarm.unit }}</th>
          <td>{{ data.unit.description }}</td>
        </tr>
      </table>

      <a href="#" @click.prevent="onShowAlarm" class="d-block text-body">
        {{ trans.alarm.show }}
      </a>
    </div>
  </div>
</template>

<script>
import { BAvatar } from "bootstrap-vue";
import store from "@/store";

export default {
  components: {
    BAvatar,
  },
  props: {
    variant: {
      type: String,
      default: "primary",
    },
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
  },
  computed: {
    trans() {
      return store.getters["i18n/messages"]("notifications");
    },
  },
  methods: {
    resolveWindowTitle() {
      return this.data.action === "update"
        ? this.trans.alarm.update_alarm_title
        : this.trans.alarm.new_alarm_title;
    },
    resolveWindowSubTitle() {
      return this.data.action === "update"
        ? this.trans.alarm.update_alarm_subtitle
        : this.trans.alarm.new_alarm_subtitle;
    },
    onShowAlarm() {
      // store.dispatch("grid/setIncomingAlarm", {...this.data, alarmId: this.data.id});

      store.dispatch("grid/setAlarmId", this.data.id);
        store.commit(
          "psimConfig/UPDATE_TOOLBARS_COLLAPSED_RIGHT",
          0
        );
        store.commit(
          "psimConfig/UPDATE_TOOLBARS_COLLAPSED",
          1
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.toastification-close-icon,
.toastification-title {
  line-height: 26px;
}

.toastification-title {
  color: inherit;
}
</style>
