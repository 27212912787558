import config from './passportConfig'

export default class PassportService {
    axiosIns = null

    passportConfig = { ...config };

    constructor(axiosIns) {
      this.axiosIns = axiosIns

      this.axiosIns.interceptors.request.use(
        config => {
          // Get token from localStorage
          const accessToken = this.getToken()

          // If token is present add it to request's Authorization Header
          if (accessToken) {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `${this.passportConfig.tokenType} ${accessToken}`
          }

          config.signal = window.abortController.signal

          return config
        },
        error => Promise.reject(error),
      )
    }

    getToken() {
      return localStorage.getItem(this.passportConfig.storageTokenKeyName)
    }

    getRefreshToken() {
      return localStorage.getItem(this.passportConfig.storageRefreshTokenKeyName)
    }

    setToken(value) {
      localStorage.setItem(this.passportConfig.storageTokenKeyName, value)
    }

    setRefreshToken(value) {
      localStorage.setItem(this.passportConfig.storageRefreshTokenKeyName, value)
    }

    login(...args) {
      return this.axiosIns.post(this.passportConfig.loginEndpoint, ...args)
    }

    register(...args) {
      return this.axiosIns.post(this.passportConfig.registerEndpoint, ...args)
    }

    refreshToken() {
      return this.axiosIns.post(this.passportConfig.refreshEndpoint, {
        refreshToken: this.getRefreshToken(),
      })
    }
}
