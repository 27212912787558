import axios from "@axios";
import store from "@/store";
import {useUtils as useI18nUtils} from "@core/libs/i18n";
export default {
  namespaced: true,

  state: {
    alarmViews: [],

    // Layout -----------------------------
    slotData: null,
    slotSelection: false,
    layoutItems: [],
  },
  getters: {
    layoutItems(state) {
      return state.layoutItems;
    },
  },
  mutations: {
    setAlarmViews(state, value) {
      state.setAlarmViews = value;
    },

    // Layout -----------------------------
    setSlotData(state, value) {
      state.slotData = value;
    },
    setSlotSelection(state, value) {
      state.slotSelection = value
    },
    setLayoutItem(state, value) {
      state.layoutItems.push(value);
    },
    emptyLayoutItems(state) {
      state.layoutItems = [];
    },
    setLayoutItems(state, value) {
      state.layoutItems = [...value];
    },
    removeLayoutItem(state, value) {
      const i = state.layoutItems.map(item => item.pos).indexOf(value);
      if (i >= 0) {
        state.layoutItems.splice(i, 1);
      }
    },
    clear(state) {
      state.alarmViews = []
      state.slotData = null
      state.slotSelection = false
      state.layoutItems = []
    }
  },
  actions: {
    async export({commit}, params) {
      const response = await axios.get("v1/services/views/get-views", {
        params,
        responseType: params?.action == "export" ? "blob" : "json",
      });
      if (!response.data) {
        return [];
      }
      if (params?.action == "export") {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        const {t} = useI18nUtils();

        const notification = {
          id: response.headers["content-disposition"].split("filename=")[1],
          progress: 100,
          completed: true,
          view: "Vista de alarmas",
          url: url,
          download: response.headers["content-disposition"].split("filename=")[1],
          title: "Reporte de Vista de alarmas",
          body: "Descarga completa",
          error: false,
          timestamp:
            new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(),
        };
        store.dispatch("grid/updateDownloadNotification", notification);
      } else {
        commit("setData", response.data.data);
        return response.data;
      }
    },
    async getAlarmViews({ commit }, params) {
      const { data } = await axios.get('v1/services/views/get-views', { params })
      commit('setAlarmViews', data.data);

      if (!data.success) {
        return [];
      }

      return data;
    },

    // Layout -----------------------------
    addLayoutItem({ state, commit }, data) {
      commit('removeLayoutItem', data.pos);
      commit('setLayoutItem', data);
    },
    emptyLayoutItems({ commit }) {
      commit('emptyLayoutItems');
    },
    setSlotData({ commit }, data) {
      commit('setSlotData', data);
      commit('setSlotSelection', true);
    },
    removeLayoutItem({ commit }, data) {
      commit('removeLayoutItem', data);
    },
    resetSlotSelection({ commit }, data) {
      commit('setSlotData', null);
      commit('setSlotSelection', false);
    },
    clear({ commit }) {
      commit('clear')
    }
  },
}
