export default {
  namespaced: true,
  traffic: {
    title: "traffic",
    request: "request",
    created: "arrived",
    imei: "imei",
    ip: "ip",
    alarm: "alarm",
  },
};
