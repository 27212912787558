export default {
  namespaced: true,
  camera_proxy: {
    title: "camera proxy | cameras proxy",
    id: "camera proxy id",
    rtsp_available: "Ativar transmissão RTSP",
    last_connection: "última conexão",
    assigned: "atribuída"
  },
};
