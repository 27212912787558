import axios from '@axios'
import store from "@/store"
import { EventBus } from "@/libs/event-bus";


export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    getItems(state) {
      return state.items
    },
    getSelectedItems(state) {
      return state.items.map(device_interphone => ({
        value: device_interphone.id,
        label: device_interphone.device_id,
      }))
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value
    },
  },
  actions: {
    async getAll({ commit }, params) {
      const response = await axios.get('v1/device_interphone',
                                        {
                                          params,
                                          responseType: params?.action == 'export' ? 'blob': 'json'
                                        })

      if (!response.data) {
        return []
      }

      if (params?.action == 'export') {
        const url = window.URL.createObjectURL(new Blob([response.data],
          { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',
            response.headers["content-disposition"].split("filename=")[1]);
        document.body.appendChild(link);
        link.click();
        link.remove();

        const notification = {
          id: response.headers["content-disposition"].split("filename=")[1],
          progress: 100,
          completed: true,
          view: 'device-interphone',
          url: url,
          download: response.headers["content-disposition"].split("filename=")[1],
          title: 'Reporte de dispotivo interfone',
          body: 'Descarga completa',
          error: false,
          timestamp: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
        }
        store.dispatch('grid/updateDownloadNotification', notification)
      } else {
        commit('setData', response.data.data)
        return response.data
      }
    },
    async add(_, params) {
      const { data } = await axios.post('v1/device_interphone', params)

      return data
    },
    async edit(_, { id, params }) {
      const { data } = await axios.put(`v1/device_interphone/${id}`, params)

      return data
    },
    async delete(_, id) {
      const { data } = await axios.delete(`v1/device_interphone/${id}`)

      return data
    },
    subscribeToForceDisconnect({ state, commit, dispatch, getters }, params) {
      const channelName = `sip-force-disconnect-${params.device_id}`;
      console.log(`Entrando a subscribeToForceDisconnect para el dispositivo: ${params.device_id}`);

      // Websocket Omnia api
      window.Echo.connector.socket.on("subscription_error", (channel, data) => {
        console.log(`SIP::subscription_error en el canal: ${channel}`, data);
      });

      if (!window.Echo.connector.socket.connected) {
        console.log("WebSocket no está conectado, intentando conectar...");
        window.Echo.connect();
      }

      try {
        if (!window.Echo.connector.channels[channelName]) {
          console.log(`Subscribiendo al canal: ${channelName}`);
          window.Echo.private(channelName)
            .listen(".forceDisconnect", (data) => {
              console.log(`Evento forceDisconnect recibido para el dispositivo: ${params.device_id}`);
              EventBus.emit("disconnect_sip");
            });

          console.log(`Suscripción al canal ${channelName} completada.`);
        } else {
          console.log(`Ya está suscrito al canal: ${channelName}`);
        }
      } catch (error) {
        console.log(`ERROR AL SUSCRIBIR al canal: ${channelName}`, error);
      }
    },
    unSubscribeToForceDisconnect({ state, commit, dispatch, getters }, params) {
      console.log(`Entrando a unSubscribeToForceDisconnect para el dispositivo: ${params.device_id}`);
      window.Echo.leave("sip-force-disconnect-"+params.device_id);
    },
    clear({ commit }) {
      commit('setData', [])
    }
  },
}
