export default {
  namespaced: true,
  country: {
    title: "pais | paises",
    code: "código",
    name: "nome",
    telephone_code: "código de telefone",
    region_count: "contagem de região",
    created: "criada",
    updated: "atualizada",
  },
};
