export default {
  namespaced: true,
  layout_builder: {
    title: 'Layouts',
    action_add: 'Crear layout',
    action_update: 'Actualizar layout',
    fields: {
      name: {
        label: 'Nombre del Layout',
        placeholder: 'Nombre del Layout'
      },
      tenant: {
        label: 'Tenant',
        placeholder: 'Tenant'
      },
      users: {
        label: 'Usuarios',
        placeholder: 'Usuarios'
      },
      default: {
        label: 'Por defecto',
      }
    },
    btns: {
      add_slot: 'Agregar espacio',
      save: 'Guardar',
    },
    strings: {
      optional: 'Opcional',
    },
    messages: {
      no_blank_spaces: 'No puede haber espacio en blanco',
      no_enough_space: 'No hay espacio suficiente para un elemento nuevo',
      created_success: 'Layout creado correctamente',
      updated_success: 'Layout actualizado correctamente'
    },
    is_default: 'Por defecto',
    preview: 'Vista previa',
  }
}
