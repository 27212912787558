export default {
  namespaced: true,
  region: {
    title: "estado | estados",
    name: "nome",
    id: "ID",
    code: "código",
    acronym: "sigla"
  },
}
