const langs = ["en", "es", "pt"];
const files = [
  "chat",
  "base",
  "grid",
  "video_watermarks",
  "layout_builder",
  "alarm_views",
  "recording_request",
  "actions",
  "messages",
  "alarm_level",
  "buttons",
  "alarm",
  "seproban",
  "commands",
  "router",
  "bridge",
  "camera",
  "log_incident",
  "presets",
  "traffic",
  "access_devices",
  "token",
  "action_plan",
  "sip",
  "notifications",
  "logs",
  "profiles",
  "swift_collector",
  "swift_sensor",
  "swift_account",
  "pages",
  "external_token",
  "external_user",
  "intelexvision",
  "alarm_comment",
  "pagination",
  "unit_groups",
  "tenants",
  "master_tenants",
  "video_wall",
  "intelexvision_alarm_type",
  "vxg_camera",
  "person_list",
  "reseller",
  "intelexvision_event",
  "vxg_master_account",
  "region",
  "auth_two_factor",
  "country",
  "safr",
  "login",
  "camera_proxy",
  "luna",
  "purge_alarm",
  "dahua_dvr",
  "dahua_camera",
  "cm_master_account",
  "schedule_alarm",
  "luxriot",
  "density_device",
  "detection_event",
  "unit",
  "omniview_reseller",
  "plane",
  "municipality",
  "users",
  "generic_devices",
  "account_settings",
  "plate",
  "breadcrumbs",
  "log_microservice",
  "tags"
];

const messages = {};

for (const lang of langs) {
  for (const fileName of files) {
    const {default: file} = require(`./${lang}/${fileName}`);
    const fileContent = file.namespaced ? file : file[fileName];
    if (messages[lang]) {
      messages[lang] = Object.assign(messages[lang], {...fileContent});
    } else {
      messages[lang] = {};
      messages[lang] = Object.assign(messages[lang], {...fileContent});
    }
  }
}

export default messages;
