export default {
  namespaced: true,
  commands: {
    title: "Custom command",
    title_main: "Custom command",
    action_add: 'Create custom command',
    action_update: 'Update custom command',
    base_commands: 'Base commands',
    custom_commands: 'Custom commands',
    fields: {
      name: {
        label: 'Command name',
        placeholder: 'Command name'
      },
      description: {
        label: 'Command description',
        placeholder: 'Command description'
      },
      alarm_compatible: {
        label: "Is it compatible with alarms?"
      },
      requirements: {
        label: 'Requirements (optional)'
      }
    },
    messages: {
      success_created: 'Custom command successfully created',
      success_updated: 'Custom command successfully updated',
      layout_empty: 'Cannot save an empty command',
      connections_empty: 'Connectionless nodes are not allowed',
      connections_qty_exceeded: 'Nodes with multiple connections are not allowed',
      incomplete_node_config: 'Incomplete configuration on the following nodes',
    },
    btns: {
      delete_image: 'Delete image',
      create: 'Save',
      update: 'Save',
      custom: 'Custom',
      base: 'Base',
    },
    requirements: {
      cols: {
        col1: 'Type',
        col2: 'Multiple selec.'
      }
    },
    list: {
      enable_unit_cameras_do: {
        name: "Activate cameras digital output",
        desc: "Activate digital output of the unit cameras",
      },
      enable_speaker: {
        name: "Activate DO1 of the router",
        desc: "Turn on the router digital output"
      },
      show_user_view: {
        name: "Show view/tab",
        desc: "Show a view/tab on SBO"
      },
      delay: {
        name: "Delay",
        desc: "",
        hint: "Amount of time in seconds:"
      },
      active_digital_output_camera: {
        name: "Activate digital output of the cameras",
        desc: "Activate digital output of the cameras",
        hint: "",
      },
      activate_digital_output_camera: {
        name: "Activate digital output of the cameras",
        desc: "Activate digital output of the cameras",
        hint: "",
      },
      deactivate_digital_output_camera: {
        name: "Deactivate digital output of the cameras",
        desc: "Deactivate digital output of the cameras",
        hint: "",
      },
      deactivate_sound_router: {
        name: "Deactivate DO1 of the router",
        desc: "Turn off the router digital output",
        hint: "",
      },
      interphone_call: {
        name: "Interphone call",
        desc: "Make a interphone call"
      },
      active_digital_output_rtu_router: {
        name: "Activate digital output RTU router",
        desc: "Activate digital output of specific digital output from router",
        hint: "",
      },
      density_shoot: {
        name: "Perform real shoot of fog",
        desc: "Perform a real shoot of fog from the specified device",
      },
      density_test_shoot: {
        name: "Perform test shoot of fog",
        desc: "Perform a test shoot of fog from the specified device",
      },
      notification_command: {
        name: "Send notification",
        desc: "Send notification from diferent ways like SMS, Email, Webhook, Whatsapps ...",
      },
      show_action_plan: {
        name: "Show an action plan",
        desc: "Shows the specified action plan",
      },
      send_c5_edomex: {
        name: "Send alarm to C5 EDOMEX",
        desc: "Send information about an alarm to C5 EDOMEX",
      },
      camera_round: {
        name: "Round of cameras",
        desc: "Command to start a round of cameras",
      },
      assignment_cameras: {
        name: "Camera assignment",
        desc: "Camera assignment",
      },
    },
    form: {
      units_select: {
        label: 'Select units for this node:'
      },
      cameras_select: {
        label: 'Select cameras for this node:'
      },
      user_views_select: {
        label: 'Select views/tabs for this node:'
      },
      users_select: {
        label: 'Select the users for this node:'
      },
      interphone_from: {
        label: 'Select the source phone:'
      },
      interphone_to: {
        label: 'Select the destination phone:'
      },
      density_device: {
        label: 'Select device Density:'
      },
      type_notificaction: {
        label: 'Type notification:'
      },
      type_verb_webhook: {
        label: 'RESTful verb:'
      },
      url1: {
        label: 'URL alarm:'
      },
      url2: {
        label: 'URL comments alarm:'
      },
      action_plan: {
        label: 'Select an action plan:'
      },
      layouts: {
        label: 'Select a layout:'
      },
      location: {
        label: 'Enter the location:',
        fields: {
          lat: 'Latitude',
          lng: 'Longitude',
          from_alarm: 'Take value from alarm'
        }
      },
      radius: {
        label: 'Enter the radius:'
      },
      delay: {
        label: 'Enter the delay:'
      },
      loop: {
        label: 'Loop:'
      },
      message_template: {
        label: 'Message template',
        characters_remaining: 'Characters remaining',
        available_placeholders: 'Available placeholders',
        help: {
          header: 'Write the text that will be sent by WhatsApp. You can use the following placeholders that will later be replaced with the alarm information:',
          footer: {
            title: 'Example',
            body: 'Command executed from facility @facility_name, alarm id #@id_alarm of type @alarm_type'
          }
        }
      },
      groups_select: {
        label: 'Select the groups for this node:'
      },
      tenants_select: {
        label: 'Select the tenants for this node:'
      },
      centers_select: {
        label: 'Select the centers for this node:'
      },
      sectors_select: {
        label: 'Select the sectors for this node:'
      },
      filter_field: {
        label: 'Add filter for this node:'
      },
      email: {
        label: 'Enter the emails',
      },
      phone: {
        label: 'Enter the phones',
      },
      receiver: {
        label: 'Enter the receiver',
      },
      authorization: {
        label: 'Enter your authorization key'
      },
      url1: {
        label: 'Enter the URL of alarm',
      },
      url2: {
        label: 'Enter the URL of feedbacks of alarm',
      }
    },
    data_types: {
      users: 'Users',
      user_views: 'User views',
      units: 'Facilities',
      cameras: 'Cameras',
      phone: 'Phones',
      email: ' E-mails',
      log_id: 'Logs',
      do: 'Digital Output',
      interphone_from: 'Interphone call (from)',
      interphone_to: 'Interphone call (to)',
      message_template: 'Message',
      type: 'Type of notification',
      receiver: 'Receiver',
      verb: 'RESTful verb',
      authorization: 'Authorization',
      url1: 'URL alarm',
      url2: 'URL comments alarm',
      webhook: 'URL Webhook',
      action_plan: 'Action plan',
      delay: 'Delay',
      layout: 'Layouts',
      location: 'Location',
      radius: 'Radius',
      loop: 'Loop',
      density_device: 'Density device',
      groups: 'Groups',
      tenants: 'Tenants',
      centers: 'Centers',
      sectors: 'Sectors',
    },
    flowchart: {
      show_hide_node_config: 'Show/hide command settings',
    },
    gridWidget: {
      title: 'Scripts',
      table: {
        no_data: "No data available",
        rows: {
          1: "Name",
          2: "Description",
        }
      }
    },
    modal: {
      title: 'Script Execution',
      body: 'The following script will be executed',
      btns: {
        continue: 'Continue',
        cancel: 'Cancel'
      },
      messages: {
        success: 'Status: Command executed successfully',
        error: 'Status: An error occurred'
      }
    },
    config_modal: {
      title: 'Command configuration',
      messages: {
        invalid_form: 'Please correct the errors in the form',
      }
    },
    logs: {
      title: 'Command Logs',
      filters: {
        commands: 'Commands'
      },
      table: {
        id_operation: 'Operation ID',
        user: 'User',
        description: 'Description',
        command: 'Command',
        command_source: 'Related comando',
        level: 'Level',
        data: 'Info',
        date: 'Date'
      }
    },
    units: {
      seconds: 'second(s)',
      meters: 'meter(s)',
    }
  }
}
