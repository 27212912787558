export default {
    namespaced: true,
    users: {
        title: "usuário | usuários",
        email: "correio eletrônico",
        name: "nome",
        username : "nome de usuário",
        first_name: "primeiro sobrenome",
        second_name: "segundo sobrenome",
        password: "senha",
        phone_number: "número de telefone",
        active: "ativo",
        vpn_access: "acesso vpn",
        last_login: "último login",
        alarms_history: "histórico de alarme",
        allowed_units_live_video_flows: "fluxos de vídeo permitidos",
        alarm_history_per_hours: "horas permitidas de histórico de alarme",
        alarm_history_per_days: "dias permitidos de histórico de alarme",
        alarm_history_by_records: "registros de histórico de alarme permitidos",
        plate_number: "no. da placa o no. de funcionário",
        shared_tenants: "tenants compartilhados",
        video_wall: "video wall",
        info: "info",
        saving_info: "guardando as informações do usuário",
        enabled_chat: "bate-papo ativado",
        id_country: "identificação do país"
    },
};
