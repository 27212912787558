import Vue from 'vue';
import { v4 as uuidv4 } from 'uuid'

const state = {
  activeTab: 'tab_1',
  tabs: {
    'tab_1': {
      name: 'Tab 1',
      layout: 1,
      slots: [],
      type: 'default',
      order: '1'
    },
  },
};

const getters = {
  tabs(state) {
    return state.tabs;
  },
  activeTabId(state) {
    return state.tabs ? state.activeTab : null;
  },
  currentTab(state) {
    return state.tabs ? state.tabs[state.activeTab] : null;
  },
  currentTabSlots(state) {
    return state.tabs[state.activeTab].slots;
  },
  tmpLayoutItems(state) {
    return state.tmpLayoutItems;
  },
  getSideBarItem(state) {
    return (id) => {
      return state.sideBarItems.find(item => item.id === id) || state.bottomBarItems.find(item => item.id === id);
    };
  },
};

const mutations = {
  setActiveTab(state, value) {
    state.activeTab = value;
  },
  addNewTab(state, value) {
    if (Array.isArray(state.tabs)) state.tabs = {};

    if (state.tabs) {
      // if (Array.isArray(state.tabs)) state.tabs = {};

      const tabsLength = Object.keys(state.tabs).length;
      Vue.set(state.tabs, value.uuid, {
        name: value.name,
        layout: value.layoutId,
        slots: [],
        type: value.type,
        order: tabsLength + 1,
        color: value.color
      });
      Object.keys(state.tabs).forEach((key, index) => {
        state.tabs[key].order = index + 1;
        if (/Tab\ [\d]+/.test(state.tabs[key].name)) {
          state.tabs[key].name = `Tab ${state.tabs[key].order}`;
        }
      })
    }
  },
  updateLayoutInTab(state, value) {
    state.tabs[state.activeTab].layout = value;
  },
  removeTab(state, value) {
    Vue.delete(state.tabs, value);
    Object.keys(state.tabs).forEach((key, index) => {
      state.tabs[key].order = index + 1;
      if (/Tab\ [\d]+/.test(state.tabs[key].name)) {
        state.tabs[key].name = `Tab ${state.tabs[key].order}`;
      }
    })
  },
  setTabs(state, value) {
    state.tabs = value;
  },
  setTab(state, value) {
    state.tabs = value;
  },
  updateTabType(state, value) {
    Object.keys(state.tabs).forEach((key) => {
      if (key == value.id) {
        state.tabs[key].type = value.type;
      }
    });
  },
  editTabName(state, data) {
    Object.keys(state.tabs).forEach((key) => {
      if (key == data.data.id) {
        state.tabs[key].name = data.data.name
      }
    });
  },
  addTabView(state, value) {
    Vue.set(state.tabs, value.id, {
      name: value.name,
      layout: value.layout,
      slots: [...value.slots],
      type: value.type,
      order: value.order
    });
  },
};

const actions = {
  setActiveTab({ commit, state }, value) {
    const tabFound = Object.keys(state.tabs).find((key) => key === value);
    commit('setActiveTab', tabFound ? value : Object.keys(state.tabs)[0]);
  },
  addNewTab({ commit, dispatch, state, getters }, data = null) {

    return new Promise(resolve => {

      // Avoid duplicated tabs
      const tab = data && data.tabName ?
        Object.values(state.tabs).find(t => t.name === data.tabName) :
        undefined;

      const tabName = data && data.tabName ? data.tabName : null;

      if (tab === undefined) {
        const uuid = uuidv4();
        let selectedLayout = null;
        selectedLayout = getters.userLayouts[0] ? getters.userLayouts[0].id : null;

        if (data && data.viewData) {
          let viewData = data.viewData;

          commit('addTabView', viewData);
          dispatch('updateUserConfig');

        } else {

          if (data && data.layout) {
            if (data.layout === 'default') {
              selectedLayout = data.layout;
            } else {
              let layout = getters.userLayouts.find(layout => layout.id === data.layout);
              if (!layout) {
                layout = getters.userLayouts.find(layout => String(layout.name).toLowerCase() === String(data.layout).toLowerCase());
              }
              selectedLayout = layout ? layout.id : selectedLayout;
            }
          }
          const type = data && data.type ? data.type : 'default';

          commit('addNewTab', { uuid, name: tabName, layoutId: selectedLayout, type });
          dispatch('updateUserConfig');
        }

        resolve({ status: true, id: uuid });

      } else {
        resolve({ status: false, id: '' });
      }
    });
  },
  removeTab({ commit, dispatch }, data) {
    commit('removeTab', data);
    dispatch('updateUserConfig');
  },
  setLayout({ commit, dispatch }, data) {
    commit('updateLayoutInTab', data);
    dispatch('updateUserConfig');
  },
  setTabs({ commit }, value) {
    commit('setTabs', value);
  },
  setTab({ commit }, value) {
    commit('setTab', value);
  },
  updateTabType({ commit }, value) {
    commit('updateTabType', value);
  },
  editTabName({ commit, dispatch }, data) {
    commit('editTabName', data);
    dispatch('updateUserConfig');
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
