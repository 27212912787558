export default {
  namespaced: true,
  region: {
    title: "region | regions",
    name: "name",
    id: "ID",
    code: "code",
    acronym: "acronym"
  },
}
