export default {
  namespaced: true,
  router: {
    title: "router | routers",
    status: "status",
    description: "description",
    latitude: "latitude",
    longitude: "longitude",
    velocity: "velocity",
    direction: "direction",
    CAD_folio: "CAD folio",
    address: "address",
    active: "active",
    notify_cad_c5_cdmx: "notify CAD C5 CDMX",
    notify_cad_c5_jalisco: "notify CAD C5 jalico",
    notify_cad_c5_edomex: "notify CAD C5 EDOMEX",
    altitude: "altitude",
    imei: "IMEI",
    totem_key: "totem key",
    totem_id: "id totem",
    ip_vpn: "IP VPN",
    last_log: "last log",
    has_router: "Has Router",
  }
}
