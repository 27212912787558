export default {
  namespaced: true,
  alarm_views: {
    title: 'Alarm views',
    action_add: 'Create View',
    action_update: 'Update View',
    subtitle_1: 'General Settings',
    subtitle_2: 'Layout Configuration',
    is_default: 'is default',
    fields: {
      name: {
        label: 'View name',
        placeholder: 'View Name',
      },
      tenant: {
        label: 'Tenant',
        placeholder: 'Tenant',
      },
      users: {
        label: 'Users',
        placeholder: 'Users',
      },
      alarm_level: {
        label: 'Alarm level',
        placeholder: 'Alarm level',
      },
      alarm_type: {
        label: 'Type of alarm',
        placeholder: 'Type of alarm',
      },
      alarm_status: {
        label: 'Alarm status',
        placeholder: 'Alarm status',
      },
      default_view: {
        label: 'Default view',
      },
      periodicity: {
        label: 'Periodicity',
        always: 'Always',
        days: {
          sun: 'S',
          mon: 'M',
          tue: 'T',
          wed: 'W',
          thu: 'T',
          fri: 'F',
          sat: 'S',
        },
        from: 'from',
        to: 'to',
        messages: {
          invalid_schedule: 'Schedule list empty or there are invalid schedules'
        },
        btns: {
          delete: 'Delete',
          add: 'Add schedule'
        }
      },
    },
    strings: {
      tags: 'Tags'
    },
    messages: {
      layout_cannot_be_empty: 'The layout cannot be empty',
      success_created: 'View created successfully',
      success_updated: 'View updated successfully',
    },
    btns: {
      create: 'Create',
      update: 'Update'
    }
  }
}
