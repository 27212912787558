import axios from "@axios";

export default {
  namespaced: true,
  state: {
    items: [],
    view: [],
  },
  getters: {
    getItems(state) {
      return state.items;
    },
    getViews(state) {
      return state.view;
    },
    getUserSelect(state) {
      return state.userSelect;
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value;
    },
    setDataView(state, value) {
      state.view = value;
    },
    setVideoWallUserStatus(state, value) {
      try {
        const userIndex = state.items.findIndex(u => u.id === value.id_user);
        state.items[userIndex].active_sesion_video_wall = value.status === 'online' ? 'on' : 'off';
      } catch (error) {
        // ...
      }
    }
  },
  actions: {
    async uservideowall({ commit }) {
      const { data } = await axios.get("v1/video-wall/users");

      if (!data.success) {
        return [];
      }

      let users = [];
      let responseData = data.data;

      if (responseData.length) {

        responseData.forEach((user) => {
          users.push({
            id: user.id,
            name: `${user.name} ${user.first_name} ${user.second_name}`,
            active_sesion_video_wall: "off",
            view: user.video_wall_view
          });
        });

        commit("setData", users);
      }
    },

    async updateVideoWallUserStatus(_, req) {
      await axios.post(`v1/video-wall/update-user-status`, req);
    },

    async ping({ commit, getters }) {
      const { data } = await axios.get(`v1/video-wall/ping`);
      const usersStatus = data.data.users;
      const users = JSON.parse(JSON.stringify(getters['getItems']));

      users.forEach(user => {
        const userIndex = usersStatus.findIndex(u => u.id === user.id);
        const status = {
          id_user: user.id,
          status: userIndex >= 0 ? 'online' : 'offline'
        };
        commit('setVideoWallUserStatus', status);
      });
    },

    async setVideoWallUserStatus({ commit }, data) {
      commit('setVideoWallUserStatus', data);
    },

    clear({ commit }) {
      commit("setData", []);
    },
  },
};
