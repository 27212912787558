export default {
  namespaced: true,
  auth_two_factor: {
    title: "autenticación dos pasos",
    description:
      "Para habilitar la autenticación en dos pasos, seleccione cualquiera de los métodos disponibles. Posteriormente se le hará llegar un código a través del método seleccionado el cual deberá ingresar en el cuadro de texto correspondiente. Una vez finalizado este paso, haz clic en guardar y se habilitará la autenticación de dos factores.",
    verify: "verificar",
    send: "enviar",
    saved_successfully: "guardado exitosamente",
    an_error_occurred: "ocurrió un error",
    sending_mail: "enviando correo",
    email_sent: "email enviado",
    sending_sms: "enviando sms",
    sms_sent: "sms enviado",
    checking_email: "revisando el correo electronico",
    email_verification_successful: "verificación de correo electrónico exitosa",
    checking_sms: "revisando sms",
    sms_verification_successful: "verificación de sms exitosa",
    change_authentication_method:"Cambiar medio de autenticacion"
  },
};
