export default {
  namespaced: true,
  recording_request: {
    title: "solicitação de gravação | solicitação de gravações",
    folio: "Folha",
    status: "Status",
    requested: "Requeridos",
    description: "Descrição",
    tag: "tag",
    start: "começo",
    finish: "Fim",
    authorized_from: "autorizado de",
    authorized_to: "autorizado até",
    recording: "gravação",
    download_timestamp: "Deseja incluir a data e hora na gravação?",
    time_expired: "O tempo autorizado para visualizá-lo expirou",
    comment: "comentário",
    authorized_by: "Autorizado por",
    files: "arquivos",
  }
}
