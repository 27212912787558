
export default {
  namespaced: true,
  state: {
    camera: null,
    token: null,
    mode: 'player',
    groupId: null,
  },
  getters: {
  },
  mutations: {
    setCamera(state, value) {
      state.camera = value;
    },
    setToken(state, value) {
      state.token = value;
    },
    setMode(state, value) {
      state.mode = value;
    },
    setGroupId(state, value) {
      state.groupId = value;
    }
  },
  actions: {},
}
