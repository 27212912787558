export default {
  namespaced: true,
  traffic: {
    title: "traffic",
    request: "petición",
    created: "llegada",
    imei: "imei",
    ip: "ip",
    alarm: "alarma",
  },
};
