export default {namespaced: true,
  municipality: {
    title: "municipality | municipalities",
    name: "name",
    acronym: "acronym",
    id_region: "id region",
    updated_at: "updated at",
    created_at: "created at",
    id: "id"
  },
};

