export default {
    namespaced: true,
    detection_event: {
      title: "detetion event|detections events",
      event_type: "event type",
      event_created_at: "Event created at",
      provider: "provider",
      data: "data",
      metadata:"metadata",
      media: "media",
    },
  };
  