export default {
    namespaced: true,
    luxriot: {
        title: 'Luxriot',
        name: 'Nombre',
        enabled: 'Activo',
        options: 'Options',
        source: 'Fuente',
        from: 'De',
        to: 'A',
        placeholderDate1: 'Seleccionar desde',
        placeholderDate2: 'Seleccionar hasta',
        resetFilters: 'Resetear filtros',
        password: 'Password',
        user: 'Usuario',
        tenant: 'Tenants',
        editMasterAccount: 'Editar cuenta maestra',
        placeholderMasterAccount: 'Seleccionar tenant',
        placeholderDescription: 'Ingresar descriptción',
        placeholderEndpoint: 'Ingresar enpoint',
        delete: 'Eliminar',
        master_account: 'Cuenta maestra',
        editCamera: 'Editar cámara',
        sector: 'Sector',
        center: 'Centro',
        units: 'Instalaciónes',
        full_name: 'Nombre completo',
        placeholderFullName: 'Ingresar nombre completo',
        placeholderEmail: 'Ingresar email',
        placeholderSector: 'Ingresar sector',
        placeholderMasterAccount: 'Ingresa cuenta maestra',
        placeholderDirectory: 'Ingresar directorio',
        placeholderTenants: 'Ingresar tenants',
        placeholderCenter: 'Ingresa centro',
        placeholderUnit: 'Ingresar instalación',
        placeholderLink: 'Ingresar enalace',
        placeholderTitle: 'Ingresar usuario',
        placeholderPassword: 'Ingresar password',
        placeholderAlias: 'Ingresar alias',
        update_from_api: "Actualizar cámaras desde API",
        addMasterAccount: "Agregar cuenta maestra",
        link: "Enlace",
        email: "E-mail",
        full_name: "Nombre completo",
        type: "Tipo",
        channel: "Canal",
        alias: "Alias",
        delayed_record_minutes: "Minutos de retraso",
        forward_record_minutes: "Minutos adelante",
        idCameraProxy: "Id de Cámara Proxy",
        luxriot_cameras: "Cámaras Luxriot",
    }
}
