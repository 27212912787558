export default {
  namespaced: true,
  actions: {
    name: "action | actions",
    add: "add",
    accept: "accept",
    edit: "edit",
    delete: "delete",
    cancel: "cancel",
    filter: "filter | filters",
    update: "update",
    export: "export",
    close: "close",
    save: "save",
    save_changes: "save changes",
    send: "send",
    download: "download",
    reset: "reset",
    drag_drop_1: "Drag and drop to upload content!",
    drag_drop_2: "...or click to select a file from your computer",
    load: "load",
    refresh: "refresh",
    next: "next",
    previous: "previous",
    finish: "finish",
    delete_all: "delete all",
    select_deselect_all: "select/deselect all",
  }
}
