export default {
  namespaced: true,
  login: {
    password: "Senha",
    email: "E-mail",
    select_method_the_type_of_authentication: "Selecione o método o tipo de autenticação",
    code: "código",
    resend_code: "Reenviar código...",
    you_can_resend_the_code_in_5_min: "Você pode reenviar o código em 5 minutos ",
    get_into: "Início da sessão",
    authentication_code_sent: "Código de autenticação enviado",
    incorrect_code: "Incorrect code",
    the_code_expired_request_a_new_code: "O código expirou, solicite um novo código",
    change_means_of_authentication: "Cambiar medio de autenticacion",
    the_email_field_is_required: "O campo Email é obrigatório",
    the_password_field_is_required: "O campo de senha é necessária",
    welcome_back: "Bem vindo de novo",
    failed_to_login:"não consegui fazer login"
  },
};
