export default {
  namespaced: true,
  swift_account: {
    title: "Cuentas swift",
    id_external: "id externa",
    name: "Nome",
    is_enabled: "ativado",
    is_monitored: "monitorou",
    retention: "retenção",
    brand_id: "id da marca",
    language: "Língua",
    creation_time: "Tempo da Criação",
    id_parent: "id parent"
  },
}
