export default {
    namespaced: true,
    tags: {
        title: 'Add Tag',
        category: 'Tag',
        name: 'Tag name',
        description: 'Description',
        date: 'Date and Time',
        image: 'Image',
        placeholderCategory: 'Select a category',
        placeholderDescription: 'Enter description',
    }
}
