export default {
  namespaced: true,
  luna: {
    title: "Lua",
    master_account: {
      title: "Conta mestre",
      name: "Nome",
      description: "Descrição",
      api_email: "Correio api",
      api_password: "Senha da api",
      api_server: "Servidor de api"
    },
    list:{
      title: "Lista",
      name_list : "Nome lista",
      id_luna: "Id Lua",
      people: "Pessoas",
      review_list: "Lista de Revisão",
      expected_similarity: "Semelhança Esperada",
      alarm_level: "Nível de alarme",
      white_list: "Lista branca"
    },
    face:{
      title: "Cara",
      name: "Nome Cara",
      avatar: "Avatar",
      detection: "Detecção",
      face_id: "Cara id",
      external_id: "Id externo",
      event_id: "Id do evento",
      image: "Imagem",
      age: "Idade",
      gender: "Gênero",
      predominant_ethnicity: "Etnia capturada",
      similarity_match: "Correspondência de similaridade"
    },
    facestream:{
      title: "Transmissão facial",
      name: "Nome",
      description: "Descrição",
    },
    face_detection:{
      title: "Detecção de rosto",
    },
  }
};
