export default {
  namespaced: true,
  sip: {
    dropdown: {
      calls_title: "Chamadas",
      calls_bandage: "Novas Chamadas",
      connected: "Conectado",
      answer_call: "Para responder",
      transfering_call: "Transferindo chamada...",
      on_call_with: "Na chamada com",
      calling_to: "Chamando para ",
      hang_up: "Desligar",
      incoming_call: "Chamada recebida",
      alarm_from: "Alarme de",
      show_alarm: "Mostrar",
      connect: "Conectar",
      disconnect: "Desconectar"
    },
    view:{
      title: "Chamadas",
      recording: "Gravação",
      calling_party_number: "Chamada de",
      remote_party_number: "Para",
      state: "Estado",
      account: "Conta"
    }
  }
}
