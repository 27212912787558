export default {
  namespaced: true,
  sip: {
    dropdown: {
      calls_title: "Calls",
      calls_bandage: "New Calls",
      connected: "Connected",
      answer_call: "Answer Call",
      transfering_call: "Transfering Call...",
      on_call_with: "On Call With",
      calling_to: "Llamando a ",
      hang_up: "Hang Up",
      incoming_call: "Incoming Call ",
      alarm_from: "Alarm From",
      show_alarm: "Show",
      hold: "Hold",
      unhold: "Unhold",
      holded: "Holded",
      connect: "Connect",
      disconnect: "Disconnect"
    },
    view:{
      title: "Calls",
      recording: "Recording",
      calling_party_number: "CallingParty",
      remote_party_number: "RemoteParty",
      state: "State",
      account: "Account"
    }
  }
}
