export default {
  namespaced: true,
  layout_builder: {
    title: 'Layouts',
    action_add: 'Criar layout',
    action_update: 'Atualizar layout',
    fields: {
      name: {
        label: 'Nome do Layout',
        placeholder: 'Nombre del Layout'
      },
      tenant: {
        label: 'Tenant',
        placeholder: 'Tenant'
      },
      users: {
        label: 'Usuários',
        placeholder: 'Usuários'
      },
      default: {
        label: 'padrão',
      }
    },
    btns: {
      add_slot: 'Adicionar espacio',
      save: 'Salve',
    },
    strings: {
      optional: 'Opcional',
    },
    messages: {
      no_blank_spaces: 'Não pode haver espaço em branco',
      no_enough_space: 'Não há espaço suficiente para um novo item',
      created_success: 'Layout criado com sucesso',
      updated_success: 'Layout atualizado com sucesso'
    },
    is_default: 'Por padrão',
    preview: 'Visualização',
  }
}
