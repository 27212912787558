export default {
    namespaced: true,
    account_settings: {
      title: "account settings",
      mobile_app: {
        title: "mobile app",
        description: "You can link your mobile app with your account by scanning the QR code",
        timeout_with_minutes: "Valid for {minutes} minutes and {seconds} seconds",
        timeout_without_minutes: "Valid for {seconds} seconds",
      }
    },
};
  