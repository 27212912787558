export default {
  namespaced: true,
  login: {
    password: "contraseña",
    email: "Correo electrónico",
    select_method_the_type_of_authentication:
      "Seleccionar método el tipo de autenticación",
    code: "código",
    resend_code: "Reenviar codigo...",
    you_can_resend_the_code_in_5_min: "Puedes reenviar el código en 5 min ",
    get_into: "Inicio de sesión",
    authentication_code_sent: "Código de autenticación enviado",
    incorrect_code: "código incorrecto",
    the_code_expired_request_a_new_code: "El código expiro, solicite un nuevo código",
    change_means_of_authentication: "Cambiar medio de autenticación",
    the_email_field_is_required: "El campo de correo electrónico es obligatorio",
    the_password_field_is_required: "El campo de contraseña es obligatorio",
    welcome_back: "Bienvenido de nuevo",
    failed_to_login:"Error al inciar sesión"
  },
};
