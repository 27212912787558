<template>
  <div class="toastification">
    <div class="d-flex align-items-start">
      <b-avatar
        size="1.8rem"
        class="mr-75 flex-shrink-0"
        :style="{ backgroundColor: 'green' }"
      >
        <span>
          <feather-icon :icon="icon" size="15" />
        </span>
      </b-avatar>
      <div class="d-flex flex-grow-1">
        <div>
          <h5
            class="mb-0 font-weight-bolder toastification-title"
            :class="`text-${variant}`"
          >
            {{ trans.sip.new_call_title }}
          </h5>
          <small class="d-inline-block text-body">
            {{ trans.sip.new_call_subtitle }}
          </small>
        </div>
        <span
          class="cursor-pointer toastification-close-icon ml-auto"
          @click="$emit('close-toast')"
        >
          <feather-icon v-if="!hideClose" icon="XIcon" class="text-body" />
        </span>
      </div>
    </div>
    <div style="margin: 1rem 0 0 2.5rem">
      <table class="table table-sm table-borderless">
        <tr v-if="data.alarm">
          <th>{{ trans.sip.facilitie }}</th>
          <td>
            <span class="mr-50">
              {{ data.alarm.router.unit.description }}
            </span>
          </td>
        </tr>
        <tr>
          <th>{{ trans.sip.calling_number }}</th>
          <td>
            <span class="mr-50">
              {{ data.callingPartyNumber }}
            </span>
          </td>
        </tr>
        <tr>
          <th>Session Id</th>
          <td>{{ data.sessionId }}</td>
        </tr>
      </table>

      <a
        href="#"
        @click.prevent="
          takeCall(userData, data.sessionId, data.callingPartyNumber, data.alarm)
        "
        class="d-block text-body"
        v-if="isRegistered"
      >
      contestar llamada
        <!-- {{ trans.sip.answer_call }} -->
      </a>
    </div>
  </div>
</template>

<script>
import { BAvatar } from "bootstrap-vue";
import store from "@/store";

export default {
  components: {
    BAvatar,
  },
  props: {
    variant: {
      type: String,
      default: "primary",
    },
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
  },
  computed: {
    userData() {
      return store.state.user.user;
    },
    isRegistered() {
      console.log("isRegistered:" + store.state.sip.isRegistered)
      return store.state.sip.isRegistered
    },
    trans() {
      return store.getters["i18n/messages"]("notifications");
    },
  },
  methods: {
    takeCall(userData, sessionId, callingPartyNumber, alarm) {
      let deviceInterphone = userData.device_interphone;
      store
        .dispatch("sip/takeCall", {
          sessionId,
          user: deviceInterphone.device_id,
          presavedCaller: callingPartyNumber,
          alarm,
        })
        .then((response) => {})
        .catch((e) => {
          console.log({ e });
        })
        .finally(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.toastification-close-icon,
.toastification-title {
  line-height: 26px;
}

.toastification-title {
  color: inherit;
}
</style>
