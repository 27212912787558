export default {
  namespaced: true,
  grid: {
    widgets: {
      alarms: {
        incoming_alarms: {
          title: "Alarmes em espera",
        },
        alarms_being_attended: {
          title: "Alarmes em atenção",
        },
        pagination: {
          showing: "Mostrando",
          records: "Registros",
          page: "Pág.",
          of: "de",
        },
        search: {
          placeholder:
            "Pesquisar por ID de alarme, endereço, inquilino, instalação, endereço...",
        },
        menu: {
          confirm: "Confirme",
          attended: "Atendidos",
          cancel: "Cancelar",
          display: "Visualizar",
        },
        table: {
          no_data: "Não há informações disponíveis",
          rows: {
            1: "Alarme",
            2: "Instalação",
            3: "Câmera",
            4: "Doença",
            5: "Data",
          },
        },
      },
      units: {
        activate_digital_output: "Ativar saída digital",
        disable_digital_output: "Desativar saída digital",
        title: "Instalações",
        messages: {
          no_data: "Não há informações disponíveis",
        },
        pagination: {
          showing: "Mostrando",
          records: "Registros",
          page: "Pág.",
          of: "de",
        },
        search: {
          placeholder: "Pesquisar por estabelecimento, endereço...",
          results: "Resultados",
        },
        table: {
          rows: {
            1: "Nome",
          },
        },
        subsystems_tree: {
          info: "Informações",
          video: {
            title: "Video",
            live_video: "Video ao vivo",
            recorded_video: "Video gravado",
            timeline: "Linha do tempo",
          },
          voip: {
            make_call: "Llamar",
          },
          activate_digital_output:"Ativar saída digital",
          disable_digital_output:"Desativar saída digital",
        },
      },
      layouts: {
        title: "Layouts",
      },
      views: {
        title: "Visualizações",
      },
      widgets: {
        title: "Widgets",
      },
      charts: {
        title: "Gráficos",
        no_data: "Nenhum dado disponível",
      },
      videowall: {
        title: "Videowall",
      },
      scripts: {
        title: "Comandos",
      },
    },
    modals: {
      alarms_status: {
        title_confirm: "Tem certeza de que deseja enviar o alarme?",
        title_attended: "Tem certeza de que atendeu o alarme?",
        title_cancel: "Tem certeza que deseja cancelar o alarme?",
        protocol: "Protocolo",
        comments: "Comentários",
        alarm_level: "Nível de alarme",
        btns: {
          send: "Mandar",
          cancel: "Cancelar",
        },
      },
      update_layout: {
        cameras: "Câmaras",
        pre_alarm: "Pré-alarme",
        info: "Informações",
        alarm_info: "Informações de alarme",
        unit_info: "Informações de instalação",
        plane: "Plano",
        unit_plane: "Mostrar plano de la instalação",
        map: "Mapa",
        alarm_location: "Localização do alarme",
        unit_location: "Localização do instalação",
        gps: "GPS",
        locations: "Localizações",
        select_slot: "Selecciona um espaço",
        btns: {
          ok: "Ok",
        },
      },
    },
    tabs: {
      new_tab: "Nova aba",
      context_menu: {
        save_as_view: "Salvar como visualização",
        update_view: "Atualizar visualização",
        update_name: "Mudar de nome",
      },
    },
    timeline: {
      waiting_for_main_camera: "Esperando pela câmera principal",
      no_footage_available: "Nenhuma filmagem disponível para o tempo selecionado",
      toolbar: {
        alarms: "Alarmes",
        objects: "Objetos",
        filter_type: "Clique para procurar objetos",
        zoom_in: "Mais Zoom",
        zoom_out: "Diminuir Zoom",
        x_seconds_fw: "5 segundos à frente",
        x_seconds_bw: "5 segundos atrás",
        play: "Reproducir",
        pause: "Pausar",
        synchronize: "Sincronizar",
        desynchronize: 'Dessincronizar',
        download: 'Baixar video',
        mostRecentEvent: 'Evento mais recente',
        speed: "Velocidade",
        facialRecognitionEnrollment: "Registro de reconhecimento facial",
      },
      objectsModal: {
        title: "Pesquisar por objetos",
        field1: {
          label: "Fornecedor",
        },
        field2: {
          label: "Tipo",
        },
        field3: {
          label: "De",
        },
        field4: {
          label: "Para",
        },
        field5: {
          label: "Objeto",
        },
        operators: {
          equal_to: "igual a",
          not_equal_to: "diferente de",
          contains: "contém",
          not_contains: "não contém",
          gt: "maior que",
          lt: "menor que",
          gte: "maior ou igual a",
          lte: "menor ou igual a",
          is_in: "está em",
          is_not_in: "não está em",
        },
      },
      downloadModal: {
        title: 'Baixar vídeos',
      },
    },
    frame: {
      url: "url",
    },
    slots: {
      main: {
        messages: {
          widget_not_be_added: "Não foi possível adicionar widget",
          timeline_widget_exists: "Já existe um widget do tipo Timeline",
          chat_widget_exists: 'Este chat já foi carregado',
          detection_event_widget_exists: 'Já existe um widget do tipo Timeline Detecção de eventos',
        },
      },
      info: {
        unit: "Instalação",
        address: "Direção",
        actions: "Ações",
        btns: {
          confirmed: "Confirme",
          attended: "Participou",
          cancel: "Cancelar",
        },
        description: "Descrição",
        company: "Companhia",
        latitude: "Latitud",
        longitude: "Longitud",
        exterior_number: "Número exterior",
        suburb: "Colônia",
        municipality: "Municipo",
        postal_code: "C.P.",
        country: "País",
        region: "Doença",
        tracing: "Rastreando",
        protocol: "Protocolo",
        attending_comments: "Motivo de atenção",
        solved_comments: "Motivo de solução",
        cancel_comments: "Motivo de cancelamento",
        status: "Status",
        folio_cad: "Folha CAD",
        data: "Informação adicional",
        camera: {
          review_start: "Início da revisão",
          review_end: "Fim de revisão",
          events_review: "Revisão de eventos",
          center: "Eixo",
          sector: "Setor",
          previous_time: "Vez anterior",
          later_time: "Mais tarde",
          reseller: {
            name: "Nome",
            company: "Companhia",
          },
          bridge: {
            account_id: "Account Id",
            bridge_id: "Bridge Id",
            device_name: "Nome do dispositivo",
          },
          bridge_account: {
            account_id: "Account Id",
            name: "Nome",
          },
        },
        sip_call: {
          state: "Estado",
          from: "Desde",
          to: "Em direção a",
          make_call: "Chamadar",
          origin_date: "Data de origem",
          audio_record: "Gravação",
        },
        fr: {
          title: "Reconhecimento facial",
          enrolled_person: "Pessoa inscrita",
          face_detected: "Rosto detectado",
          detection: "Detecção",
          camera_name: "Nome da câmera",
          person_name: "Nome do indivíduo",
          list_name: "Lista de nomes",
          age: "Era",
          gender: "Gênero",
          emotion: "Emoção capturada",
          glasses: "Uso de óculos",
          picture_quality: "Qualidade da imagem",
          blurriness: "Imprecisão",
          darkness: "Trevas",
          brightness: "Brilhar",
          saturation: "Saturação",
          score: "Pontuação de retrato",
          similarity: "Pontuação de similaridade",
          match_history: "Histórico de partidas",
          waiting_detection: "Esperando pela detecção",
          welcome: "Bem-vindo",
          genderFemale: 'Fêmea',
          genderMale: 'Masculino',
        },
        people_analytics: {
          top_color: "Cor superior",
          bottom_color: "Cor inferior",
          wearing_glasses: "Usa óculos",
          hair_length: "Comprimento do cabelo",
          carrying_objects: "Carrega objetos (mochilas, malas)",
          vehicle: "Veículo",
          gender: "Gênero",
          dominant_race: "Etnia",
          dominant_emotion: "Emoção dominante",
          age: "Idade"
        },
        detection_events: {
          default: {
            title: "Evento de detecção",
          },
          lpr: {
            title: "Reconhecimento de placas",
          },
          loitering: {
            title: "Vadiagem",
          },
          crowded: {
            title: "Aglomeração",
          },
          fr: {
            title: "Reconhecimento facial",
          },
          people_counting: {
            title: "Contagem de pessoas",
          },
          people_entering: {
            title: "Pessoas entrando",
          },
          people_exiting: {
            title: "Pessoas namorando",
          },
          prohibited_parking: {
            title: "Estacionamento proibido",
          },
          vehicle_count: {
            title: "Contagem de veículos",
          },
          wrong_way: {
            title: "Sentido contrário",
          },
          delimited_zone: {
            title: "Segurança de perímetro",
          },
          person_fallen: {
            title: "Pessoa deitada",
          },
          smart_motion_detection: {
            title: "Smart Motion Detection",
          },
          object_count: {
            title: "Contagem de Objetos",
          },
          person_in_area: {
            title: 'Pessoa na área'
          },
          labels: {
            overall_picture: "Visão geral",
            plate: "Placa",
            camera_name: "Nome da câmera",
            city: "Cidade",
            date: "Data de captura",
            speed: "Velocidade",
            brand: "Marca",
            model: "Modelo",
            color: "Cor",
            class: "Classe",
            list: "Lista",
            confidence: "Confiabilidade",
          },
        },
        sip: {
          title: "Chamada SIP",
          session_id: "Identificação de sessão",
          state: "Status",
          from: "Desde a",
          to: "uma",
        },
        workflow_logs: {
          title: "Registro de fluxo de trabalho",
        },
        analytic_record: {
          title: "Registro Analítico",
          alarm_category: "Categoría de alarme",
          camera_name: "Nome da câmera",
          matched_rules: "Regras de correspondência",
          priority: "Prioridade",
        },
        sensors: {
          title: "Sensores",
          type: "Tipo",
          measurement: "Medição de Alarme",
          high_critical: "Medição Alta Crítica",
          high_warning: "Aviso Alto de Medição",
          low_critical: "Medição Baixa Crítica",
          low_warning: "Aviso de Medição Baixa",
        },
      },
      interphone: {
        type: "Tipo",
        alarm: "Alarme",
        created: "Criado",
        updated: "Atualizada",
        user: "Usuário",
      },
    },
    widgets_section: {
      weather: {
        title: "Clima",
        errorGelocation: "Não foi possível obter a localização",
        permmisionDenied:
          "O acesso ao local não foi permitido, ative as permissões no seu dispositivo",
      },
      face_detection: {
        title: "Detecção de rosto",
      },
      map: {
        title: "Mapa",
      },
      chart: {
        title: 'Gráfico',
      },
      detection_event:{
        title: 'Detecção de eventos'
      }
    }
  }
}
