export default {
  namespaced: true,
  grid: {
    widgets: {
      alarms: {
        incoming_alarms: {
          title: "Alarmas en espera"
        },
        alarms_being_attended: {
          title: "Alarmas en atención"
        },
        pagination: {
          showing: "Mostrando",
          records: "Registros",
          page: "Pág.",
          of: "de"
        },
        search: {
          placeholder: "Buscar por Id de Alarma, Dirección, Tenant, Instalación, Dirección..."
        },
        menu: {
          confirm: "Confirmar",
          attended: "Atendida",
          cancel: "Cancelar",
          display: "Visualizar"
        },
        table: {
          no_data: "No hay información disponible",
          rows: {
            1: "Alarma",
            2: "Instalación",
            3: "Cámara",
            4: "Estado",
            5: "Fecha"
          }
        }
      },
      units: {
        activate_digital_output:"Activar salida digital",
        disable_digital_output:"Desactivar salida digital",
        title: "Instalaciones",
        messages: {
          no_data: "No hay información disponible"
        },
        pagination: {
          showing: "Mostrando",
          records: "Registros",
          page: "Pág.",
          of: "de"
        },
        search: {
          placeholder: "Buscar por Instalación, Dirección...",
          results: "Resultados"
        },
        table: {
          rows: {
            1: "Nombre"
          }
        },
        subsystems_tree: {
          info: "Información",
          video: {
            title: "Video",
            live_video: "Video en vivo",
            recorded_video: "Video grabado",
            timeline: "Linea de tiempo",
          },
          voip: {
            make_call: 'Llamar',
          },
          activate_digital_output:"Activar salida digital",
          disable_digital_output:"Desactivar salida digital",
        }
      },
      layouts: {
        title: "Layouts"
      },
      views: {
        title: "Vistas"
      },
      widgets: {
        title: "Widgets"
      },
      charts: {
        title: "Gráficas",
        no_data: "No hay información disponible",
      },
      videowall: {
        title: "Videowall"
      },
      scripts: {
        title: "Comandos"
      }
    },
    modals: {
      alarms_status: {
        title_confirm: "¿Está seguro de enviar la alarma?",
        title_attended: "¿Está seguro de dar por atendida la alarma?",
        title_cancel: "¿Está seguro de cancelar la alarma?",
        protocol: 'Protocolo',
        comments: "Comentarios",
        alarm_level: "Nivel de alarma",
        btns: {
          send: "Enviar",
          cancel: "Cancelar"
        }
      },
      update_layout: {
        cameras: "Cámaras",
        pre_alarm: "Pre-Alarma",
        info: "Información",
        alarm_info: "Información de la alarma",
        unit_info: "Información de la instalación",
        plane: "Plano",
        unit_plane: "Mostrar plano de la instalación",
        map: "Mapa",
        alarm_location: "Ubicación de la alarma",
        unit_location: "Ubicación de la instalación",
        gps: "GPS",
        locations: "Localizaciones",
        select_slot: "Selecciona un espacio",
        btns: {
          ok: "Ok"
        }
      }
    },
    tabs: {
      new_tab: "Nueva pestaña",
      context_menu: {
        save_as_view: "Guardar como vista",
        update_view: "Actualizar vista",
        update_name: "Cambiar nombre"
      }
    },
    timeline: {
      waiting_for_main_camera: 'Esperando cámara principal',
      no_footage_available: 'No footage available for the selected time',
      toolbar: {
        alarms: 'Alarmas',
        objects: 'Objetos',
        filter_type: 'Clic para buscar por objetos',
        zoom_in: 'Acercar',
        zoom_out: 'Alejar',
        x_seconds_fw: '5 segundos adelante',
        x_seconds_bw: '5 segundos atrás',
        play: 'Reproducir',
        pause: 'Pausar',
        synchronize: 'Sincronizar',
        desynchronize: 'Desincronizar',
        download: 'Descargar',
        mostRecentEvent: 'Evento más reciente',
        speed: 'Velocidad',
        facialRecognitionEnrollment: 'Enrolamiento de Reconocimiento Facial',
      },
      objectsModal: {
        title: 'Búsqueda por objetos',
        field1: {
          label: 'Proveedor'
        },
        field2: {
          label: 'Tipo'
        },
        field3: {
          label: 'Desde'
        },
        field4: {
          label: 'Hasta'
        },
        field5: {
          label: 'Objeto'
        },
        operators: {
          equal_to: 'igual a',
          not_equal_to: 'diferente de',
          contains: 'contiene',
          not_contains: 'no contiene',
          gt: 'mayor que',
          lt: 'menor que',
          gte: 'mayor o igual que',
          lte: 'menor o igual que',
          is_in: 'está en',
          is_not_in: 'no está en',
        }
      },
      downloadModal: {
        title: 'Descarga de vídeos',
      },
    },
    frame: {
      url: 'url'
    },
    slots: {
      main: {
        messages: {
          widget_not_be_added: 'No fue posible agregar el widget',
          timeline_widget_exists: 'Ya existe un widget del tipo Timeline',
          chat_widget_exists: 'Ya se cargo este chat',
          detection_event_widget_exists: 'Ya existe un widget del tipo Detección de eventos',
        }
      },
      info: {
        unit: 'Instalación',
        address: 'Dirección',
        actions: 'Acciones',
        btns: {
          confirmed: 'Confirmar',
          attended: 'Atendida',
          cancel: 'Cancelar',
        },
        description: 'Descripción',
        company: 'Empresa',
        latitude: 'Latitud',
        longitude: 'Longitud',
        exterior_number: 'Número exterior',
        suburb: 'Colonia',
        municipality: 'Municipo',
        postal_code: 'C.P.',
        country: 'País',
        region: 'Estado',
        tracing: 'Seguimiento',
        protocol: 'Protocolo',
        attending_comments: 'Motivo de atención',
        solved_comments: 'Motivo de solución',
        cancel_comments: 'Motivo de cancelación',
        status: 'Estatus',
        folio_cad: 'Folio CAD',
        data: 'Información adicional',
        camera: {
          review_start: 'Inicio de revisión',
          review_end: 'Fin de revisión',
          events_review: 'Revisión de eventos',
          center: 'Centro',
          sector: 'Sector',
          previous_time: 'Tiempo previo',
          later_time: 'Tiempo posterior',
          reseller: {
            name: 'Nombre',
            company: 'Empresa'
          },
          bridge: {
            account_id: 'Account Id',
            bridge_id: 'Bridge Id',
            device_name: 'Nombre de dispositivo'
          },
          bridge_account: {
            account_id: 'Account Id',
            name: 'Nombre'
          },
        },
        sip_call: {
          state: 'Estado',
          from: 'Desde',
          to: 'Hacia',
          make_call: 'Llamar',
          origin_date: 'Fecha de Origen',
          audio_record: 'Grabación'
        },
        fr: {
          title: 'Reconocimiento Facial',
          enrolled_person: 'Persona enrolada',
          face_detected: 'Rostro detectado',
          detection: 'Detección',
          camera_name: 'Nombre de la cámara',
          person_name: 'Nombre de la persona',
          list_name: 'Nombre de la lista',
          age: 'Edad',
          gender: 'Género',
          emotion: 'Emoción captada',
          glasses: 'Uso de lentes',
          picture_quality: 'Calidad de imagen',
          blurriness: 'Borrosidad',
          darkness: 'Oscuridad',
          brightness: 'Brillo',
          saturation: 'Saturación',
          score: 'Puntaje de retrato',
          similarity: 'Puntaje de similitud',
          match_history: 'Historial De Coincidencias',
          waiting_detection: "Esperando detección",
          welcome: "Bienvenido",
          genderFemale: 'Femenino',
          genderMale: 'Masculino',
        },
        people_analytics: {
          top_color: "Color superior",
          bottom_color: "Color inferior",
          wearing_glasses: "Usa lentes",
          hair_length: "Longitud del cabello",
          carrying_objects: "Porta objetos (mochilas, maletas)",
          vehicle: "Vehículo",
          gender: "Género",
          dominant_race: "Etnia",
          dominant_emotion: "Emoción dominante",
          age: "Edad"
        },
        detection_events: {
          default: {
            title: 'Evento de detección'
          },
          lpr: {
            title: 'Reconocimiento de placas',
          },
          loitering: {
            title: 'Merodeo',
          },
          crowded: {
            title: 'Aglomeración'
          },
          fr: {
            title: 'Reconocimiento facial',
          },
          people_counting: {
            title: 'Conteo de personas',
          },
          people_entering: {
            title: 'Personas entrando',
          },
          people_exiting: {
            title: 'Personas saliendo',
          },
          prohibited_parking: {
            title: 'Estacionamiento prohibido',
          },
          vehicle_count: {
            title: 'Conteo de vehículos',
          },
          wrong_way: {
            title: 'Sentido contrario',
          },
          delimited_zone: {
            title: 'Seguridad perimetral',
          },
          person_fallen: {
            title: 'Persona acostada',
          },
          smart_motion_detection: {
            title: 'Smart Motion Detection',
          },
          object_count: {
            title: 'Conteo de objetos',
          },
          person_in_area: {
            title: 'Persona en área'
          },
          labels: {
            overall_picture: 'Imagen general',
            plate: 'Placa',
            camera_name: 'Nombre de la cámara',
            city: 'Ciudad',
            date: 'Fecha de captura',
            speed: 'Velocidad',
            brand: 'Marca',
            model: 'Modelo',
            color: 'Color',
            class: 'Clase',
            list: 'Lista',
            confidence: 'Fiabilidad',
          },
        },
        sip: {
          title: 'SIP Call',
          session_id: 'SessionId',
          state: 'Estado',
          from: 'Desde',
          to: 'a',
        },
        workflow_logs: {
          title: 'Registro de flujos de trabajo',
        },
        analytic_record: {
          title: 'Registro Analítico',
          alarm_category: 'Categoría de alarma',
          camera_name: 'Nombre de la cámara',
          matched_rules: 'Reglas coincidentes',
          priority: 'Prioridad',
        },
        sensors: {
          title: 'Sensores',
          name: 'Nombre',
          type: 'Tipo',
          last_measurement: 'Última medición',
          measurement: 'Medición de Alarma',
          high_critical: 'Medición Alta Critica',
          high_warning: 'Advertencia de Medición Alta',
          low_critical: 'Medición Baja Critica',
          low_warning: 'Advertencia de Medición Baja',
        }
      },
      interphone: {
        type: 'Tipo',
        alarm: 'Alarma',
        created: 'Creado',
        updated: 'Actualizado',
        user: 'Usuario',
      }
    },
    widgets_section: {
      weather: {
        title: 'Clima',
        errorGeloocation: 'No se pudo obtener la ubicación',
        permmisionDenied: 'No se permitio acceder a la ubicación,  habilíte los permisos en su dispositivo.'
      },
      face_detection: {
        title: 'Detección de rostro',
      },
      map: {
        title: 'Mapa',
      },
      chart: {
        title: 'Gráfica',
      },
      detection_event: {
        title: 'Detección de evento',
      },
    }
  }
}
