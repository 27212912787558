import axios from '@axios'
import store from "@/store"

export default {
  namespaced: true,
  state: {
    items: [],
    matchedRules: [],
    priorities: [],
    typeAlarms: [],
    progress: 0,
    error: '',
    url: '',
    isCreatedFile: false
  },
  getters: {
    getItems(state) {
      return state.items
    },
    getProgress(state) {
      return state.progress
    },
    getError(state) {
      return state.error
    },
    getUrl(state) {
      return state.url
    },
    getMatchedRules(state) {
      return state.matchedRules
    },
    getPriorities(state) {
      return state.priorities
    },
    getTypeAlarms(state) {
      return state.typeAlarms
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value
    },
    setMatchedRules(state, value) {
      state.matchedRules = value
    },
    setPriorities(state, value) {
      state.priorities = value
    },
    setTypeAlarms(state, value) {
      state.typeAlarms = value
    },
  },
  actions: {
    async getAll({ commit }, params) {
      const { data } = await axios.get('v1/analytic_record', { params })

      if (!data) {
        return []
      }

      commit('setData', data.data)
      return data
    },
    resetProgress({ state }) {
      state.progress = 0
    },
    setExportAnalyticRecord({ state, commit, dispatch }, jobId) {
      if (!window.Echo.connector.socket.connected) window.Echo.connect();

      const notification = {
        id: `analytic-record-export-${jobId}`,
        progress: 0,
        completed: false,
        view: 'analytic-record',
        url: '',
        title: 'Reporte de registro de analiticos',
        body: 'Descargando...',
        error: false
      }
      store.dispatch('grid/addDownloadNotification', notification)

      window.Echo.private(`analytic-record-export-${jobId}`)
        .listen(".process", (data) => {
          const notification = {
            id: `analytic-record-export-${jobId}`,
            progress: data.progress,
            completed: false,
            view: 'analytic-record',
            url: '',
            title: 'Reporte de registro de analiticos',
            body: 'Descargando...',
            error: false,
            timestamp: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
          }
          store.dispatch('grid/updateDownloadNotification', notification)
          state.progress = data.progress
        })
        .listen(".end", (data) => {
          const notification = {
            id: `analytic-record-export-${jobId}`,
            progress: 100,
            completed: true,
            view: 'analytic-record',
            url: data.url,
            title: 'Reporte de registro de analiticos',
            body: 'Descarga completa',
            error: false,
            timestamp: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
          }
          store.dispatch('grid/updateDownloadNotification', notification)
          window.Echo.leave(`analytic-record-export-${jobId}`)

          state.url = data.url
        })
        .listen(".exception", (data) => {
          const notification = {
            id: `analytic-record-export-${jobId}`,
            progress: 100,
            completed: true,
            view: 'analytic-record',
            url: data.url,
            title: 'Reporte de registro de analitico',
            body: 'Ocurrio un error',
            error: true,
            timestamp: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
          }
          store.dispatch('grid/updateDownloadNotification', notification)
          window.Echo.leave(`analytic-record-export-${jobId}`)
          state.error = 'Ocurrio un error'
        })
    },
    async getMatchedRules({ commit }, params) {
      const { data } = await axios.get('v1/matched_rules/analytic_record', { params })

      if (!data) {
        return []
      }

      commit('setMatchedRules', data.data)
      return data
    },
    async getPriorities({ commit }, params) {
      const { data } = await axios.get('v1/priorities/analytic_record', { params })

      if (!data) {
        return []
      }

      commit('setPriorities', data.data)
      return data
    },
    async getTypeAlarms({ commit }, params) {
      const { data } = await axios.get('v1/types_alarms/analytic_record', { params })

      if (!data) {
        return []
      }

      commit('setTypeAlarms', data.data)
      return data
    },
    clear({ commit }) {
      commit('setData', [])
      commit('setMatchedRules', [])
      commit('setPriorities', [])
      commit('setTypeAlarms', [])
    }
  },
}
