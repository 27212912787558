export default {
  namespaced: true,
  plate: {
    title: "placas | placa",
    list: "lista",
    active_since: "activo desde",
    activated_until: "activo hasta",
    comments: "comentarios",
    description: "descripción",
    number_of_detections: "número de detecciones",
    last_detection: "última deteccion",
    brand: "marca",
    color: "color",
    model: "modelo",
    type: "tipo",
    vehicle: "vehículo",
    vehicle_data: "Datos de vehiculo",
  },
};
