import axios from '@axios'

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    getItems(state) {
      return state.items
    },
    getSelectedItems(state) {
      return state.items.map(recording_request_status => ({
        value: recording_request_status.id,
        label: recording_request_status.status,
      }))
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value
    },
  },
  actions: {
    async getAll({ commit }, params) {
      const { data } = await axios.get('v1/recording_request_status', { params })

      if (!data.success) {
        return []
      }

      commit('setData', data.data)
      return data
    },
    clear({ commit }) {
      commit('setData', [])
    }
  },
}
