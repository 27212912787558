export default {
  namespaced: true,
  notifications: {
    alarm: {
      new_alarm_title: "Novo alarme registrado",
      new_alarm_subtitle: "Um novo alarme foi registrado.",
      updated_alarm_title: "Atualização de alarme",
      updated_alarm_subtitle: "O alarme foi atualizado com sucesso.",
      type: "Tipo",
      unit: "Instalação",
      show: "Mostrar alarme",
      updated_to_attending_success: "Alarme disparado corretamente",
      updated_to_attended_success: "Alarme marcado como atendido corretamente",
      updated_to_canceled_success: "Alarme marcado como cancelado corretamente",
      updated_error: "Ocorreu um erro ao atualizar o alarme",
    },
    sip: {
      new_call_title: "Nova Chamada Registrada",
      new_call_subtitle: "Uma nova chamada foi registrada",
      facilitie: "Instalação",
      calling_number: "Extensão de chamada",
      answer_call: "Atender chamada",
    },
    read_allnotifications: "Leia todas as notificações",
  },
};
