export default {
  namespaced: true,
  logs: {
    title: "log | logs",
    user: "usuário",
    date: "data",
    application: "aplicativo",
    action: "ação",
    type: "tipo",
    resourse: "recurso",
    identifier: "indentificador",
    message: "mensagem",
    system_logs: "Logs do sistema",
    ip: "IP",
  },
};
