export default {
  namespaced: true,
  cm_master_account: {
    title: "conta mestre | contas mestras",
    name: "nome",
    topic: "tópico do message broker",
    user: "usuário",
    password: "senha",
    client_id: "ID do cliente",
    customer_key: "chave do cliente",
    reseller_id: "ID do reseller",
    reseller_number: "número do reseller",
    api_key: "Chave API",
    account_number: "número de conta"
  },
};
