export default {
  namespaced: true,
  camera: {
    title: "câmera | câmeras",
    name: "nome",
    mac_address: "endereço MAC",
    type: "Tipo",
    address: "endereço",
    review_facial_recognition_events: "Revisar eventos de reconhecimento facial na área",
    previous_time_of_prealarma: "tempo anterior de prealarma",
    later_time_of_prealarma: "tempo posterior de prealarma",
    online: "online",
    user: "usuário",
    password: "senha",
    people_detection_start: "início de detecção de pessoas na área",
    end_of_person_detection: "fim de detecção de pessoas na área",
    camera_id: "id da câmera",
    serial_number: "número de série",
    people_detection: "Detecção de Pessoas",
    update_cameras: "atualizar câmeras",
    esn: "ESN",
    resellers_validation: "você precisa selecionar pelo menos um revendedor",
    enable_smart_detection: "Detecção Inteligente Ativada",
    enable_person_smart_detection: "Detecção Inteligente de Pessoas Ativada",
    camera_manager: "Gestor de Câmera",
    status_cameras: "Estado da Câmera",
  },
};
