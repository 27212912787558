export default {
  namespaced: true,
  recording_request: {
    title: "Petición de grabación| Petición de grabaciones",
    folio: "Folio",
    status: "estatus",
    requested: "solicitada",
    description: "descripcion",
    tag: "etiqueta",
    start: "inicio",
    finish: "fin",
    authorized_from: "autorizada desde",
    authorized_to: "autorizada hasta",
    recording: "grabacion",
    download_timestamp: "¿Desea Incluir La Fecha Y Hora En La Grabación?",
    time_expired: "caduco el tiempo autorizado para visualizarlo",
    comment: "comentario",
    authorized_by: "Authorizado por",
    files: "archivos",
  }
}
