export default {
  namespaced: true,
  actions: {
    facial_recognition: "Reconocimiento Facial",
    title: "Coincidencias Reconocimiento Facial",
    people_list: "lista de personas",
    facility: "instalacion",
    image: "imagen",
    person_id: "id de la persona",
    name: "nombre",
    age: "edad",
    gender: "genero",
    wears_glasses: "usa gafas",
    capture_blur: "borrocidad de captura",
    capture_darkness: "oscuridad de captura",
    catch_light: "luz de captura",
    capture_saturation: "saturacion de captura",
    captured_emotion: "emocion de captura",
    captured_ethnicity: "etnia capturada",
    catch_percentage: "porcentaje de captura",
    match_percentage: "porcentaje de coincidencia"
  }
}
