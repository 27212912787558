export default {
  namespaced: true,
  country: {
    title: "pais | paises",
    code: "codigo",
    name: "nombre",
    telephone_code: "codigo telefonico",
    region_count: "conteo de regiones",
    created: "Creado",
    updated: "Actualizado",
  },
};
