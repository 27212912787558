export default {
  namespaced: true,
  log_microservice: {
    title: "microservicio de logs",
    client_host: "host del cliente",
    provider: "proveedor",
    level: "nivel",
    message: "id de mensaje",
    log_message: "mensaje de registro",
    description: "descripción",
    path: "path",
    resource: "recurso",
    difference: "diferencia",
    tenant_id: "tenant",
    user_id: "usuario",
    action: "acción",
    start_date: "fecha de inicio",
    end_date: "fecha fin",
    The_start_date_value_is_required_for_filter_by_date: "El valor de la fecha de inicio es obligatorio para filtrar por fecha.",
    The_end_date_value_is_required_to_filter_by_date: "El valor de la fecha de finalización es necesario para filtrar por fecha.",
  },
};
