import Vue from 'vue'
import store from '@/store'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_BASE_URL,
  // timeout: 1000,
  headers: {'module': 'SBO'}
})

axiosIns.interceptors.request.use(
  function (config) {

    const locale = store.getters['user/getPreferences'].lang;
    config.headers['X-localization'] = locale;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

Vue.prototype.$http = axiosIns

export default axiosIns
