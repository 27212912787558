import axios from '@axios'
import {useUtils as useI18nUtils} from "@core/libs/i18n";
import store from "@/store";

export default {
  namespaced: true,
  state: {
    items: []
  },
  getters: {
    getItems(state) {
      return state.items
    },
    getSelectedItems(state) {
      return state.items.map(municipality => ({
        value: municipality.id,
        label: municipality.name
      }))
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value
    },
  },
  actions: {
    async export({commit}, params) {
      const response = await axios.get("v1/municipality", {
        params,
        responseType: params?.action == "export" ? "blob" : "json",
      });

      if (!response.data) {
        return [];
      }
      if (params?.action == "export") {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        const {t} = useI18nUtils();

        const notification = {
          id: response.headers["content-disposition"].split("filename=")[1],
          progress: 100,
          completed: true,
          view: "Unit",
          url: url,
          download: response.headers["content-disposition"].split("filename=")[1],
          title: "Reporte de Instalaciones",
          body: "Descarga completa",
          error: false,
          timestamp:
            new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(),
        };
        store.dispatch("grid/updateDownloadNotification", notification);
      } else {
        commit("setData", response.data.data);
        return response.data;
      }
    },
    async getAll({commit}, params = {}) {
        const { data } = await axios.get('v1/municipality', { params })

        if (!data) {
          return []
        }
  
        commit('setData', data.data)
        return data
    },
    async add(_, params) {
      const {data} = await axios.post('v1/municipality', params)

      return data
    },
    async edit(_, {id, params}) {
      const {data} = await axios.put(`v1/municipality/${id}`, params)

      return data
    },
    async delete(_, id) {
      const {data} = await axios.delete(`v1/municipality/${id}`)

      return data
    },
    async get(_, id) {
      const {data} = await axios.get(`v1/municipality/${id}`)

      return data
    },
    clear({commit}) {
      commit('setData', [])
    }
  },
}
