export default {
  namespaced: true,
  video_watermarks: {
    title: "Marca de agua para videos",
    title_main: "Configuración de marca de agua para videos",
    action_add: 'Crear marca de agua',
    action_update: 'Actualizar marca de agua',
    title_location: 'Ubicación De La Marca De Agua',
    fields: {
      name: {
        label: 'Nombre de la marca de agua',
        placeholder: 'Nombre de la marca de agua'
      },
      tenant: {
        label: 'Tenant',
        placeholder: 'Tenant'
      },
      image: {
        label: 'Seleccionar imagen',
        hint: 'Arrastra una imagen'
      },
      opacity: {
        label: 'Opacidad de la imagen'
      },
      text_lines: {
        label: 'Línea #'
      },
      image_pos: {
        label: 'Posición de la imagen'
      },
      text_pos: {
        label: 'Posición del texto'
      }
    },
    subtitle_1: 'Texto',
    subtitle_2: 'Posicionamiento',
    placeholders_options: {
      ph1: 'ID_ÚNICO',
      ph2: 'FECHA_ACTUAL',
      ph3: 'HORA_ACTUAL',
      ph4: 'NOMBRE_OPERADOR',
      ph5: 'EMAIL_OPERADOR',
    },
    position_options: {
      pos1: 'Esquina superior izquierda',
      pos2: 'Esquina superior derecha',
      pos3: 'Esquina inferior izquierda',
      pos4: 'Esquina inferior derecha',
      pos5: 'Mosaico',
    },
    messages: {
      success_created: 'Marca de agua creada correctamente',
      success_updated: 'Marca de agua actualizada correctamente',
    },
    btns: {
      delete_image: 'Eliminar imagen',
      create: 'Guardar',
      update: 'Actualizar',
    }
  }
}
