export default {
  namespaced: true,
  intelexvision_alarm_type: {
    title: "Intelexvision tipo de alarme|Intelexvision tipos de alarmes",
    alarm_type: "Tipo de alarme",
    name: "Nome",
    description: "Descrição",
    rule: "Gerar alarme sem regra",
    alarm_type_intelexvision: "tipo de alarme em intelexvision",
    priority: "prioridade",
  },
};
