export default {
  namespaced: true,
  density_device: {
    shot_time:"shot time (in seconds)",
    unit_authorization_key:"Unit Authorization Key",
    device_id:"device id",
    title: "density device master accounts",
    actions: "actions",
    name: "name",
    description: "description",
    email: "email",
    password: "password",
    creation_date: "creation date",
    update_date: "update date",
    add_master_account: "add master account",
    edit_master_account: "edit master account",
    density_devices: "density devices",
    add_device_density: "add device density",
    edit_device_density: "edit device density",
    type: "type",
    protocol_version: "protocol version",
    firmware_version: "firmware version",
    device_version: "device version",
    usercode: "usercode",
    connection:"connection",
    ready:"ready",
    armed:"armed",
    tampered:"tampered",
    low_liquid:"low liquid",
    fluid_level:"fluid level",
    boil_temp:"temperature",
    shot_number:"shot number",
    ac_mains:"ac mains",
    diagnostic:"diagnostic",
    failed_shot:"failed shot",
    last_shot:"last shot",
    last_check:"last check",
  },
};
