export default {
  namespaced: true,
  vxg_camera: {
    id_vxg: "ID External",
    name: "Name",
    rec_mode: "Rec Mode",
    live_stream: "Live Stream",
    recording_stream: "Recording Stream",
    access_token_watch: "Access Token Watch",
    access_token_all: "Access Token All",
    streaming: "Streaming",
    timezone: "Timezone",
    update_from_vxg: "Update cameras from API",
    select_multiple: "Select Multiple",
    delete_vxg_sbo: "Delete VaaS and SBO",
    delete_sbo: "Delete in SBO",
    removed: "Removed",
    removed_cameras: "Removed cameras",
  },
};
