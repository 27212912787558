export default {
    namespaced: true,
    account_settings: {
      title: "configuración de cuenta",
      mobile_app: {
        title: "aplicación móvil",
        description: "Puedes vincular tu aplicación móvil con tu cuenta escaneando el código QR",
        timeout_with_minutes: "Válido por {minutes} minutos y {seconds} segundos",
        timeout_without_minutes: "Válido por {seconds} segundos",
      }
    },
};
  