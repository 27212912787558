export default {
    namespaced: true,
    state: {
        isToolbarsCollapsed: false,
        isToolbarsCollapsedRight: true,
        configModeStatus: false,
    },
    getters: {},
    mutations: {
        UPDATE_TOOLBARS_COLLAPSED(state, val) {
            localStorage.setItem('toolbarsCollapsed', val)
            state.isToolbarsCollapsed = val
        },
        UPDATE_TOOLBARS_COLLAPSED_RIGHT(state, val) {
          localStorage.setItem('isToolbarsCollapsedRight', val)
          state.isToolbarsCollapsedRight = val
      },
        TOGGLE_CONFIG_MODE(state, val) {
            state.configModeStatus = val
        },
    },
    actions: {
        initializeStore(ctx) {
            ctx.commit(
                'UPDATE_TOOLBARS_COLLAPSED',
                !!JSON.parse(localStorage.getItem('toolbarsCollapsed'))
            );
        }
    },
}
