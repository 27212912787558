export default {
    namespaced: true,
    account_settings: {
      title: "configurações de conta",
      mobile_app: {
        title: "aplicativo móvel",
        description: "Você pode vincular seu aplicativo móvel à sua conta digitalizando o código QR",
        timeout_with_minutes: "Válido por {minutes} minutos e {seconds} segundos",
        timeout_without_minutes: "Válido por {seconds} segundos",
      }
    },
};
  