export default {
  namespaced: true,
  plate: {
    title: "placas | placa",
    list: "lista",
    active_since: "ativo desde",
    activated_until: "ativo até",
    comments: "comentários",
    description: "descrição",
    number_of_detections: "número de detecções",
    last_detection: "última detecção",
    brand: "marca",
    color: "cor",
    model: "modelo",
    type: "tipo",
    vehicle: "veículo",
    vehicle_data: "Dados do veículo",
  },
};
