import axios from "@axios";

export default {
  namespaced: true,
  state: {
    items: [],
    itemsAvailable: [],
    idNotification: "",
    url: "",
  },
  getters: {
    getItems(state) {
      return state.items;
    },
    getSelectedItems(state) {
      return state.items.map((country) => ({
        value: country.id,
        label: country.name,
      }));
    },
    getSelectedItemsCode(state) {
      let itemCountry=[]
      state.items.forEach(element => {
        if(element.telephone_code!=null){
          itemCountry.push({
            value: element.id,
            label: `${element.code} (+${element.telephone_code})`,
          })
        }
      });
      return itemCountry
    },
    getIdNotification(state) {
      return state.idNotification;
    },
    getUrl(state) {
      return state.url;
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value;
    },
    setIdNotification(state, value) {
      state.idNotification = value;
    },
    setUrl(state, value) {
      state.url = value;
    },
  },
  actions: {
    async getAll({ commit }, params) {
      const response = await axios.get("v1/country", {
        params,
        responseType: params?.action == "export" ? "blob" : "json",
      });
      if (!response.data) {
        return [];
      }
      if (params?.action == "export") {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
        link.remove();

        commit(
          "setIdNotification",
          response.headers["content-disposition"].split("filename=")[1]
        );
        commit("setUrl", url);
      } else {
        commit("setData", response.data.data);
        return response.data;
      }
    },
    async add(_, params) {
      const { data } = await axios.post("v1/country", params);
      return data;
    },
    async edit(_, { id, params }) {
      const { data } = await axios.put(`v1/country/${id}`, params);
      return data;
    },
    async delete(_, id) {
      const { data } = await axios.delete(`v1/country/${id}`);
      return data;
    },
    clear({ commit }) {
      commit("setData", []);
    },
  },
};
