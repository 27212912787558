export default {
    namespaced: true,
    safr: {
        title: 'SAFR',
        addGroup: 'Adicionar grupo',
        editGroup: 'Editar grupo',
        name: 'Nome',
        enabled: 'Ativo',
        groups: 'Grupos',
        people: 'Pessoas',
        addPeople: 'Adicionar pessoa',
        editPeople: 'Editar persona',
        age: 'Idade',
        photo: 'Imagem',
        gender: 'Gênero',
        options: 'Opções',
        genderFemale: 'Fêmea',
        genderMale: 'Masculino',
        events: 'Eventos',
        event_id: 'Evento',
        source_id: 'Fonte',
        type: 'Cara',
        date: 'Data',
        person: 'Pessoa',
        similarity: 'Semelhança',
        matches: 'Coincidências',
        from: 'De',
        to: 'Até',
        placeholderPerson: 'Selecionar pessoa',
        placeholderDate1: 'Selecione de',
        placeholderDate2: 'Selecione até',
        resetFilters: 'Redefinir filtros',
        password: 'Senha',
        user: 'Do utilizador',
        tenant: 'Tenants',
        links: 'Enpoints',
        addMasterAccount: 'Adicionar conta mestra',
        editMasterAccount: 'Editar conta mestra',
        placeholderMasterAccount: 'Selecione tenant',
        placeholderDescription: 'Insira a descrição',
        placeholderId: 'Entrar id',
        placeholderEndpoint: 'Entrar enpoint',
        placeholderName: 'Entrar nombre',
        placeholderAge: 'Entrar idade',
        placeholderGender: 'Digite o gênero',
        placeholderGroups: 'Entrar em grupos',
        placeholderImage: 'Insira a imagem',
        placeholderType: 'Digite o tipo',
        type: 'Tipo',
        delete: 'Eliminar',
        master_account: 'Conta mestre',
        addCamera: 'Adicionar câmera',
        editCamera: 'Editar câmera',
        sector: 'Setor',
        center: 'Centro',
        units: 'Instalações',
        placeholderSector: 'Entre no setor',
        placeholderMasterAccount: 'Insira a conta principal',
        placeholderDirectory: 'Entre no diretório',
        placeholderTenants: 'Entrar tenants',
        placeholderCenter: 'Entrar centro',
        placeholderUnit: 'Entrar instalação',
        update_from_api: "Atualize as câmeras da API",
        directory: 'Diretório',
        safr_name: 'Câmera safr',
        update_people_from_api: 'Atualize as pessoas da API',
        token: 'Token',
        accessClearance: 'Autorização de acesso',
        placeholderAccessClearance: 'Insira a autorização de acesso',
        update_access_clearance_from_api: 'Atualize autorização de acesso da API',
        permanent: 'Permanent',
        addAccessClearance: 'Adicionar autorização de acesso',
        level: 'nível',
        placeholderLevel: 'Entrar nível'
    }
}
