export default {
  namespaced: true,
  master_tenants: {
    title: "master tenant | master tenants",
    _name: "Nombre",
    number_of_tenants: "Número de Tenants",
    active: "activo",
    description: "descripción",
  },
};
