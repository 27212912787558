export default {
  namespaced: true,
  purge_alarm: {
    title: 'limpeza de alarme|limpeza de alarmes',
    name: "nome",
    alarm_status_in: "status de alarme de entrada",
    alarm_status_out: "status de alarme de saída",
    comment: "comente",
    protocol: "protocolo",
    timeout: "tempo de espera em minutos",
  },
};
