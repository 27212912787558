export default {
  namespaced: true,
  breadcrumbs: {
    vass:{
      camera_manager: {
        master_account: 'VaaS > Gerenciador de Câmeras > Conta Mestra',
        reseller: 'VaaS > Gerenciador de Câmeras > Revendedor',
        cameras: 'VaaS > Gerenciador de Câmeras > Câmeras',
        status_cameras: 'VaaS > Gerenciador de Câmeras > Status das Câmeras',
      },
      ee_vms: {
        master_accounts: 'VaaS > EE VMS > Contas Mestras',
        accounts: 'VaaS > EE VMS > Contas',
        bridges: 'VaaS > EE VMS > Pontes',
        cameras: 'VaaS > EE VMS > Câmeras',
        presets: 'VaaS > EE VMS > Presets',
      },
      luxriot: {
        master_accounts: 'VaaS > Luxriot > Contas Mestras',
        cameras: 'VaaS > Luxriot > Câmeras',
      },
      omnicloud: {
        master_accounts: 'VaaS > OmniCloud > Contas Mestras',
        cameras: 'VaaS > OmniCloud > Câmeras',
        omnia_reseller: 'VaaS > OmniCloud > Revendedor da Omnia',
      },
      dahua: {
        dahua_dvr: 'VaaS > Dahua > Dahua DVR',
        dahua_cameras: 'VaaS > Dahua > Câmeras Dahua',
      },
      generic_devices: 'VaaS > Dispositivos Genéricos',
      recording_requests: {
        tag: 'VaaS > Solicitações de Gravação > Tag',
        recording_requests: 'VaaS > Solicitações de Gravação > Solicitações de Gravação',
      },
      analytics: {
        facial_recognition: {
          sarf: {
            events: 'VaaS > Análises > Reconhecimento Facial > SAFR > Eventos',
            groups: 'VaaS > Análises > Reconhecimento Facial > SAFR > Grupos',
            people: 'VaaS > Análises > Reconhecimento Facial > SAFR > Pessoas',
          },
          luna: {
            master_accounts: 'VaaS > Análises > Reconhecimento Facial > Luna > Contas Mestras',
            list: 'VaaS > Análises > Reconhecimento Facial > Luna > Lista',
            face: 'VaaS > Análises > Reconhecimento Facial > Luna > Rosto',
            facial_recognition_matches: 'VaaS > Análises > Reconhecimento Facial > Luna > Correspondências de Reconhecimento Facial',
            facestream: 'VaaS > Análises > Reconhecimento Facial > Luna > Transmissão facial',
          }
        },
        lpr: {
          plate_list: 'VaaS > Análises > LPR > Lista de Placas',
          plate: 'VaaS > Análises > LPR > Placa',
          lpr_matches: 'VaaS > Análises > LPR > Correspondências de LPR',
        },
        detections_events: 'VaaS > Análises > Eventos de Detecção',
      }
    },
    tenants: {
      all_tenants: 'Tenants > Todos os Tenants',
      master_tenants: 'Tenants > Tenants Mestres',
      incident_list: 'Tenants > Lista de Incidentes',
      incident_chart: 'Tenants > Gráfico de Incidentes',
      logs_incidents: 'Tenants > Registros de Incidentes',
      layouts: 'Tenants > Layouts',
      device_tag: 'Tenants > Tag do Dispositivo',
      token: 'Tenants > Token',
      token_omnitag: 'Tenants > Token Omnitag',
    },
    users: {
      all_users: 'Usuários > Todos os Usuários',
      profile: 'Usuários > Perfil',
      permissions: 'Usuários > Permissões',
      groups: 'Usuários > Grupos',
    },
    alarms: {
      all: 'Alarmes > Todos',
      seproban: 'Alarmes > Seproban',
      alarms_levels: 'Alarmes > Níveis de Alarme',
      alarm_comment: 'Alarmes > Comentário de Alarme',
      alarm_views: 'Alarmes > Visualizações de Alarme',
    },
    facilities: {
      all_facilities: 'Instalações > Todas as Instalações',
      centers: 'Instalações > Centros',
      sectors: 'Instalações > Setores',
      municipalities: 'Instalações > Municípios',
    },
    logs: {
      system_logs: 'Registros > Registros do Sistema',
      traffic: 'Registros > Tráfego',
      command_logs: 'Registros > Registros de Comandos',
      action_plan_logs: 'Registros > Registros de Planos de Ação',
    },
    automation: {
      custom_command: 'Automatização > Comando Personalizado',
      workflow: 'Automatização > Fluxo de Trabalho',
      action_plan: 'Automatização > Plano de Ação',
    },
    voip: {
      iotcomms_master_accounts: 'VoIP > Contas Mestras de IoTComms',
      types_devices_interphones: 'VoIP > Tipos de Dispositivos Interfones',
      devices_interphones: 'VoIP > Dispositivos Interfones',
      calls: 'VoIP > Chamadas',
    },
  },
}
