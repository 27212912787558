export default {
  namespaced: true,
  plane: {
    title: "Plano",
    add: "Adicionar",
    name: "Nome",
    instructions: "Instruções",
    devices: "Dispositivos",
    image: "Arraste uma imagem",
    add_device: "Para texto ou dispositivo para adicionar ao plano",
    move_plane: "Segure para mover o avião",
    rotate_device: "Pressione e segure para girar o dispositivo"
  },
}
  