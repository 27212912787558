export default {
  namespaced: true,
  login: {
    password: "password",
    email: "Email",
    select_method_the_type_of_authentication: "Select method the type of authentication",
    code: "code",
    resend_code: "Resend code...",
    you_can_resend_the_code_in_5_min: "You can resend the code in 5 min ",
    get_into: "Login",
    authentication_code_sent: "Authentication code sent",
    incorrect_code: "Incorrect code",
    the_code_expired_request_a_new_code: "The code expired, request a new code",
    change_means_of_authentication: "Change Means of Authentication",
    the_email_field_is_required: "The Email Field Is Required",
    the_password_field_is_required: "The Password Field Is Required",
    welcome_back: "Welcome back",
    failed_to_login:"failed to login"
  },
};
