export default {
  namespaced: true,
  intelexvision_event: {
    title: "Analytic record",
    camera_proxy: "Camera proxy",
    camera_name: "Camera name",
    id_alarm: "Id alarm",
    image: "Image",
    url_video: "Url video",
    alarm_category: "Alarm category",
    type_alarm: "Alarm type",
    alarm_created: "Alarm created",
    intelexvision_server: "Intelexvision server",
    alarm_intelexvision: "Intelexvision alarm",
    matched_rules: "Matched rules",
    priority: "Priority",
  },
};
