import axios from '@axios'

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    getItems(state) {
      return state.items
    },
    getSelectedItems(state) {
        return state.items.map(center => ({
            value: center.id,
            label: center.name,
        }))
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value
    },
  },
  actions: {

    async getAll({ commit }, id_group) {
      const { data } = await axios.get(`/v1/proxy_camera/getCentersAndSectorsForGroup/${id_group}`)

      if (!data) {
        return []
      }

      commit('setData', data.data)
      return data
    },
    async getDataTable(_,params) {
      const { data } = await axios.get(`v1/proxy_camera/getGroupCamerasToAssign`, {params})

      return data
    },
    async setCameraToGroup(_,params) {
      const { data } = await axios.post(`v1/proxy_camera/setCameraToGroup`, params)

      return data
    },
    async setAllCameraSectorToGroup(_,params) {
      const { data } = await axios.post(`v1/proxy_camera/setAllCameraSectorToGroup`, params)

      return data
    },
    async deleteAllCameraSectorToGroup(_,params) {
      const { data } = await axios.post(`v1/proxy_camera/deleteAllCameraSectorToGroup`, params)

      return data
    },
    async setAllCameraCenterToGroup(_,params) {
      const { data } = await axios.post(`v1/proxy_camera/setAllCameraCenterToGroup`, params)

      return data
    },
    async deleteAllCameraCenterToGroup(_,params) {
      const { data } = await axios.post(`v1/proxy_camera/deleteAllCameraCenterToGroup`, params)

      return data
    },
    async setAllCameraAllCenterToGroup(_,params) {
      const { data } = await axios.post(`v1/proxy_camera/setAllCameraAllCenterToGroup`, params)

      return data
    },
    async deleteAllCameraAllCenterToGroup(_,params) {
      const { data } = await axios.post(`v1/proxy_camera/deleteAllCameraAllCenterToGroup`, params)

      return data
    },
    clear({ commit }) {
      commit('setData', [])
    }
  },
}
