import axios from '@axios'

export default {
    namespaced: true,
    state: {
        layouts: [],
        userLayouts: []
    },
    getters: {
        getUserLayouts(state) {
            return state.userLayouts.map((layout) => ({
                id: layout.id,
                name: layout.name,
                grid: layout.data,
            }));
        }
    },
    mutations: {
        setLayouts(state, value) {
            state.layouts = value;
        },
        setUserLayouts(state, value) {
            state.userLayouts = value;
        },
    },
    actions: {
        async getLayouts({ commit }, params) {
            const { data } = await axios.get('v1/layout', { params })
            commit('setLayouts', data.data);

            if (!data.success) {
                return [];
            }

            return data;
        },

        async getUserLayouts({ commit }, params) {
            const _params = {
              max: 1000,
              ...params
            };
            const { data } = await axios.get('v1/layout', { params: _params })
            commit('setUserLayouts', data.data);

            if (!data.success) {
                return [];
            }

            return data;
        },

        async getLayout({ commit }, id) {
            const { data } = await axios.get(`v1/layout/${id}`)

            if (!data.success) {
                return [];
            }

            return data;
        },

        async delete(_, id) {
            const { data } = await axios.delete(`v1/layout/${id}`)

            return data
        },
        clear({ commit }) {
          commit('setLayouts', [])
          commit('setUserLayouts', [])
        }
    }
}
