export default {
  namespaced: true,
  swift_sensor: {
    title: "swift sensor",
    id_external: "id external",
    first_contact_time: "first contact time",
    last_contact_time: "last contact time",
    id_hardware: "id hardware",
    batery_level: "batery level",
    wireless_type: "wireless type",
    sample_rate: "sample rate",
    is_event_based: "is event based",
    id_sensor: "id sensor",
    id_threshold: "id threshold",
    id_unit_measure: "id unit measure",
    id_type_sensor: "id type sensor",
    offset: "offset",
    manufacturer: "manufacturer",
    model: "model",
    hardware_revision: "hardware revision",
    software_revision: "software revision",
    name: "name",
    description: "description",
    latitude: "latitude",
    longitude: "longitude",
    elevation: "elevation",
    signal_strength: "signal strength",
    is_new: "is new",
    ethernet_mac_address: "ethernet mac address",
    wifi_mac_address: "wifi mac address",
    ip_address: "ip address",
    wifi: "wifi",
    ble: "ble",
    cell: "cell",
    gps: "gps",
    radio_gen2: "radio gen2",
    ble_gen3: "ble gen3",
    zigbee: "zegbee",
    zwave: "zwave",
    radio: "radio",
    id_swift_account: "id swift account",
    id_unit: "id unit",
    id_swift_gateway: "id swift gateway",
    sync_threshold: "sync thresholds from API",
  },
};
