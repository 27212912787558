export default {
  namespaced: true,
  vxg_camera: {
    id_vxg: "ID VXG",
    name: "Nombre",
    rec_mode: "Modo Grabación",
    live_stream: "Transmisión en Vivo",
    recording_stream: "Transmisión Video Grabado",
    access_token_watch: "Token de Acceso a Video",
    access_token_all: "Token de Acceso a Todo",
    streaming: "Streaming",
    timezone: "Zona Horaria",
    update_from_vxg: "Actualizar Camaras desde API",
    select_multiple: "Seleccionar Múltiple",
    delete_vxg_sbo: "Eliminar VaaS y SBO",
    delete_sbo: "Eliminar en SBO",
    removed: "Elimando",
    removed_cameras: "Eliminando camaras",
  },
};
