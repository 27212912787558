export default {
  namespaced: true,
  external_user: {
    title: "usuario externo | usuarios externos",
    description: "descripción",
    token: "token",
    host: "host",
    is_validated: "esta validado",
  },
};
