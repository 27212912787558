export default {
  namespaced: true,
  messages: {
    delete: {
      title: "Você tem certeza que quer apagar isso?",
      body: "Uma vez excluídos, todos os registros associados serão excluídos",
      deleted_correctly: "Eliminado corretamente",
      title_n: "Tem certeza de que deseja excluir {n} elementos?",
      all:"Tem certeza de que deseja excluir tudo?",
    },
    reset: {
      title: "Tem certeza que deseja redefinir a senha?",
    },
    error: {
      server_error: "Erro ao obter as informações",
    },
    validation: {
      email_placeholder: "Insira um ou mais e-mails",
    },
    info: {
      begin_download: "Iniciando processo para exportar vídeo...",
      in_process_download: "Processo iniciado com sucesso...",
      finish_download: "A gravação foi baixada com sucesso",
      sending: "enviando",
      advanced_search: "busca avançada",
      clear_filters: "limpar filtros",
    },
    export: {
      title: "Exportar",
      download: "Download",
      Generating_file: "Generando arquivo...",
      download_succesfully: "Download completa...",
      report_of: "Relatório de {model}",
      report: "Relatorio de",
      full_download: "Download completo",
    },
    edit_save: {
      successfully_created: "Criado com sucesso",
      updated_successfully: "Editado com sucesso",
    },
    copy: {
      copied_successfully: "Copiado com sucesso",
      could_not_copy: "Não foi possível copiar",
    },
    import: {
      title: "Importar",
      select_file: "Selecione ou arraste e solte o arquivo",
      upload: "Subiendo",
      uploading_file: "Carregando arquivo...",
      upload_succesfully: "Carga completa...",
      example_file: "Arquivo de Amostra",
    },
    loading: {
      title: "Carregando"
    }
  },
};
