export default {
  namespaced: true,
  state: {
    items: {
      locale: "en",
      img: require("@/assets/images/flags/en.png"),
      name: "English",
    },
  },
  getters: {
    getItems(state) {
      return state.items;
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value;
    },
  },
};
