export default {
  namespaced: true,
  unit: {
    title: "facility | facilities",
    all: "all facilities",
    description: "description",
    address_1: "address",
    address_2: "reference",
    exterior_number: "exterior number",
    suburb: "suburb",
    number: "number",
    postal_code: "postal code",
    route: "route",
    plate_number: "plate number",
    vin: "VIN",
    driver: "driver",
    provider: "provider",
    owner: "owner",
    company: "company",
    vehicle: "Is a vehicle?",
    reaction_patrol: "reaction patrol",
    allow_virtual_button_alarm: "allow virtual button alarm",
    active_digital_output: "active digital output",
    camera_count: "cameras",
    allow_alarms: "Allow alarms",
  },
};
