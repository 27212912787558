export default {
  namespaced: true,
  commands: {
    title: "Comando personalizado",
    title_main: "Comando personalizado",
    action_add: 'Criar comando personalizado',
    action_update: 'Atualizar comando personalizado',
    base_commands: 'Comandos básicos',
    custom_commands: 'Comandos personalizados',
    fields: {
      name: {
        label: 'Nome do comando',
        placeholder: 'Nome do comando'
      },
      description: {
        label: 'Descrição do comando',
        placeholder: 'Descrição do comando'
      },
      alarm_compatible: {
        label: "É compatível com alarmes?"
      },
      requirements: {
        label: 'Requisitos (opcional)'
      }
    },
    messages: {
      success_created: 'Comando personalizado criado com sucesso',
      success_updated: 'Comando personalizado atualizado com sucesso',
      layout_empty: 'Não é possível salvar um comando vazio',
      connections_empty: 'Não são permitidos nós sem conexões',
      connections_qty_exceeded: 'Não são permitidos nós com múltiplas conexões',
      incomplete_node_config: 'Configuração incompleta nos seguintes nós',
    },
    btns: {
      delete_image: 'Excluir imagem',
      create: 'Salvar',
      update: 'Atualizar',
      custom: 'Personalizado',
      base: 'Padrão',
    },
    requirements: {
      cols: {
        col1: 'Tipo',
        col2: 'Seleção Múltip.'
      }
    },
    list: {
      enable_unit_cameras_do: {
        name: "Ativar saída digital das câmeras",
        desc: "Ativa a saída digital das câmeras da instalação",
      },
      enable_speaker: {
        name: "Ativar DO1 do roteador",
        desc: "Ativa a saída digital do roteador"
      },
      show_user_view: {
        name: "Mostrar visualização/tab",
        desc: "Muestra uma visualização/tab en SBO"
      },
      delay: {
        name: "Atraso",
        desc: "",
        hint: "Quantidade de tempo em segundos:"
      },
      active_digital_output_camera: {
        name: "Ativa saída digital das câmeras (antigas)",
        desc: "Ativar saída digital das câmeras",
        hint: "",
      },
      activate_digital_output_camera: {
        name: "Ativa saída digital das câmeras",
        desc: "Ativar saída digital das câmeras",
        hint: "",
      },
      deactivate_digital_output_camera: {
        name: "Desativar saída digital das câmeras",
        desc: "Desativa a saída digital das câmeras",
        hint: "",
      },
      deactivate_sound_router: {
        name: "Desativa DO1 do roteador",
        desc: "Desativar a saída digital do roteador",
        hint: "",
      },
      push_camera_monitor: {
        name: "Coloque a câmera no monitor",
        desc: "Coloca a câmera selecionada na visualização do monitor",
        hint: "",
      },
      interphone_call: {
        name: "Chamada de intercomunicador",
        desc: "Faz uma chamada de intercomunicador"
      },
      active_digital_output_rtu_router: {
        name: "Ativar saída digital RTU roteador",
        desc: "Ativa uma saída digital específica do roteador",
        hint: "",
      },
      density_shoot: {
        name: "Realize um tiro real de neblina",
        desc: "Execute um disparo real de névoa do dispositivo especificado",
      },
      density_test_shoot: {
        name: "Realizar disparo de prueba de niebla",
        desc: "Realize um disparo de teste de névoa do dispositivo especificado",
      },
      notification_command: {
        name: "Enviar notificação",
        desc: "Envie notificações de diferentes formas como Mensagem de Texto, E-mail, Webhook, Whatsapps...",
      },
      show_action_plan: {
        name: "Mostrar um plano de ação",
        desc: "Mostra o plano de ação especificado",
      },
      camera_round: {
        name: "Rotação de câmeras",
        desc: "Comando para iniciar uma rotação de câmeras",
      },
      assignment_cameras: {
        name: "Atribuição de câmera",
        desc: "Atribuição de câmera",
      },
    },
    form: {
      units_select: {
        label: 'Selecione as instalações para este nó:'
      },
      cameras_select: {
        label: 'Selecciona las cámaras para este nodo:'
      },
      user_views_select: {
        label: 'Selecione as câmeras para este nó:'
      },
      users_select: {
        label: 'Selecione os usuários para este nó:'
      },
      interphone_from: {
        label: 'Selecione o telefone de origem:'
      },
      interphone_to: {
        label: 'Selecione o telefone de destino:'
      },
      density_device: {
        label: 'Selecione o dispositivo Density:'
      },
      type_notificaction: {
        label: 'Tipo de notificação:'
      },
      type_verb_webhook: {
        label: 'Verbo RESTful:'
      },
      url1: {
        label: 'URL de alarme:'
      },
      url2: {
        label: 'URL de feedback de alarme:'
      },
      action_plan: {
        label: 'Selecione um plano de ação:'
      },
      layouts: {
        label: 'Selecione um layout:'
      },
      loop: {
        label: 'Ciclo:'
      },
      location: {
        label: 'Insira a localização:',
        fields: {
          lat: 'Latitude',
          lng: 'Longitude',
          from_alarm: 'Pegar valor do alarme'
        }
      },
      radius: {
        label: 'Insira o raio:'
      },
      delay: {
        label: 'Insira o atraso',
      },
      message_template: {
        label: 'Modelo de mensagem',
        characters_remaining: 'Personagens restantes',
        available_placeholders: 'Espaços reservados disponíveis',
        help: {
          header: 'Escreva o texto que será enviado pelo WhatsApp. Você pode usar os seguintes espaços reservados que serão posteriormente substituídos pelas informações do alarme:',
          footer: {
            title: 'Exemplo',
            body: 'Comando executado do instalação @facility_name, id do alarme #@id_alarm do tipo @alarm_type'
          }
        }
      },
      groups_select: {
        label: 'Selecione os grupos para este nó:'
      },
      tenants_select: {
        label: 'Selecione os tenants para este nó:'
      },
      centers_select: {
        label: 'Selecione os centers para este nó:'
      },
      sectors_select: {
        label: 'Selecione os sectors para este nó:'
      },
      filter_field: {
        label: 'Adicionar filtro para este nó:'
      },
      email: {
        label: 'Insira os e-mails',
      },
      phone: {
        label: 'Insira os telefones',
      },
      receiver: {
        label: 'Insira o receptor',
      },
      authorization: {
        label: 'Insira sua chave de autorização',
      },
      url1: {
        label: 'Insira o URL do alarme',
      },
      url2: {
        label: 'Insira o URL dos feedbacks do alarme',
      },
    },
    data_types: {
      users: 'Usuários',
      user_views: 'Visualizações do usuário',
      units: 'Instalações',
      cameras: 'Câmeras',
      phone: 'Telefones',
      email: 'E-mails',
      log_id: 'Registros',
      do: 'Saída digital',
      interphone_from: 'Chamada interfon (de)',
      interphone_to: 'Chamada interfon (a)',
      message_template: 'Mensagem',
      type: 'Tipo de notificação',
      receiver: 'Receptor',
      verb: 'Verbo RESTful',
      authorization: 'Autorização',
      url1: 'URL de alarme',
      url2: 'URL de feedback de alarme',
      webhook: 'URL Webhook',
      action_plan: 'Plano de ação',
      delay: 'Atraso',
      layout: 'Layouts',
      location: 'Localização',
      radius: 'Radio',
      loop: 'Ciclo',
      density_device: 'Dispositivo Density',
      groups: 'Grupos',
      tenants: 'Tenants',
      centers: 'Centers',
      sectors: 'Sectors',
    },
    flowchart: {
      show_hide_node_config: 'Mostrar/ocultar configurações de comando',
    },
    gridWidget: {
      title: 'Comandos',
      table: {
        no_data: "Nenhuma informação disponível",
        rows: {
          1: "Nome",
          2: "Descrição",
        }
      }
    },
    modal: {
      title: 'Execução de comando',
      body: 'O seguinte comando será executado',
      btns: {
        continue: 'Prosseguir',
        cancel: 'Cancelar'
      },
      messages: {
        success: 'Status: Comando executado com sucesso',
        error: 'Estado: Ocorreu um erro'
      }
    },
    config_modal: {
      title: 'Configuração de comando',
      messages: {
        invalid_form: 'Por favor, corrija os erros no formulário',
      }
    },
    logs: {
      title: 'Log de comandos',
      filters: {
        commands: 'Comandos'
      },
      table: {
        id_operation: 'ID da operação',
        user: 'Usuário',
        description: 'Descrição',
        command: 'Comando',
        command_source: 'Comando relacionado',
        level: 'Nivel',
        data: 'Informação',
        date: 'Data'
      }
    },
    units: {
      seconds: 'segundo(s)',
      meters: 'metro(s)',
    }
  }
}
