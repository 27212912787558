export default {
  namespaced: true,
  traffic: {
    title: "traffic",
    request: "petição",
    created: "chegada",
    imei: "imei",
    ip: "ip",
    alarm: "alarme",
  },
};
