import Vue from "vue";
import AlarmContent from "@/components/toastification/AlarmContent.vue";
import store from "@/store";
import { EventBus } from "@/libs/event-bus";
import LayoutApi from "@/libs/LayoutApi";
import { SLOT_TYPES, SOURCES } from "@/config/layoutConfig";

const actions = {
  showMessage({ state }, payload) {
    if (state.alarms.filter(alarm => alarm.id != payload.data.id)) {
      Vue.$toast({
        component: AlarmContent,
        position: "top-right",
        timeout: 10000,
        props: {
          title: payload.title,
          variant: payload.variant,
          icon: "BellIcon",
          text: payload.msg,
          data: payload.data,
        },
      });
    }
  },

  setShowUserViewWS({ state, commit, dispatch }) {
    const user = this.getters["user/getUser"];

    window.Echo.connector.socket.on("subscription_error", (channel, data) => {
      console.log("grid::websockets:userviewsws", channel, data);
    });

    if (!window.Echo.connector.socket.connected) window.Echo.connect();

    window.Echo.private(`show-user-view-${user.id}`).listen(".update", (data) => {
      if (data.message.id === "default_alarm_view") {
        dispatch("setDefaultAlarmView", data.message.alarm_data);
      } else {
        const viewData = data.message.data;

        if (!Object.keys(state.tabs).includes(viewData.id)) {
          dispatch("addNewTab", { viewData });
        }
        dispatch("setActiveTab", viewData.id);
      }
    });
  },

  setActionPlanWS({ state, commit, dispatch }) {
    const user = this.getters["user/getUser"];

    window.Echo.connector.socket.on("subscription_error", (channel, data) => {
      console.log("grid::websockets:userviewsws", channel, data);
    });

    if (!window.Echo.connector.socket.connected) window.Echo.connect();

    window.Echo.private(`show-action-plan-${user.id}`).listen(".update", async (wsData) => {

      const data = wsData.message;
      console.log(data);
      const layoutApi = new LayoutApi(this);
      const newTab = await layoutApi.addNewTab(data.action_plan_name, 'default');
      layoutApi.setActiveTab(newTab.id);
      const currentTabId = layoutApi.getCurrentTabId();

      setTimeout(() => {

        if (newTab.id === currentTabId) {

          const slotInfo = {
            name: `action_plan_${newTab.id}`,
            position: 3,
            data: {
              id_action_plan: data.id_action_plan,
              id_alarm: data.id_alarm,
              in_app_source: SOURCES.COMMAND,
            },
            slotType: SLOT_TYPES.ACTION_PLAN
          };
          layoutApi.setSlot(
            slotInfo.name,
            slotInfo.position,
            slotInfo.data,
            slotInfo.slotType
          );
        }

      }, 500);

    });
  },

  setAlarmsWS({ state, commit, dispatch }) {
    const user = store.state.user.user;
    window.Echo.connector.socket.on("subscription_error", (channel, data) => {
      console.log("grid::websockets:alarmsws", channel, data);
    });

    if (!window.Echo.connector.socket.connected) window.Echo.connect();

    window.Echo.private(`user-${user.id}`)
      .listen(".Alarm", (data) => {
        dispatch("updateAlarms");
        dispatch("addNotificationAlarm", data);

        if (data.event == "created") {

          if (
            this.getters["user/getPreferences"].show_alarm_notifications &&
            data.alarm_status == "waiting"
          ) {
            // TO DO: poner configuracion de noticaciones por tipos (estatus alarmas)
            dispatch("showMessage", {
              title: null,
              msg: null,
              variant: "success",
              data,
            });

            if (data.alarm_level.media) {
              let audio = new Audio(data.alarm_level.media.url);
              audio.play();
            }
          }

          if (data.detection_event_data && data.detection_event_data.id_camera_proxy) {
            const cameraProxyId = data.detection_event_data.id_camera_proxy;
            EventBus.emit(`new_alarm:${cameraProxyId}`, data);
          }
        }

        if (store.state.sip.presavedAlarm.alarmJson) {
          console.log(store.state.sip.presavedAlarm.alarmJson);
          if (store.state.sip.presavedAlarm.alarmJson.id == data.id) {
            if (data.alarm_status) {
              store.dispatch("sip/changePresavedAlarmStatus", data.alarm_status);
              EventBus.emit("change-alarm-status", data.alarm_status);
            }
          }
        }
      });
  },
  setUserSetUp({ state, commit, dispatch }) {
    const user = store.state.user.user;
    window.Echo.connector.socket.on("subscription_error", (channel, data) => {
      console.log("grid::websockets:setUserSetUp", channel, data);
    });

    if (!window.Echo.connector.socket.connected) window.Echo.connect();
    window.Echo.private(`user-setup-${user.id}`)
      .listen(".call", (data) => {
        EventBus.emit(`call_device`, { id: data.device_id });
      })
      .listen(".updateConfig", async (data) => {
        console.log({ data });
        const userData = data
        userData.ability = [];
        if (userData.video_wall) {
          store
            .dispatch("video_wall/online", userData)
            .then((response) => {
              console.log(response);
            })
            .catch((response) => console.log(response));
        }
        if (userData.is_master_admin) {
          userData.ability.push({
            action: "manage",
            subject: "all",
          });
        } else {
          userData.ability = data.abilities;
          userData.ability.push({
            action: "read",
            subject: "Auth",
          });

          userData.ability.push({
            action: "read",
            subject: "Home",
          });
        }
        localStorage.setItem("userData", JSON.stringify(userData)); //update userData local storage
        store.dispatch("user/setUserDataFromLocalStorage", userData); //update user state on store
        dispatch("updateAlarms"); //update alarms
        EventBus.emit("refresh_navbar_elements_for_user_update", userData); //update navbar horizontal
        //Compare units if are the same dont update, valid if it's better to have 2 events, one for the units and other for the config.


        await store.dispatch("grid/resetUnits"); //reset units
        // units = {...store.state.grid.units }
        // units.filter ()
      });

    window.Echo.private(`user-${user.id}`)
      .listen(".ExternalUserCreated", (data) => {
        let userData = JSON.parse(localStorage.getItem("userData"));
        userData.external_users.push(data);
        localStorage.setItem("userData", JSON.stringify(userData));
        store.dispatch("user/setUserDataFromLocalStorage", userData);
        dispatch("updateAlarms");
      })
      .listen(".ExternalUserDeleted", (data) => {
        let userData = JSON.parse(localStorage.getItem("userData"));
        userData.external_users = userData.external_users.filter(
          (ext) => ext.id != data.id
        );
        localStorage.setItem("userData", JSON.stringify(userData));
        store.dispatch("user/setUserDataFromLocalStorage", userData);
        dispatch("updateAlarms");
      });
  },
};

export default {
  actions,
};
