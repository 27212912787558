import axios from '@axios'

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    getItems(state) {
      return state.items
    },
    getSelectedItems(state) {
      return state.items.map(plane => ({
        value: plane.id,
        label: plane.description,
      }))
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value
    },
  },
  actions: {
    async add(_, params) {
      const { data } = await axios.post('v1/plane', params, { headers: { 'Content-Type': 'multipart/form-data' } })

      return data
    },
    async edit(_, { id, params }) {
      const { data } = await axios.put(`v1/plane/${id}`, params)

      return data
    },
    async delete(_, id) {
      const { data } = await axios.delete(`v1/plane/${id}`)

      return data
    },

    async get(_, id) {
      const { data } = await axios.get(`v1/unit/${id}`)

      return data
    },
    clear({ commit }) {
      commit('setData', [])
    }
  },
}
