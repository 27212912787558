export default {
  namespaced: true,
  buttons: {
    title: "botões",
    button_type:"tipo de botões",
    description:" descrição",
    divice_id:"id del dispositivo",
    device_type_id:"id do tipo dispositivo",
    button_report:"Relatório de Botões",
    all: "todos os botões",
    preview: "Pré-visualização",
  }
}
