export default {
  namespaced: true,
  recording_request: {
    title: "Recording request | Recording requests",
    folio: "Folio",
    status: "status",
    requested: "requested",
    description: "description",
    camera: "camera",
    tag: "tag",
    start: "start",
    finish: "finish",
    authorized_from: "authorized from",
    authorized_to: "authorized to",
    recording: "recording",
    download_timestamp: "do you want to include the date and time in the recording?",
    time_expired: "the time authorized to view it has expired",
    comment: "comment",
    authorized_by: "Authorized by",
    files: "files",
  }
}
