import axios from '@axios'
import permission from '../permission'

export default {
  namespaced: true,
  state: {
    items: [],
    avaible_tenants: [],
    avaible_permissions: [],
    avaible_type_alarms : []
  },
  getters: {
    getItems(state) {
      return state.items
    },
    getSelectedItems(state) {
      return state.items.map(tenant => ({
        value: tenant.id,
        label: tenant.name,
      }))
    },
    getAvaibleTenants(state) {
      console.log({state})
      return state.avaible_tenants.map(tenant => ({
        value: tenant.id,
        label: tenant.name,
      }))
    },
    getAvaiblePermissions(state) {
      return state.avaible_permissions.map(permission => ({
        value: permission.id,
        label: permission.description,
      }))
    },
    getAvaibleTypeAlarms(state) {
      return state.avaible_type_alarms.map(type_alarm => ({
        value: type_alarm.id,
        label: type_alarm.description,
      }))
    },

  },
  mutations: {
    setData(state, value) {
      state.items = value
    },
    setAvaibleTenants(state, value) {
      state.avaible_tenants = value;
    },
    setAvaiblePermissions(state, value) {
      state.avaible_permissions = value;
    },
    setAvaibleTypeAlarms(state, value) {
      state.avaible_type_alarms = value;
    },
  },
  actions: {
    async getAll({ commit }, {id, params}) {
      const { data } = await axios.get(`v1/tenantShared/datatable/${id}`, { params })

      if (!data.success) {
        return []
      }

      commit('setData', data.data)
      return data
    },
    async add(_, params) {
      const { data } = await axios.post('v1/tenantShared', params, { headers: { 'Content-Type': 'application/json' } })

      return data
    },
    async edit(_, { id, params }) {
      const { data } = await axios.put(`v1/tenantShared/${id}`, params, { headers: { 'Content-Type': 'application/json' } })

      return data
    },
    async delete(_, id) {
      const { data } = await axios.delete(`v1/tenantShared/${id}`)

      return data
    },
    async getAvaibleTenants({ commit }, id) {
      const { data } = await axios.get(`v1/tenantSharedAvailable/${id}`)

      if (!data.success) {
        return []
      }

      console.log({data})

      commit('setAvaibleTenants', data.data)
      return data
    },
    async getAvaiblePermissions({ commit }, id) {
      const { data } = await axios.get(`v1/tenants/permissions?id_tenant=${id}`)

      if (!data.success) {
        return []
      }

      commit('setAvaiblePermissions', data.data)
      return data
    },
    async getAvaibleTypeAlarms({ commit }, id) {
      const { data } = await axios.get(`v1/tenants/types_alarms?id_tenant=${id}`)

      if (!data.success) {
        return []
      }

      commit('setAvaibleTypeAlarms', data.data)
      return data
    },
    clear({ commit }) {
      commit('setData', [])
    }
  },
}
