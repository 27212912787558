import axios from "@axios";
import store from "@/store";

export default {
  namespaced: true,
  state: {
    items: [],
    progress: 0,
    error: '',
    url: '',
    isCreatedFile: false,
    idNotification: "",
    url: "",
  },
  getters: {
    getItems(state) {
      return state.items;
    },
    getSelectedItems(state) {
      return state.items.map((traffic) => ({
        value: traffic.id,
        label: traffic.request,
      }));
    },
    getProgress(state) {
      return state.progress
    },
    getError(state) {
      return state.error
    },
    getUrl(state) {
      return state.url
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value;
    },
  },
  actions: {
    async getAll({ commit }, params) {
      const response = await axios.get("v1/detection_event", {
        params,
        responseType: params?.action == "export" ? "blob" : "json",
      });

      if (!response.data) {
        return [];
      }
    
      if (params?.action == "export") {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
        link.remove();

        commit(
          "setIdNotification",
          response.headers["content-disposition"].split("filename=")[1]
        );
        commit("setUrl", url);
      } else {
        commit("setData", response.data.data);
        return response.data;
      }
    },
    clear({ commit }) {
      commit("setData", []);
    },
    resetProgress({ state }) {
      state.progress = 0
    },
    getIdNotification(state) {
      return state.idNotification;
    },
    getUrl(state) {
      return state.url;
    },
    setExport({ state, commit, dispatch }, jobId) {
      if (!window.Echo.connector.socket.connected) window.Echo.connect();

      const notification = {
        id: `detection-event-export-${jobId}`,
        progress: 0,
        completed: false,
        view: 'detection-event',
        url: '',
        title: 'Reporte de detection events',
        body: 'Descargando...',
        error: false
      }
      store.dispatch('grid/addDownloadNotification', notification)

      window.Echo.private(`detection-event-export-${jobId}`)
        .listen(".process", (data) => {
          const notification = {
            id: `detection-event-export-${jobId}`,
            progress: data.progress,
            completed: false,
            view: 'detection-event',
            url: '',
            title: 'Reporte de detection events',
            body: 'Descargando...',
            error: false,
            timestamp: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
          }
          store.dispatch('grid/updateDownloadNotification', notification)
          state.progress = data.progress
        })
        .listen(".end", (data) => {
          const notification = {
            id: `detection-event-export-${jobId}`,
            progress: 100,
            completed: true,
            view: 'detection-event',
            url: data.url,
            title: 'Reporte de detection events',
            body: 'Descarga completa',
            error: false,
            timestamp: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
          }
          store.dispatch('grid/updateDownloadNotification', notification)
          window.Echo.leave(`detection-event-export-${jobId}`)

          state.url = data.url
        })
        .listen(".exception", (data) => {
          const notification = {
            id: `detection-event-export-${jobId}`,
            progress: 100,
            completed: true,
            view: 'detection-event',
            url: data.url,
            title: 'Reporte de detection events',
            body: 'Ocurrio un error',
            error: true,
            timestamp: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
          }
          store.dispatch('grid/updateDownloadNotification', notification)
          window.Echo.leave(`detection-event-export-${jobId}`)
          state.error = 'Ocurrio un error'
        })
    },
  },
};
