export default {
  namespaced: true,
  swift_sensor: {
    title: "Sensores Swift",
    id_external: "id externa",
    first_contact_time: "hora do primeiro contato",
    last_contact_time: "último horário de contato",
    guid: "guía",
    manufacturer: "fabricante",
    model: "modelo",
    hardware_revision: "patch de hardware",
    software_revision: "patch de software",
    serial_number: "número de série",
    name: "Nome",
    description: "Descrição",
    latitude: "latitud",
    longitude: "longitud",
    elevation: "elevação",
    connection_type: "Tipo de conexão",
    connection_pref: "preferência de conexão",
    network: "rede",
    signal_strength: "sinal de força",
    is_new: "é novo",
    ethernet_mac_address: "endereço mac ethernet",
    wifi_mac_address: "endereço mac wifi",
    ip_address: "endereço ip",
    wifi: "wifi",
    ble: "ble",
    cell: "cell",
    gps: "gps",
    radio_gen2: "radio gen2",
    ble_gen3: "ble gen3",
    zigbee: "zegbee",
    zwave: "zwave",
    radio: "radio",
    id_swift_account: "id swift account",
    id_unit: "id instalação",
    id_hardware: "id hardware",
    batery_level: "nivel de bateria",
    wireless_type: "tipo sem fio",
    sample_rate: "taxa de amostragem",
    is_event_based: "é baseado em eventos",
    id_sensor: "id sensor",
    id_threshold: "id límite",
    id_unit_measure: "id unidade de medida",
    id_type_sensor: "id tipo do sensor",
    offset: "offset",
    id_swift_gateway: "id swift gateway",
    sync_threshold: "sincronizar umbrales da API",
  },
};
