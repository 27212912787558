export default {
  namespaced: true,
  actions: {
    facial_recognition: "Facial Recognition",
    title: "Facial Recognition Matches",
    people_list: "people list",
    facility: "facility",
    image: "image",
    person_id: "person id",
    name: "name",
    age: "age",
    gender: "gender",
    wears_glasses: "wears glasses",
    capture_blur: "capture blur",
    capture_darkness: "capture darkness",
    catch_light: "catch light",
    captured_saturation: "capture saturacion",
    captured_emotion: "capture emotion",
    captured_ethnicity: "captured ethnicity",
    catch_percentage: "catch percentage",
    match_percentage: "match percentage"
  }
}
