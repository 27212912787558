export default {
  namespaced: true,
  swift_account: {
    title: "Cuentas swift",
    id_external: "id externa",
    name: "nombre",
    is_enabled: "habilitado",
    is_monitored: "monitoreado",
    retention: "retencion",
    brand_id: "id de marca",
    language: "lenguaje",
    creation_time: "Tiempo de creacion",
    id_parent: "id parent"
  },
}
