export default {
  namespaced: true,
  external_user: {
    title: "external user | external users",
    description: "description",
    token: "token",
    host: "host",
    is_validated: "is validated",
  },
};
