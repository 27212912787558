export default {
  namespaced: true,
  breadcrumbs: {
    vass:{
      camera_manager: {
        master_account: 'VaaS > Gestor de Cámaras > Cuenta Maestra',
        reseller: 'VaaS > Gestor de Cámaras > Revendedor',
        cameras: 'VaaS > Gestor de Cámaras > Cámaras',
        status_cameras: 'VaaS > Gestor de Cámaras > Estado de Cámaras',
      },
      ee_vms: {
        master_accounts: 'VaaS > EE VMS > Cuentas Maestras',
        accounts: 'VaaS > EE VMS > Cuentas',
        bridges: 'VaaS > EE VMS > Puentes',
        cameras: 'VaaS > EE VMS > Cámaras',
        presets: 'VaaS > EE VMS > Ajustes Preestablecidos',
      },
      luxriot: {
        master_accounts: 'VaaS > Luxriot > Cuentas Maestras',
        cameras: 'VaaS > Luxriot > Cámaras',
      },
      omnicloud: {
        master_accounts: 'VaaS > OmniCloud > Cuentas Maestras',
        cameras: 'VaaS > OmniCloud > Cámaras',
        omnia_reseller: 'VaaS > OmniCloud > Revendedor de Omnia',
      },
      dahua: {
        dahua_dvr: 'VaaS > Dahua > Dahua DVR',
        dahua_cameras: 'VaaS > Dahua > Cámaras Dahua',
      },
      generic_devices: 'VaaS > Dispositivos Genéricos',
      recording_requests: {
        tag: 'VaaS > Solicitudes de Grabación > Etiqueta',
        recording_requests: 'VaaS > Solicitudes de Grabación > Solicitudes de Grabación',
      },
      analytics: {
        facial_recognition: {
          sarf: {
            events: 'VaaS > Analíticas > Reconocimiento Facial > SAFR > Eventos',
            groups: 'VaaS > Analíticas > Reconocimiento Facial > SAFR > Grupos',
            people: 'VaaS > Analíticas > Reconocimiento Facial > SAFR > Personas',
          },
          luna: {
            master_accounts: 'VaaS > Analíticas > Reconocimiento Facial > Luna > Cuentas Maestras',
            list: 'VaaS > Analíticas > Reconocimiento Facial > Luna > Lista',
            face: 'VaaS > Analíticas > Reconocimiento Facial > Luna > Rostro',
            facial_recognition_matches: 'VaaS > Analíticas > Reconocimiento Facial > Luna > Coincidencias de Reconocimiento Facial',
            facestream: 'VaaS > Analíticas > Reconocimiento Facial > Luna > Transmisión Facial',
          }
        },
        lpr: {
          plate_list: 'VaaS > Analíticas > LPR > Lista de Placas',
          plate: 'VaaS > Analíticas > LPR > Placas',
          lpr_matches: 'VaaS > Analíticas > LPR > Coincidencias de LPR',
        },
        detections_events: 'VaaS > Analíticas > Eventos de Detección',
      }
    },
    tenants: {
      all_tenants: 'Tenanants > Todos los Tenanants',
      master_tenants: 'Tenanants > Tenanants Maestros',
      incident_list: 'Tenanants > Lista de Incidentes',
      incident_chart: 'Tenanants > Gráfico de Incidentes',
      logs_incidents: 'Tenanants > Registros de Incidentes',
      layouts: 'Tenanants > Diseños',
      device_tag: 'Tenanants > Etiqueta del Dispositivo',
      token: 'Tenanants > Token',
      token_omnitag: 'Tenanants > Token Omnitag',
    },
    users: {
      all_users: 'Usuarios > Todos los Usuarios',
      profile: 'Usuarios > Perfil',
      permissions: 'Usuarios > Permisos',
      groups: 'Usuarios > Grupos',
    },
    alarms: {
      all: 'Alarmas > Todas',
      seproban: 'Alarmas > Seproban',
      alarms_levels: 'Alarmas > Niveles de Alarmas',
      alarm_comment: 'Alarmas > Comentario de Alarma',
      alarm_views: 'Alarmas > Vistas de Alarma',
    },
    facilities: {
      all_facilities: 'Instalaciones > Todas las Instalaciones',
      centers: 'Instalaciones > Centros',
      sectors: 'Instalaciones > Sectores',
      municipalities: 'Instalaciones > Municipios',
    },
    logs: {
      system_logs: 'Registros > Registros del Sistema',
      traffic: 'Registros > Tráfico',
      command_logs: 'Registros > Registros de Comandos',
      action_plan_logs: 'Registros > Registros de Planes de Acción',
    },
    automation: {
      custom_command: 'Automatización > Comando Personalizado',
      workflow: 'Automatización > Flujo de Trabajo',
      action_plan: 'Automatización > Plan de Acción',
    },
    voip: {
      iotcomms_master_accounts: 'VoIP > Cuentas Maestras de IoTComms',
      types_devices_interphones: 'VoIP > Tipos de Dispositivos Interfonos',
      devices_interphones: 'VoIP > Dispositivos Interfonos',
      calls: 'VoIP > Llamadas',
    },
  },
}
