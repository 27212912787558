import axios from '@axios'

export default {
  namespaced: true,
  state: {
    items: [],
    deviceTypes: []
  },
  getters: {
    getItems(state) {
      return state.items
    },
    getSelectedItems(state) {
        return state.items.map(center => ({
            value: center.id,
            label: center.name,
        }))
    },
    getDeviceTypes(state){
      return state.deviceTypes
    }
  },
  mutations: {
    setData(state, value) {
      state.items = value
    },
    setDeviceTypes(state, value) {
      state.deviceTypes = value
    },
  },
  actions: {
    async getDeviceTypes({ commit }, params) {
      const { data } = await axios.get('/v1/proxy_camera/getDeviceTypes', { params })

      if (!data) {
        return []
      }

      commit('setDeviceTypes', data.data)
      return data
    },
    async getAll({ commit }, params) {
      const { data } = await axios.get('/v1/proxy_camera/getCentersAndSectors', { params })

      if (!data) {
        return []
      }

      commit('setData', data.data)
      return data
    },
    async getDataTable(_,params) {
      const { data } = await axios.get(`v1/proxy_camera/getUserCamerasToAssign`, {params})

      return data
    },
    async setCameraToUser(_,params) {
      const { data } = await axios.post(`v1/proxy_camera/setCameraToUser`, params)

      return data
    },
    async setAllCameraSectorToUser(_,params) {
      const { data } = await axios.post(`v1/proxy_camera/setAllCameraSectorToUser`, params)

      return data
    },
    async deleteAllCameraSectorToUser(_,params) {
      const { data } = await axios.post(`v1/proxy_camera/deleteAllCameraSectorToUser`, params)

      return data
    },
    async setAllCameraCenterToUser(_,params) {
      const { data } = await axios.post(`v1/proxy_camera/setAllCameraCenterToUser`, params)

      return data
    },
    async deleteAllCameraCenterToUser(_,params) {
      const { data } = await axios.post(`v1/proxy_camera/deleteAllCameraCenterToUser`, params)

      return data
    },
    async setAllCameraAllCenterToUser(_,params) {
      const { data } = await axios.post(`v1/proxy_camera/setAllCameraAllCenterToUser`, params)

      return data
    },
    async deleteAllCameraAllCenterToUser(_,params) {
      const { data } = await axios.post(`v1/proxy_camera/deleteAllCameraAllCenterToUser`, params)

      return data
    },
    clear({ commit }) {
      commit('setData', [])
    }
  },
}
