export default {
  namespaced: true,
  alarm_level: {
    title: "nivel de alarma | niveles de alarmas",
    description: "descripción",
    level: "nivel",
    color: "color",
    media: "sonido",
    allowed: "permite solamente archivos en formato mp3",
  }
}
