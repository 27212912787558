export default {
  namespaced: true,
  plane: {
    title: "Plano",
    add: "Agregar",
    name: "Nombre",
    instructions: "Instrucciones",
    devices: "Dispositivos",
    image: "Arrastra una imagen",
    add_device: "Al texto o dispositivo para agregar al plano",
    move_plane: "Mantenga presionado para mover el plano",
    rotate_device: "Mantenga presionado para rotar el dispositivo"
  },
}
  