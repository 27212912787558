import Vue from "vue";
import Vuex from "vuex";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import psimConfig from "./psim-config";
import center from "./center";
import sector from "./sector";
import camera_type from "./camera_type";
import cm_master_account from "./camera_manager/master_account";
import cm_reseller from "./camera_manager/reseller";
import cm_camera from "./camera_manager/camera";
import master_tenant from "./master-tenant";
import tenant from "./tenant";
import incident from "./tenant/incident";
import log_incident from "./tenant/log-incident";
import type_alarm from "./type_alarm";
import unit from "./unit";
import grid from "./grid";
import subsystem_tree from "./subsystem_tree";
import layouts from "./layouts";
import bd_camera from "./bridge/camera";
import bd_bridge from "./bridge/bridge";
import bd_account from "./bridge/account";
import bd_master_account from "./bridge/master_account";
import bbva_request_tag from "./bbva/tag";
import user from "./user";
import profile from "./profile";
import router from "./router";
import permission from "./permission";
import logs from "./logs";
import country from "./country";
import region from "./region";
import vxg_camera from "./vxg_camera";
import preset from "./preset";
import button from "./buttons";
import type_button from "./button_type";
import camera_status from "./status_cameras";
import seproban from "./seproban";
import token_omnitag from "./tokens_omnitag";
import plate_list from "./plate_list";
import plate from "./plate";
import group from "./group";
import traffic from "./traffic";
import alarm from "./alarm";
import alarm_views from "./alarm_views";
import lpr_coincidences from "./lpr_matches";
import facial_recognition from "./facial_recognition";
import incident_list from "./incident_list";
import user_app from "./user_app";
import swift_account from "./swift_account";
import swift_gateway from "./swift_collector";
import swift_sensor from "./swift_sensor";
import plane from "./plane";
import token from "./token";
import command from "./command";
import recording_request from "./recording_request";
import alarm_comment from "./alarm_comment";
import action_plan from "./tenant/action_plan";
import user_cameras from "./user_cameras";
import timeline from "./timeline";
import person_list from "./person_list";
import workflow from "./workflow";
import schedule_task from "./schedule_task";
import frequency_schedule_task from "./frequency_schedule_task";
import access_devices from "./access_devices";
import shared_tenant from "./shared_tenant";
import video_watermarks from "./video_watermarks";
import group_cameras from "./group_cameras";
import group_shared_tenant from "./group_shared_tenant";
import sip from "./sip";
import iotcomms_master_account from "./iotcomms/master_account";
import type_device_interphone from "./iotcomms/type_device_interphone";
import device_interphone from "./iotcomms/device_interphone";
import alarm_level from "./alarm/alarm_level";
import recording_request_status from "./recording_request_status";
import breadcrumbNav from "./breadcrumb";
import i18n from "./i18n";
import pages from "./pages";
import external_token from "./external_token";
import external_user from "./external_user";
import intelexvision from "./intelexvision";
import video_wall from "./video_wall";
import video_tag from "./video_tag";
import auth_two_factor from "./auth_two_factor";
import intelexvision_alarm_type from "./intelexvision/alarm_type";
import analytic_record from "./intelexvision/analytic_record";
import vxg_master_account from "./vxg_camera/master_account";
import locale_login from "./locale_login";
import luna_master_account from "./luna/master_account";
import luna_list from "./luna/list";
import luna_face from "./luna/face";
import sip_call from "./sip_call";
import alarm_purge from "./alarm/alarm_purge";
import schedule_alarm from "./schedule_alarm";
import dahua_dvr from "./dahua/dvr";
import dahua_camera from "./dahua/camera";
import density from "./density";
import density_devices from "./density_devices";
import detection_event from "./detection_event";
import omniview_reseller from "./omniview_reseller";
import time_zone from "./time_zone";
import camera_proxy from "./camera_proxy";
import cm_proxy from "./camera_proxy";
import image_modal from "./image_modal";
import municipality from "./municipality";
import commands from "./commands";
import luna_facestream from "./luna/facestream";
import generic_devices from "./generic_devices";
import microservice_logs from "./microservice_logs";
import log_messages from "./log_messages"
import vehicle_brand from "./vehicle_brand";
import vehicle_model from "./vehicle_model";
import luna_face_detection from "./luna/face_detection";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    psimConfig,
    center,
    sector,
    camera_type,
    cm_master_account,
    cm_reseller,
    cm_camera,
    master_tenant,
    tenant,
    unit,
    grid,
    subsystem_tree,
    layouts,
    bd_camera,
    bd_bridge,
    bd_account,
    bd_master_account,
    type_alarm,
    incident,
    log_incident,
    bbva_request_tag,
    user,
    country,
    region,
    profile,
    router,
    permission,
    vxg_camera,
    preset,
    button,
    type_button,
    camera_status,
    seproban,
    token_omnitag,
    logs,
    plate_list,
    plate,
    lpr_coincidences,
    traffic,
    alarm,
    alarm_views,
    group,
    facial_recognition,
    incident_list,
    user_app,
    swift_account,
    swift_gateway,
    swift_sensor,
    token,
    plane,
    command,
    recording_request,
    alarm_comment,
    action_plan,
    user_cameras,
    timeline,
    person_list,
    workflow,
    schedule_task,
    frequency_schedule_task,
    shared_tenant,
    video_watermarks,
    group_cameras,
    access_devices,
    iotcomms_master_account,
    type_device_interphone,
    device_interphone,
    alarm_level,
    sip,
    recording_request_status,
    breadcrumbNav,
    i18n,
    pages,
    external_token,
    external_user,
    intelexvision,
    video_tag,
    video_wall,
    auth_two_factor,
    intelexvision_alarm_type,
    analytic_record,
    vxg_master_account,
    locale_login,
    luna_master_account,
    luna_list,
    luna_face,
    sip_call,
    alarm_purge,
    group_shared_tenant,
    schedule_alarm,
    dahua_dvr,
    dahua_camera,
    density_devices,
    density,
    detection_event,
    omniview_reseller,
    time_zone,
    camera_proxy,
    cm_proxy,
    image_modal,
    municipality,
    generic_devices,
    ...commands,
    vehicle_brand,
    luna_facestream,
    microservice_logs,
    log_messages,
    vehicle_model,
    luna_face_detection
  },
  strict: process.env.DEV,
});
