export default {
  namespaced: true,
  alarm: {
    alarm_id: "Alarme ID",
    description: "Descrição",
    title: "Alarme | Alarmes",
    folioCAD: "Folha CAD",
    statusCAD: "Status CAD",
    reaction_patrol: "Patrulha de reação",
    alarm_type: "Tipo de alarme",
    status: "Status",
    attending: "Em atenção",
    attended: "Atendidos",
    waiting: "Em Espera",
    canceled: "Canceladas",
    all: "Todos",
    start_date_filter: "Data de início",
    end_date_filter: "Data final",
    date_filter: "Filtro de Data",
    new_alarms: " novos alarmes",
    alarms: "Alarmes",
    subsystem_action_modal: {
      send_alarm: "Tem certeza de que deseja enviar o alarme?",
      notify_c5_cdmx: "A notificação será enviada para o CAD do C5 da CDMX, tem certeza?",
      notify_c5_jal: "A notificação será enviada para o CAD do C5 de Jalisco, tem certeza?",
      notify_c5_edomexe: "A notificação será enviada para o CAD do C5 do Estado do México, tem certeza?",
      create_vitual_alarm: "Será criado um alarme virtual, tem certeza?",
      comments: "Comentários",
      level_alarm: "Nível de Alarme",
    },
  },
};
