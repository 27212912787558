export default {
  namespaced: true,
  vxg_master_account: {
    title: "master account",
    name: "Name",
    description: "Description",
    api_server: "Api server",
    api_key: "Api key",
    number_cameras: "Number of cameras"
  },
};
