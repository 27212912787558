export default {
  namespaced: true,
  dahua_dvr: {
    title: "dahua DVR | dahua DVR's",
    name: "nome",
    ip: "IP",
    user: "usuário",
    password: "senha",
    http_schema: "esquema http(s)",
    rtsp_schema: "esquema rtsp(s)",
    http_port: "porta http",
    rtsp_port: "porta rtsp",
  },
};
