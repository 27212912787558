export default {
  namespaced: true,
  alarm_views: {
    title: 'visualização de alarme',
    action_add: 'criar visualização',
    action_update: 'atualizar visualização',
    subtitle_1: 'Configuração geral',
    subtitle_2: 'Configuração de layout',
    is_default: 'por padrão',
    fields: {
      name: {
        label: 'Nome da visualização',
        placeholder: 'Nome da visualização',
      },
      tenant: {
        label: 'Tenant',
        placeholder: 'Tenant',
      },
      users: {
        label: 'Usuários',
        placeholder: 'Usuários',
      },
      alarm_level: {
        label: 'Nível de alarme',
        placeholder: 'Nível de alarme',
      },
      alarm_type: {
        label: 'Tipo de alarme',
        placeholder: 'Tipo de alarme',
      },
      alarm_status: {
        label: 'Estado de alarme',
        placeholder: 'Estado de alarme',
      },
      default_view: {
        label: 'Visualização padrão',
      },
      periodicity: {
        label: 'Periodicidade',
        always: 'Sempre',
        days: {
          sun: 'D',
          mon: 'S',
          tue: 'T',
          wed: 'Q',
          thu: 'Q',
          fri: 'S',
          sat: 'S',
        },
        from: 'de',
        to: 'uma',
        messages: {
          invalid_schedule: 'Lista de horários vazia ou horários inválidos existem'
        },
        btns: {
          delete: 'Eliminar',
          add: 'Adicionar horário'
        }
      },
    },
    strings: {
      tags: 'tag'
    },
    messages: {
      layout_cannot_be_empty: 'O layout não pode estar completamente vazio',
      success_created: 'Visualização criada com sucesso',
      success_updated: 'Visualização atualizada com sucesso',
    },
    btns: {
      create: 'Criar',
      update: 'Atualizar'
    }
  }
}
