import axios from "@axios";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import store from "@/store";

export default {
  namespaced: true,
  state: {
    items: [],
    unitsLoadingStatus: false,
  },
  getters: {
    getItems(state) {
      return state.items;
    },
    getSelectedItems(state) {
      return state.items.map((unit) => ({
        value: unit.id,
        label: unit.description,
        id_tenant: unit.router ? unit.router.id_tenant : null,
      }));
    },
    getSelectedItemsCameras(state) {
      return state.items.map((unit) => ({
        value: unit.id,
        label: unit.description,
      }));
    },
    getSelectedItemsWithoutTenant(state) {
      return state.items.map((unit) => ({
        value: unit.id,
        label: unit.description,
      }));
    },
    getUnitsLoadingStatus(state) {
      return state.unitsLoadingStatus;
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value;
    },
    setUnitsLoadingStatus(state, value) {
      state.unitsLoadingStatus = value;
    },
  },
  actions: {
    async export({ commit }, params) {
      const response = await axios.get("v1/unit", {
        params,
        responseType: params?.action == "export" ? "blob" : "json",
      });

      if (!response.data) {
        return [];
      }
      if (params?.action == "export") {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        const { t } = useI18nUtils();

        const notification = {
          id: response.headers["content-disposition"].split("filename=")[1],
          progress: 100,
          completed: true,
          view: "Unit",
          url: url,
          download: response.headers["content-disposition"].split("filename=")[1],
          title: "Reporte de Instalaciones",
          body: "Descarga completa",
          error: false,
          timestamp:
            new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(),
        };
        store.dispatch("grid/updateDownloadNotification", notification);
      } else {
        commit("setData", response.data.data);
        return response.data;
      }
    },
    async getAll({ commit }, params = {}) {
      const { data } = await axios.get("v1/unit", { params });

      let items = data.data;

      if (!data.success) {
        return [];
      }

      if (params.max) {
        commit("setData", items);
        return data;
      }

      let promises = [];

      for (let i = 2; i <= data.meta.last_page; i++) {
        promises.push(axios.get(`v1/unit?page[n]=${i}`));
      }

      Promise.all(promises).then((values) => {
        if (values.length == promises.length) {
          values.forEach((units) => {
            items = items.concat(units.data.data);
          });
        }
        commit("setData", items);
      });

      return data;
    },
    async add(_, params) {
      const { data } = await axios.post("v1/unit", params);

      return data;
    },
    async edit(_, { id, params }) {
      const { data } = await axios.put(`v1/unit/${id}`, params);

      return data;
    },
    async delete(_, id) {
      const { data } = await axios.delete(`v1/unit/${id}`);

      return data;
    },
    async get(_, id) {
      const { data } = await axios.get(`v1/unit/${id}`);

      return data;
    },
    getUnitsByTenant({ commit }, params) {
      commit("setUnitsLoadingStatus", true);
      axios
        .get("/v1/services/tenants/tenants-by-user", { params })
        .then(async ({ data }) => {
          let subPromises = [];

          let promises = data.data.map((tenant) => {
            return new Promise((resolve) => {
              axios.get(`${tenant.units}&max=500`).then(({ data }) => {
                if (data.meta.last_page > 1) {
                  let baseUrl = data.links.first;
                  baseUrl = baseUrl.substr(0, baseUrl.length - 2);

                  for (let page = 2; page <= data.meta.last_page; page++) {
                    subPromises.push(
                      new Promise((resolve) => {
                        axios
                          .get(`${baseUrl}=${page}&max=500`)
                          .then(({ data }) => resolve(data.data));
                      })
                    );
                  }
                }
                resolve(data.data);
              });
            });
          });

          let units = [];

          await Promise.all(promises).then((resp) => (units = units.concat(...resp)));

          await Promise.all(subPromises).then((resp) => (units = units.concat(...resp)));

          commit("setData", Object.freeze(units));
          commit("setUnitsLoadingStatus", false);
        });
    },
    clear({ commit }) {
      commit("setData", []);
    },
  },
};
