import axios from '@axios'
import store from "@/store"

export default {
  namespaced: true,
  state: {
    items: [],
    progress: 0,
    error: '',
    url: '',
    isCreatedFile: false
  },
  getters: {
    getItems(state) {
      return state.items
    },
    getSelectedItems(state) {
      return state.items.map(lpr_coincidences => ({
        value: lpr_coincidences.id,
        label: lpr_coincidences.originalPlateNumber,
      }))
    },
    getProgress(state) {
      return state.progress
    },
    getError(state) {
      return state.error
    },
    getUrl(state) {
      return state.url
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value
    },
  },
  actions: {
    async getAll({ commit }, params) {
      const { data } = await axios.get('v1/lpr_match', { params })

      if (!data) {
        return []
      }

      commit('setData', data.data)
      return data
    },
    async add(_, params) {
      const { data } = await axios.post('v1/lpr_match', params)

      return data
    },
    async edit(_, { id, params }) {
      const { data } = await axios.put(`v1/lpr_match/${id}`, params)

      return data
    },
    async delete(_, id) {
      const { data } = await axios.delete(`v1/lpr_match/${id}`)

      return data
    },
    resetProgress({ state }) {
      state.progress = 0
    },
    setExportLprMatches({ state, commit, dispatch }, jobId) {
      if (!window.Echo.connector.socket.connected) window.Echo.connect();

      const notification = {
        id: `lpr-matches-export-${jobId}`,
        progress: 0,
        completed: false,
        view: 'lpr-matches',
        url: '',
        title: 'Reporte de LPR matches',
        body: 'Descargando...',
        error: false
      }
      store.dispatch('grid/addDownloadNotification', notification)

      window.Echo.private(`lpr-matches-export-${jobId}`)
        .listen(".process", (data) => {
          const notification = {
            id: `lpr-matches-export-${jobId}`,
            progress: data.progress,
            completed: false,
            view: 'lpr-matches',
            url: '',
            title: 'Reporte de LPR matches',
            body: 'Descargando...',
            error: false,
            timestamp: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
          }
          store.dispatch('grid/updateDownloadNotification', notification)
          state.progress = data.progress
        })
        .listen(".end", (data) => {
          const notification = {
            id: `lpr-matches-export-${jobId}`,
            progress: 100,
            completed: true,
            view: 'lpr-matches',
            url: data.url,
            title: 'Reporte de LPR matches',
            body: 'Descarga completa',
            error: false,
            timestamp: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
          }
          store.dispatch('grid/updateDownloadNotification', notification)
          window.Echo.leave(`lpr-matches-export-${jobId}`)

          state.url = data.url
        })
        .listen(".exception", (data) => {
          const notification = {
            id: `lpr-matches-export-${jobId}`,
            progress: 100,
            completed: true,
            view: 'lpr-matches',
            url: data.url,
            title: 'Reporte de LPR matches',
            body: 'Ocurrio un error',
            error: true,
            timestamp: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
          }
          store.dispatch('grid/updateDownloadNotification', notification)
          window.Echo.leave(`lpr-matches-export-${jobId}`)
          state.error = 'Ocurrio un error'
        })
    },
    clear({ commit }) {
      commit('setData', [])
    }
  },
}
