export default {
  namespaced: true,
  video_watermarks: {
    title: 'Video Watermarks',
    title_main: "Video Watermark Settings",
    action_add: 'Create watermark',
    action_update: 'Update Watermark',
    title_location: 'Watermark Location',
    fields: {
      name: {
        label: 'Watermark name',
        placeholder: 'Watermark name'
      },
      tenant: {
        label: 'Tenant',
        placeholder: 'Tenant'
      },
      image: {
        label: 'Select Image',
        hint: 'Drag and drop an image'
      },
      opacity: {
        label: 'Image opacity'
      },
      text_lines: {
        label: 'Line #'
      },
      image_pos: {
        label: 'Image Position'
      },
      text_pos: {
        label: 'Text position'
      }
    },
    subtitle_1: 'Text',
    subtitle_2: 'Positioning',
    placeholders_options: {
      ph1: 'UNIQUE_ID',
      ph2: 'CURRENT_DATE',
      ph3: 'CURRENT_TIME',
      ph4: 'OPERATOR_NAME',
      ph5: 'EMAIL_OPERATOR',
    },
    position_options: {
      pos1: 'Top Left Corner',
      pos2: 'Top Right Corner',
      pos3: 'Lower Left Corner',
      pos4: 'Lower Right Corner',
      pos5: 'Grid',
    },
    messages: {
      success_created: 'Watermark successfully created',
      success_updated: 'Watermark successfully updated',
    },
    btns: {
      delete_image: 'Delete Image',
      create: 'Save',
      update: 'Update',
    }
  }
}
