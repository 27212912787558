import axios from '@axios'
import store from "@/store"
import { useUtils as useI18nUtils } from "@core/libs/i18n"

export default {
  namespaced: true,
  state: {
    items: [],
    itemsAvailable : []
  },
  getters: {
    getItems(state) {
      return state.items
    },
    getSelectedItems(state) {
      return state.items.map(router => ({
        value: router.id,
        label: router.imei,
      }))
    },
    getSelectedItemsAvailable(state) {
      return state.itemsAvailable.map(router => ({
        value: router.id,
        label: router.imei,
      }))
    },
    getSelectedItemsDescription(state) {
      return state.items.map(router => ({
        value: router.id,
        label: router.description,
      }))
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value
    },
    setDataAvailable(state, value){
      state.itemsAvailable = value
    }
  },
  actions: {
    async getAll({ commit }, params) {
      const response = await axios.get("v1/router", {
        params,
        responseType: params?.action == "export" ? "blob" : "json",
      });

      if (!response.data) {
        return [];
      }
      if (params?.action == "export") {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        const { t } = useI18nUtils();
        const notification = {
          id: response.headers["content-disposition"].split("filename=")[1],
          progress: 100,
          completed: true,
          view: "routers",
          url: url,
          download: response.headers["content-disposition"].split("filename=")[1],
          title: "Reporte de Routers",
          body: "Descarga completa",
          error: false,
          timestamp:
            new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(),
        };
        store.dispatch("grid/updateDownloadNotification", notification);
      } else {
        commit("setData", response.data.data);
        return response.data;
      }
    },
    async add(_, params) {
      const { data } = await axios.post('v1/router', params)

      return data
    },
    async edit(_, { id, params }) {
      const { data } = await axios.put(`v1/router/${id}`, params)

      return data
    },
    async delete(_, id) {
      const { data } = await axios.delete(`v1/router/${id}`)

      return data
    },
    async get(_, id) {
      const { data } = await axios.get(`v1/router/${id}`)

      return data
    },
    async getAllAvailable({ commit }, params) {
      const { data } = await axios.get('v1/router?is_available=true', { params })

      commit('setDataAvailable', data.data)
      return data
    },
    async export({ commit }, params) {
      const { data } = await axios.get('v1/routers/status/cameras', { params })

      if (!data.success) {
        return []
      }

      commit('setData', data.data)
      return data
    },
    clear({ commit }) {
      commit('setData', [])
    }
  },
}
