export default {
  namespaced: true,
  person_list: {
    title: "lista de personas",
    list: "lista",
    luna_id: "luna id",
    people: "personas",
    review_list: "revisar lista",
    expected_similarity: "similitud esperada",
    alarm_level: "nivel de alarma",
    add_person_list: "agregar lista de personas",
    edit_person_list: "editar lista de personas",
  },
};



