export default {
  namespaced: true,
  chat: {
    error: "You doesn't have an user for chat",
    new_message: "New message",
    group_name: "Group name",
    users: "Users",
    chats: "Chats",
    contacts: "Contacts",
    chats_group: "Chats Group",
    start_conversation: "Start Conversation",
    send: "Send"
  }
}
