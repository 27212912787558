export default {
  namespaced: true,
  cm_master_account: {
    title: "cuenta maestra | cuentas maestras",
    name: "nombre",
    topic: "tema message broker",
    user: "usuario",
    password: "contraseña",
    client_id: "ID cliente",
    customer_key: "llave cliente",
    reseller_id: "ID reseller",
    reseller_number: "número de resellers",
    api_key: "Llave API",
    account_number: "número de cuenta"
  },
};