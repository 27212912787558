export default {
  namespaced: true,
  density_device: {
    shot_time:"tempo de tiro (em segundos)",
    unit_authorization_key:"Chave de autorização da unidade",
    device_id:"id de dispositivo",
    title: "Contas mestras de dispositivos de densidade",
    actions: "ações",
    name: "nome",
    description: "descrição",
    email: "email",
    password: "senha",
    creation_date: "data de criação",
    update_date: "data de atualização",
    add_master_account: "adicionar conta mestra",
    edit_master_account: "editar conta mestra",
    density_devices: "dispositivos de densidade",
    add_device_density: "adicionar dispositivo de densidade",
    edit_device_density: "editar dispositivo de densidade",
    type: "tipo",
    protocol_version: "versão do protocolo",
    firmware_version: "versão do firmware",
    device_version: "versão do dispositivo",
    usercode: "código do usuário",
    connection: "conexão",
    ready: "pronto",
    armed: "armado",
    tampered: "manipulado",
    low_liquid: "líquido baixo",
    fluid_level: "nível do líquido",
    boil_temp: "temperatura",
    shot_number: "número de disparos",
    ac_mains: "energia AC",
    diagnostic: "diagnóstico",
    failed_shot: "disparos falhados",
    last_shot: "último disparo",
    last_check: "última verificação",
  },
};
