export default {
    namespaced: true,
    luxriot: {
        title: 'Luxriot',
        name: 'Name',
        enabled: 'Enabled',
        options: 'Options',
        source: 'Source',
        from: 'From',
        to: 'To',
        placeholderDate1: 'Select from',
        placeholderDate2: 'Select to',
        resetFilters: 'Reset filters',
        password: 'Password',
        user: 'User',
        tenant: 'Tenants',
        editMasterAccount: 'Edit master account',
        placeholderMasterAccount: 'Select tenant',
        placeholderDescription: 'Enter description',
        placeholderId: 'Enter id',
        placeholderEndpoint: 'Enter enpoint',
        type: 'Type',
        delete: 'Delete',
        master_account: 'Master account',
        editCamera: 'Edit camera',
        sector: 'Sector',
        center: 'Center',
        units: 'Units',
        full_name: 'Full name',
        placeholderFullName: 'Enter full name',
        placeholderEmail: 'Enter email',
        placeholderSector: 'Enter sector',
        placeholderMasterAccount: 'Enter master account',
        placeholderCenter: 'Enter center',
        placeholderDirectory: 'Enter directory',
        placeholderTenants: 'Enter tenants',
        placeholderLink: 'Enter link',
        placeholderUnit: 'Enter unit',
        placeholderTitle: 'Enter user',
        placeholderPassword: 'Enter password',
        placeholderAlias: 'Enter alias',
        update_from_api: "Update cameras from API",
        link: "Link",
        email: "E-mail",
        full_name: "Full name",
        channel: "Channel",
        alias: "Alias",
        delayed_record_minutes: "Late minutes",
        forward_record_minutes: "Forward minutes",
        idCameraProxy: "Camera proxy id",
        luxriot_cameras: "Luxriot cameras",
    }
}
