export default {
  namespaced: true,
  alarm_comment: {
    title: "comentarios de alarmas",
    comment: "comentario",
    alarm_type: "tipo de alarma",
    tenant: "tenant",
    attending: "atendiendo",
    attended: "atendida",
    canceled: "cancelada",
    status: "estatus"
  },
};
