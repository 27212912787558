export default {
  namespaced: true,
  reseller: {
    title: "revendedor | revendores",
    update_password_periodically: "Atualize a senha Periódicamente",
    name: "nome",
    lastname: "Sobrenome",
    address: "Endereço",
    country: "país",
    business: "companhia",
    time_zone: "fuso horário",
    password: "senha",
    email: "Correo",
    number_of_cameras: "número de câmeras",
    last_login: "último login",
    max_amount_cameras: "número máximo de câmeras (licenças)",
    cm_number_of_cameras: "câmeras cadastradas no camera manager",
    invoice_period: "período de pagamento",
    quality: "qualidade de vídeo",
  },
};
