import axios from "@axios";
import store from "@/store";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
  namespaced: true,
  state: {
    url: '',
  },
  getters: {
    getImageUrl(state){
      return state.url;
    }
  },
  mutations: {
    setImageUrl(state, value){
      state.url = value;
    }
  },
  actions: {
    imageUrl({ commit, state }, params) {
      commit("setImageUrl", params);
    },
  },
};
