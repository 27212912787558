export default {
  namespaced: true,
  camera_proxy: {
    title: "camera proxy | cameras proxy",
    id: "camera proxy id",
    rtsp_available: "Enabled RTSP flow",
    last_connection: "last connection",
    assigned: "assigned"
  },
};
