export default {
  namespaced: true,
  vxg_camera: {
    id_vxg: "ID VXG",
    name: "Nome",
    rec_mode: "Modo Gravar",
    live_stream: "Transmissão ao Vivo",
    recording_stream: "Transmissão de Vídeo Gravado",
    access_token_watch: "Token de acesso ao vídeo",
    access_token_all: "Token de acesso total",
    streaming: "Transmissão",
    timezone: "Fuso horário",
    update_from_vxg: "Atualizar câmeras da API",
    select_multiple: "Selecione vários",
    delete_vxg_sbo: "Excluir VaaS e SBO",
    delete_sbo: "Excluir em SBO",
    removed: "Removido",
    removed_cameras: "câmeras removidas",
  },
};
