export default {
  namespaced: true,
  facial_recognition: {
    facial_recognition: "Reconhecimento facial",
    title: "Correspondência de reconhecimento facial",
    people_list: "lista de pessoas",
    facility: "instalação",
    image: "imagem",
    person_id: "id da pessoa",
    name: "nome",
    age: "era",
    gender: "gênero",
    wears_glasses: "usar óculos",
    capture_blur: "borrão imagem",
    capture_darkness: "escuridão de imagem",
    catch_light: "luz de imagem",
    captured_saturation: "saturação de imagem",
    captured_emotion: "emoção de imagem",
    captured_ethnicity: "etnia imagem",
    catch_percentage: "percentagem de imagem",
    match_percentage: "porcentagem de correspondência"
  }
}
