export default {
  namespaced: true,
  omniview_reseller: {
    title: "Omnia reseller",
    device_assignment: "Atribuição de dispositivo",
    actions: "actions",
    name: "name",
    description: "description",
    time_zone: "fuso horário",
    number_of_devices: "número de dispositivos",
    add_omnia_reseller: "agregar omnia reseller",
    assign: "atribuir",
    type: "tipo",
    camera_id: "câmera de identificação",
    center: "centro",
    sector: "setor",
    facilities: "instalações",
    email: "email",
    password: "password",
    assign_cameras: "atribuir câmeras",
    id_camera_proxy: "id câmera proxy",
  },
};
