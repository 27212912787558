export default {
  namespaced: true,
  luna: {
    title: "Luna",
    master_account: {
      title: "Master Account",
      name: "Name",
      description: "Description",
      api_email: "Api Email",
      api_password: "Api Password",
      api_server: "Api Server"
    },
    list:{
      title: "List",
      name_list : "Name List",
      id_luna: "Id Luna",
      people: "People",
      review_list: "Review list",
      expected_similarity: "Expected similarity",
      alarm_level: "Alarm level",
      white_list: "White list"
    },
    face:{
      title: "Face",
      name: "Name Face",
      avatar: "Avatar",
      detection: "Detection",
      face_id: "Face id",
      external_id: "External id",
      event_id: "Event id",
      image: "Image",
      age: "Age",
      gender: "Gender",
      predominant_ethnicity: "Predominant ethnicity",
      similarity_match: "Similarity match"
    },
    facestream:{
      title: "FaceStream",
      name: "Name",
      description: "Description",
    },
    face_detection:{
      title: "Face Detection",
    },
  }
};
