import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'

// Axios Mock Adapter
import '@/@fake-db/db'

// // google maps
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GMAPS_KEY,
    libraries: 'places,visualization',
  },
})

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Custom directive 'properties' -> <input v-properties="dynamicProperties" />
Vue.directive('properties', {
  bind(el, binding) {
    if (Array.isArray(binding.value)) {
      binding.value.forEach(prop => {
        if (prop.name && prop.value !== undefined) {
          el.setAttribute(prop.name, prop.value);
        }
      });
    }
  }
});

import Echo from 'laravel-echo'
window.io = require('socket.io-client').io
window.Echo = new Echo({
  broadcaster: 'socket.io',
  host: `${process.env.VUE_APP_LARAVEL_ECHO_HOST}`,
  transport: ['websocket'],
  auth: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    },
  },
})

import VueKonva from 'vue-konva'
import { localize } from 'vee-validate'
Vue.use(VueKonva)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

window.abortController = new AbortController()

new Vue({
  router,
  store,
  i18n,
  beforeCreate() {
    this.$store.dispatch('user/setUserDataFromLocalStorage')
    this.$store.dispatch('psimConfig/initializeStore')
    i18n.locale = this.$store.getters["user/getPreferences"].lang || 'en';
    this.$store.dispatch('i18n/setMessages', i18n, i18n.locale);
    localize(i18n.locale);
  },
  render: h => h(App),
}).$mount('#app')
