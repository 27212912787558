import axios from '@axios'

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    getItems(state) {
      return state.items
    },
    getSelectedItems(state) {
      return state.items.map(plate_List => ({
        value: plate_List.id,
        label: plate_List.name,
      }))
    },
    getSelectedType(state) {
      let arrayTypes = [];
      state.items.forEach(plateList => {
        let value = {value: plateList.type, label: plateList.type}
        if (arrayTypes.some(elm => elm.value == value.value)) return
        arrayTypes.push(value)
      })
      //return arrayTypes
      return [
        {value: "whitelist", label: "whitelist"},
        {value: "blacklist", label: "blacklist"},
        {value: "orange", label: "orange"}
      ]
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value
    },
  },
  actions: {
    async getAll({commit}, params) {
      const {data} = await axios.get('v1/plate_list', {params})

      if (!data) {
        return []
      }

      commit('setData', data.data)
      return data
    },
    async add(_, params) {
      const {data} = await axios.post('v1/plate_list', params)

      return data
    },
    async edit(_, {id, params}) {
      const {data} = await axios.put(`v1/plate_list/${id}`, params)

      return data
    },
    async delete(_, id) {
      const {data} = await axios.delete(`v1/plate_list/${id}`)

      return data
    },
    clear({commit}) {
      commit('setData', [])
    }
  },
}
