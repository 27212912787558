export default {
  namespaced: true,
  luna: {
    title: "Luna",
    master_account: {
      title: "Cuenta Maestra",
      name: "Nombre",
      description: "Descripción",
      api_email: "Api correo",
      api_password: "Api contraseña",
      api_server: "Api Server"
    },
    list:{
      title: "Lista",
      name_list : "Nombre lista",
      id_luna: "Id Luna",
      people: "Personas",
      review_list: "Revisar Lista",
      expected_similarity: "Similitud Esperada",
      alarm_level: "Nivel De Alarma",
      white_list: "Lista blanca"
    },
    face:{
      title: "Rostro",
      name: "Nombre Rostro",
      avatar: "Avatar",
      detection: "Detección",
      face_id: "Face id",
      external_id: "External id",
      event_id: "Event id",
      image: "Imagen",
      age: "Edad",
      gender: "Genero",
      predominant_ethnicity: "Etnia Capturada",
      similarity_match: "Coincidencia de similitud"
    },
    facestream:{
      title: "Transmisión facial",
      name: "Nombre",
      description: "Descripción",
    },
    face_detection:{
      title: "Detección de rostro",
    },
  }
};
