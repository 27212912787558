export default {
  namespaced: true,
  dahua_camera: {
    title: "Dahua Camera | Dahua Cameras",
    dahua: 'Dahua',
    name: "name",
    channel: "channel",
    type: "type",
    ip: "IP",
    class: "class",
    encryption: "encryption",
    poe: "has POE?",
    poe_port: "POE port",
    http_port: "http port",
    https_port: "https port",
    port: "port",
    port_type: "port type",
    rtsp_port: "RTSP port",
    serial_number: "serial number",
    user: "user",
    dvr_validation: "you need to select an DVR"
  },
};

