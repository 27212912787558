export default {
    namespaced: true,
    plane: {
      title: "Plane",
      add: "Add",
      name: "Name",
      instructions: "Instructions",
      devices: "Devices",
      image: "Drag an image",
      add_device: "To text or device to add to plan",
      move_plane: "Hold to move flat",
      rotate_device: "Hold to rotate device"
    },
}
  