export default {

  sideBarItems: [
    {
      id: 1,
      title: "Alarmas en espera",
      collapsible: true,
      isOpen: true,
      defaultPosition: 'sidebar',
      filters: { filter: 'waiting' },
      componentName: "alarms-widget",
    },
    {
      id: 2,
      title: "Alarmas en atención",
      collapsible: true,
      isOpen: true,
      defaultPosition: 'sidebar',
      filters: { filter: 'attending' },
      componentName: "alarms-widget",
    },
    {
      id: 3,
      collapsible: true,
      isOpen: true,
      defaultPosition: 'sidebar',
      componentName: "layouts-widget"
    },
    {
      id: 4,
      collapsible: true,
      isOpen: false,
      defaultPosition: 'bottombar',
      componentName: "tenants-widget",
    },
    {
      id: 5,
      collapsible: true,
      isOpen: true,
      defaultPosition: 'sidebar',
      componentName: "widgets-widget",
    },
    {
      id: 6,
      collapsible: true,
      isOpen: true,
      defaultPosition: 'sidebar',
      componentName: "views-widget",
    },
    {
      id: 7,
      collapsible: true,
      isOpen: true,
      defaultPosition: 'sidebar',
      componentName: "scripts-widget",
    },
    {
      id: 8,
      collapsible: true,
      isOpen: false,
      defaultPosition: 'sidebar',
      componentName: "pages-widget",
    },
    {
      id: 10,
      collapsible: true,
      isOpen: false,
      defaultPosition: 'sidebar',
      componentName: "video-wall-widget",
    },
    {
      id: 'unit_groups_widget',
      collapsible: true,
      isOpen: false,
      defaultPosition: 'sidebar',
      componentName: "unit-groups-widget",
    },
    {
      id: 'user_charts_widget',
      collapsible: true,
      isOpen: false,
      defaultPosition: 'sidebar',
      componentName: "user-charts-widget",
    },
    {
      id: 'chat_widget',
      collapsible: true,
      isOpen: false,
      defaultPosition: 'sidebar',
      componentName: "chat-widget",
    },
  ],
  bottomBarItems: [
  ]
};
