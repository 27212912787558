import axios from '@axios'

export default {
  namespaced: true,
  state: {
    items: [],
    itemsNotViewed: [],
  },
  getters: {
    getItems(state) {
      return state.items
    },
    getItemsNotViewed(state) {
      return state.itemsNotViewed
    }
  },
  mutations: {
    setData(state, value) {
      state.items = value
    },
    setDataNotViewed(state, value) {
      state.itemsNotViewed = value
    },
  },
  actions: {
    async getAll({ commit }, params) {
      const { data } = await axios.get('v1/recording_request', { params })

      if (!data.success) {
        return []
      }

      commit('setData', data.data)
      return data
    },
    async getAllNotViewed({ commit }) {
      const { data } = await axios.get('v1/recording_request', { params: { viewed: 0 }})

      if (!data.success) {
        return []
      }

      commit('setDataNotViewed', data.data)
      return data
    },
    async add(_, params) {
      const { data } = await axios.post('v1/recording_request', params)

      return data
    },
    async edit(_, { id, params }) {
      const { data } = await axios.put(`v1/recording_request/${id}`, params)

      return data
    },
    async delete(_, id) {
      const { data } = await axios.delete(`v1/recording_request/${id}`)

      return data
    },
    async get({ commit }, id) {
      const { data } = await axios.get(`v1/recording_request/${id}?withRecording=true`)

      if (!data.success) {
          return [];
      }
      commit('setData', data.data)
      return data
    },
    clear({ commit }) {
      commit('setData', [])
    }
  },
}
