export default {
  namespaced: true,
  bridge: {
    bridge_cameras:"Câmeras de Bridge",
    title: "bridge | bridges",
    name: "nome",
    number_of_cameras: "número de câmeras",
    video_status: "status de video",
    ip: "IP",
    accounts: "Contas",
  },
};
