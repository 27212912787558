export default {
  namespaced: true,
  reseller: {
    title: "reseller | resellers",
    update_password_periodically: "Update Password Periodically",
    name: "name",
    lastname: "lastname",
    address: "address",
    country: "country",
    business: "company",
    time_zone: "time zone",
    password: "password",
    email: "email",
    number_of_cameras: "number of cameras",
    last_login: "Last Login",
    max_amount_cameras: "max amount of cameras (licenses)",
    cm_number_of_cameras: "cameras registered in camera manager",
    invoice_period: "invoice period",
    quality: "quality",
  },
};
