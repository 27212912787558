export default {
  namespaced: true,
  state: {
    itemsNav: [],
  },
  getters: {
    getItemsNav(state) {
      return state.itemsNav;
    },
  },
  mutations: {
    setItemsNav(state, value) {
      if (!state.itemsNav.find((e) => e.to === value.to)) {
        state.itemsNav.push(value);
      } else {
        state.itemsNav.forEach((element, index) => {
          if (element.to === value.to) {
            let long = state.itemsNav.length - index + 1;
            state.itemsNav.splice(index + 1, long);
            element.active = true;
          }
        });
      }
    },
    updateItemsNav(state, value) {
      if (value.active == true) {
        state.itemsNav.forEach((element) => {
          if (element.to == value.to) {
            element.active = !value.active;
          }
        });
      }

      if (value.itemFilter != []) {
        state.itemsNav.forEach((element) => {
          if (element.to == value.to) {
            element.itemFilter = value.itemFilter;
          }
        });
      }
      if (value.searchQuery != "") {
        state.itemsNav.forEach((element) => {
          if (element.to == value.to) {
            element.searchQuery = value.searchQuery;
          }
        });
      }
    },
    clearBreadcrumbsPath(state) {
      state.itemsNav = [];
    },
  },
  actions: {
    intoItemsNav({ commit }, value) {
      commit("setItemsNav", value);
    },
    updateItemsNav({ commit }, value) {
      commit("updateItemsNav", value);
    },
    clearBreadcrumbs({ commit }) {
      commit("clearBreadcrumbsPath");
    },
  },
};
