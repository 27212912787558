export default {
  namespaced: true,
  purge_alarm: {
    title: 'limpieza de alarma|limpieza de alarmas',
    name: "nombre",
    alarm_status_in: "estatus de alarma de entrada",
    alarm_status_out: "estatus de alarma de salida",
    comment: "comentario",
    protocol: "protocolo",
    timeout: "tiempo de espera en minutos",
  },
};
