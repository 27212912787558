export default {
  namespaced: true,
  external_user: {
    title: "usuario externo | usuarios externos",
    description: "descrição",
    token: "token",
    host: "host",
    is_validated: "é validado",
  },
};
