export default {
  namespaced: true,
  logs: {
    title: "log | logs",
    user: "user",
    date: "date",
    application: "application",
    action: "action",
    type: "type",
    resourse: "resourse",
    identifier: "indentifier",
    message: "message",
    system_logs: "System logs",
    ip: "IP",
  },
};
