import axios from "@axios";
import store from "@/store";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
  namespaced: true,
  state: {
    items: [],
    device_tags: [],
    idNotification: "",
    url: "",
  },
  getters: {
    getItems(state) {
      return state.items;
    },
    getSelectedItems(state) {
      return state.items.map((tenant) => ({
        value: tenant.id,
        label: tenant.name,
      }));
    },
    getDeviceTags(state) {
      return state.device_tags;
    },
    getIdNotification(state) {
      return state.idNotification;
    },
    getUrl(state) {
      return state.url;
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value;
    },
    setDeviceTags(state, value) {
      state.device_tags = value;
    },
    setIdNotification(state, value) {
      state.idNotification = value;
    },
    setUrl(state, value) {
      state.url = value;
    },
  },
  actions: {
    async export({ commit }, params) {
      const response = await axios.get("v1/tenant", {
        params,
        responseType: params?.action == "export" ? "blob" : "json",
      });

      if (!response.data) {
        return [];
      }
      if (params?.action == "export") {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
        link.remove();

        commit(
          "setIdNotification",
          response.headers["content-disposition"].split("filename=")[1]
        );
        commit("setUrl", url);
      } else {
        commit("setData", response.data.data);
        return response.data;
      }
    },
    async getAll({ commit }, params) {
      const { data } = await axios.get("v1/tenant", { params });

      if (!data.success) {
        return [];
      }

      commit("setData", data.data);
      return data;
    },
    async getDeviceTags({ commit }, params) {
      const { data } = await axios.get(`v1/tenants/device-tags`, { params });
      commit("setDeviceTags", data.data);

      return data;
    },
    async getDeviceTagsByTenantIds({ commit }, params) {
      const { data } = await axios.get(`v1/tenants/device-tags/get-by-tenants-ids`, {
        params,
      });
      commit("setDeviceTags", data.data);

      return data;
    },
    async add(_, params) {
      const { data } = await axios.post("v1/tenant", params, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      return data;
    },
    async edit(_, { id, params }) {
      const { data } = await axios.post(`v1/tenant/${id}?_method=put`, params, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      return data;
    },
    async delete(_, id) {
      const { data } = await axios.delete(`v1/tenant/${id}`);

      return data;
    },
    clear({ commit }) {
      commit("setData", []);
    },
  },
};
