export default {
  namespaced: true,
  video_watermarks: {
    title: "Marca d'água para vídeos",
    title_main: "Configurações de marca d'água para vídeos",
    action_add: "Criar marca d'água",
    action_update: "Atualizar marca d'água",
    title_location: "Localização da marca d'água",
    fields: {
      name: {
        label: "Nome da marca d'água",
        placeholder: "Nome da marca d'água"
      },
      tenant: {
        label: 'Tenant',
        placeholder: 'Tenant'
      },
      image: {
        label: 'Selecione a imagem',
        hint: 'Arraste uma imagem'
      },
      opacity: {
        label: 'Opacidade da imagem'
      },
      text_lines: {
        label: 'Línea #'
      },
      image_pos: {
        label: 'Posição da imagem'
      },
      text_pos: {
        label: 'Posição del texto'
      }
    },
    subtitle_1: 'Texto',
    subtitle_2: 'Posicionamiento',
    placeholders_options: {
      ph1: 'ID_ÚNICO',
      ph2: 'FECHA_ACTUAL',
      ph3: 'HORA_ACTUAL',
      ph4: 'NOMBRE_OPERADOR',
      ph5: 'EMAIL_OPERADOR',
    },
    position_options: {
      pos1: 'Canto superior esquerdo',
      pos2: 'Canto superior direito',
      pos3: 'Canto inferior esquerdo',
      pos4: 'Canto inferior direito',
      pos5: 'Mosaico',
    },
    messages: {
      success_created: "Marca d'água criada com sucesso",
      success_updated: "Marca d'água atualizada com sucesso",
    },
    btns: {
      delete_image: 'Excluir imagem',
      create: 'Guarda',
      update: 'Atualizar',
    }
  }
}
