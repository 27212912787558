export default {
  namespaced: true,
  logs: {
    title: "log | logs",
    user: "usuario",
    date: "fecha",
    application: "aplicativo",
    action: "accion",
    type: "tipo",
    resourse: "recurso",
    identifier: "indentificador",
    message: "mensaje",
    system_logs: "Logs del sistema",
    ip: "IP",
  },
};
