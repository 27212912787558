export default {
  namespaced: true,
  intelexvision_event: {
    title: "Registro analítico",
    camera_proxy: "Câmera proxy",
    camera_name: "Nome da câmera",
    id_alarm: "Id de alarme",
    image: "Imagem",
    url_video: "Url do vídeo",
    alarm_category: "Categoria de alarme",
    type_alarm: "Tipo de alarme",
    alarm_created: "Alarme criado",
    intelexvision_server: "Intelexvision server",
    alarm_intelexvision: "Intelexvision alarme",
    matched_rules: "Regras de correspondência",
    priority: "Prioridade",
  },
};
