export default {
  namespaced: true,
  purge_alarm: {
    title: 'purge alarm|purge alarms',
    name: "name",
    alarm_status_in: "input alarm status",
    alarm_status_out: "output alarm status",
    comment: "comment",
    protocol: "protocol",
    timeout: "timeout in minutes",
  },
};
