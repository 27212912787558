export default {
    namespaced: true,
    luxriot: {
        title: 'Luxriot',
        name: 'Nome',
        enabled: 'Ativo',
        options: 'Opções',
        source: 'Fonte',
        from: 'De',
        to: 'Para',
        placeholderDate1: 'Selecione de',
        placeholderDate2: 'Selecione até',
        resetFilters: 'Redefinir filtros',
        password: 'Password',
        user: 'Do utilizador',
        tenant: 'Tenants',
        editMasterAccount: 'Editar conta principal',
        placeholderMasterAccount: 'Seleccione tenant',
        placeholderDescription: 'Insira a descrição',
        placeholderEndpoint: 'Insira enpoint',
        delete: 'Eliminar',
        master_account: 'Conta principal',
        editCamera: 'Editar câmera',
        sector: 'Setor',
        center: 'Centro',
        units: 'Instalações',
        full_name: 'Nombre completo',
        placeholderFullName: 'Entrar nombre completo',
        placeholderEmail: 'Entrar email',
        placeholderSector: 'Entrar sector',
        placeholderMasterAccount: 'Entrar conta principal',
        placeholderTenants: 'Entrar tenants',
        placeholderCenter: 'Entrar centro',
        placeholderUnit: 'Entrar instalação',
        placeholderLink: 'Entrar link',
        placeholderTitle: 'Entrar o utilizado',
        placeholderPassword: 'Entrar password',
        placeholderAlias: 'Entrar alias',
        update_from_api: "Atualize as câmeras da API",
        addMasterAccount: "Adicionar conta principal",
        link: "EnlaLinkce",
        email: "E-mail",
        full_name: "Nombre completo",
        type: "Cara",
        channel: "Canal",
        alias: "Alias",
        delayed_record_minutes: "Minutos atrasado",
        forward_record_minutes: "Minutos à frente",
        idCameraProxy: "Camera proxy id",
        luxriot_cameras: "Câmeras Luxriot",
    }
}
