export default {
  namespaced: true,
  action_plan: {
    title: 'Plan de acción',
    label: 'Plan de acción',
    fields: {
      name: {
        label: 'Nombre del plan de acción',
        placeholder: 'Nombre del plan de acción'
      },
      default: {
        label: "Plan de acción por default"
      }
    },
    nodes: {
      decision: {
        name: 'Decisión',
        config: {
          question: 'Pregunta',
          yes: 'Sí',
          no: 'No',
          new_output: 'Respuesta',
        }
      },
      terminate: {
        name: 'Finalizar'
      }
    },
    viewer: {
      preview: "vista previa",
      answer: 'Respuesta',
      comments: "Comentarios",
      execute_command: 'Ejecutar comando',
      execute: 'Ejecutar',
      completed: 'Listo',
      error: 'Error',
      continue: 'Continuar',
      finalized: 'Finalizado',
    },
    list: {
      title: 'Log de planes de acción',
      filters: {
        tenants: 'Tenants',
        start_date: "Fecha de inicio",
        end_date: "Fecha de fin",
      },
      cols: {
        col1: 'Nombre',
        col2: 'Usuario',
        col3: 'Tenant',
        col4: 'Alarma',
        col5: 'Fecha',
      }
    }
  },
}
