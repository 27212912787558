export default {
  namespaced: true,
  token: {
    title: "tokens",
    token: "token",
    provider: "fornecedor",
    add_token: "Adicionar token",
    adit_token: "Editar token",
  },
};
