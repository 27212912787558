export default {
  namespaced: true,
  omniview_reseller: {
    title: "Omnia reseller",
    device_assignment: "Device assignment",
    name: "name",
    description: "description",
    time_zone: "time zone",
    number_of_devices: "number of devices",
    add_omnia_reseller: "add omnia reseller",
    assign: "assign",
    type:"type",
    camera_id:"camera id",
    center:"center",
    sector:"sector",
    facilities:"facilities",
    email: "email",
    password: "password",
    assign_cameras:"assign cameras",
    id_camera_proxy:"id camera proxy"
  },
};
