export default {
  namespaced: true,
  intelexvision_event: {
    title: "Registro analítico",
    camera_proxy: "Camara proxy",
    camera_name: "Nombre camara",
    id_alarm: "Id alarma",
    image: "Imagen",
    url_video: "Url video",
    alarm_category: "Categoria alarma",
    type_alarm: "Tipo de alarma",
    alarm_created: "Alarma creada",
    intelexvision_server: "Intelexvision server",
    alarm_intelexvision: "Intelexvision alarma",
    matched_rules: "Reglas coincidentes",
    priority: "Prioridad",
  },
};
