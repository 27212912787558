export default {
  namespaced: true,
  messages: {
    delete: {
      title: "¿Estas seguro de eliminarlo?",
      body: "Una vez eliminado se borraran todos los registros asociados",
      deleted_correctly: "Eliminado correctamente",
      title_n: "¿Estas seguro de eliminar {n} elementos?",
      all: "¿Estás seguro de borrar todo?",
    },
    reset: {
      title: "¿Estas seguro de resetar la contraseña?",
    },
    error: {
      server_error: "Error obteniendo la información",
    },
    validation: {
      email_placeholder: "Ingresa uno o varios correos",
    },
    info: {
      begin_download: "Iniciando proceso para exportar video...",
      in_process_download: "Se ha iniciado el proceso de forma correcta...",
      finish_download: "Se descargo grabacion correctamente",
      sending: "enviando",
      advanced_search: "búsqueda avanzada",
      clear_filters: "limpiar filtros",
    },
    export: {
      title: "Exportar",
      download: "Descarga",
      Generating_file: "Generando archivo...",
      download_succesfully: "Descarga completa...",
      report_of: "Reporte de {model}",
      report: "Reporte de",
      full_download: "Descarga completa",
    },
    edit_save: {
      successfully_created: "Creado correctamente",
      updated_successfully: "Editado correctamente",
    },
    copy: {
      copied_successfully: "Copiado correctamente",
      could_not_copy: "No se pudo copiar",
    },
    import: {
      title: "Importar",
      select_file: "Seleccione o arrastre y suelte el archivo",
      upload: "Subiendo",
      uploading_file: "Cargando archivo...",
      upload_succesfully: "Carga completa...",
      example_file: "Archivo de ejemplo",
    },
    loading: {
      title: "Cargando"
    }
  },
};
