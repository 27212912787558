export default {
  namespaced: true,
  access_devices: {
    title: "Dispositivos de Acceso",
    name: "nombre",
    serial_number: "número de serie",
    mac_address: "direccion mac",
    model: "modelo",
    synchronize:"sincronizar",
    iot_id: "IOT ID"
  },
};
