export default {
  namespaced: true,
  chat: {
    error: "No tienes un usuario para el chat",
    new_message: "Nuevo mensaje de {user}",
    group_name: "Nombre de grupo",
    users: "Usuários",
    chats: "Chats",
    contacts: "Contactos",
    chats_group: "Grupo de chats",
    start_conversation: "Iniciar conversación",
    send: "Enviar"
  }
}
