export default {
  namespaced: true,
  tenants: {
    title: "tenant | tenants",
    number_of_routers: "Número de Routers",
    _name: "Nombre",
    main_image: "Imagen principal",
    logo_image: "imagen Logo",
    image_mobile_app: "Imagen App Movil",
    all: "todos los tenants",
    shared: "tenants compartidos"
  },
};
