const sidebarMinWidth = 380;
const sidebarRightMinWidth = 500;
const state = {
  sideBarItems: [],
  bottomBarItems: [],
  sidebarStartDragging: false,
  sidebarWidth: 580,
  sidebarRightStartDragging: false,
  sidebarRightWidth: 580,
  alarm_id: null
};

const getters = {
  sidebarMinWidthReached(state) {
    return sidebarMinWidth > state.sidebarWidth;
  },
  sidebarRighMinWidthReached(state) {
    return sidebarRightMinWidth > state.sidebarRightWidth;
  },
  alarmId(state) {
    return state.alarm_id;
  }
};

const mutations = {
  setSideBarItems(state, value) {
    state.sideBarItems = value;
  },
  setSidebarStartDragging(state, value) {
    state.sidebarStartDragging = value;
  },
  setSidebarRightStartDragging(state, value) {
    state.sidebarRightStartDragging = value;
  },
  setBottomBarItems(state, value) {
    state.bottomBarItems = value;
  },
  setAlarmId(state, value) {
    state.alarm_id = value;
  },
  updateWidgetStatus(state, value) {
    const location = value.location === 'sideBar' ? 'sideBarItems' : 'bottomBarItems';
    const item = state[location].find(item => item.id === value.id);
    if (item) item[value.property] = value.value;
  },
  updateSidebarWidth(state, value) {
    state.sidebarWidth = value;
  },
  updateSidebarRightWidth(state, value) {
    state.sidebarRightWidth = value;
  }
};

const actions = {
  setSideBarItems({ commit, dispatch }, data) {
    commit('setSideBarItems', data);
    dispatch('updateUserConfig');
  },
  setBottomBarItems({ commit }, data) {
    commit('setBottomBarItems', data);
  },
  setAlarmId({ commit }, data) {
    commit('setAlarmId', data);
  },
  updateWidgetStatus({ commit, dispatch }, data) {
    commit('updateWidgetStatus', data);
    dispatch('updateUserConfig');
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
