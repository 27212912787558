export default {
  namespaced: true,
  vxg_master_account: {
    title: "cuenta maestra",
    name: "Nombre",
    description: "Descripción",
    api_server: "Api server",
    api_key: "Api clave",
    number_cameras: "Número de camaras"
  },
};
