export default {
  namespaced: true,
  unit_groups: {
    title: "Grupos de instalaciones",
    title_main: "Configurar grupo de instalaciones",
    action_add: 'Crear grupo de instalaciones',
    action_update: 'Actualizar grupo de instalaciones',
    parent_name: 'Grupos',
    fields: {
      name: {
        label: 'Nombre para el grupo de instalaciones',
        placeholder: 'Nombre para el grupo de instalaciones'
      },
      group_name: {
        label: 'Nombre del grupo',
        placeholder: 'Nombre del grupo'
      },
    },
    messages: {
      success_created: 'Grupo creado correctamente',
      success_updated: 'Grupo actualizado correctamente',
      success_deleted: 'Grupo eliminado correctamente',
      delete_prompt: '¿Estás seguro de eliminar este grupo?',
    },
    btns: {
      add: 'crear grupo'
    }
  }
}
