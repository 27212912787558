import axios from '@axios'
import store from "@/store"

export default {
  namespaced: true,
  state: {
    items: [],
    logs: [],
    selectedScript: null
  },
  getters: {
    getItems(state) {
      return state.items
    },
    getSelectedItems(state) {
      return state.items.map(cmd => ({
        value: cmd.id,
        label: cmd.name,
        description: cmd.description,
        cmd: cmd.command,
        requirements: cmd.requirements,
        id_user: cmd.id_user,
        alarm_compatible: cmd.alarm_compatible
      }))
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value
    },
    setSelectedScript(state, value) {
      state.selectedScript = value;
    },
    unsetSelectedScript(state) {
      state.selectedScript = null;
    },
    setLogsData(state, value) {
      state.logs = value
    },
  },
  actions: {
    async getAll({ commit }, params) {
      const response = await axios.get('v1/command',
        {
          params,
          responseType: params?.action == 'export' ? 'blob' : 'json'
        })

      if (!response.data) {
        return []
      }

      if (params?.action == 'export') {
        const url = window.URL.createObjectURL(new Blob([response.data],
          { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',
          response.headers["content-disposition"].split("filename=")[1]);
        document.body.appendChild(link);
        link.click();
        link.remove();

        const notification = {
          id: response.headers["content-disposition"].split("filename=")[1],
          progress: 100,
          completed: true,
          view: 'command',
          url: url,
          download: response.headers["content-disposition"].split("filename=")[1],
          title: 'Reporte de comandos',
          body: 'Descarga completa',
          error: false,
          timestamp: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
        }
        store.dispatch('grid/updateDownloadNotification', notification)
      } else {
        commit('setData', null)
        commit('setData', response.data.data)
        return response.data
      }
    },
    async add(_, params) {
      const { data } = await axios.post('v1/command', params)

      return data
    },
    async show(_, id) {
      const { data } = await axios.get(`v1/command/${id}`);

      return data;
    },
    async edit(_, { id, params }) {
      const { data } = await axios.put(`v1/command/${id}`, params)

      return data
    },
    async delete(_, id) {
      const { data } = await axios.delete(`v1/command/${id}`)

      return data
    },
    async getLogs({ commit }, params) {
      const { data } = await axios.get('v1/command/logs', { params })

      if (!data) {
        return []
      }

      commit('setLogsData', data.data)
      return data
    },
    setSelectedScript({ commit }, data) {
      commit('setSelectedScript', data);
    },
    unsetSelectedScript({ commit }) {
      commit('unsetSelectedScript');
    },
    clear({ commit }) {
      commit('setData', [])
    },
  },
}
