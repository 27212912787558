import axios from "@axios";
import store from "@/store";

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    getItems(state) {
      return state.items;
    },
    getSelectedItems(state) {
      return state.items.map((vide_tag) => ({
        value: vide_tag.id,
        label: vide_tag.description,
      }));
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value;
    },
  },
  actions: {
    async getAll({ commit }, params) {
      const { data } = await axios.get('v1/video_tag', { params })

      if (!data) {
        return []
      }

      commit('setData', data.data)
      return data
    },
    async add(_, params) {
      const { data } = await axios.post("v1/video_tag", params);

      return data;
    },
    async edit(_, { id, params }) {
      const { data } = await axios.put(`v1/video_tag/${id}`, params);

      return data;
    },
    async delete(_, id) {
      const { data } = await axios.delete(`v1/video_tag/${id}`);

      return data;
    },
    clear({ commit }) {
      commit("setData", []);
    },
  },
};
