export default {
  namespaced: true,
  dahua_dvr: {
    title: "dahua DVR | dahua DVR's",
    name: "nombre",
    ip: "IP",
    user: "usuario",
    password: "contraseña",
    http_schema: "esquema http(s)",
    rtsp_schema: "esquema rtsp(s)",
    http_port: "puerto http",
    rtsp_port: "puerto rtsp",
  },
};
