export default {
  namespaced: true,
  seproban: {
    title: "seproban",
    id_alarm: "id alarme",
    path: "path",
    order: "ordem de envío",
    is_send: "enviei",
    sent_at: "enviado em"
 }
}
