import axios from "@axios";
import store from "..";
import {debounce} from "lodash";

const defaultPreferences = {lang: "en", show_alarm_notifications: true};
const cleanObject = (source, validObject) => {
  return Object.keys(validObject).reduce((acc, key) => {
    if (key in source) acc[key] = source[key];
    return acc;
  }, {});
};

export default {
  namespaced: true,
  state: {
    items: [],
    user: {},
    changeTraslate: 1
  },
  getters: {
    getChangeTraslate(state) {
      return state.changeTraslate;
    },
    getItems(state) {
      return state.items;
    },
    getSelectedItems(state) {
      return state.items.map((user) => ({
        value: user.id,
        label: user.email,
      }));
    },
    getUser(state) {
      let userLocalStorage = JSON.parse(localStorage.getItem("userData"));
      if (userLocalStorage.video_wall == undefined) {
        userLocalStorage["video_wall"] = 0;
        localStorage.setItem("userData", JSON.stringify(userLocalStorage));
      }
      return state.user;
    },
    getPreferences(state) {
      return state.user.config ? state.user.config.preferences : {};
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value;
    },
    setUserData(state, value) {
      state.user = value;
    },
    updateUserConfig(state, value) {
      state.changeTraslate++
      const userConfig = state.user.config;
      if (userConfig && "preferences" in value) {
        const validPreferences = cleanObject(value.preferences, defaultPreferences);
        const userPreferences = cleanObject(userConfig.preferences, defaultPreferences);
        userConfig.preferences = {};
        const preferences = Object.assign(
          {},
          {...userPreferences, ...validPreferences}
        );
        value.preferences = preferences;
      }
      state.user.config = Object.assign(state.user.config || {}, {...value});
    },
    setUserImage(state, value) {
      state.user.image = value;
    },
  },
  actions: {
    async getAll({commit}, params) {
      const response = await axios.get("v1/users", {
        params,
        responseType: params?.action == "export" ? "blob" : "json",
      });

      if (!response.data) {
        return [];
      }

      if (params?.action == "export") {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        const notification = {
          id: response.headers["content-disposition"].split("filename=")[1],
          progress: 100,
          completed: true,
          view: "users",
          url: url,
          download: response.headers["content-disposition"].split("filename=")[1],
          title: "Reporte de usuarios",
          body: "Descarga completa",
          error: false,
          timestamp:
            new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(),
        };
        store.dispatch("grid/updateDownloadNotification", notification);
      } else {
        commit("setData", response.data.data);
        return response.data;
      }
    },
    async add(_, params) {
      const {data} = await axios.post("v1/users", params);

      return data;
    },
    async edit(_, {id, params}) {
      const {data} = await axios.put(`v1/users/${id}`, params);

      return data;
    },
    async delete(_, id) {
      const {data} = await axios.delete(`v1/users/${id}`);

      return data;
    },
    async assignCameras(_, params) {
      const { data } = await axios.post("v1/user/assign_cameras", params);
      return data;
  },
    setUserDataFromLocalStorage({ dispatch }) {
      const userData = JSON.parse(localStorage.getItem("userData"));
      if (userData) {
        dispatch("setUserData", userData);
      }
    },
    setUserData({commit, dispatch}, data) {
      commit("setUserData", data);
      dispatch("setUserConfig", data);
    },
    setUserConfig({commit, dispatch}, data) {
      if (data.config && data.config.preferences) {
        dispatch("updateUserConfig", data.config);
      } else {
        const defaultData = Object.assign(data.config || {}, {
          preferences: {...defaultPreferences},
        });
        dispatch("updateUserConfig", defaultData);
      }

      if (data.config && data.config.grid) {
        this.dispatch("grid/initializeStore", data.config.grid);
      } else {
        this.dispatch("grid/initializeStore", {
          activeTab: null,
          tabs: {},
          sideBar: null,
          sideBarWidth: 580,
          bottomBar: null,
        });
      }
    },
    updateUserConfig: debounce(function ({commit, state}, data) {
      commit("updateUserConfig", data);
      axios.put("v1/users/update-config", {config: state.user.config});
      const userData = JSON.parse(localStorage.getItem("userData"));
      userData.config = state.user.config;
      localStorage.setItem("userData", JSON.stringify(userData));
    }, 500),
    async updateUserFrameColorAndCheckAlarms(_, {params}) {
      const {data} = await axios.put("v1/users/update-checkalarms-framecolor", params);
      return data;
    },
    async updateUserPassword(_, {params}) {
      const {data} = await axios.put("v1/users/changePassword", params);
      return data;
    },
    async saveUserImage({commit}, {formData}) {
      const {data} = await axios.post("v1/users/image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      commit("setUserImage", data.data);
      return data;
    },
    clear({commit}) {
      commit("setData", []);
    },
  },
};
