export default {
  namespaced: true,
  generic_devices: {
    title: 'Generic devices',
    status: 'Status',
    name: 'Name',
    provider: 'Provider',
    type: 'Type',
    idCameraProxy: 'ID Cameras Proxy',
    data: 'Configuration',
    units: 'Facilities',
    add_device: 'Add new device',
    edit_device: 'Edit device',
    device_config: 'Device Configuration',
    reseller: 'Reseller',
    camera_type: 'Camera Type',
    onvif: 'Onvif',
    form: {
      name: {
        label: 'Name',
        placeholder: 'Name',
      },
      provider: {
        label: 'Provider',
        placeholder: 'Provider'
      },
      type: {
        label: 'Type',
        placeholder: 'Type'
      },
      custom_fields: {
        url: {
          label: 'Url',
          placeholder: 'Url',
        },
        protocol: {
          label: 'Protocol',
          placeholder: 'Protocol',
        },
        port: {
          label: 'Port',
          placeholder: 'Port',
        },
        user: {
          label: 'User',
          placeholder: 'User',
        },
        password: {
          label: 'Password',
          placeholder: 'Password',
        },
        onvif_host: {
          label: 'Onvif Host (IP/Hostname)',
          placeholder: 'Onvif Host',
        },
        onvif_port: {
          label: 'Onvif Port',
          placeholder: 'Onvif Port',
        },
        enabled_onvif_events: {
          label: 'Enabled onvif events',
          placeholder: 'Enabled onvif events',
        },
      }
    },
    types: {
      camera: 'Camera',
      generic_device: 'Generic Device',
    },
  }
}
