export default {
  namespaced: true,
  intelexvision: {
    title: "intelexvision",
    host: "host",
    description: "Descrição",
    tenant: "tenant",
    servers: "servidores",
 }
}
