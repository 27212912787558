export default {
  namespaced: true,
  action_plan: {
    title: 'Action plan',
    label: 'Action plan',
    fields: {
      name: {
        label: 'Action plan name',
        placeholder: 'Action plan name'
      },
      default: {
        label: "Action plan by default"
      }
    },
    nodes: {
      decision: {
        name: 'Decision',
        config: {
          question: 'Question',
          yes: 'Yes',
          no: 'No',
          new_output: 'Answer',
        }
      },
      terminate: {
        name: 'Terminate'
      }
    },
    viewer: {
      preview: "preview",
      answer: 'Answer',
      comments: 'Comments',
      execute_command: 'Execute command',
      execute: 'Execute',
      completed: 'Ready',
      error: 'Error',
      continue: 'Continue',
      finalized: 'Finalized',
    },
    list: {
      title: 'Action plan Logs',
      filters: {
        tenants: 'Tenants',
        start_date: "From",
        end_date: "To",
      },
      cols: {
        col1: 'Name',
        col2: 'User',
        col3: 'Tenant',
        col4: 'Alarm',
        col5: 'Date',
      }
    }
  },
}
