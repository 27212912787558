export default {
  namespaced: true,
  master_tenants: {
    title: "master tenant | master tenants",
    _name: "Name",
    number_of_tenants: "number of tenants",
    active: "active",
    description: "description",
  },
};
