export default {
  namespaced: true,
  unit_groups: {
    title: 'Facilities groups',
    title_main: "Facilities group",
    action_add: 'Create facilities group',
    action_update: 'Update facilities group',
    parent_name: 'Groups',
    fields: {
      name: {
        label: 'Facilities group name',
        placeholder: 'Facilities group name'
      },
      group_name: {
        label: 'Group name',
        placeholder: 'Group name'
      },
    },
    messages: {
      success_created: 'Facilities group successfully created',
      success_updated: 'Facilities group successfully updated',
      success_deleted: 'Facilities group successfully deleted',
      delete_prompt: 'Are you sure to delete this unit group?',
    },
    btns: {
      add: 'add group'
    }
  }
}
