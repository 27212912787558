export default {
  namespaced: true,
  alarm_level: {
    title: "nível de alarme | níveis de alarme",
    description: "descrição",
    level: "nível",
    color: "cor",
    media: "som",
    allowed: "permite apenas arquivos em formato mp3",
  }
}
