export default {
  namespaced: true,
  token: {
    title: "tokens",
    token: "token",
    provider: "proveedor",
    add_token: "Agregar token",
    adit_token: "Editar token",
  },
};
