import axios from "@axios";

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    getItems(state) {
      return state.items;
    },
    getSelectedItems(state) {
      return state.items.map((device) => ({
        value: device.id,
        label: device.name,
      }));
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value;
    },
  },
  actions: {
    async getAll({ commit }, params) {
      const { data } = await axios.get("v1/generic-devices", { params });

      if (!data.success) {
        return [];
      }

      commit("setData", data.data);
      return data;
    },
    async add(_, params) {
      const { data } = await axios.post("v1/generic-devices", params);

      return data;
    },
    async export({ commit }, params) {
      const { data } = await axios.get("v1/generic-devices", {
        params: {
          ...params,
          export: true,
        },
        headers: {
          "Content-Disposition": "attachment; filename=template.xlsx",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer",
      });

      return data;
    },
    async edit(_, { id, params }) {
      const { data } = await axios.put(`v1/generic-devices/${id}`, params);

      return data;
    },
    async delete(_, id) {
      const { data } = await axios.delete(`v1/generic-devices/${id}`);

      return data;
    },

    clear({ commit }) {
      commit("setData", []);
    },
  },
};
