export default {
  namespaced: true,
  auth_two_factor: {
    title: "two step authentication",
    description:
      "To enable two-step authentication select any of the available methods. Later it will be done to you receive a code via the selected method which you must enter in thecorresponding text box. Once this step is finished, click on save and two-factor authentication will be enabled.",
    verify: "verify",
    send: "send",

    saved_successfully: "saved successfully",
    an_error_occurred: "an error occurred",
    sending_mail: "sending mail",
    email_sent: "email sent",
    sending_sms: "sending sms",
    sms_sent: "sms sent",
    checking_email: "checking email",
    email_verification_successful: "email verification successful",
    checking_sms:"checking sms",
    sms_verification_successful:"sms verification successful",
    change_authentication_method:"change authentication method"
  },
};
