export default {
    namespaced: true,
    tags: {
        title: 'Adicionar etiqueta',
        category: 'Marcação',
        name: 'Nome da etiqueta',
        description: 'Descrição',
        date: 'Data e hora',
        image: 'Imagen',
        placeholderCategory: 'Selecione a categoria',
        placeholderDescription: 'insira a descrição',
    }
}
