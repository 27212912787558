export default {
  namespaced: true,
  token: {
    title: "tokens",
    token: "token",
    provider: "provider",
    add_token: "add token",
    edit_token: "edit token",
  },
};
