export default {
  namespaced: true,
  density_device: {
    shot_time:"tiempo de disparo (en segundos)",
    unit_authorization_key:"Clave de autorización de la unidad",
    device_id:"id del dispositivo",
    title: "cuentas maestras de dispositivos density",
    actions: "acciones",
    description: "descripcion",
    name: "nombre",
    email: "correo",
    password: "contraseña",
    creation_date: "fecha de creacion",
    update_date: "fecha de actualizacion",
    add_master_account: "agregar cuenta maestra",
    edit_master_account: "editar cuenta maestra",
    density_devices: "dispositivos density",
    add_device_density: "agregar dispositivo density",
    edit_device_density: "editar dispositivo density",
    type: "tipo",
    protocol_version: "version de protocolo",
    firmware_version: "version de firmware",
    device_version: "version de dispositvo",
    usercode: "codigo de usuario",
    connection:"conexion",
    ready:"listo",
    armed:"armado",
    tampered:"manipulado",
    low_liquid:"liquido bajo",
    fluid_level:"nivel del liquido",
    boil_temp:"temperatura",
    shot_number:"numero de disparos",
    ac_mains:"ac mains",
    diagnostic:"diagnostico",
    failed_shot:"disparos fallados",
    last_shot:"ultimo disparo",
    last_check:"ultima revision",
  },
};
