export default {
    namespaced: true,
    detection_event: {
      title: "eventos|eventos",
      event_type: "tipo de evento",
      event_created_at: "Fecha de creación de evento",
      provider: "proveedor",
      data: "datos",
      metadata:"metadata",
      media: "imagen",
    },
  };
  