import axios from '@axios'

export default {
  namespaced: true,
  state: {
    downloadNotifications: [],
  },
  getters: {
    getDownloadNotifications(state) {
      return state.downloadNotifications
    },
    getDownloadNotification(state, id) {
      return state.downloadNotifications.find(not => not.id == id)
    },
  },
  actions: {
    addDownloadNotification({ commit, state }, notification) {
      state.downloadNotifications.push(notification)
    },
    removeDownloadNotification({ commit, state }, id) {
      state.downloadNotifications = state.downloadNotifications.filter(notification => notification.id != id)
    },
    updateDownloadNotification({ commit, state }, notification) {
      state.downloadNotifications = state.downloadNotifications.filter(not => not.id != notification.id)
      state.downloadNotifications.push(notification)
    },
    findDownloadNotification({ commit, state }, view) {
      return state.downloadNotifications.filter(not => not.view == view && !not.error && !not.completed)
    },
    removeAllDownloadNotification({ commit, state }) {
      state.downloadNotifications = []
    }

  },
}
