export default {
  namespaced: true,
  messages: {
    delete: {
      title: "Are you sure to remove it?",
      body: "If you remove it, you lost all elements related with it",
      deleted_correctly: "Deleted successfully",
      title_n: "Are you sure to remove {n} elements?",
      all: "Are you sure to delete everything?",
    },
    reset: {
      title: "Are you sure that you wanna reset the password?",
    },
    error: {
      server_error: "error when trying to get the information",
    },
    validation: {
      email_placeholder: "type one or more emails",
    },
    info: {
      begin_download: "starting the process to export video...",
      in_process_download: "the process has been started successfully...",
      finish_download: "Recording downloaded successfully",
      sending: "sending",
      advanced_search: "advanced search",
      clear_filters: "clear filters",
    },
    export: {
      title: "Export",
      download: "Download",
      Generating_file: "Generating file...",
      download_succesfully: "Download successfully",
      report_of: "Report of {model}",
      report: "Report of",
      full_download: "Full download",
    },
    edit_save: {
      successfully_created: "Successfully created",
      updated_successfully: "Updated successfully",
    },
    copy: {
      copied_successfully: "Copied successfully",
      could_not_copy: "Could not copy",
    },
    import: {
      title: "Import",
      select_file: "Select or drag and drop the file",
      upload: "Uploading",
      uploading_file: "Uploading file...",
      upload_succesfully: "Upload successfully",
      example_file: "Example File",
    },
    loading: {
      title: "Loading"
    }
  },
};
