export default {
  namespaced: true,
  dahua_dvr: {
    title: "dahua DVR | dahua DVR's",
    name: "name",
    ip: "IP",
    user: "user",
    password: "password",
    http_schema: "schema http(s)",
    rtsp_schema: "schema rtsp(s)",
    http_port: "http port",
    rtsp_port: "rtsp port",
  },
};
