import axios from '@axios'
import store from '@/store'

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    getItems(state) {
      return state.items
    },
    getSelectedItems(state) {
      return state.items.map(center => ({
        value: center.id,
        label: center.name,
      }))
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value
    },
  },
  actions: {
    async getAll({ commit }, params) {
      const response = await axios.get("v1/camera_status", {
        params,
        responseType: params?.action == "export" ? "blob" : "json",
      });

      if (!response.data) {
        return [];
      }

      if (params?.action == "export") {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
        link.remove();

        const notification = {
          id: response.headers["content-disposition"].split("filename=")[1],
          progress: 100,
          completed: true,
          view: "camera-status",
          url: url,
          download: response.headers["content-disposition"].split("filename=")[1],
          title: "Reporte de estatus de cámaras",
          body: "Descarga completa",
          error: false,
          timestamp:
            new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(),
        };
        store.dispatch("grid/updateDownloadNotification", notification);
      } else {
        commit("setData", response.data.data);
        return response.data;
      }
    },
    clear({ commit }) {
      commit('setData', [])
    }
  },
}
