import Vue from 'vue';
import ability from '@/libs/acl/ability'
import axios from '@axios'
import { SLOT_TYPES, SOURCES } from '@/config/layoutConfig';
import moment from "moment";

const state = {
  slotData: null,
  slotSelection: false,
  selectedAlarm: null,
  slotReordering: false,
  slotReorderingPath: {
    from: null,
    to: null
  },
  isConfigLayout: false,
  tmpLayoutItems: [],
  layoutModal: {
    data: {},
    source: ''
  },
};

const getters = {
  slotDataWillOpenATab(state) {
    if (
      state.slotData &&
      state.slotData.source === SOURCES.SUBSYSTEM_TREE_DND
    ) {
      return true;
    }

    return false;
  }
};

const mutations = {
  setSlotData(state, value) {
    state.slotData = value;
  },
  setSlotSelection(state, value) {
    state.slotSelection = value
  },
  setIsConfigLayout(state, value) {
    state.configLayout = value;
  },
  setLayoutSlot(state, value) {
    state.tabs[state.activeTab].slots.push(value);
  },
  setSelectedAlarm(state, value) {
    state.selectedAlarm = value;
  },
  unsetLayoutSlot(state, value) {
    const i = state.tabs[state.activeTab].slots.map(item => item.pos).indexOf(value);
    if (i >= 0) {
      state.tabs[state.activeTab].slots.splice(i, 1);
    }
  },
  setTmpLayoutItem(state, value) {
    state.tmpLayoutItems.push(value);
  },
  removeTmpLayoutItem(state, value) {
    const i = state.tmpLayoutItems.map(item => item.pos).indexOf(value);
    if (i >= 0) {
      state.tmpLayoutItems.splice(i, 1);
    }
  },
  emptyTmpLayoutItems(state) {
    state.tmpLayoutItems = [];
  },
  updateLayoutSlots(state) {
    state.tabs[state.activeTab].slots = [];
    state.tabs[state.activeTab].slots = [...state.tmpLayoutItems];
  },
  forceLayoutSlots(state, value) {
    state.tabs[state.activeTab].slots = [];
    state.tabs[state.activeTab].slots = [...value];
  },
  setCurrentLayoutToTmp(state) {
    state.tmpLayoutItems = [...state.tabs[state.activeTab].slots];
  },
  showLayoutModal(state, value) {
    state.layoutModal = value;
  },
  resetLayoutModal(state) {
    state.layoutModal = {
      data: {},
      source: ''
    };
  },
  updateSlotData(state, value) {
    const slot = state.tabs[state.activeTab].slots.find(slot => slot.pos === value.pos);
    slot.data = Object.assign({}, slot.data, value.data)
  },
  setSlotReordering(state, value) {
    state.slotReordering = value;
  },
  updateSlotsOrder(state) {

    const path = state.slotReorderingPath;
    const slotA = state.tabs[state.activeTab].slots.find(slot => slot.pos === path.from.pos);
    const slotB = state.tabs[state.activeTab].slots.find(slot => slot.pos === path.to.pos);

    if (slotB) {
      slotA.pos = path.to.pos;
      slotB.pos = path.from.pos;
    } else {
      slotA.pos = path.to.pos;
    }

  },
  slotReorderingPath(state, value) {
    state.slotReorderingPath = Object.assign(state.slotReorderingPath, value);
  },
};

const actions = {
  setIsConfigLayout({ commit }, data) {
    commit('setIsConfigLayout', data);
  },
  setSlotData({ commit }, data) {
    commit('setSlotData', data);
    commit('setSlotSelection', true);
  },
  resetSlotSelection({ commit }, data) {
    commit('setSlotData', null);
    commit('setSlotSelection', false);
  },
  addItemToLayout({ commit, dispatch }, data) {
    commit('setLayoutSlot', data);
    dispatch('updateUserConfig');
  },
  removeItemFromLayout({ commit, dispatch }, data) {
    commit('unsetLayoutSlot', data);
    dispatch('updateUserConfig');
  },
  addTmpLayoutItem({ state, commit }, data) {
    const item = state.tmpLayoutItems.find(item => item.pos === data.pos);
    commit('removeTmpLayoutItem', data.pos);
    commit('setTmpLayoutItem', data);
  },
  removeTmpLayoutItem({ commit }, data) {
    commit('removeTmpLayoutItem', data);
  },
  setSelectedAlarm({ commit }, data) {
    commit('setSelectedAlarm', data);
  },
  unsetSelectedAlarm({ commit }, data) {
    commit('setSelectedAlarm', null);
  },
  emptyTmpLayoutItems({ commit }) {
    commit('emptyTmpLayoutItems');
  },
  setCurrentLayoutToTmp({ commit }) {
    commit('setCurrentLayoutToTmp');
  },
  updateLayoutSlots({ commit, dispatch }) {
    commit('updateLayoutSlots');
    dispatch('updateUserConfig');
  },
  showLayoutModal({ commit }, data) {
    commit('showLayoutModal', data);
  },
  resetLayoutModal({ commit }) {
    commit('resetLayoutModal');
  },
  updateSlotData({ commit, dispatch }, data) {
    commit('updateSlotData', data);
    // TODO
    // dispatch('updateUserConfig');
  },
  setReorderingStatus({ commit, dispatch }, value) {
    commit('setSlotReordering', value);
    commit('setSlotSelection', value);
  },
  reorderSlots({ commit, dispatch }) {
    commit('updateSlotsOrder');
    dispatch('updateUserConfig');
  },
  slotReorderingPath({ commit }, value) {
    commit('slotReorderingPath', value);
  },

  async setAlarmView({ commit, dispatch }, payload) {

    try {
      let view = null;
      const alarm = payload.alarm;

      if (payload.view) {
        view = payload.view;
      } else {

        try {
          const { data } = await axios.get(alarm.view_link);
          view = data.data;

          if (!view) {
            dispatch('setDefaultAlarmView', alarm);
            return;
          }

        } catch (error) {

          dispatch('setDefaultAlarmView', alarm);
          return;
        }

      }

      const cameras = alarm.cameras;

      view.layout_config.forEach((slot) => {

        switch (slot.type) {
          case SLOT_TYPES.INFO:
            slot.data = { ...alarm, in_app_source: SOURCES.ALARM_VIEW };
            break;
          case SLOT_TYPES.MAP:
            slot.data = { data: alarm };
            break;
          case SLOT_TYPES.GPS:
            slot.data = { data: alarm, unit_id: alarm.unit.id };
            break;
          case SLOT_TYPES.UNITS_MAP:
            slot.data = { unit_id: alarm.unit.id };
            break;
          case SLOT_TYPES.PLANE:
            slot.data = alarm.unit_planes ? { planes_url: alarm.unit_planes } : null;
            break;
          case SLOT_TYPES.DETECTION_EVENT:
            slot.data = { in_app_source: SOURCES.ALARM_VIEW };
            break;
          case SLOT_TYPES.TIMELINE:
            slot.data = { in_app_source: SOURCES.ALARM_VIEW };
            break;
          case SLOT_TYPES.TAG:
            const chunks = slot.data.tag.split(':');
            const tag = chunks[0];
            const isCAM_LIVE = chunks.length === 1;
            const camera = cameras.find(cam => cam.tag === tag);

            if (camera) {
              if (isCAM_LIVE && ability.can('live_video', 'Video')) {
                slot.data = { camera, ts: null, host: alarm.host, in_app_source: SOURCES.ALARM_VIEW };
                slot.type = SLOT_TYPES.CAM_LIVE;
              } else if (ability.can('record_video', 'Video')) {
                slot.data = { camera, ts: alarm.created, host: alarm.host, in_app_source: SOURCES.ALARM_VIEW };
                slot.type = SLOT_TYPES.CAM_PA;
              } else {
                slot.type = null;
                slot.data = null;
              }
            } else {
              slot.type = null;
              slot.data = null;
            }

            break;
          case SLOT_TYPES.ACTION_PLAN:
            slot.data = alarm;
            break;
        }

      });

      const tabName = `Alarma ${alarm.unit.description} | ${alarm.alarm_type}`;
      const tabColor = alarm.alarm_level.color;
      const tab = await dispatch("addNewTab", { tabName, layout: view.id_layout, tabColor });

      if (tab.status) {
        dispatch("setActiveTab", tab.id);
        commit("forceLayoutSlots", view.layout_config);
      }

    } catch (error) {
      console.log('setAlarmViewError:', error);
    }

  },

  async setAlarmViewByAlarmId({ dispatch }, payload) {
    const { data } = await axios.get(`/v1/services/views/find?id_alarm=${payload.alarmId}`);

    if (data.data.view) {
      dispatch('setAlarmView', data.data);
    } else {
      dispatch('setDefaultAlarmView', data.data.alarm);
    }
  },

  async setIncomingAlarm({ dispatch }, payload) {
    const { data } = await axios.get(`/v1/services/views/find?id_alarm=${payload.alarmId}`);

    if (data.data.view) {
      dispatch('setAlarmView', data.data);
    } else {
      dispatch('setDefaultAlarmView', data.data.alarm);
    }
  },

  async setDefaultAlarmView({ commit, dispatch }, alarm) {

    const slots = (alarm) => {
      const slots = [];

      const camera1 = alarm.cameras[0];
      const camera2 = alarm.cameras.length > 1 ? alarm.cameras[1] : null;
      const spacesQuadrant1 = [1, 2, 4, 5];
      const spacesQuadrant2 = [3];
      const spacesQuadrant3 = [6];
      const spacesQuadrant4 = [7];
      const alarmType = alarm.type;
      const hasTracking = !!alarm.has_locations;

      if (camera1 || camera2) {

        if (camera1) {
          if (ability.can('live_video', 'Video')) {
            slots.push({
              name: camera1.name,
              pos: 1,
              data: {
                camera: camera1,
                ts: alarm.created,
                region: alarm.unit.region,
                host: alarm.host
              },
              type: SLOT_TYPES.CAM_LIVE
            });
          }

          if (camera1.recording_support && ability.can('record_video', 'Video')) {
            slots.push({
              name: camera1.name,
              pos: 2,
              data: {
                camera: camera1,
                ts: alarm.created,
                region: alarm.unit.region,
                host: alarm.host
              },
              type: SLOT_TYPES.CAM_PA
            });
          }
        }

        if (camera2) {
          if (ability.can('live_video', 'Video')) {
            slots.push({
              name: camera2.name,
              pos: 4,
              data: {
                camera: camera2,
                ts: alarm.created,
                region: alarm.unit.region,
                host: alarm.host
              },
              type: SLOT_TYPES.CAM_LIVE
            });
          }

          if (camera1.recording_support && ability.can('record_video', 'Video')) {
            slots.push({
              name: camera2.name,
              pos: 5,
              data: {
                camera: camera2,
                ts: alarm.created,
                region: alarm.unit.region,
                host: alarm.host
              },
              type: SLOT_TYPES.CAM_PA
            });
          }
        }

      }

      slots.push({
        name: 'Alarm info', pos: 3,
        data: {
          ...alarm,
          in_app_source: SOURCES.ALARM_VIEW
        },
        type: SLOT_TYPES.INFO
      });

      if (alarmType === 5) {
        slots.push({
          name: 'Alarm tracking map',
          pos: 6,
          data: {
            alarm,
            unit_id: alarm.unit.id,
          },
          type: SLOT_TYPES.FR_TRACKING_MAP
        });
      } else if (alarmType === 6) {
        slots.push({
          name: 'Alarm tracking map',
          pos: 6,
          data: {
            alarm,
            unit_id: alarm.unit.id,
          },
          type: SLOT_TYPES.LPR_TRACKING_MAP
        });
      } else {
        slots.push({
          name: 'Alarm map',
          pos: 6,
          data: {
            data: alarm,
            unit_id: alarm.unit.id,
            in_app_source: SOURCES.ALARM_VIEW
          },
          type: SLOT_TYPES.MAP
        });
      }

      slots.push({
        name: 'Action plan', pos: 7,
        data: {
          ...alarm,
          in_app_source: SOURCES.ALARM_VIEW
        },
        type: SLOT_TYPES.ACTION_PLAN
      });

      return slots;
    }

    const tabName = `Alarma ${alarm.unit.description} | ${alarm.alarm_type}`;
    const tab = await this.dispatch("grid/addNewTab", { tabName, layout: 'null' });

    if (tab.status) {
      await this.dispatch("grid/setActiveTab", tab.id);
      this.commit("grid/updateLayoutInTab", 'default');
      this.commit("grid/forceLayoutSlots", slots(alarm));
    }

  },

  async setUnitLiveVideo({ commit, dispatch }, data) {
    const slots = (data) => {
      const slots = [];

      if (ability.can('live_video', 'Video')) {
        const cameras = data.data.cameras ? data.data.cameras : data.cameras;
        for (let index = 0; index < cameras.length; index++) {
          if (index < 6) {
            slots.push({
              name: cameras[index].name,
              pos: index + 1,
              data: {
                camera: cameras[index],
                // ts: alarm.created,
                region: '',
                // host: alarm.host
              },
              type: SLOT_TYPES.CAM_LIVE
            });
          }
        }
      }

      return slots;
    }

    const tabName = `Live Video ${data.data.description ? data.data.description : data.data.data.description }`;
    const tab = await this.dispatch("grid/addNewTab", { tabName, layout: 'null' });

    if (tab.status) {
      await this.dispatch("grid/setActiveTab", tab.id);
      this.commit("grid/updateLayoutInTab", 'unit-live');
      this.commit("grid/forceLayoutSlots", slots(data));
    }

  },

  async setUnitRecordingVideo({ commit, dispatch }, data) {
    const slots = (data) => {
      const slots = [];

      if (ability.can('record_video', 'Video')) {
        const cameras = data.data.cameras ? data.data.cameras : data.cameras;
        for (let index = 0; index < cameras.length; index++) {
          if (index < 6) {
            slots.push({
              name: cameras[index].name,
              pos: index + 1,
              data: {
                camera: cameras[index],
                // ts: alarm.created,
                region: '',
                // host: alarm.host
              },
              type: SLOT_TYPES.CAM_RECORDED
            });
          }
        }
      }

      return slots;
    }

    const tabName = `Recording Video ${data.data.description ? data.data.description : data.data.data.description }`;
    const tab = await this.dispatch("grid/addNewTab", { tabName, layout: 'null' });

    if (tab.status) {
      await this.dispatch("grid/setActiveTab", tab.id);
      this.commit("grid/updateLayoutInTab", 'unit-recording');
      this.commit("grid/forceLayoutSlots", slots(data));
    }

  },

  async setStaticsView({ commit, dispatch }, data) {
    const slots = (data) => {
      const slots = [];
      const chartDataId1 = crypto.randomUUID();
      const chartDataId2 = crypto.randomUUID();

      slots.push({
        name: data.data.description,
        pos: 1,
        data: {
          url: data.data.info_url,
          in_app_source: SOURCES.SUBSYSTEM_TREE
        },
        type: SLOT_TYPES.INFO
      });

      slots.push({
        name: 'Unit map',
        pos: 4,
        data: {
          unit_id: data.data.id
        },
        type: SLOT_TYPES.UNITS_MAP
      });

      slots.push({
        name: 'Chart Cameras',
        pos: 2,
        data: {
          chartData: {
            id: chartDataId1,
            chart_id: chartDataId1,
            data: [],
            name: "Status Cameras",
            chart: "pie",
            field: "online",
            model: "CamerasProxy",
            filters: { units: [data.data.id] },
            keep_filters: ['units'],
            date_field: null
          },
        },
        type: SLOT_TYPES.CHARTS_SDK
      });

      slots.push({
        name: 'Chart Cameras Data',
        pos: 3,
        data: {
          chart_id: chartDataId1,
          model: "CamerasProxy",
          filters: { units: [data.data.id] }
        },
        type: SLOT_TYPES.CHARTS_SDK_DATA
      });

      slots.push({
        name: 'Chart Events',
        pos: 5,
        data: {
          chartData: {
            id: chartDataId2,
            chartId: chartDataId2,
            data: [],
            name: "Events",
            chart: "bar",
            field: "created",
            model: "Alarm",
            filters: { units: [data.data.id] },
            keep_filters: ['units'],
            date_field: null
          },
        },
        type: SLOT_TYPES.CHARTS_SDK
      });

      slots.push({
        name: 'Chart Cameras Data',
        pos: 6,
        data: {
          chart_id: chartDataId2,
          model: "Alarm",
          filters: { units: [data.data.id] }
        },
        type: SLOT_TYPES.CHARTS_SDK_DATA
      });

      return slots;
    }

    const tabName = `Statistics ${data.data.description}`;
    const tab = await this.dispatch("grid/addNewTab", { tabName, layout: 'null' });

    if (tab.status) {
      await this.dispatch("grid/setActiveTab", tab.id);
      this.commit("grid/updateLayoutInTab", 'statistics');
      this.commit("grid/forceLayoutSlots", slots(data));
    }

  },
  async setTimelineView({ commit, dispatch }, data) {
    const slots = (data) => {
      const slots = [];

      slots.push({
        name: data.name,
        pos: 1,
        data: {
          camera: data,
          ts: moment().toISOString(),
          region: '',
          // host: alarm.host
        },
        type: SLOT_TYPES.CAM_RECORDED
      });

      slots.push({
        name: 'Timeline',
        pos: 2,
        data: {
          filters: {
            event_filter: {
              event: 'ALL',
              ts: null,
              source: SOURCES.SUBSYSTEM_TREE
            },
          },
          camera: data,
          in_app_source: SOURCES.SUBSYSTEM_TREE_VA,
        },
        type: SLOT_TYPES.TIMELINE
      });

      return slots;
    }

    const tabName = `Timeline ${data.data.name}`;
    const tab = await this.dispatch("grid/addNewTab", { tabName, layout: 'null' });

    if (tab.status) {
      await this.dispatch("grid/setActiveTab", tab.id);
      this.commit("grid/updateLayoutInTab", 'timeline');
      this.commit("grid/forceLayoutSlots", slots(data.data));
    }

  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
