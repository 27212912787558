export default {
  namespaced: true,
  layout_builder: {
    title: 'Layouts',
    action_add: 'Create layout',
    action_update: 'Update layout',
    fields: {
      name: {
        label: 'Layout Name',
        placeholder: 'Layout Name'
      },
      tenant: {
        label: 'Tenant',
        placeholder: 'Tenant'
      },
      users: {
        label: 'Users',
        placeholder: 'Users'
      },
      default: {
        label: 'Default',
      }
    },
    btns: {
      add_slot: 'Add slot',
      save: 'Save',
    },
    strings: {
      optional: 'Optional',
    },
    messages: {
      no_blank_spaces: 'No blank spaces',
      no_enough_space: 'Not enough space for a new item',
      created_success: 'Layout created successfully',
      updated_success: 'Layout updated successfully'
    },
    is_default: 'Default',
    preview: 'Preview',
  }
}
