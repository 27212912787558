export default {
  namespaced: true,
  alarm_comment: {
    title: "comentários de alarme",
    comment: "comentários",
    alarm_type: "tipo de alarme",
    tenant: "tenant",
    attending: "em atenção",
    attended: "atendido",
    canceled: "cancelada",
    status: "status"
  },
};
