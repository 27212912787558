export default {
  namespaced: true,
  person_list: {
    title: "person list",
    list: "list",
    luna_id: "luna id",
    people: "people",
    review_list: "review list",
    expected_similarity: "expected similarity",
    alarm_level: "alarm level",
    add_person_list: "add person list",
    edit_person_list: "edit person list",
  },
};
