export default {
  namespaced: true,
  action_plan: {
    title: 'Plano de ação',
    label: 'Plano de ação',
    fields: {
      name: {
        label: 'Nome do plano de ação',
        placeholder: 'Nome do plano de ação'
      },
      default: {
        label: "Plano de ação padrão"
      }
    },
    nodes: {
      decision: {
        name: 'Decisão',
        config: {
          question: 'Pergunta',
          yes: 'Sim',
          no: 'Não',
          new_output: 'Resposta',
        }
      },
      terminate: {
        name: 'Finalizar'
      }
    },
    viewer: {
      preview: "vista prévia",
      answer: 'Resposta',
      comments: "Comentários",
      execute_command: 'Executar comando',
      execute: 'Executar',
      completed: 'Pronta',
      error: 'Erro',
      continue: 'Prosseguir',
      finalized: 'Finalizado',
    },
    list: {
      title: 'Registro de planos de ação',
      filters: {
        tenants: 'Tenants',
        start_date: "De",
        end_date: "Para",
      },
      cols: {
        col1: 'Nome',
        col2: 'Usuário',
        col3: 'Tenant',
        col4: 'Alarme',
        col5: 'Data',
      }
    }
  },
}
