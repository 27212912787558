import { SLOT_TYPES } from "@/config/layoutConfig";

export default {
  namespaced: false,
  base: {
    id: "ID",
    iot: "IOT",
    downloads: "baixar",
    download: "baixar",
    remove_all: "deletar tudo",
    Tag: "Tag",
    BBVA: "BBVA",
    "Camera Manager": "Camera Manager",
    Menu: "Menu",
    "Master Accounts": "Contas Maestras",
    Accounts: "Contas",
    Bridges: "Bridges",
    Resellers: "Revendedor",
    Tenants: "Tenants",
    "All Tenants": "Todos os Tenants",
    "Master Tenants": "Master Tenants",
    Cameras: "Câmaras",
    "EE VMS": "EE VMS",
    Incidents: "Incidentes",
    "Charts Incidents": "Gráficas Incidentes",
    Users: "usuários",
    "All Users": "Todos os usuários",
    Profiles: "Perfiles",
    Permissions: "Permisos",
    Units: "Units",
    "All Units": "Todos os Instalação",
    Routers: "Routers",
    VaaS: "VaaS",
    VOIP: "VoIP",
    omnicloud: "Omnicloud",
    analytics: "Analiticos",
    Description: "Descrição",
    active: "de ativos",
    no_record_found: "Nenhum registro encontrado",
    filter: "filtro",
    filters: "filtros",
    downloads: "download",
    frame: "quadro",
    name: "nome",
    home: "baixar",
    no: "começo",
    yes: "sim",
    email: "começo",
    video: "video",
    table: {
      show: "mostra",
      of: "de",
      entries: "registros",
      search: "Buscar",
      showing: "mostrando del {from} to {to} de um total de {of} registros",
      total_assigned: "câmeras atribuídas a este setor {total_assigned}",
    },
    date: {
      created_at: "data de criação",
      updated_at: "data de atualização",
      expired_at: "data de expiração",
    },
    master_tenants: {
      title: "master tenant | master tenants",
      _name: "Nome",
      number_of_tenants: "Número de Tenants",
      active: "de ativos",
      description: "Descrição",
    },
    tenants: {
      title: "tenant | tenants",
      number_of_routers: "Número de Routers",
      _name: "Nome",
      main_image: "Imagem do Principal",
      logo_image: "Imagem do Logo",
      image_mobile_app: "Imagem do App Movil",
      all: "todos os tenants",
    },
    shared_tenants: {
      title: "tenant compartilhado | tenants compartilhados",
    },
    types_alarms: {
      title: "tipo de alarme | tipos de alarmes",
      description: "descrDescriçãoipción",
      acronym: "acronimo",
      alarms_count: "número of alarmes",
    },
    permissions: {
      title: "permissão | permissão",
      count: "número de permissões",
      add_permission: "adicionar permissão",
      description: "Descrição",
      shared_tenant_only: "somente tenant compartilhado",
      resourse: "recurso",
      active: "de ativos",
      action: "ação",
      edit_permission: "editar ação",
    },
    centers: {
      title: "centro | centros",
      add_center: "adicionar centro",
      _name: "nome",
      description: "Descrição",
      active: "de ativos",
      edit_center: "editar centro",
      sectors: "setores",
    },
    sectors: {
      title: "setor | setores",
      add_sector: "adicionar setor",
      center: "centro",
      town_hall: "Prefeitura",
      supplier: "fornecedor",
      edit_sector: "editar setor",
    },
    account: {
      title: "conta | contas",
      _name: "nome",
      bridge_number: "Número de Bridges",
      online_cameras: "Câmeras Online",
      number_of_cameras: "Número de Câmeras",
      number_of_users: "Número de Câmeras",
      active: "de ativos",
    },
    master_account: {
      title: "conta maestra | contas maestras",
      user: "usuário",
      password: "Senha",
      client_id: "Id cliente",
      customer_key: "llave cliente",
      reseller_id: "id Senha",
      reseller_number: "Numero do revendedor",
      api_key: "Chave API",
      account_number: "Número de Conta",
    },
    incident: {
      title: "incidente | incidentes",
      add_incident: "Agregar Incidente",
      name: "nome",
      alarm_type: "tipo do alarme",
      colour: "color",
      alarm: "alarme",
      priority: "prioridade",
      resolution: "resolução",
      frames_for_second: "frames por segundo",
      live_video_time: "tempo de vídeo ao vivo",
      edit_incident: "Editar Incidente",
    },
    status_cameras: {
      name: "status de câmera",
      _name: "nome",
      camera_id: "id de câmera",
      online: "online",
      recording_on_cloud: "gravação na nuvem",
      recording_on_sd: "gravando em SD",
      audio_enabled: "Audio ativado",
      password_known: "senha conhecida",
      password_status: "estado da senha",
      firmware_status: "estado do firmware",
      connection_type: "tipo de conexão",
      last_connection_result: "resultado de la última conexão",
      update_the_status_of_the_cameras_with_the_highest_request_for_live_video:
        "atualizar status das câmeras com maior solicitação de vídeo ao vivo",
      update_the_status_of_the_cameras_with_the_highest_recorded_video_request:
        "atualizar o status das câmeras com maior solicitação de vídeo gravado",
      update_status_of_cameras_that_are_currently_offline:
        "atualizar o status das câmeras que estão off-line no momento",
      update_status_of_the_cameras_that_are_currently_online:
        "atualizar o status das câmeras que estão online no momento",
      update_all_cameras_from_camera_manage:
        "atualizar todas as câmeras do camara manager",
    },

    token_omnitag: {
      title: "token omnitag | tokens omnitag",
      token_number: "Número de tokens",
      qr: "QR",
      token: "token",
    },
    lpr: {
      title: "LPR",
    },
    swift: {
      title: "SWIFT",
    },
    plate_list: {
      _name: "lista de placas",
      list: "lista",
      name: "nome",
      list_type: "tipo de lista",
      colour: "color",
      description: "Descrição",
      renew_items_expiration: "renew items expiration",
    },
    group: {
      title: "conjunto | grupos",
      name: "nome",
      user: "usuário",
      alarm: "alarme",
      tenants: "tenants",
      shared_tenants: "tenants compartidos",
      permissions: "permissões",
      type_alarms: "tipos de alarmes",
      add_group: "adicionar grupo",
      edit_group: "editar grupo",
      check_alarms: "moldura alarmas vídeo"
    },
    user_profile: {
      general: "geral",
      change_password: "alterar a senha",
      username: "usuário",
      email: "correo",
      name: "nome",
      first_name: "apelido paterno",
      second_name: "apelido materno",
      plate_number: "numero de placa",
      password: "senha",
      new_password: "nova senha",
      retype_password: "Confirme nova senha",
      retype_password_holder: "digite a nova senha novamente",
      save_changes: "guardar mudanças",
      reset: "limpiar",
      upload: "subir",
      choose_holder: "Escolha um arquivo ou arraste-o",
      allowed: "JPG, GIF ou PNG permitido. Tamanho máximo 800kB",
      selected: "Arquivo seleccionado:",
      _name: "lista de placas",
      notifications: {
        title: "Notificações",
        alarm_notifications: "Mostrar notificações de alarmes",
        video_alarm_notify: "Moldura de notificação de alarmes em vídeo",
        show_frame_color: "Mostrar moldura de cor",
      },
    },
    plate: {
      _name: "placas",
      list: "lista",
      active_since: "Ativo desde",
      activated_until: "Ativo até",
      comments: "comentarios",
      description: "Descrição",
      number_of_detections: "número de detecções",
      last_detection: "última detecções",
    },
    lpr_matches: {
      _name: "Partidas LPR",
      date_of_capture: "Data de captura",
      plate: "placa",
      plate_detected: "placa detectada",
      original_plate: "placa original",
      picture_plate: "imagem de la placa",
      status: "estatus",
      cameras: "câmeras",
      country: "pais",
      direction: "direção",
      speed: "velocidad",
      zone_id: "zona id",
      original_city: "ciudad original",
      confidential: "confidencial",
      ocr_image_id: "id de imagem ocr",
      signposted: "señalizado",
      digit_height: "altura do dígito",
      multiplate_rate: "multiplate rate",
      processing_time_ocr: "tiempo de procesamiento ocr",
      kamikaze_alarm: "alarme kamikaze",
      time_in_zone_alarm: "tempo no alarme de zona",
      alarm_blacklist: "lista negra de alarmes",
      alarm_speed: "taxa de alarmes",
      prowling_alarm: "prowling alarm",
      vehicle_maker: "marca del vehículo",
      vehicle_model: "modelo del vehículo",
      vehicle_color: "cor del vehículo",
      cross_time: "cross time",
      has_exit: "has exit",
      signal_id: "id do sinal",
      category: "categoria",
      vehicle_class: "classe del vehiculo",
      region: "região",
      alarm: "alarme",
      edit: "editar correspondências LPR",
      reliability: "fiabilidad",
      list: "lista",
      isAlarm: "é um alarme",
    },
    facial_recognition: {
      facial_recognition: "Reconhecimento Facial",
      title: "Correspondências de Reconhecimento Facial",
      people_list: "lista de pessoas",
      facility: "instalação",
      image: "imagem",
      person_id: "id da pessoa",
      name: "nome",
      age: "idade",
      gender: "gênero",
      wears_glasses: "usa óculos",
      capture_blur: "borrão de captura",
      capture_darkness: "escuridão da captura",
      catch_light: "captura de luz",
      captured_saturation: "saturação de captura",
      captured_emotion: "emoção capturada",
      captured_ethnicity: "etnia capturada",
      catch_percentage: "porcentagem de captura",
      match_percentage: "porcentagem de correspondência",
    },
    incident_list: {
      title: "Lista de Incidentes",
      name: "nome",
      users: "usuários",
      incident_count: "incidentes associados",
      tenant: "tenant",
      edit_incidents: "Editar Incidentes",
    },
    import_excel: {
      _name: "exportar",
    },
    user_cameras: {
      camera_proxy_user_is_assigned: "atribuído",
      unit: "instalação",
      camera_type: "tipo de dispositivo",
      cameraID: "cameraID",
      name: "nome",
      id_user: "ID user",
      id_account: "ID cuenta",
      assign_all: "atribuir todas as câmeras",
      remove_all: "remover todas as câmeras",
      assign_all_center: "atribuir câmeras centrais",
      remove_all_center: "remover câmeras do centro",
    },
    group_cameras: {
      camera_proxy_group_is_assigned: "atribuído",
      unit: "instalação",
      camera_type: "tipo de dispositivo",
      cameraID: "cameraID",
      name: "nome",
      id_user: "ID user",
      id_account: "ID cuenta",
      assign_all: "atribuir todas as câmeras",
      remove_all: "remover todas as câmeras",
      assign_all_center: "atribuir câmeras centrais",
      remove_all_center: "remover câmeras do centro",
    },
    users_app: {
      title: "usuários app",
      name: "nome",
      first_name: "apelido paterno",
      second_name: "apelido materno",
      email: "correo",
      phone_number: "número de telefone",
      photo: "Foto",
      height: "estatura",
      complexion: "complexion",
      lips: "labios",
      nose: "nariz",
      eye_size: "tamanho dos olhos",
      skin: "tez",
      face_type: "tipo de cara",
      hair_type: "tipo de cabello",
      age: "edad",
      particular_signs: "peculiaridades especiais",
      edit: "editar users app",
      add: "adicionar users app",
      reset_password: "redefinir senha",
      cancel: "cancelar",
      next: "próximo",
      new_password: "novo senha",
      write_the_password_again: "Escreva a senha novamente",
    },
    layout: {
      title: "layout",
    },
    device_tag: {
      title: "etiqueta do dispositivo",
    },
    chart_incident: {
      title: "gráfico de incidentes",
    },
    automation: {
      title: "automação",
    },
    alarm_workflow: {
      title: "fluxo de trabalho de alarmes",
    },
    workflow: {
      add_workflow: "adicionar fluxo de trabalho",
      edit_workflow: "editar fluxo de trabalho",
      workflow: "fluxo de trabalho",
      alarm_status: "status de alarme",
      frequency: "habilitar frequência de la alarme",
      params: "parâmetros",
      data: "data",
      view: "visão",
      days: "dias",
      start_time: "hora inicio",
      end_time: "hora final",
    },
    schedule_task: {
      title: "tarefa programada",
      frequency: "frecuencia",
      add_user_views: "Visualizações/Abas",
    },
    plane: {
      title: "planos",
    },
    shared_tenant: {
      name: "nome",
      permissions: "permissões",
      type_alarms: "tipos de alarme permitidos",
    },
    iotcomms_master_account: {
      title: "conta mestra iotcomms | conta mestra iotcomms",
      email: "correo",
      password: "senha",
      token: "token",
      domain: "dominio",
      number_devices: "número de dispositivos",
    },
    cronEditor: {
      title: "Cronograma personalizado",
      options: {
        op1: "Cada hora(s)",
        op2: "Diariamente",
        op3: "Semanalmente",
        op4: "Por mês",
      },
      every: "Cada",
      hours: "Hora(s)",
      minutes: "Minuto(s)",
      days: "Día(s)",
      day: "Día",
      months: "Mes(es)",
      on_minute: "No minuto",
      at: "A las",
      on_the: "En el día",
      day_of_every_month: "de cada mês",
      weekdays: {
        sun: "Domingo",
        mon: "Segunda-feira",
        tue: "Terça-feira",
        wed: "Quarta-feira",
        thu: "Quinta-feira",
        fri: "Sexta-feir",
        sat: "Sábado",
      },
      table: {
        desc: "Descrição",
      },
      btn_add: "Adicionar",
      btn_update: "Atualizar",
      errors: {
        err1: "Já existe uma expressão semelhante",
        err2: "Verifique os campos",
      },
    },
    scheduleOptions: {
      every_minute: {
        name: "Cada minuto",
        desc: "",
      },
      every_two_minutes: {
        name: "A cada dois minutos",
        desc: "",
      },
      every_three_minutes: {
        name: "Cada Três Minutos",
        desc: "",
      },
      every_four_minutes: {
        name: "Cada Quatro minutos",
        desc: "",
      },
      every_five_minutes: {
        name: "Cada cinco minutos",
        desc: "",
      },
      every_ten_minutes: {
        name: "Cada Dez minutos",
        desc: "",
      },
      every_fifteen_minutes: {
        name: "Cada Quinze minutos",
        desc: "",
      },
      every_thirty_minutes: {
        name: "Cada Trinta minutos",
        desc: "",
      },
      hourly: {
        name: "Cada hora",
        desc: "",
      },
      hourly_at: {
        name: "Cada hora desde",
        desc: "",
      },
      every_two_hours: {
        name: "Cada duas horas",
        desc: "",
      },
      every_three_hours: {
        name: "Cada três horas",
        desc: "",
      },
      every_four_hours: {
        name: "Cada quatro horas",
        desc: "",
      },
      every_six_hours: {
        name: "Cada seis horas",
        desc: "",
      },
      daily_at: {
        name: "Diário Para",
        desc: "",
      },
      weekly_on: {
        name: "toda semana para",
        desc: "",
      },
      monthly_on: {
        name: "todo mês para",
        desc: "",
      },
      last_day_of_month: {
        name: "O último dia de cada mês para",
        desc: "",
      },
      custom: {
        name: "Personalizado",
        desc: "",
      },
    },
    type_device_interphone: {
      title:
        "tipo de dispositivo intercomunicação| tipos de dispositivos intercomunicação",
      type: "tipo",
    },
    device_interphone: {
      title: "dispositivo intercomunicação | dispositivos intercomunicação",
      ha1: "ha1",
      alarm: "alarme",
      device_id: "número",
      password: "senha",
      ivr: "IVR",
    },
    country: {
      title: "pais | paises",
    },
    slot_types: {
      [SLOT_TYPES.CAM_LIVE]: "Câmera Ao Vivo",
      [SLOT_TYPES.CAM_PA]: "Pré-Alarme",
      [SLOT_TYPES.CAM_RECORDED]: "Gravação",
      [SLOT_TYPES.ACTION_PLAN]: "Plano de ação",
      [SLOT_TYPES.INFO]: "Informação de alarme",
      [SLOT_TYPES.MAP]: "Mapa de alarme",
      [SLOT_TYPES.PLANE]: "Plantas da instalação",
      [SLOT_TYPES.TAG]: "Tag",
      [SLOT_TYPES.UNITS_MAP]: "Mapa de instalações",
      [SLOT_TYPES.TIMELINE]: "Timeline",
      [SLOT_TYPES.GPS]: "Geolocalização",
      [SLOT_TYPES.DETECTION_EVENT]: "Eventos de detecção",
      [SLOT_TYPES.INTERPHONE]: "Interfone",
      [SLOT_TYPES.FRAME]: "Widget de site",
      [SLOT_TYPES.CHARTS_SDK]: "Widget de gráfico",
      [SLOT_TYPES.CHARTS_SDK_DATA]: "Dados de gráfico",
      [SLOT_TYPES.FR_TRACKING_MAP]: "Widget de mapa de reconhecimento facial",
      [SLOT_TYPES.LPR_TRACKING_MAP]: "Widget de mapa de reconhecimento de placas",
      [SLOT_TYPES.AR_TABLE_DATA]: "Dados de tabela AR",
      [SLOT_TYPES.WEATHER]: "Widget meteorológico",
      [SLOT_TYPES.FACE_DETECTION]: "Widget de detecção de rostos",
      [SLOT_TYPES.WAZE]: "Widget de Waze",
      [SLOT_TYPES.DETECTION_EVENT]: "Widget de detecção de eventos",
    },
    live: "Ao vivo",
    pre_alarm: "Pré-alarme",
    boolean: {
      y: "Sim",
      n: "Não",
    },
    profile: 'Perfil',
    logout: 'Sair',
    search: 'Procurar',
    close: 'Fechar',
    no_results_found: 'Nenhum resultado encontrado',
    light_mode: "Modo claro",
    dark_mode: "Modo escuro",
  },
};
