export default {
  namespaced: true,
  grid: {
    widgets: {
      alarms: {
        incoming_alarms: {
          title: "Incoming Alarms"
        },
        alarms_being_attended: {
          title: "Attending Alarms"
        },
        pagination: {
          showing: "Showing",
          records: "Records",
          page: "Page",
          of: "of"
        },
        search: {
          placeholder: "Search by Alarm Id, Address, Tenant, Unit, Address..."
        },
        menu: {
          confirm: "Confirm",
          attended: "Attend",
          cancel: "Cancel",
          display: "Display"
        },
        table: {
          no_data: "No data available",
          rows: {
            1: "Alarm",
            2: "Facility",
            3: "Camera",
            4: "Region",
            5: "Date"
          }
        }
      },
      units: {
        title: "Facilities",
        messages: {
          no_data: "No data available"
        },
        pagination: {
          showing: "Showing",
          records: "Records",
          page: "Page",
          of: "of"
        },
        search: {
          placeholder: "Search by Unit, Address...",
          results: "Results"
        },
        table: {
          rows: {
            1: "Name"
          }
        },
        subsystems_tree: {
          info: "Information",
          video: {
            title: "Video",
            live_video: "Live Video",
            recorded_video: "Recorded video",
            timeline: "Timeline",
          },
          voip: {
            make_call: 'Make a call',
          },
          activate_digital_output: "Activate digital output",
          disable_digital_output: "Disable digital output",
        }
      },
      layouts: {
        title: "Layouts"
      },
      views: {
        title: "Views"
      },
      widgets: {
        title: "Widgets"
      },
      charts: {
        title: "Charts",
        no_data: "No data available",
      },
      videowall: {
        title: "Videowall"
      },
      scripts: {
        title: "Scripts"
      },
    },
    modals: {
      alarms_status: {
        title_confirm: "Are You Sure To Send The Alarm?",
        title_attended: "Are you sure to end the alarm?",
        title_cancel: "Are You Sure To Cancel The Alarm?",
        protocol: 'Protocol',
        comments: "Comments",
        alarm_level: "Alarm level",
        btns: {
          send: "Send",
          cancel: "Cancel"
        }
      },
      update_layout: {
        cameras: "Cameras",
        pre_alarm: "Pre-Alarm",
        info: "Information",
        alarm_info: "Alarm info",
        unit_info: "Unit Information",
        plane: "Plano",
        unit_plane: "Show unit plan",
        map: "Map",
        alarm_location: "Alarm location",
        unit_location: "Unit location",
        gps: "gps",
        locations: "Locations",
        select_slot: "Select a slot",
        btns: {
          ok: "Ok"
        }
      }
    },
    tabs: {
      new_tab: "Add new tab",
      context_menu: {
        save_as_view: "Save as view",
        update_view: "Update View",
        update_name: "Update tab name"
      }
    },
    timeline: {
      waiting_for_main_camera: 'Waiting for main camera',
      no_footage_available: 'No footage available for the selected time',
      toolbar: {
        alarms: 'Alarms',
        objects: 'Objects',
        filter_type: 'Click to search for objects',
        zoom_in: 'Zoom In',
        zoom_out: 'Zoom out',
        x_seconds_fw: 'Forward 5 seconds',
        x_seconds_bw: 'Backward 5 seconds',
        play: 'Play',
        pause: 'Pause',
        synchronize: 'Synchronize',
        desynchronize: 'Desynchronize',
        download: 'Download',
        mostRecentEvent: 'Most recent event',
        speed: 'Speed',
        facialRecognitionEnrollment: 'Facial Recognition Enrollment',
      },
      objectsModal: {
        title: 'Search for objects',
        field1: {
          label: 'Provider'
        },
        field2: {
          label: 'Type'
        },
        field3: {
          label: 'From'
        },
        field4: {
          label: 'To'
        },
        field5: {
          label: 'Object'
        },
        operators: {
          equal_to: 'equal to',
          not_equal_to: 'not equal to',
          contains: 'contains',
          not_contains: 'does not contain',
          gt: 'greater than',
          lt: 'less than',
          gte: 'greater than or equal to',
          lte: 'less than or equal to',
          is_in: 'is in',
          is_not_in: 'is not in',
        }
      },
      downloadModal: {
        title: 'Download videos',
      },
    },
    frame: {
      url: 'url'
    },
    slots: {
      main: {
        messages: {
          widget_not_be_added: 'Widget could not be added',
          timeline_widget_exists: 'A widget of type Timeline already exists',
          chat_widget_exists: 'This chat has been already loaded',
          detection_event_widget_exists: 'A widget of type Detection event already exists',
        }
      },
      info: {
        unit: 'Facility',
        address: 'Address',
        actions: 'Actions',
        btns: {
          confirmed: 'Confirmed',
          attended: 'Attend',
          cancel: 'Cancel',
        },
        description: 'Description',
        company: 'Company',
        latitude: 'Latitude',
        longitude: 'Longitude',
        exterior_number: 'Exterior number',
        suburb: 'Suburb',
        municipality: 'Municipality',
        postal_code: 'Postal code',
        country: 'Country',
        region: 'Region',
        tracing: 'Logs',
        protocol: 'Protocol',
        attending_comments: 'Reason for attention',
        solved_comments: 'Reason for solution',
        cancel_comments: 'Reason for cancellation',
        status: 'Status',
        folio_cad: 'Folio CAD',
        data: 'Additional Info',
        camera: {
          review_start: 'Review start',
          review_end: 'Review end',
          events_review: 'Events review',
          center: 'Center',
          sector: 'Sector',
          previous_time: 'Previous time',
          later_time: 'Later time',
          reseller: {
            name: 'Name',
            company: 'Company'
          },
          bridge: {
            account_id: 'Account Id',
            bridge_id: 'Bridge Id',
            device_name: 'Device name'
          },
          bridge_account: {
            account_id: 'Account Id',
            name: 'Name'
          },
        },
        sip_call: {
          state: 'State',
          from: 'From',
          to: 'To',
          make_call: 'Call',
          origin_date: 'Origin Date',
          audio_record: 'Audio Record'
        },
        fr: {
          title: 'Face Recognition',
          enrolled_person: 'Enrolled person',
          face_detected: 'Face detected',
          detection: 'Detection',
          camera_name: 'Camera name',
          person_name: 'Person name',
          list_name: 'List name',
          age: 'Age',
          gender: 'Gender',
          emotion: 'Emotion',
          glasses: 'Use of glasses',
          picture_quality: 'Picture quality',
          blurriness: 'Blurriness',
          darkness: 'Darkness',
          brightness: 'Brightness',
          saturation: 'Saturation',
          score: 'Score',
          similarity: 'Similarity score',
          match_history: 'Match History',
          waiting_detection: "Waiting for detection",
          welcome: "Welcome",
          genderFemale: 'Female',
          genderMale: 'Male',
        },
        people_analytics: {
          top_color: "Top Color",
          bottom_color: "Bottom Color",
          wearing_glasses: "Wearing Glasses",
          hair_length: "Hair Length",
          carrying_objects: "Carrying Objects (backpacks, suitcases)",
          vehicle: "Vehicle",
          gender: "Gender",
          dominant_race: "Ethnicity",
          dominant_emotion: "Dominant Emotion",
          age: "Age"
        },
        detection_events: {
          default: {
            title: 'Detection event'
          },
          lpr: {
            title: 'License plate recognition',
          },
          loitering: {
            title: 'Loitering',
          },
          crowded: {
            title: 'Crowded'
          },
          fr: {
            title: 'Face recognition',
          },
          people_counting: {
            title: 'People counting',
          },
          people_entering: {
            title: 'People entering',
          },
          people_exiting: {
            title: 'People exiting',
          },
          prohibited_parking: {
            title: 'Prohibited parking',
          },
          vehicle_count: {
            title: 'Vehicle count',
          },
          wrong_way: {
            title: 'Wrong way',
          },
          delimited_zone: {
            title: 'Delimited Zone',
          },
          person_fallen: {
            title: 'Person lying down',
          },
          smart_motion_detection: {
            title: 'Smart Motion Detection',
          },
          object_count: {
            title: 'Object Count',
          },
          person_in_area: {
            title: 'Person in area'
          },
          labels: {
            overall_picture: 'Overall picture',
            plate: 'Plate',
            camera_name: 'Camera name',
            city: 'City',
            date: 'Date',
            speed: 'Speed',
            brand: 'Brand',
            model: 'Modelo',
            color: 'Color',
            class: 'Class',
            list: 'List',
            confidence: 'Confidence',
          }
        },
        sip: {
          title: 'SIP Call',
          session_id: 'SessionId',
          state: 'State',
          from: 'From',
          to: 'To',
        },
        workflow_logs: {
          title: 'Workflows log',
        },
        analytic_record: {
          title: 'Analytic Record',
          alarm_category: 'Alarm category',
          camera_name: 'Camera name',
          matched_rules: 'Matched rules',
          priority: 'Priority',
        },
        sensors: {
          title: 'Sensors',
          name: 'Name',
          type: 'Type',
          last_measurement: 'Last Measurement',
          measurement: 'Alarm Measurement',
          high_critical: 'Measurement High Critical',
          high_warning: 'Measurement High Warning',
          low_critical: 'Measurement Low Critical',
          low_warning: 'Measurement Low Warning',
        }
      },
      interphone: {
        type: 'Type',
        alarm: 'Alarm',
        created: 'Created',
        updated: 'Updated',
        user: 'User',
      }
    },
    widgets_section: {
      weather: {
        title: 'Weather',
        errorGeloocation: 'Could not get location',
        permmisionDenied: 'Access to location was not allowed, please enable permissions on your device'
      },
      face_detection: {
        title: 'Face detection',
      },
      map: {
        title: 'Map',
      },
      chart: {
        title: 'Chart',
      },
      detection_event: {
        title: 'Detection Event',
      },
    }
  }
}
