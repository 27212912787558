import Vue from 'vue'
import VueI18n from 'vue-i18n'
import sections from './sections'

Vue.use(VueI18n)

export default new VueI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: sections,
});
