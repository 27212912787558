export default {
    namespaced: true,
    tags: {
        title: 'Agregar Tag',
        category: 'Tag',
        name: 'Nombre del Tag',
        description: 'Descripción',
        date: 'Fecha y hora',
        image: 'Imagen',
        placeholderCategory: 'Selecionar categoría',
        placeholderDescription: 'Ingresar descripción',
    }
}
