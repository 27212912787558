export default {
  namespaced: true,
  log_microservice: {
    title: "registrar microsserviço",
    client_host: "anfitrião do cliente",
    provider: "o provedor",
    level: "nível",
    message: "id da mensagem",
    log_message: "mensagem de registro",
    description: "descrição",
    path: "path",
    resource: "recurso",
    difference: "diferença",
    tenant_id: "tenant",
    user_id: "usuário",
    action: "ação",
    start_date: "data de início",
    end_date: "data final",
    The_start_date_value_is_required_for_filter_by_date: "O valor da data de início é necessário para filtrar por data.",
    The_end_date_value_is_required_to_filter_by_date: "O valor da data de término é obrigatório para filtrar por data",
  },
};
