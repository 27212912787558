import axios from "@axios";
import store from "@/store";

export default {
  namespaced: true,
  state: {
    items: [],
    progress: 0,
    error: '',
    url: '',
    isCreatedFile: false
  },
  getters: {
    getSelectedItems(state) {
      return state.items.map((face) => ({
        value: face.id,
        label: face.user_data,
      }));
    },
    getProgress(state) {
      return state.progress
    },
    getError(state) {
      return state.error
    },
    getUrl(state) {
      return state.url
    },
    getItems(state) {
      return state.items;
    },
  },
  mutations: {
    setData(state, value) {
      state.items = value;
    },
  },
  actions: {
    resetProgress({ state }) {
      state.progress = 0
    },
    setExportLunaFace({ state, commit, dispatch }, jobId) {
      if (!window.Echo.connector.socket.connected) window.Echo.connect();

      const notification = {
        id: `luna-face-export-${jobId}`,
        progress: 0,
        completed: false,
        view: 'luna-face',
        url: '',
        title: 'Reporte de luna face',
        body: 'Descargando...',
        error: false
      }
      store.dispatch('grid/addDownloadNotification', notification)

      window.Echo.private(`luna-face-export-${jobId}`)
        .listen(".process", (data) => {
          const notification = {
            id: `luna-face-export-${jobId}`,
            progress: data.progress,
            completed: false,
            view: 'luna-face',
            url: '',
            title: 'Reporte de luna face',
            body: 'Descargando...',
            error: false,
            timestamp: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
          }
          store.dispatch('grid/updateDownloadNotification', notification)
          state.progress = data.progress
        })
        .listen(".end", (data) => {
          const notification = {
            id: `luna-face-export-${jobId}`,
            progress: 100,
            completed: true,
            view: 'luna-face',
            url: data.url,
            title: 'Reporte de luna face',
            body: 'Descarga completa',
            error: false,
            timestamp: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
          }
          store.dispatch('grid/updateDownloadNotification', notification)
          window.Echo.leave(`luna-face-export-${jobId}`)

          state.url = data.url
        })
        .listen(".exception", (data) => {
          const notification = {
            id: `luna-face-export-${jobId}`,
            progress: 100,
            completed: true,
            view: 'luna-face',
            url: data.url,
            title: 'Reporte de luna face',
            body: 'Ocurrio un error',
            error: true,
            timestamp: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
          }
          store.dispatch('grid/updateDownloadNotification', notification)
          window.Echo.leave(`luna-face-export-${jobId}`)
          state.error = 'Ocurrio un error'
        })
    },
    async getAll({ commit }, params) {
      const { data } = await axios.get("v1/services/facial-recognition/luna/luna-face", { params });

      if (!data) {
        return [];
      }

      commit("setData", data.data);
      return data;
    },
    async add(_, params) {
      const { data } = await axios.post("v1/services/facial-recognition/luna/luna-face", params);

      return data;
    },
    async edit(_, { id, params }) {
      const { data } = await axios.put(`v1/services/facial-recognition/luna/luna-face/${id}`, params);

      return data;
    },
    async delete(_, id) {
      const { data } = await axios.delete(`v1/services/facial-recognition/luna/luna-face/${id}`);

      return data;
    },
    async extract(_, params) {
      const { data } = await axios.post("v1/services/facial-recognition/luna/extract/luna-face", params);

      return data;
    },
    async syncFace(_, params) {
      const { data } = await axios.get(`v1/services/facial-recognition/luna/luna-face/sync`)

      return data
    },
    async addFace(_, params) {
      const { data } = await axios.post("v1/services/facial-recognition/luna/luna-face/add-face", params);

      return data;
    },
    async deleteFace(_, params) {
      const { data } = await axios.post(`v1/services/facial-recognition/luna/luna-face/delete-face`, params);

      return data;
    },
    clear({ commit }) {
      commit("setData", []);
    },
  },
};
