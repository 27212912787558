export default {
  namespaced: true,

  state: {
    messages: {},
  },

  getters: {
    messages(state) {
      return (key) => {
        if (key in state.messages) {
          return state.messages[key];
        }
        console.error(`[VUEX] i18n: '${key}' not found in i18n messages`);
      };
    }
  },

  mutations: {
    setMessages(state, value) {
      Object.keys(value).forEach(str => state.messages[str] = value[str]);
    }
  },

  actions: {
    setMessages({ commit }, i18n) {

      const messages = {
        notifications: i18n.t('notifications'),
        // ...
      };

      commit('setMessages', messages);
    }
  },
}
