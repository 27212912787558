export default {
  namespaced: true,
  alarm_level: {
    title: "alarm level | alarms levels",
    description: "description",
    level: "level",
    color: "color",
    media: "sound",
    allowed: "only allows files in mp3 format",
  }
}
