export default {
  namespaced: true,
  buttons: {
    title: "botones",
    button_type:"tipo de boton",
    description:" descripcion",
    divice_id:"id del dispositivo",
    device_type_id:"id del tipo dispositivo",
    button_report:"Informe de Botones",
    all: "todos los botones",
    preview: "Vista previa",
  }
}
