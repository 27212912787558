
export default {
  namespaced: true,
  state: {
    subsystemTreeModal: {
      data: {},
      source: ''
    },
  },
  mutations: {
    showSubsystemTreeModal(state, value) {
      state.subsystemTreeModal = value;
    },
    resetSubsystemTreeModal(state, value) {
      state.subsystemTreeModal = {
        data: {},
        source: ''
      };
    }
  },
  actions: {
    showSubsystemTreeModal({ commit }, data) {
      return new Promise(resolve => {
        commit('showSubsystemTreeModal', data);
        resolve();
      })
    },
    resetSubsystemTreeModal({ commit }) {
      commit('resetSubsystemTreeModal');
    },
  }
};
