import { SLOT_TYPES } from "@/config/layoutConfig";

export default {
  namespaced: false,
  base: {
    id: "ID",
    iot: "IOT",
    downloads: "downloads",
    download: "download",
    remove_all: "remove all",
    message: {
      title: "Card Title",
      text: "Cake sesame snaps cupcake gingerbread danish I love gingerbread. Apple pie pie jujubes chupa chups muffin halvah lollipop. Chocolate cake oat cake tiramisu marzipan sugar plum. Donut sweet pie oat cake dragée fruitcake cotton candy lemon drops.",
      pagelength: "Showing 1 to",
      of: "of",
      pageText2: "entries",
      seachLabel: "Search",
      SearchPlaceholder: "Search",
      tableHeader: {
        name: "Name",
        email: "Email",
        date: "Date",
        salary: "Salary",
        status: "Status",
        action: "Action",
      },
    },
    name: "name",
    home: "Home",
    no: "no",
    yes: "yes",
    email: "email",
    video: "video",
    "UI Elements": "UI Elements",
    "Forms & Tables": "Forms & Tables",
    Pages: "Pages",
    "Charts & Maps": "Charts & Maps",
    Others: "Others",
    Typography: "Typography",
    Colors: "Colors",
    Feather: "Feather",
    Cards: "Cards",
    Basic: "Basic",
    Advance: "Advance",
    Statistic: "Statistic",
    Analytic: "Analytic",
    "Card Action": "Card Action",
    Components: "Components",
    Alert: "Alert",
    Aspect: "Aspect",
    Avatar: "Avatar",
    Badge: "Badge",
    Breadcrumb: "Breadcrumb",
    Button: "Button",
    "Button Group": "Button Group",
    "Button Toolbar": "Button Toolbar",
    Calendar: "Calendar",
    Carousel: "Carousel",
    Collapse: "Collapse",
    Dropdown: "Dropdown",
    Embed: "Embed",
    Image: "Image",
    "List Group": "List Group",
    Media: "Media",
    Modal: "Modal",
    Nav: "Nav",
    Overlay: "Overlay",
    Pagination: "Pagination",
    "Pagination Nav": "Pagination Nav",
    Pill: "Pill",
    "Pill Badge": "Pill Badge",
    Popover: "Popover",
    Progress: "Progress",
    Sidebar: "Sidebar",
    spinner: "spinner",
    Tab: "Tab",
    Time: "Time",
    Toasts: "Toasts",
    Tooltip: "Tooltip",
    Extensions: "Extensions",
    "Sweet Alert": "Sweet Alert",
    "Quill Editor": "Quill Editor",
    "Drag & Drop": "Drag & Drop",
    Swiper: "Swiper",
    Clipboard: "Clipboard",
    "Video Player": "Video Player",
    "Context Menu": "Context Menu",
    Toastification: "Toastification",
    I18n: "I18n",
    Slider: "Slider",
    Tour: "Tour",
    "Auto Suggest": "Auto Suggest",
    Tree: "Tree",
    "Date Time Picker": "Date Time Picker",
    "Vue Select": "Vue Select",
    "Forms Elements": "Forms elements",
    Select: "Select",
    Switch: "Switch",
    Checkbox: "Checkbox",
    Radio: "Radio",
    Input: "Input",
    Textarea: "Textarea",
    Spinbutton: "Spinbutton",
    "Input Group": "Input Group",
    "Form Rating": "Form Rating",
    "Form Tag": "Form Tag",
    "Form Datepicker": "Form Datepicker",
    "Form Timepicker": "Form Timepicker",
    "File Input": "File Input",
    "Input Mask": "Input Mask",
    "Form Layout": "Form Layout",
    "Form Wizard": "Form Wizard",
    "Form Validation": "Form Validation",
    "Form Repeater": "Form Repeater",
    "BS Table": "BS Table",
    "Good Table": "Good Table",
    Charts: "Charts",
    "Apex Chart": "Apex Chart",
    Chartjs: "Chartjs",
    Echart: "Echart",
    Leaflet: "Leaflet",
    Profile: "Profile",
    "Account Settings": "Account Settings",
    Faq: "Faq",
    "Knowledge Base": "Knowledge Base",
    Pricing: "Pricing",
    Blog: "Blog",
    List: "List",
    Detail: "Detail",
    Edit: "Edit",
    Search: "Search",
    "Menu Levels": "Menu Levels",
    "Menu Level 2.1": "Menu Level 2.1",
    "Menu Level 2.2": "Menu Level 2.2",
    "Menu Level 3.1": "Menu Level 3.1",
    "Menu Level 3.2": "Menu Level 3.2",
    "Disabled Menu": "Disabled Menu",
    Support: "Support",
    "Raise Support": "Raise Support",
    Documentation: "Documentation",
    Dashboards: "Dashboards",
    eCommerce: "eCommerce",
    Analytics: "Analytics",
    "Apps & Pages": "Apps & Pages",
    Email: "Email",
    Chat: "Chat",
    Todo: "Todo",
    Invoice: "Invoice",
    Preview: "Preview",
    Add: "Add",
    Shop: "Shop",
    Details: "Details",
    Wishlist: "Wishlist",
    Checkout: "Checkout",
    User: "User",
    View: "View",
    Authentication: "Authentication",
    "Login v1": "Login v1",
    "Login v2": "Login v2",
    "Register v1": "Register v1",
    "Register v2": "Register v2",
    "Forget Password v1": "Forget Password v1",
    "Forget Password v2": "Forget Password v2",
    "Forgot Password v1": "Forgot Password v1",
    "Forgot Password v2": "Forgot Password v2",
    "Reset Password v1": "Reset Password v1",
    "Reset Password v2": "Reset Password v2",
    Miscellaneous: "Miscellaneous",
    "Coming Soon": "Coming Soon",
    "Not Authorized": "Not Authorized",
    "Under Maintenance": "Under Maintenance",
    Error: "Error",
    Statistics: "Statistics",
    "Card Actions": "Card Actions",
    "Media Objects": "Media Objects",
    Timeline: "Timeline",
    "Access Control": "Access Control",
    Apps: "Apps",
    "User Interface": "User Interface",
    "Mail Templates": "Mail Templates",
    Welcome: "Welcome",
    "Reset Password": "Reset Password",
    "Verify Email": "Verify Email",
    "Deactivate Account": "Deactivate Account",
    Promotional: "Promotional",
    "Page Layouts": "Page Layouts",
    "Collapsed Menu": "Collapsed Menu",
    "Layout Boxed": "Layout Boxed",
    "Without Menu": "Without Menu",
    "Layout Empty": "Layout Empty",
    "Layout Blank": "Layout Blank",
    Tag: "Tag",
    BBVA: "BBVA",
    "Camera Manager": "Camera Manager",
    Menu: "Menu",
    "Master Accounts": "Master Accounts",
    Accounts: "Accounts",
    Bridges: "Bridges",
    Resellers: "Resellers",
    Cameras: "Cameras",
    "All Tenants": "All Tenants",
    "EE VMS": "EE VMS",
    Incidents: "Incidents",
    "Charts Incidents": "Charts Incidents",
    Users: "Users",
    "All Users": "All Users",
    Profiles: "Profiles",
    Permissions: "Permissions",
    Units: "Facilities",
    "All Units": "All Facilities",
    Routers: "Routers",
    VaaS: "VaaS",
    VOIP: "VoIP",
    omnicloud: "Omnicloud",
    analytics: "Analytics",
    Description: "description",
    active: "active",
    no_record_found: "No records found",
    filter: "filter",
    filters: "filters",
    frame: "frame",
    table: {
      show: "show",
      of: "of",
      entries: "entries",
      search: "Search",
      showing: "showing from {from} to {to} of {of} entries",
      total_assigned: "Cameras assigned from this sector {total_assigned}",
    },
    date: {
      created_at: "created at",
      updated_at: "updated at",
      expired_at: "expired at",
    },
    shared_tenants: {
      title: "shared tenant | shared tenants",
    },
    types_alarms: {
      title: "type alarm | type alarms",
      description: "description",
      acronym: "acronym",
      alarms_count: "number of alarms",
    },
    permissions: {
      title: "permission | permissions",
      count: "number of permissions",
      add_permission: "add permission",
      description: "description",
      shared_tenant_only: "shared tenant only",
      resourse: "resource",
      active: "active",
      action: "action",
      edit_permission: "edit permission",
    },
    centers: {
      title: "center | centers",
      add_center: "add center",
      _name: "name",
      description: "description",
      active: "active",
      edit_center: "edit center",
      sectors: "sectors",
    },
    sectors: {
      title: "sector | sectors",
      add_sector: "add sector",
      center: "center",
      town_hall: "town hall",
      supplier: "supplier",
      edit_sector: "edit sector",
    },
    account: {
      title: "account | accounts",
      _name: "name",
      bridge_number: "Bridge number",
      online_cameras: "online cameras",
      number_of_cameras: "number of cameras",
      number_of_users: "number of users",
      active: "active",
    },
    master_account: {
      title: "master account | masters accounts",
      user: "user",
      password: "password",
      client_id: "client id",
      customer_key: "Client Key",
      reseller_id: "reseller id",
      reseller_number: "reseller number",
      api_key: "API key",
      account_number: "account number",
    },
    bridge: {
      title: "bridge | bridges",
      name: "name",
      number_of_cameras: "number of cameras",
      video_status: "video status",
      ip: "IP",
    },
    incident: {
      title: "incident | incidents",
      add_incident: "add incident",
      name: "name",
      alarm_type: "alarme type",
      colour: "colour",
      alarm: "alarm",
      priority: "priority",
      resolution: "resolution",
      frames_for_second: "frames for second",
      live_video_time: "live video time",
      edit_incident: "Edit Incident",
    },

    buttons: {
      title: "button | buttons",
      all: "all buttons",
      button_type: "button type | buttons type",
      description: "description",
      divice_id: "divice id",
      divice_type_id: "divice type id",
    },
    status_cameras: {
      name: "status cameras",
      _name: "name",
      camera_id: "camera id",
      online: "online",
      recording_on_cloud: "recording on cloud",
      recording_on_sd: "recording on sd",
      audio_enabled: "audio enabled",
      password_known: "password known",
      password_status: "password status",
      firmware_status: "firmware status",
      connection_type: "connection type",
      last_connection_result: "last connection result",
      update_the_status_of_the_cameras_with_the_highest_request_for_live_video:
        "update the status of the cameras with the highest request for live video",
      update_the_status_of_the_cameras_with_the_highest_recorded_video_request:
        "update the status of the cameras with the highest recorded video request",
      update_status_of_cameras_that_are_currently_offline:
        "update status of cameras that are currently offline",
      update_status_of_the_cameras_that_are_currently_online:
        "update status of the cameras that are currently online",
      update_all_cameras_from_camera_manage: "update all cameras from camera manage",
    },
    seproban: {
      title: "SEPROBAN",
      id_alarm: "id alarm",
      path: "path",
      order: "send order",
      is_send: "is sent",
      sent_at: "sent at",
    },
    cameras: {
      label: "camera | cameras",
    },

    token_omnitag: {
      title: "token omnitag | tokens omnitag",
      token_number: "token number",
      qr: "QR",
      token: "token",
    },
    lpr: {
      title: "LPR",
    },
    swift: {
      title: "SWIFT",
    },
    plate_list: {
      _name: "plate list",
      list: "list",
      name: "name",
      list_type: "list type",
      colour: "colour",
      description: "Description",
      renew_items_expiration: "renew items expiration",
    },
    group: {
      title: "group | groups",
      name: "name",
      add_group: "add group",
      edit_group: "edit group",
      check_alarms: "alarm frame video"
    },
    user_profile: {
      general: "general",
      change_password: "change password",
      username: "username",
      email: "email",
      name: "name",
      first_name: "first name",
      second_name: "second name",
      plate_number: "plate_number",
      password: "password",
      new_password: "new password",
      retype_password: "retype new password",
      retype_password_holder: "retype new password",
      save_changes: "save changes",
      reset: "reset",
      upload: "upload",
      choose_holder: "Choose a file or drop it here...",
      allowed: "Allowed JPG, GIF or PNG. Max size of 800kB",
      selected: "Selected file:",
      _name: "plate list",
      notifications: {
        title: "Notifications",
        alarm_notifications: "Show alarm notifications",
        video_alarm_notify: "Video alarm notify",
        show_frame_color: "Show frame color",
      },
    },
    plate: {
      _name: "plate",
      list: "list",
      active_since: "active since",
      activated_until: "activated until",
      comments: "comments",
      description: "description",
      number_of_detections: "number of detections",
      last_detection: "last detection",
    },
    lpr_matches: {
      _name: "LPR matches",
      date_of_capture: "date of capture",
      plate: "plate",
      plate_detected: "plate detected",
      original_plate: "original plates",
      picture_plate: "picture plate",
      status: "status",
      cameras: "cameras",
      country: "country",
      direction: "direction",
      speed: "speed",
      zone_id: "zone id",
      original_city: "original city",
      confidential: "confidential",
      ocr_image_id: "ocr image id",
      signposted: "signposted",
      digit_height: "digit height",
      multiplate_rate: "multiplate rate",
      processing_time_ocr: "processing time ocr",
      kamikaze_alarm: "kamikaze alarm",
      time_in_zone_alarm: "time in zone alarm",
      alarm_blacklist: "alarm blacklist",
      alarm_speed: "alarm speed",
      prowling_alarm: "prowling alarm",
      vehicle_maker: "vehicle maker",
      vehicle_model: "vehicle model",
      vehicle_color: "vehicle color",
      cross_time: "cross time",
      has_exit: "has exit",
      signal_id: "signal id",
      category: "category",
      vehicle_class: "vehicle class",
      region: "region",
      alarm: "alarm",
      edit: "edit LPR matches",
      reliability: "reliability",
      list: "list",
      isAlarm: "is an alarm",
    },
    facial_recognition: {
      facial_recognition: "Facial Recognition",
      title: "Facial Recognition Matches",
      people_list: "people list",
      facility: "facility",
      image: "image",
      person_id: "person id",
      name: "name",
      age: "age",
      gender: "gender",
      wears_glasses: "wears glasses",
      capture_blur: "capture blur",
      capture_darkness: "capture darkness",
      catch_light: "catch light",
      captured_saturation: "capture saturacion",
      captured_emotion: "capture emotion",
      captured_ethnicity: "captured ethnicity",
      catch_percentage: "catch percentage",
      match_percentage: "match percentage",
    },
    incident_list: {
      title: "Incident List",
      name: "name",
      users: "users",
      incident_count: "incident count",
      tenant: "tenant",
      edit_incidents: "edit incidents",
    },
    swift_account: {
      _name: "swift account",
      id_external: "id external",
      name: "name",
      is_enabled: "is enabled",
      is_monitored: "is monitored",
      retention: "retention",
      brand_id: "brand id",
      language: "language",
      creation_time: "creation time",
      id_parent: "id parent",
    },
    swift_sensor: {
      title: "swift sensor",
      id_external: "id external",
      first_contact_time: "first contact time",
      last_contact_time: "last contact time",
      id_hardware: "id hardware",
      batery_level: "batery level",
      wireless_type: "wireless type",
      sample_rate: "sample rate",
      is_event_based: "is event based",
      id_sensor: "id sensor",
      id_threshold: "id threshold",
      id_unit_measure: "id unit measure",
      id_type_sensor: "id type sensor",
      offset: "offset",
      manufacturer: "manufacturer",
      model: "model",
      hardware_revision: "hardware revision",
      software_revision: "software revision",
      name: "name",
      description: "description",
      latitude: "latitude",
      longitude: "longitude",
      elevation: "elevation",
      signal_strength: "signal strength",
      is_new: "is new",
      ethernet_mac_address: "ethernet mac address",
      wifi_mac_address: "wifi mac address",
      ip_address: "ip address",
      wifi: "wifi",
      ble: "ble",
      cell: "cell",
      gps: "gps",
      radio_gen2: "radio gen2",
      ble_gen3: "ble gen3",
      zigbee: "zegbee",
      zwave: "zwave",
      radio: "radio",
      id_swift_account: "id swift account",
      id_unit: "id unit",
      id_swift_gateway: "id swift gateway",
    },
    command: {
      title: "command | commands",
      name: "name",
      description: "description",
    },
    import_excel: {
      _name: "export",
    },
    user_cameras: {
      camera_proxy_user_is_assigned: "assigned",
      unit: "unit",
      camera_type: "device type",
      cameraID: "cameraID",
      name: "name",
      id_user: "ID user",
      id_account: "ID account",
      assign_all: "assign all cameras",
      remove_all: "remove all cameras",
      assign_all_center: "assign center cameras",
      remove_all_center: "remove center cameras",
    },
    group_cameras: {
      camera_proxy_group_is_assigned: "assigned",
      unit: "unit",
      camera_type: "device type",
      cameraID: "cameraID",
      name: "name",
      id_user: "ID user",
      id_account: "ID account",
      assign_all: "assign all cameras",
      remove_all: "remove all cameras",
      assign_all_center: "assign center cameras",
      remove_all_center: "remove center cameras",
    },
    users_app: {
      title: "users app",
      name: "name",
      first_name: "first name",
      second_name: "second name",
      email: "email",
      phone_number: "phone number",
      photo: "photo",
      height: "height",
      complexion: "complexion",
      lips: "lips",
      nose: "nose",
      eye_size: "eye size",
      skin: "skin",
      face_type: "face type",
      hair_type: "hair type",
      age: "age",
      particular_signs: "particular signs",
      edit: "edit users app",
      add: "add users app",
      reset_password: "reset password",
      cancel: "cancel",
      next: "next",
      new_password: "new password",
      write_the_password_again: "write the password again",
    },
    layout: {
      title: "layout",
    },
    alarm_view: {
      title: "alarm view",
    },
    video_watermarks: {
      title: "Video Watermarks",
    },
    device_tag: {
      title: "device tag",
    },
    chart_incident: {
      title: "incident chart",
    },
    automation: {
      title: "automation",
    },
    alarm_workflow: {
      title: "alarm workflow",
    },
    workflow: {
      add_workflow: "add workflow",
      adit_workflow: "edit workflow",
      workflow: "workflow",
      alarm_status: "alarm status",
      frequency: "enable alarm frequency",
      params: "params",
      data: "data",
      view: "view",
      days: "days",
      start_time: "start time",
      end_time: "end time",
    },
    schedule_task: {
      title: "schedule task",
      frequency: "frequency",
      add_user_views: "Views/Tabs",
    },
    plane: {
      title: "planos",
    },
    shared_tenant: {
      name: "name",
      permissions: "permissions",
      type_alarms: "avaible type alarms",
    },
    iotcomms_master_account: {
      title: "iotcomms master account | iotcomms masters accounts",
      email: "email",
      password: "password",
      token: "token",
      domain: "domain",
      number_devices: "number of devices",
    },
    cronEditor: {
      title: "Custom frequency",
      options: {
        op1: "Every hour(s)",
        op2: "Daily",
        op3: "Weekly",
        op4: "Monthly",
      },
      every: "Every",
      hours: "Hour(s)",
      minutes: "Minute(s)",
      days: "Day(s)",
      day: "Day",
      months: "Month(s)",
      on_minute: "On minute",
      at: "At",
      on_the: "On the",
      day_of_every_month: "day of every month",
      weekdays: {
        sun: "Sunday",
        mon: "Monday",
        tue: "Tuesday",
        wed: "Wednesday",
        thu: "Thursday",
        fri: "Friday",
        sat: "Saturday",
      },
      table: {
        desc: "Description",
      },
      btn_add: "Add",
      btn_update: "Update",
      errors: {
        err1: "An equal expression already exists",
        err2: "Please check the fields",
      },
    },
    scheduleOptions: {
      every_minute: {
        name: "Every minute",
        desc: "",
      },
      every_two_minutes: {
        name: "Every two minutes",
        desc: "",
      },
      every_three_minutes: {
        name: "Every three minutes",
        desc: "",
      },
      every_four_minutes: {
        name: "Every four minutes",
        desc: "",
      },
      every_five_minutes: {
        name: "Every five minutes",
        desc: "",
      },
      every_ten_minutes: {
        name: "Every ten minutes",
        desc: "",
      },
      every_fifteen_minutes: {
        name: "Every fifteen minutes",
        desc: "",
      },
      every_thirty_minutes: {
        name: "Every thirty minutes",
        desc: "",
      },
      hourly: {
        name: "Hourly",
        desc: "",
      },
      hourly_at: {
        name: "Every hour at",
        desc: "",
      },
      every_two_hours: {
        name: "Every two hours",
        desc: "",
      },
      every_three_hours: {
        name: "Every three hours",
        desc: "",
      },
      every_four_hours: {
        name: "Every four hours",
        desc: "",
      },
      every_six_hours: {
        name: "Every six hours",
        desc: "",
      },
      daily_at: {
        name: "Daily at",
        desc: "",
      },
      weekly_on: {
        name: "Every week at",
        desc: "",
      },
      monthly_on: {
        name: "Every month at",
        desc: "",
      },
      last_day_of_month: {
        name: "The last day of each month at",
        desc: "",
      },
      custom: {
        name: "Custom",
        desc: "",
      },
    },
    type_device_interphone: {
      title: "type device interphone | types devices interphones",
      type: "type",
    },
    device_interphone: {
      title: "device interphone | devices interphones",
      ha1: "ha1",
      alarm: "alarm",
      device_id: "number",
      password: "password",
      ivr: "IVR",
    },
    slot_types: {
      [SLOT_TYPES.CAM_LIVE]: "Camera Live",
      [SLOT_TYPES.CAM_PA]: "Pre-Alarm",
      [SLOT_TYPES.CAM_RECORDED]: "Recording",
      [SLOT_TYPES.ACTION_PLAN]: "Action plan",
      [SLOT_TYPES.INFO]: "Alarm information",
      [SLOT_TYPES.MAP]: "Alarm Map",
      [SLOT_TYPES.PLANE]: "Facility Plans",
      [SLOT_TYPES.TAG]: "Tag",
      [SLOT_TYPES.UNITS_MAP]: "Facilities map",
      [SLOT_TYPES.TIMELINE]: "Timeline",
      [SLOT_TYPES.GPS]: "Geolocation",
      [SLOT_TYPES.DETECTION_EVENT]: "Detection events",
      [SLOT_TYPES.INTERPHONE]: "Interphone",
      [SLOT_TYPES.FRAME]: "Web site widget",
      [SLOT_TYPES.CHARTS_SDK]: "Chart widget",
      [SLOT_TYPES.CHARTS_SDK_DATA]: "Chart Data",
      [SLOT_TYPES.FR_TRACKING_MAP]: "Facial recognition map widget",
      [SLOT_TYPES.LPR_TRACKING_MAP]: "Plate recognition map widget",
      [SLOT_TYPES.AR_TABLE_DATA]: "",
      [SLOT_TYPES.WEATHER]: "Weather widget",
      [SLOT_TYPES.FACE_DETECTION]: "Faces detection widget",
      [SLOT_TYPES.WAZE]: "Waze widget",
      [SLOT_TYPES.DETECTION_EVENT]: "Detection widget",
    },
    live: "Live",
    pre_alarm: "Pre-Alarm",
    boolean: {
      y: "Yes",
      n: "No",
    },
    profile: 'Profile',
    logout: 'Logout',
    search: 'Search',
    close: 'Close',
    no_results_found: 'No results found',
    light_mode: "Light mode",
    dark_mode: "Dark mode",
  },
};
