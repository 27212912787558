export default {
  namespaced: true,
  region: {
    title: "estado | estados",
    name: "nombre",
    id: "ID",
    code: "código",
    acronym: "acronimo"
  },
}
